import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
}

export const StaffVouchersSlice = createSlice({
  name: 'StaffVouchers',
  initialState,
  reducers: {
    Staff_Vouchers_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    Staff_Vouchers_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};

    },
    Staff_Vouchers_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_Staff_Vouchers_Check: (state, action) => {
      state.value = {}
      state.error = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const {   Staff_Vouchers_request,  Staff_Vouchers_success,   Staff_Vouchers_failure, Clear_Staff_Vouchers_Check } = StaffVouchersSlice.actions

export default StaffVouchersSlice.reducer