import React, { useEffect, useState } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useStyles } from "./Style";
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded';

const ActivitySlider = (props) => {
    const classes = useStyles();
    const { datalist } = props;
    const [programList, setprogramList] = useState([])
    useEffect(() => {
        if (datalist != null && datalist != undefined ) {
            setprogramList(datalist)
        }
    }, [datalist])

    var settings = {
        dots: false,
        arrows: true,
        infinite: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        cssEase: "linear",
        nextArrow: <NavigateNextRoundedIcon />,
        prevArrow: <NavigateBeforeRoundedIcon />,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 899,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 390,
                settings: {
                    slidesToShow: 2
                }
            }
        ]
    };
    return (
        <>
       {programList?.length>0 &&     <Slider {...settings} className={`${classes.ActivitySlider}`}>

                {programList?.map((data) => {
                    return <div>
                        <Card className="act-slider-card" sx={{ height: '142px', paddingLeft: '10px', paddingRight: '10px' }} title={data?.title}>
                            <CardCover>
                                <img
                                    src={data?.banners[0]?.image_url?data?.banners[0]?.image_url:"https://images.unsplash.com/photo-1542773998-9325f0a098d7?auto=format&fit=crop&w=320&dpr=2 2x"}
                                    srcSet={data?.banners[0]?.image_url?data?.banners[0]?.image_url:"https://images.unsplash.com/photo-1542773998-9325f0a098d7?auto=format&fit=crop&w=320&dpr=2 2x"}
                                    loading="lazy"
                                    alt=""
                                />
                            </CardCover>
                            <CardCover
                                sx={{
                                    background:
                                        'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                }}
                            />
                            <CardContent sx={{ justifyContent: 'flex-end' }}>
                                <h5>{data?.used_count}/{data?.total_count}</h5>
                                <p className='text-truncate'>{data?.title}</p>
                            </CardContent>
                        </Card>
                    </div>
                })}

            </Slider>}
        </>
    )
}

export default ActivitySlider