import { makeStyles } from '@mui/styles';
import ColorTheme from '../../Common/CommonCss/ColorTheme';
export const useStyles = makeStyles({

    MenuIcon: {
        fontSize: "16px !important",
        color: `${ColorTheme.textColor} !important`,
        marginRight: "-10px !important"
    },

    Sidebar: {
        "& .MuiDrawer-paper": {
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            padding: "20px 20px 30px 20px",
        },
        "& .sidebar-container": {
            listStyle: "none",
            padding: "0px",
            margin: "0",
            textAlign: "center",
            "& li": {
                margin: "16px 0",
                "& .btn": {
                    fontFamily: ColorTheme.publicSans,
                    // padding: "8px 24px !important",
                    fontSize: "16px !important",
                    fontWeight: "500 !important",
                    textTransform: "capitalize !important",
                    color: ColorTheme.textColor,
                    background: ColorTheme.lightGrey,
                    width: "100%"
                },
                "& .btn-logout": {
                    color: ColorTheme.dangerColor,
                }
            }
        }
    },


});