import React from 'react'
import {useStyles} from './Style'
import PhoneInHand from '../../Images/phone-in-hand.png'

const PreventDesktop = () => {
    const classes = useStyles();
    const company_data=JSON.parse(localStorage.getItem("company_data"))
  return (
    <>
    <div className={classes.PreventDesktop}>
        <div className='container'>
          <div className='cl'>
            <img src = {PhoneInHand} alt='Not Found!' />
            <h1>{company_data?.app_name}</h1>
            <p>Kindly access the provided URL using a mobile device for an optimal browsing experience.</p>
          </div>
        </div>
    </div>
    </>
  )
}

export default PreventDesktop