import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
}

export const ImportCsvSlice = createSlice({
  name: 'ImportCsv',
  initialState,
  reducers: {
    Import_Csv_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    Import_Csv_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};

    },
    Import_Csv_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_Import_Csv_Check: (state, action) => {
      state.value = {}
      state.error = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const {Clear_Import_Csv_Check, Import_Csv_failure, Import_Csv_success, Import_Csv_request } = ImportCsvSlice.actions

export default ImportCsvSlice.reducer