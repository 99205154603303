import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useStyles } from "./Style";
import Item from './../../Images/slide-1.png';
import { ReactComponent as SliderDotActive } from '../../Images/icons/dot-active.svg'

const SliderHome = ({ eventsList, urlprogram }) => {
  const classes = useStyles();

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    margin: 16,
    centerPadding: 20,
    centerMode: true,
    appendDots: dots => <ul>{dots}</ul>,
    customPaging: i => (
      <button>
        <SliderDotActive />
      </button>
    )
  };
  console.log("vvvvv", eventsList)
  return (
    <>
      {!urlprogram && <Slider {...settings} className={`${classes.HomeSlider} home-slider ${eventsList?.event_banner?.length == 1 ? "single-slide" : ""}`}>

        {eventsList?.event_banner?.map((data) => {
          return <>
            {
              data?.url  ? <>
                <a href={data?.url?.includes("http") ? data?.url : `https://${data?.url}`} target="_blank" rel="noreferrer" className={`${classes.SlideCard} ${classes.SlideCard1}`} >
                  <img src={data?.image_url} alt="" className='cover' />
                </a>

              </> : <>
                <span className={`${classes.SlideCard} ${classes.SlideCard1}`} >
                  <img src={data?.image_url} alt="" className='cover' />

                </span>
              </>
            }


          </>
        })
        }

      </Slider>
      }



      {urlprogram && <Slider {...settings} className={`${classes.HomeSlider} home-slider ${eventsList?.banners?.length == 1 ? "single-slide" : ""}`}>

        {eventsList?.banners?.map((data) => {
          return <div className={classes.SlideCard}>
            <img src={data?.image_url} alt="" className='cover' />
          </div>
        })
        }

      </Slider>
      }
    </>
  )
}

export default SliderHome