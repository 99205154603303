import axios from "axios";
import { APIURL } from "../../../APIHandler/APIURL"
import { Headers } from "../../../APIHandler/HeaderAPI";
import { reloadHandler } from "../../../utlis/utlis";
import { QR_Staff_Generator_failure, QR_Staff_Generator_request, QR_Staff_Generator_success } from "../../Reducer/QrStaffgenrator/QrStaffgenrator";
import { Scan_Activity_failure, Scan_Activity_request, Scan_Activity_success } from "../../Reducer/ScanActivity/ScanActivity";

const abortController = new AbortController();

export const ScanActivityAPI = (payload,setCheckincheck,apiCalledRef) => async (dispatch, getState) => {
   
    if (apiCalledRef.current) {
        return; // If API call has already been made, do nothing
    }
    apiCalledRef.current = true;
    dispatch(Scan_Activity_request())
    setCheckincheck(true)
    // const eventItemId=localStorage.getItem("eventItemId")
    const auth_token = localStorage.getItem("auth_token")
    localStorage.setItem("checkin",true)
  
    try {
        const { data } = await axios.post(`${APIURL.scanactivity}`, payload,{
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
               "event-slug":Headers?.["event-slug"],
                "Content-type": "application/json",
                Authorization: `Bearer ${auth_token}`
            },
            signal: abortController.signal
        })
        dispatch(Scan_Activity_success(data))

    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(Scan_Activity_failure(error?.response?.data))

    }
}
export const cancelScanActivityAPI = () => {
    abortController.abort();
};