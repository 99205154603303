import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
  error:{}
}

export const EventManagerPostSlice = createSlice({
  name: 'EventManagerPost',
  initialState,
  reducers: {
    EventManagerPost_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    EventManagerPost_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};

    },
    EventManagerPost_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_EventManagerPost_Check: (state, action) => {
      state.value = {}
      state.error = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const {EventManagerPost_request, EventManagerPost_success, EventManagerPost_failure, Clear_EventManagerPost_Check } = EventManagerPostSlice.actions

export default EventManagerPostSlice.reducer