import React, { useEffect, useState } from 'react'
import { useStyles } from "./Style";
import Typography from '@mui/joy/Typography';
import Input from '@mui/joy/Input';
import Buttonprimary from '../../Components/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { ForgetPasswordAPI } from '../../redux/Action/ForgetPassword/ForgetPassword';
import Snacbar from '../../Components/Snackbar/Snacbar';
import { Clear_ForgetPassword_Details } from '../../redux/Reducer/ForgetPassword/ForgetPassword';
import Loader from '../../Components/Loader/Loader';
import { useNavigate } from 'react-router-dom';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { Clear_Email_Check } from '../../redux/Reducer/EmailCheck/EmailCheckSlice';
import { EmailCheckAPI } from '../../redux/Action/EmailCheck/EmailCheck';
import SelectInput from '../../Components/SelectInput/SelectInput';

const ForgotPassword = () => {
    const classes = useStyles();
    const company_data = JSON.parse(localStorage.getItem("company_data"));
    const dispatch = useDispatch();
    const ForgetPasswordSlice = useSelector(state => state?.ForgetPasswordSlice)
    const [Forgotemail, setforgetemail] = useState("");
    const [Forgotemailerror, setforgetemailerror] = useState(false);
    const [forgotemailsucess, setforgetemailsuccess] = useState(false);
    const [Forgotemailsuccess, setforgetemailsucess] = useState(false);
    const [emailsuccess, setemailsuccess] = useState(false);
    const EmailCheckSlice = useSelector(state => state?.EmailCheckSlice);
    const [eventList, seteventList] = useState("");
    const [open, setOpen] = useState(false)
    const [emailerrormsg, setemailerrormsg] = useState("");
    const EventIDSlice = useSelector(state => state?.EventIDSlice?.value);

    const navigate = useNavigate();
    // ForgetPasswordAPI
    const handleforgetEmail = (e) => {
        e.preventDefault()
        if (emailsuccess == false) {
            const obj = {
                email: Forgotemail
            }
            dispatch(EmailCheckAPI(obj))
        }
        else if (emailsuccess == true) {
            const formData = new FormData();
            formData.append('email', Forgotemail);
            formData.append('event_id', EventIDSlice);
            // const obj = {
            //     email: Forgotemail
            // }
            dispatch(ForgetPasswordAPI(formData))

        }


    }
    useEffect(() => {

        if (ForgetPasswordSlice?.error?.message) {
            setforgetemailerror(true)
            setforgetemailsuccess(false)
            setTimeout(() => {
                dispatch(Clear_ForgetPassword_Details())
            }, 3000)
        } else if (ForgetPasswordSlice?.value?.message) {
            // navigate("/")
            setforgetemailsuccess(true)
        }
    }, [ForgetPasswordSlice])
    useEffect(() => {
        if (eventList?.length == 1) {
            const formData = new FormData();
            formData.append('email', Forgotemail);
            formData.append('event_id', eventList[0]?.event_id);
            // const obj = {
            //     email: Forgotemail
            // }
            dispatch(ForgetPasswordAPI(formData))
        }
    }, [eventList])
    useEffect(() => {
        if (EmailCheckSlice?.value?.data?.length > 0) {
            setemailsuccess(true)
            seteventList(EmailCheckSlice?.value?.data)
            // localStorage.setItem("roles", EmailCheckSlice?.value?.data[0]?.role)
        } else if (EmailCheckSlice?.error?.message) {
            // localStorage.removeItem("roles")
            setOpen(true)
            setemailerrormsg(EmailCheckSlice?.error?.message)
            setTimeout(() => {
                dispatch(Clear_Email_Check())
            }, 3000)
        }
    }, [EmailCheckSlice])
    useEffect(() => {
        if (ForgetPasswordSlice?.value?.message) {
            setforgetemailsucess(true);
            setTimeout(() => {
                dispatch(Clear_ForgetPassword_Details())
            }, 3000)
        }
    }, [ForgetPasswordSlice])
    const handleClick = () => {
        navigate("/")
    }
useEffect(()=>{
dispatch(Clear_Email_Check())
setemailsuccess(false)
},[])
    // console.log("ForgetPasswordSlice", ForgetPasswordSlice)
    return (
        <>
            {
                (ForgetPasswordSlice?.error?.message) && (
                    <Snacbar
                        open={Forgotemailerror}
                        setOpen={setforgetemailerror}
                        messagetype={"error"}
                        message={(ForgetPasswordSlice?.error?.message)}
                    />

                )
            }
            {
                (EmailCheckSlice?.error?.message ) && (
                    <Snacbar
                        open={open}
                        setOpen={setOpen}
                        messagetype={"error"}
                        message={(EmailCheckSlice?.error?.message )}
                    />

                )
            }
            {
                (ForgetPasswordSlice?.value?.message) && (
                    <Snacbar
                        open={Forgotemailsuccess}
                        setOpen={setforgetemailsucess}
                        messagetype={"success"}
                        message={(ForgetPasswordSlice?.value?.message)}
                    />

                )
            }
            {
                ForgetPasswordSlice?.loading == true && (
                    <Loader />
                )
            }
            <div className={`${classes.Login} main pb-0`} style={{ backgroundImage: `url(${company_data?.banner})` }}>
                <div className={classes.header}>
                    <img src={company_data?.banner} alt="" className='cover' />
                </div>
                <button className='btn btn-back' onClick={() => {
                     dispatch(Clear_Email_Check())
                    navigate(-1)}}><KeyboardBackspaceOutlinedIcon />Back</button>
                <div className={`${classes.loginWrapper} login-card`}>
                    {/* {forgotemailsucess == false && <form onSubmit={(e) => handleforgetEmail(e)} >
                        <Typography level="h2">Forgot Password</Typography>
                        <div className="form-group">
                            <label className={`${classes.Label} form-label`}>Email Address</label>
                            <Input type="email" placeholder="Email address" className={`${classes.Input} form-input`} value={Forgotemail} onChange={(e) => {
                                setforgetemail(e.target.value)
                            }} />
                        </div>
                        {emailsuccess == true && (
                            <>
                                {
                                    eventList?.length > 1 && (
                                        <>
                                            <div className="form-group">
                                                <label className={`${classes.Label} form-label`}>Select Event</label>


                                                <SelectInput eventList={eventList} />

                                            </div>
                                        </>

                                    )
                                }


                                
                            </>
                        )}
                        <Buttonprimary label={'Next'} type="submit" />
                    </form>} */}
                    {forgotemailsucess == false && <form onSubmit={(e) => handleforgetEmail(e)} >
                        <Typography level="h2">Forgot Password</Typography>
                        <div className="form-group">
                            <label className={`${classes.Label} form-label`} style={{marginTop:"20%",textAlign:"center",lineHeight:"25px"}}>Please contact {company_data?.email} or Event staff if you forget password.
                            </label>
                          
                        </div>
                       
                        <Buttonprimary disabledcheck={true} label={'Back to login'} type="button" handleClick={() => handleClick()} />

                    </form>}
                    {forgotemailsucess == true && <div className='instruction-card'>
                        <h2 className="h2">Email sent</h2>
                        <p>An email with instructions to reset your password has been sent to your registered email account.</p>
                        <p>If you do not receive the email within the next few minutes, please check your account's Junk/Spam folder.</p>
                        {/* <p>Didn't receive the email? <button className={classes.BtnLink}>Resend again</button></p> */}
                        <Buttonprimary disabledcheck={true} label={'Back to login'} type="button" handleClick={() => handleClick()} />
                    </div>}
                </div>
            </div>
        </>
    )
}

export default ForgotPassword