import axios from "axios";
import { APIURL } from "../../../../APIHandler/APIURL"
import { Headers } from "../../../../APIHandler/HeaderAPI";
import { reloadHandler } from "../../../../utlis/utlis";
import { Admin_Sample_Export_failure, Admin_Sample_Export_request, Admin_Sample_Export_success } from "../../../Reducer/Admin/Sampleexport/SampleExportSlice";
import { Import_Csv_failure, Import_Csv_request, Import_Csv_success } from "../../../Reducer/Admin/Sampleexport/ImportCSVSlice";
import { PartcipantsAPI } from "../Partcipants/Partcipants";

export const AdminSampleImportAPI = (payload,id) => async (dispatch, getState) => {
    // const eventItemId=localStorage.getItem("eventItemId")
    const auth_token = localStorage.getItem("auth_token")
    dispatch(Import_Csv_request())
    const admineventid=localStorage.getItem("admineventid")
    // console.log("admineventid",admineventid)
    try {
        const { data } = await axios.post(`${APIURL.adminsampleimport}/${id?id:admineventid}`, payload,{
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
                'Content-Type': 'multipart/form-data',
               "event-slug":Headers?.["event-slug"],
                Authorization: `Bearer ${auth_token}`
            },
        })
        // downloadCSV(data)
        dispatch(Import_Csv_success(data))
        // if(id){

        //     dispatch(PartcipantsAPI(id))
        // }else{

        //     dispatch(PartcipantsAPI(admineventid))
        // }
    } catch (error) {
        reloadHandler(error?.response?.status)
        if(id){

            dispatch(PartcipantsAPI(id))
        }else{

            dispatch(PartcipantsAPI(admineventid))
        }
        dispatch(Import_Csv_failure(error?.response?.data))
    }
}

