import React from 'react'
import { useStyles } from "./Style"
import { Oval } from "react-loader-spinner";
import AdminTheme from '../../Common/AdminTheme';
const AdmLoader = () => {
    const classes = useStyles();
  return (
    <div className={`${classes.loaderContainer} loader`}>
    <div className={`${classes.loaderContainerInner} loader-inner`}>
      <Oval
        ariaLabel="loading-indicator"
        height={40}
        width={40}
        strokeWidth={5}
        strokeWidthSecondary={5}
        color={`${AdminTheme.themeColor}`}
        secondaryColor={`${AdminTheme.white}`}
      />
    </div>
  </div>
  )
}

export default AdmLoader
