import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
  error:{}
}

export const SetPasswordSlice = createSlice({
  name: 'SetPassword',
  initialState,
  reducers: {
    SetPassword_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    SetPassword_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};

    },
    SetPassword_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_SetPassword_Details: (state, action) => {
      state.value = {}
      state.error = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const { SetPassword_request, SetPassword_success, SetPassword_failure, Clear_SetPassword_Details } = SetPasswordSlice.actions

export default SetPasswordSlice.reducer