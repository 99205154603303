import { makeStyles } from '@mui/styles';
import AdminTheme from '../../Common/AdminTheme';

export const useStyles = makeStyles({

    IncDecButtonRow: {
        height: "38px",
        background: AdminTheme.inputBg,
        display: "flex",
        alignItems: "center",
        maxWidth: "114px",
        borderRadius: "4px",
        padding: "4px",
        "& .MuiButtonBase-root, & .MuiButtonBase-root:hover": {
            width: "32px !important",
            minWidth: "32px !important",
            height: "32px !important",
            borderRadius: "6px",
            padding: "0 !important",
            background: AdminTheme.inputBg,
            color: "#D6D6E0"
        },
        "& .MuiButtonBase-root:nth-child(2)": {
            flex:"1",
            background: "transparent",
        },
        "& .MuiSvgIcon-root": {
            color: AdminTheme.btnBgColor
        },

        "&[Background='transparent']":{
            background: "transparent",
        },

        "&[Varient='small']": {
            padding: "2px",
            "& .MuiButtonBase-root, & .MuiButtonBase-root:hover": {
                width: "26px !important",
                minWidth: "26px !important",
                height: "26px !important",
                color: "#434349"
            },
        }
    },
    
});