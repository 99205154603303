import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: [],
  loading: false,
}

export const ImportExportSoketSlice = createSlice({
  name: 'ImportExportSoket',
  initialState,
  reducers: {
   
    ImportExportSoket_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      
    }
  }
})

// Action creators are generated for each case reducer function
export const {ImportExportSoket_success } = ImportExportSoketSlice.actions

export default ImportExportSoketSlice.reducer