import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useStyles } from "./Style";
import { useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import AddLinkIcon from '@mui/icons-material/AddLink';
import Input from '@mui/joy/Input';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import IconButton from '@mui/material/IconButton';
import { useDispatch } from "react-redux";
import { DeleteActivityBannerUrlAPI, DeleteBannerUrlAPI, UpdateBannerUrlAPI } from "../../../redux/Action/UpdateBannerUrl/UpdateBannerUrl";
import { DeleteLoginBannerAPI } from "../../../redux/Action/DeleteLoginBanner/DeleteLoginBanner";

const fileTypes = ["JPG", "PNG", "GIF"];

const FileInputLogin = (props) => {
    const classes = useStyles();
    const { FileIcon, editgetimage, Text, editopen, multiimage, setmultimage, setmultimageerror, setmultimagelength } = props;

    const [file, setFile] = useState(null);
    const [filePreview, setFilePreview] = useState(null);
    const [fileEdit, setFileEdit] = useState(null);
    const [showURLInput, setShowURLInput] = useState(false);
    const [inputTitle, setInputTitle] = useState("");

    const editactivityid = localStorage.getItem("editactivityid")
    const dispatch = useDispatch()
    const { id } = useParams();
    const url = window.location.href
    const urlevent = url?.includes("events/create-event/event-information") ? true : false
    const archive = JSON.parse(localStorage.getItem("archive"));

    useEffect(() => {
        if (editgetimage!="" && editgetimage) {
            setFileEdit(editgetimage);
            setFilePreview(editgetimage);
        }
    }, [editgetimage]);

    const handleChange = (file) => {
        setFile(file);
        setFilePreview(URL.createObjectURL(file));
        setmultimage(file);
        setmultimageerror(false);
    };

    const handleClear = () => {
        const obj = {
            id: fileEdit?.id,
            banner_url: inputTitle,
            event_id: id
        }
        dispatch(UpdateBannerUrlAPI(obj, id));
        setShowURLInput(false);
        setInputTitle("");
    };

    const handleClearInput = () => {
        setShowURLInput(false);
        setInputTitle("");
    };

    const handleDelete = () => {
        if (id) {

            dispatch(DeleteLoginBannerAPI(id, fileEdit?.id));
        }

        setFile(null);
        setFilePreview(null);
        setFileEdit(null);
        setmultimage([]);
    };

    return (
        <>
            <div className={classes.FileInputBoxMultiple}>
                <div className={classes.FileInputBox}>
                    <div className={classes.FileInputLabel}>
                        <img src={FileIcon} alt="" className={classes.FileInputIcon} />
                        <p>{Text}</p>
                    </div>
                    <FileUploader
                        handleChange={handleChange}
                        name="file"
                        types={fileTypes}
                        multiple={false}
                        disabled={archive === true}
                    />
                </div>
                {filePreview && (
                    <div className="after-uploaded-file">
                        <div className="img-container img-with-add-url-btn">
                            {archive !== true && (
                                <IconButton aria-label="delete" size="small" className="btn-remove" onClick={handleDelete}>
                                    <DeleteOutlineIcon fontSize="small" />
                                </IconButton>
                            )}
                            <div className="rw">
                                <div className="cl">
                                    <div className="uploaded-img-card">
                                        <img src={filePreview} alt="Uploaded" />
                                    </div>
                                </div>
                                <div className="cl-right">
                                    {fileEdit?.url && <h6>{fileEdit?.url?.slice(0, 50)}</h6>}

                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default FileInputLogin;
