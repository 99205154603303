import React from 'react'
import {useStyles} from './Style'
import ButtonTab from '../ButtonTab/ButtonTab';
// import EventInfoIcon from '../../../Images/admin/event-info-icon.svg'
import { ReactComponent as EventInfoIcon } from '../../../Images/admin/event-info-icon.svg'
import { ReactComponent as AddSpeakerIcon } from '../../../Images/admin/add-speaker-icon.svg'
import { ReactComponent as AddProgramIcon } from '../../../Images/admin/add-program-icon.svg'
import { ReactComponent as RegistrationImportIcon } from '../../../Images/admin/registration-import-icon.svg'
import { ReactComponent as Arrow } from '../../../Images/admin/arrow-right.svg'

const TabHeader = (props) => {
    const classes = useStyles();
    const {active} = props;
  return (
    <>
    <div className={`${classes.TabHeader} tabheader`}>
      <div className={`${active} inner`}>
        <ButtonTab label="1. Event Information" icon={<EventInfoIcon/>} className="btn-info" />
        <div className='arrow arrow-1'><Arrow /></div>
        <ButtonTab label="2. Add Speaker" icon={<AddSpeakerIcon/>} className="btn-speaker" />
        <div className='arrow arrow-2'><Arrow /></div>
        <ButtonTab label="3. Add Program Activities" icon={<AddProgramIcon/>} className="btn-prog-activities" />
        <div className='arrow arrow-3'><Arrow /></div>
        <ButtonTab label="4. Add Station" icon={<AddProgramIcon/>} className="btn-staff" />
        <div className='arrow arrow-4'><Arrow /></div>
        <ButtonTab label="5. Registration Import" icon={<RegistrationImportIcon/>} className="btn-reg-import" />
      </div>
    </div>
    </>
  )
}

export default TabHeader