import React from 'react'
import { useStyles } from "./Style"
import ProfilePicture from "../../../Images/admin/header.png"
import ButtonSecondary from '../ButtonSecondary/ButtonSecondary';
import ButtonPrimary from '../ButtonPrimary/ButtonPrimary';
import { useDispatch } from 'react-redux';
import { EditSpeakerAPI } from '../../../redux/Action/Admin/Speakers/EditSpeakerss';
import { DeleteSpeakerAPI } from '../../../redux/Action/Admin/Speakers/DeleteSpeaker';
import { GetEditActivitiesAPI } from '../../../redux/Action/Admin/CreateActitvites/GetEditActivity';
import { GetIdSpeakerAPI } from '../../../redux/Action/Admin/Speakers/GetIdSpeaker';
import { Link as Linkscroll } from "react-scroll";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import { useParams } from 'react-router-dom';

const SpeakerCard = (props) => {
    const { image, description, event, job_title, name, setopenform, setcheckeditoradd, seteditid } = props;
    const classes = useStyles();
    const dispatch = useDispatch()
    const {id}=useParams();
  const archive = JSON.parse(localStorage.getItem("archive"));

    //     const handleEdit=(id)=>{
    // dispatch(EditSpeakerAPI(obj,id))
    //     }
    const handleDelete = (ids) => {
        dispatch(DeleteSpeakerAPI(ids,id))
    }

    const handleEdit = (id) => {
        setopenform(true)
        dispatch(GetIdSpeakerAPI(id))
        setcheckeditoradd(true)
        seteditid(id)

    }
    return (
        <>
            <div className={`${classes.SpeakerDecCard} speaker-dec-card`}>
                <div className='sdc-inner'>
                    <div className={classes.Pic}>
                        <img src={image ? image : ProfilePicture} className='cover' alt='' />
                    </div>
                    <div className={classes.SDCRight}>
                        <div className='title-rw'>
                            <h4 className='text-truncate'>{name?.slice(0,40)}</h4>
                            {archive!=true && <div className='actions'>
                            <Linkscroll
                                activeClass="active"
                                to="editspeaker"
                                spy={true}
                                smooth={true}
                                offset={50}
                                duration={300}
                            >
                                <IconButton aria-label="Edit" onClick={(id) => handleEdit(event)}>
                                    <EditNoteRoundedIcon />
                                </IconButton>
                            </Linkscroll>
                            <IconButton aria-label="Delete" onClick={(id) => handleDelete(event)}>
                                <DeleteIcon />
                            </IconButton>
                            </div>}
                        </div>
                        <h6>{job_title} </h6>
                        
                        <div className='dec'
                        dangerouslySetInnerHTML={{
                                __html: description?.slice(0,1000)
                                    }}
                        ></div>
                    </div>
                </div>
                {/* <div className={classes.SDCFooter}>
                    <Linkscroll
                        activeClass="active"
                        to="editspeaker"
                        spy={true}
                        smooth={true}
                        offset={50}
                        duration={300}
                    >

                        <ButtonSecondary label="Edit" handleClick={(id) => handleEdit(event)} />
                    </Linkscroll>
                    <ButtonPrimary label="Delete" handleClick={(id) => handleDelete(event)} />
                </div> */}
            </div>
        </>
    )
}

export default SpeakerCard