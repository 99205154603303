import { makeStyles } from '@mui/styles';
import ColorTheme from '../../Common/CommonCss/ColorTheme';
export const useStyles = makeStyles({

    // ParticipantSlider:{
    //     marginLeft: "25px",
    //     paddingTop: "20px",
    //     "& li":{
    //         display: "none"
    //     },
    //     "@media(max-width: 340px)": {
    //         marginLeft: "15px",
    //     }
    // }

    ParticipantSlider:{
        padding: "20px 20px",
        display: "flex",
        overflowX: "auto",
        "& .participant-card": {
            flexShrink: "0",
            minWidth: "90px",
            maxWidth: "120px",
        },
        "&::-webkit-scrollbar": {
            height: "0px",
            opacity: "0",
            visibility: "hidden"
        },
        "@media(max-width: 340px)": {
            marginLeft: "15px",
        }
    },
    ReadMore: {
        fontSize: "14px !important",
        fontWeight: "500 !important",
        lineHeight: "16px !important",
        fontFamily: `${ColorTheme.publicSans} !important`,
        color: ColorTheme.textColor,
        paddingLeft:'20px !important'
    },
    Para: {
        fontSize: "16px !important",
        fontWeight: "400 !important",
        lineHeight: "24px !important",
        fontFamily: `${ColorTheme.publicSans} !important`,
        color: ColorTheme.textColor,
        width:"90%",
        marginLeft:"5%",
        wordBreak:"break-all",
        opacity: "0.6",
        marginBottom: "10px !important",
        "& p": {
            marginTop: "0"
        },
    },
    Description: {
        marginBottom: "20px!important",
    },
});