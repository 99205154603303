import React from 'react'
import { useStyles } from "./Style";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';


const AdminLogout = (props) => {
  const { setOpen, open } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  //   const handleClickOpen = () => {
  //     setOpen(true);
  //   };

  const handleClose = () => {
    setOpen(false);
  };
  const handleLogout = () => {
    localStorage.clear();
    localStorage.removeItem("roles")
    navigate("/")
    window.location.reload()

  }


  return (
    <>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-description"
        className={classes.AdminLogOutPopUp}
      >
        <DialogTitle>Log Out</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-description">
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={`${classes.Btn} ${classes.BtnCancel}`} onClick={handleClose}>Cancel</Button>
          <Button autoFocus className={`${classes.Btn} ${classes.BtnLogout}`} onClick={() => {
            handleLogout()
          }}>Log out</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AdminLogout