import React, { useEffect, useState } from 'react';
import Header from '../../Components/Header/Header';
import SliderHome from '../../Components/Slider/Slider';
import { useStyles } from "./Style";
import { Grid } from '@mui/joy';
import Typography from '@mui/material/Typography';
import { CommonStyle } from '../../Common/CommonCss/CommonStyle';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ColorTheme from '../../Common/CommonCss/ColorTheme';
import { Stack } from '@mui/system';
import CheckIcon from './../../Images/icons/check-success-icon.svg'
import ParticipantSlider from '../../Components/ParticipantSlider/ParticipantSlider';
import Buttonprimary from '../../Components/Button/Button';
import MapImg from '../../Images/map.png'
import { useDispatch, useSelector } from 'react-redux';
import { EventDetailsAPI } from '../../redux/Action/EventDetails/EventDetails';
import Loader from '../../Components/Loader/Loader';
import { ProgramDetailsAPI } from '../../redux/Action/ProgramDetails/ProgramDetails';
import { useParams } from 'react-router';
import { ProgramQrGenerateAPI } from '../../redux/Action/programqrgenrate/ProgramQrGenerate';
import Button from '@mui/material/Button';
import { PDFBox } from '../../Components/PDFBox/PDFBox';

const AboutEvent = () => {
    const classes = useStyles();
    const eventItemId = localStorage.getItem("eventItemId")
    const dispatch = useDispatch();
    const [eventsList, seteventList] = useState([])
    const userdetails = JSON.parse(localStorage.getItem("userdetails"));
    const url = window.location.href;
    const { id } = useParams()
    const [eventdata, seteventdata] = useState([])
    const urlprogram = url?.includes("/program-event") ? true : false
    const ProgramDetailsSlice = useSelector(state => state?.ProgramDetailsSlice)
    const EventDetailsSlice = useSelector(state => state?.EventDetailsSlice)

    useEffect(() => {
        if (EventDetailsSlice?.value?.data != undefined && EventDetailsSlice?.value?.data != null && userdetails != undefined && userdetails != null && !urlprogram) {

            seteventList(EventDetailsSlice?.value?.data ? EventDetailsSlice?.value?.data : userdetails?.data)
        }
        if (ProgramDetailsSlice?.value?.data != undefined && ProgramDetailsSlice?.value?.data != null && userdetails != undefined && userdetails != null && urlprogram) {

            seteventList(ProgramDetailsSlice?.value?.data ? ProgramDetailsSlice?.value?.data : [])
        }

    }, [EventDetailsSlice, ProgramDetailsSlice])
    useEffect(() => {
        if (urlprogram) {
            dispatch(ProgramDetailsAPI(id))
        } else {

            dispatch(EventDetailsAPI(eventItemId))
            dispatch(ProgramQrGenerateAPI(eventItemId))
        }
    }, [])

    // console.log("id", id)
    const month = {
        "01": "Jan",
        "02": "Feb",
        "03": "March",
        "04": "April",
        "05": "May",
        "06": "June",
        "07": "July",
        "08": "Aug",
        "09": "Sept",
        "10": "Oct",
        "11": "Nov",
        "12": "Dec"
    }
    // console.log("ProgramDetailsSlice", ProgramDetailsSlice)


    const [truncate, setTruncate] = useState("text-truncate-4");
    const [readcheck, setreadcheck] = useState(true);
    const [text, setText] = useState("Read more");
    const onClickChange = () => {
        setreadcheck(!readcheck)
        // var currentVal = truncate==="text-truncate-4" ? "" : "text-truncate-4";
        // setTruncate(currentVal);
        var currentText = text === "Read more" ? "Read less" : "Read more";
        setText(currentText)
    }
    const convertOembedToIframe = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const figureTags = doc.querySelectorAll('figure');


        figureTags.forEach(figure => {
            figure.style.margin = '0';
            const oembedTags = doc.querySelectorAll('oembed');
            oembedTags.forEach(oembed => {
               
                const url = oembed.getAttribute('url');

                // Convert the YouTube URL to an embed URL
                const embedUrl = url.replace('watch?v=', 'embed/').split('&')[0];

                // Create an <iframe> element
                const iframe = document.createElement('iframe');
                iframe.setAttribute('src', embedUrl);
                iframe.setAttribute('frameborder', '0');
                iframe.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
                iframe.setAttribute('allowfullscreen', '');
                iframe.style.border = 'none';
                iframe.style.margin = '0';
                iframe.style.width = '100%';
                iframe.style.height = '100%';

                // Replace the <oembed> tag with the <iframe> tag
                oembed.parentNode.replaceChild(iframe, oembed);
                // }
            });
        });

        return doc.body.innerHTML;
    };
    const newContent = convertOembedToIframe(eventsList?.description);

    return (
        <>
            {
                EventDetailsSlice?.loading == true && (
                    <Loader />
                )
            }
            {
                ProgramDetailsSlice?.loading == true && (
                    <Loader />
                )
            }
            <div className='main'>
                <Header EventTitle={urlprogram ? userdetails?.data?.name : "About Event"} />
                <SliderHome eventsList={eventsList} urlprogram={urlprogram} />
                <div className='container'>
                    <div className={classes.AboutEventCard}>
                        <Typography variant="h2" sx={CommonStyle.TitleStyle} >{urlprogram ? eventsList?.title : eventsList?.name}</Typography>
                        {/* <div className={classes.Status}><img src={CheckIcon} alt="" />Included in your ticket</div> */}
                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                            spacing={2}
                            sx={{ flexGrow: 1, marginTop: '20px' }}
                        >
                            <Grid className={classes.ColDate} xl={2} lg={3} md={4} sx={6}>
                                <Stack direction="row" sx={{ alignItems: 'center' }}>
                                    <CalendarMonthIcon sx={{ color: ColorTheme.textColor, fontSize: '12px' }} />
                                    <Typography variant="h5">Start Date</Typography>
                                </Stack>
                                <Typography variant="h6">{eventsList?.start_date?.split("-")[2]} {month[eventsList?.start_date?.split("-")[1]]}, {eventsList?.start_date?.split("-")[0]}</Typography>
                            </Grid>
                            <Grid className={classes.ColDate} xl={2} lg={3} md={4} sx={6}>
                                <Stack direction="row" sx={{ alignItems: 'center' }}>
                                    <CalendarMonthIcon sx={{ color: ColorTheme.textColor, fontSize: '12px' }} />
                                    <Typography variant="h5">End Date</Typography>
                                </Stack>
                                <Typography variant="h6">{eventsList?.end_date?.split("-")[2]} {month[eventsList?.end_date?.split("-")[1]]}, {eventsList?.end_date?.split("-")[0]}</Typography>
                            </Grid>
                        </Grid>

                        <div className={classes.Description}>
                            <div className={`${classes.Para}`}
                                dangerouslySetInnerHTML={{
                                    __html: readcheck ? newContent?.slice(0, 190) + "..." : newContent,
                                }}
                            />
                            {eventsList?.description?.length > 190 &&
                                <span className={classes.ReadMore} onClick={() => onClickChange()}>{text}</span>
                            }
                        </div>




                        {(eventsList?.pdf != "" && eventsList?.pdf != undefined && eventsList?.pdf != null) &&
                            <PDFBox eventsList={eventsList?.pdf} />}

                    </div>
                </div>

                {eventsList?.organiser?.length > 0 && (
                    <>

                        <ParticipantSlider organiserdata={eventsList?.organiser} />

                    </>
                )
                }

                {/* <div className='container'> */}
                {/* <div className={classes.MapCard}>
                        <img src={MapImg} alt='' className='cover' />
                    </div>

                    <Buttonprimary label={'Get direction'} />
                </div> */}
            </div>
        </>
    )
}

export default AboutEvent