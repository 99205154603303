import { makeStyles } from '@mui/styles';
import ColorTheme from '../../Common/CommonCss/ColorTheme';
export const useStyles = makeStyles({   

    PDFContainer: {
        padding: "8px 30px 8px 10px",
        borderRadius: "8px",
        border: "1px solid",
        borderColor: ColorTheme.borderColor,
        display: "inline-flex",
        alignItems: "center",
        textDecoration: "none",
        "& .icon": {
            marginRight: "10px",
            height: "24px",
            "& .icn": {
                color: ColorTheme.orange,
            }
        },
        "& .text": {
            flex: "1",
            fontSize: "14px",
            lineHeight: "18px",
            color: ColorTheme.textColor,
            fontFamily: ColorTheme.publicSans,
            fontWeight: "500"
        }
    }
    
});