import axios from "axios";
import { APIURL } from "../../../../APIHandler/APIURL"
import { Headers } from "../../../../APIHandler/HeaderAPI";
import { reloadHandler } from "../../../../utlis/utlis";
import { Admin_Create_Event_failure, Admin_Create_Event_request, Admin_Create_Event_success } from "../../../Reducer/Admin/CreateEvents/CreateEvents";
import { Get_Activites_failure, Get_Activites_request, Get_Activites_success } from "../../../Reducer/Admin/CreateActivities/GetActivitiesSlice";
import { ArchiveEvent_failure, ArchiveEvent_request, ArchiveEvent_success } from "../../../Reducer/ArchiveEvent/ArchiveEventSlice";

export const ArchiveEvent = (page) => async (dispatch, getState) => {
    // const eventItemId=localStorage.getItem("eventItemId")
    const auth_token = localStorage.getItem("auth_token")
    dispatch(ArchiveEvent_request())
    try {
        const { data } = await axios.get(`${APIURL.archive}?page=${page?page:1}`,{
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
               "event-slug":Headers?.["event-slug"],
                "Content-type": "application/json",
                Authorization: `Bearer ${auth_token}`
            },
        })
        dispatch(ArchiveEvent_success(data))
    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(ArchiveEvent_failure(error?.response?.status))
    }
}


  