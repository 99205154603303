import axios from "axios";
import { APIURL } from "../../../APIHandler/APIURL"
import { Headers } from "../../../APIHandler/HeaderAPI";
import { reloadHandler } from "../../../utlis/utlis";
import { Vouchers_failure, Vouchers_request, Vouchers_success } from "../../Reducer/Vouchers/VouchersSlice";
import { UserAttendace_failure, UserAttendace_request, UserAttendace_success } from "../../Reducer/UserAttendance/UserAttendanceSlice";

export const UserAttendanceAPI = (payload) => async (dispatch, getState) => {
    dispatch(UserAttendace_request())
    const eventItemId=localStorage.getItem("eventItemId")
    const user_id=localStorage.getItem("user_id")
    const auth_token = localStorage.getItem("auth_token")
  
    try {
        const { data } = await axios.get(`${APIURL.attendancecheck}/${eventItemId}/${user_id}`, {
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
               "event-slug":Headers?.["event-slug"],
                "Content-type": "application/json",
                Authorization: `Bearer ${auth_token}`
            },
        })
        dispatch(UserAttendace_success(data))

    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(UserAttendace_failure(error?.response?.data))

    }
}