import React from 'react'
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import { useStyles } from "./Style";
import QR from '../../../Images/admin/qr.svg'
import ButtonWithIcon from '../../Components/ButtonWithIcon/ButtonWithIcon'
import { ReactComponent as PrintIcon } from '../../../Images/admin/file-plus.svg'

const QRCode = (props) => {
  const classes = useStyles();
  const {setis_qr_enable,setis_qr_enableerror,is_qr_enable}=props;
  const handleClick = (e) => {
    // console.log("vikalp", e.target.value)
    setis_qr_enable(e.target.value)
    setis_qr_enableerror(false)
  }
  return (
    <>
      <div className={classes.QRRow}>
        <FormControl className={classes.RadioButtonCard} sx={{ width: "100%" }}>
          <h6>QR code</h6>
          <RadioGroup row onClick={(e) => handleClick(e)}>
            <FormControlLabel className={classes.RadioButtonText} value="1" control={<Radio className={classes.RadioButtonMark} />} label="Enable" labelPlacement="start" checked={is_qr_enable==1?true:false}/>
            <FormControlLabel className={classes.RadioButtonText} value="0"  control={<Radio className={classes.RadioButtonMark}  />} label="Disable" labelPlacement="start" checked={is_qr_enable==0?true:false}/>
          </RadioGroup>
        </FormControl>

        {/* <div className={classes.QRCodeImage}>
        <img src={QR} alt='QR Code' />
      </div> */}

        {/* <ButtonWithIcon label="Print" icon={<PrintIcon/>} /> */}
      </div>
    </>
  )
}

export default QRCode