import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
}

export const EventDetailsSlice = createSlice({
  name: 'EventDetails',
  initialState,
  reducers: {
    Event_Details_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    Event_Details_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};

    },
    Event_Details_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_Event_Details: (state, action) => {
      state.value = {}
      state.error = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const { Event_Details_request, Event_Details_success, Event_Details_failure, Clear_Event_Details } = EventDetailsSlice.actions

export default EventDetailsSlice.reducer