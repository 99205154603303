import ColorTheme from "../../Common/CommonCss/ColorTheme";


export const Style = {
    Header: {
        padding: '15px 20px',
        alignItems: 'center', 
        justifyContent: 'space-between',
        "& .lft": {
            marginLeft: "-5px",
        },
        "@media(max-width:340px)": {
            padding: '15px 15px', 
        }
    },
    HeaderTitle :{
        fontSize: "18px",
        fontWeight: "600",
        lineHeight: "22px",
        color: ColorTheme.textColor,
        fontFamily: ColorTheme.publicSans,
        marginTop: "0px",
        marginBottom: "0px",
        textTransform: "capitalize",
        "@media(min-width:576px)": {
            fontSize: "24px",
            lineHeight: "28px",
        }
    },
}