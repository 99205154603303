import React, { useEffect, useState } from 'react'
import AdminHeader from '../../Components/Header/Header'
import ButtonTab from '../../Components/ButtonTab/ButtonTab'
import EventInfoIconActive from '../../../Images/admin/event-info-icon-active.svg'
import AddProgramIcon from '../../../Images/admin/add-program-icon.svg'
import RegistrationImportIcon from '../../../Images/admin/registration-import-icon.svg'
import { ReactComponent as Arrow } from '../../../Images/admin/arrow-right.svg'
import { ReactComponent as ArrowActive } from '../../../Images/admin/arrow-right-active.svg'
import Input from '@mui/joy/Input';
import { useStyles } from "../../Common/FormStyle"
import Divider from '@mui/joy/Divider';
import TextEditor from '../../Components/TextEditor/TextEditor'
import Grid from '@mui/joy/Grid'
import DateInput from '../../Components/DatePicker/DateInput'
import TimeInput from '../../Components/TimePicker/TimeInput'
import TimeZone from '../../Components/TimeZone/TimeZone'
import { Helmet } from 'react-helmet'
import ButtonPrimary from '../../Components/ButtonPrimary/ButtonPrimary'
import { ReactComponent as EventIcon } from '../../../Images/admin/event-icon.svg'
import { useDispatch, useSelector } from 'react-redux'
import { AdminSampleExportAPI } from '../../../redux/Action/Admin/SampleExport/SampleExport'
import { slugify, supplyDateFormat } from '../../../utlis/utlis'
import { AdminCreateEventAPI } from '../../../redux/Action/Admin/CreateEvent/CreateEventSlice'
import { getAllCountries, getTimezonesForCountry } from 'countries-and-timezones';
import { useNavigate, useParams } from 'react-router-dom'
import { Start_Date_success } from '../../../redux/Reducer/startdateSlice'
import { validateEmail } from '../../../utlis/Validations'
import { GetEditEventListAPI } from '../../../redux/Action/Admin/getEditEventList/GetEditEventList'
import { Clear_GetEdit_EventList_Check } from '../../../redux/Reducer/Admin/getEditEventList/getEditEventList'
import { EditEventListAPI } from '../../../redux/Action/Admin/getEditEventList/EditEventList'
import { End_Date_success } from '../../../redux/Reducer/EndDateSlice'
import Checkbox from '@mui/joy/Checkbox';
import Snacbar from '../../../Components/Snackbar/Snacbar'
import { Clear_Admin_Create_Event_Check } from '../../../redux/Reducer/Admin/CreateEvents/CreateEvents'
import FileInput from '../../Components/FileInput/FileInput'
import FileIconImage from '../../../Images/admin/photo-video-icon.svg'
import { Clear_Get_ID_speakers_Check } from '../../../redux/Reducer/Admin/Speakers/GetIdSpeakerSlice'
import { EventSlugAPI } from '../../../redux/Action/EventSlug/EventSlug'
import FileInputLogin from '../../Components/FileInput/FileInputLogin'

const EventInfo = () => {
  const classes = useStyles();
  const [name, setname] = useState("");
  const [slug, setslug] = useState("");
  const [slugerror, setslugerror] = useState("");
  const [errorname, seterrorname] = useState("");
  const [email, setemail] = useState("");
  const [emailerror, setemailerror] = useState("");
  const [emailformateerror, setemailformateerror] = useState("");
  const [start_date, setstart_date] = useState("");
  const [start_dateerror, setstart_daterror] = useState("");
  const [end_date, setend_date] = useState("");
  const [end_dateerror, setend_dateerror] = useState("");
  const [start_time, setstart_time] = useState("");
  const [start_timeerror, setstart_timeerror] = useState("");
  const [end_time, setend_time] = useState("");
  const [end_timeerror, setend_timeerror] = useState("");
  const [description, setdescription] = useState("");
  const [descriptionerror, setdescriptionerror] = useState("");
  const [countries, setCountries] = useState([]);
  const [countrieserror, setCountrieserror] = useState([]);
  const [timezone, settimezone] = useState("")
  const [height, setheight] = useState(29)
  const [heighterror, setheighterror] = useState(false)
  const [width, setwidth] = useState(62)
  const [widtherror, setwidtherror] = useState(false)
  const [content, setcontent] = useState("")
  const [contenterror, setcontenterror] = useState(false)
  const [timezoneerror, settimezoneerror] = useState(false)
  const AdminCreateEventSlice = useSelector(state => state?.AdminCreateEventSlice)
  const getEditEventListSlice = useSelector(state => state?.getEditEventListSlice)
  const EventSlugSlice = useSelector(state => state?.EventSlugSlice)
  const [checkIn, setCheckIn] = useState(1);
  const [editgetimage, seteditgetimage] = useState([])
  const [editgetloginimage, seteditgetloginimage] = useState([])
  const [multiimage, setmultimage] = useState([]);
  const [multiimagelogin, setmultimagelogin] = useState("");
  const [multiimagelength, setmultimagelength] = useState([]);
  const [multiimageloginlength, setmultimageloginlength] = useState([]);
  const [multiimageerror, setmultimageerror] = useState(false);
  const [multiimageloginerror, setmultimageloginerror] = useState(false);
  const [editopen, seteditopen] = useState(false);
  const [editloginopen, seteditloginopen] = useState(false);
  const [savecheck, setshavecheck] = useState(1)
  const archive = JSON.parse(localStorage.getItem("archive"));
  const navigate = useNavigate();
  const { id } = useParams();
  const event_slug = localStorage.getItem("event_slug")
  const [open, setopen] = useState(false)
  const disabledcheck = (name != "" && start_date != "" && end_date != "" && end_date != null && start_time != "" && end_time != "" && description != "" && email != "" && validateEmail(email) == false && slug != "" && (multiimagelength?.length > 0 || editgetimage?.length > 0)) ? true : false
  //  console.log("checkdisable",disabledcheck,"hgfgghjk",name != "" , start_date != "" , end_date != "" , end_date != null , start_time != "" , end_time != "" , description != "" , email != "" , validateEmail(email)==false  , slug != "" , (multiimagelength?.length > 0 || editgetimage?.length > 0))
  useEffect(() => {
    dispatch(GetEditEventListAPI(id))
  }, [])
  useEffect(() => {
    const fetchCountries = async () => {
      const allCountries = getAllCountries();
      setCountries(allCountries);
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    if (!id) {
      setcontent('<p>hello `name`</p><p>welcome to the event.</p>')

    }
  }, [id])

  useEffect(() => {
    if (getEditEventListSlice?.value?.data) {
      setname(getEditEventListSlice?.value?.data?.name)
      setdescription(getEditEventListSlice?.value?.data?.description)
      setcontent(getEditEventListSlice?.value?.data?.content)
      setemail(getEditEventListSlice?.value?.data?.email)
      setheight(getEditEventListSlice?.value?.data?.height != "" ? getEditEventListSlice?.value?.data?.height : 29)
      setwidth(getEditEventListSlice?.value?.data?.width != "" ? getEditEventListSlice?.value?.data?.width : 62)
      setstart_date({ dob: getEditEventListSlice?.value?.data?.start_date })
      setend_date({ dob: getEditEventListSlice?.value?.data?.end_date })
      setstart_time(getEditEventListSlice?.value?.data?.start_time)
      localStorage.setItem("eventstarttime", getEditEventListSlice?.value?.data?.start_time)
      setend_time(getEditEventListSlice?.value?.data?.end_time)
      localStorage.setItem("eventendtime", getEditEventListSlice?.value?.data?.end_time)
      settimezone(getEditEventListSlice?.value?.data?.timezone)
      setCheckIn(getEditEventListSlice?.value?.data?.is_check_in)
      seteditgetimage(getEditEventListSlice?.value?.data?.event_banner)
      seteditgetloginimage(getEditEventListSlice?.value?.data?.login_banner)
      setmultimagelength(getEditEventListSlice?.value?.data?.event_banner)
      seteditopen(true)
      localStorage.setItem("End_date", getEditEventListSlice?.value?.data?.end_date)
      localStorage.setItem("Start_date", getEditEventListSlice?.value?.data?.start_date)
      // setname(getEditEventListSlice?.value?.data?.name)
      setTimeout(() => {

        dispatch(Clear_GetEdit_EventList_Check())
      }, 100)
    } else if (!getEditEventListSlice?.value?.data) {

    }
    console.log("getEditEventListSlice", getEditEventListSlice)
  }, [getEditEventListSlice])



  const handleCountryChange = (countryCode) => {
    const timezones = getTimezonesForCountry(countryCode);
    console.log(timezones);
  };
  const dispatch = useDispatch();
  // validateEmail
  // console.log("validateEmail",validateEmail(email))
  const handleClick = () => {
    if (EventSlugSlice?.error?.message) {
      return
    }
    // console.log("description",description)
    dispatch(Clear_Get_ID_speakers_Check())
    if (name === "" && start_date === "" && end_date === "" && end_date == null && start_time === "" && end_time === "" && description === "" && email == "" && validateEmail(email)) {
      // Handle case when all variables are empty
      seterrorname(true)
      setstart_daterror(true)
      setstart_timeerror(true)
      settimezoneerror(true)
      setemailerror(true)
      setend_dateerror(true)
      setend_timeerror(true)
      setdescriptionerror(true)
      setCountrieserror(true)
    } else if (
      (name === "" || start_date === "" || end_date === "" || end_date == null || start_time === "" || end_time === "" || description === "" || email == "" || validateEmail(email)) || description?.length > 20000 || slug == "" || (multiimagelength?.length == 0 && editgetimage?.length == 0) || (multiimagelogin == "" && editgetloginimage == "")

    ) {
      if (name == "") {
        seterrorname(true)
      }
      if (start_date == "") {
        setstart_daterror(true)
      }
      if (start_time == "") {
        setstart_timeerror(true)
      }
      if (slug == "") {
        setslugerror(true)
      }
      if (email == "") {
        setemailerror(true)
      }
      if (validateEmail(email)) {
        setemailformateerror(true)
      }
      if (end_date == "" || end_date == null) {
        setend_dateerror(true)
      }
      if (end_time == "") {
        setend_timeerror(true)
      }
      if (countries == "") {
        setCountrieserror(true)
      }
      if (description == "") {
        setdescriptionerror(true)
      }

      if (multiimagelength?.length == 0 && editgetimage?.length == 0) {
        setmultimageerror(true)
      }
      if (multiimagelogin == "" && editgetloginimage == "") {
        setmultimageloginerror(true)
      }


      // Handle other specific cases
    }
    else {


      // console.log("validations",validations)
      const formData = new FormData();
      formData.append('name', name);
      formData.append('slug', slug);
      formData.append('start_date', start_date?.dob);
      formData.append('end_date', end_date?.dob);
      formData.append('start_time', start_time?.split(" ")[0]);
      formData.append('end_time', end_time?.split(" ")[0]);
      formData.append('description', description);
      formData.append('content', content);
      formData.append('height', height);
      formData.append('width', width);
      formData.append('timezone', timezone);
      formData.append('email', email);
      formData.append('is_check_in', checkIn);
      multiimage?.forEach((item, index) => {
        if (item) {
          formData.append(`banners[]`, item);
        }
      });
      if (multiimagelogin != "") {

        formData.append(`login_banner`, multiimagelogin)
      }
      const obj = {
        name: name,
        start_date: start_date?.dob,
        end_date: end_date?.dob,
        start_time: start_time?.split(" ")[0],
        end_time: end_time?.split(" ")[0],
        description: description,
        timezone: timezone,
        email: email,
        is_check_in: checkIn
      }
      if (id) {

        dispatch(EditEventListAPI(formData, id))
      } else {
        dispatch(AdminCreateEventAPI(formData))

      }
      console.log("obj", obj)
    }
  }
  const bindingHandler = (date) => {
    setstart_date((pre) => ({
      ...pre,
      dob: supplyDateFormat(date[""]),
    }));
    setstart_daterror(false)

    localStorage.setItem("Start_date", supplyDateFormat(date[""]))
    dispatch(Start_Date_success(supplyDateFormat(date[""])))
  }
  const bindingHandler2 = (date) => {
    setend_date((pre) => ({
      ...pre,
      dob: supplyDateFormat(date[""]),
    }));
    // setdoberor(false)
    setend_dateerror(false)
    localStorage.setItem("End_date", supplyDateFormat(date[""]))
    dispatch(End_Date_success(supplyDateFormat(date[""])))
  }
  const StartDateSlice = useSelector(state => state?.StartDateSlice?.value)

  const searchtextbox = () => {
    if (id) {

      dispatch(EventSlugAPI(slug, id))
    } else {

      dispatch(EventSlugAPI(slug))
    }
  }
  console.log("archive", archive == true ? true : false)
  useEffect(() => {
    const delay = 500; // Adjust the debounce delay in milliseconds
    const timerId = setTimeout(() => {
      // if(pickupsearchcheck==true){
      if (slug != "") {

        searchtextbox();
      }
      // }
    }, delay);

    // Cleanup the timer when the component unmounts or when inputValue changes
    return () => clearTimeout(timerId);
  }, [slug]);
  // console.log("slugifyslugify", slugify(name))
  useEffect(() => {
    if (StartDateSlice != "") {
      setend_date(null)
      setend_time("")
      setstart_time("")
    }
  }, [StartDateSlice])
  const bindingTimeHandler = (date) => {
    // setvalidationdob(true)
    const newdate = new Date()

    const timeformate = date[""]?.toLocaleString('en-IN', {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
      hourCycle: 'h23'

    });
    console.log("datetime", timeformate?.split(" ")[0])
    localStorage.setItem("eventstarttime", timeformate?.split(" ")[0])
    setstart_time(timeformate)

    setstart_timeerror(false)
    console.log("timeset", timeformate)
    // console.log("dateofbirth", date[""], supplyDateFormat(date[""]))
    // setdob((pre) => ({
    //     ...pre,
    //     dob: supplyDateFormat(date[""]),
    // }));
  };
  const bindingTimeHandler2 = (date) => {
    // setvalidationdob(true)
    const newdate = new Date()

    const timeformate = date[""]?.toLocaleString('en-IN', {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
      hourCycle: 'h23'

    });
    console.log("date", timeformate)
    setend_time(timeformate)
    setend_timeerror(false)
    console.log("timeset", timeformate)
    localStorage.setItem("eventendtime", timeformate?.split(" ")[0])
    // console.log("dateofbirth", date[""], supplyDateFormat(date[""]))
    // setdob((pre) => ({
    //     ...pre,
    //     dob: supplyDateFormat(date[""]),
    // }));
  };
  useEffect(() => {
    if (AdminCreateEventSlice?.value?.success == true) {
      const slugPath = '';
      if (savecheck == 1) {
        navigate(`/admin${slugPath}/events/create-event/event-information/${AdminCreateEventSlice?.value?.data?.id}`)
        window.location.reload()

      } else {

        if (id) {
          navigate(`/admin${slugPath}/events/create-event/add-speakers/${id}`)
        } else {

          navigate(`/admin${slugPath}/events/create-event/add-speakers/${id}`)
          // window.location.reload()
        }
      }
    }
  }, [AdminCreateEventSlice])
  console.log("AdminCreateEventSlice", AdminCreateEventSlice)
  useEffect(() => {
    if (AdminCreateEventSlice?.error?.message) {
      setopen(true)
      setTimeout(() => {
        dispatch(Clear_Admin_Create_Event_Check())
      }, 3000)
    }
  }, [AdminCreateEventSlice])
  const handlecheckChange = (e) => {
    setCheckIn(e.target.checked == true ? 1 : 0)
    // console.log("eeeee",e.target.checked)
  }
  useEffect(() => {
    setslug(slugify(name))
  }, [name])
  console.log("disabledcheck", disabledcheck)
  const handleKeyDown = (event) => {
    if (event.keyCode === 69) {
      event.preventDefault();
    }
  };
  console.log("multiimagelogin", multiimagelogin)
  return (

    <>
      {
        (AdminCreateEventSlice?.error?.message) && (
          <Snacbar
            open={open}
            setOpen={setopen}
            messagetype={"error"}
            message={(AdminCreateEventSlice?.error?.message)}
          />

        )
      }

      <div className='form-container'>
        <h2 className={classes.FormTitle}>Let’s start with Event Information</h2>
        <div className={classes.FormGroup}>
          <label className={classes.FormLabel}>Title<span>*</span></label>
          <Input placeholder="Event Name" variant="soft" className={classes.FormInput} value={name} onChange={(e) => {
            if (e.target.value.length <= 100) {
              if (archive != true) {
                setname(e.target.value)
                seterrorname(false)
                setslugerror(false)
              }
            }
          }} />
          {
            errorname && (
              <p className='error-msg'>Name is required.</p>
            )
          }
        </div>
        <div className={classes.FormGroup}>
          <label className={classes.FormLabel}>Slug<span>*</span></label>
          <Input placeholder="Slug" variant="soft" className={classes.FormInput} value={slug} onChange={(e) => {
            if (e.target.value.length <= 100) {
              if (archive != true) {

                setslug(slugify(e.target.value))
                setslugerror(false)
              }
            }
          }} />
          {
            slugerror && (
              <p className='error-msg'>Slug is required.</p>
            )
          }
          {
            EventSlugSlice?.error?.message == "Slug Already Exists" && (
              <p className='error-msg'>Slug Already Exists.</p>
            )
          }
        </div>
        <div className={classes.FormGroup}>
          <label className={classes.FormLabel}>Description</label>
          <TextEditor setdescription={setdescription} description={description} setdescriptionerror={setdescriptionerror} />
          {
            descriptionerror && (
              <p className='error-msg'>Description is required.</p>
            )
          }
          {
            description?.length > 20000 && (
              <p className='error-msg'>Description length is not more than 20,000 character.</p>
            )
          }
        </div>

        <Grid container spacing={2} sx={{ flexGrow: 1 }}>
          <Grid lg={4} md={12} xs={12}>
            <div className={classes.FormGroup}>
              <label className={classes.FormLabel}>Start Date</label>
              <DateInput
                start_date={start_date}
                setstart_time={setstart_time}
                dateValue={start_time}
                onBinding={bindingHandler}
                disabled={archive == true ? true : false}
              />
              {
                start_dateerror && (
                  <p className='error-msg'>Date is required.</p>
                )
              }
            </div>
          </Grid>
          <Grid lg={4} md={12} xs={12}>
            <div className={classes.FormGroup}>
              <label className={classes.FormLabel}>Time</label>
              <TimeInput
                // eventcheck={true}
                start_date={start_date}
                // setend_time={setend_time}
                dateValue={start_time}
                onBinding={bindingTimeHandler}
                disabled={archive == true ? true : false}

              />
              {
                start_timeerror && (
                  <p className='error-msg'>Time is required.</p>
                )
              }
            </div>
          </Grid>
          <Grid lg={4} md={12} xs={12}>
            <div className={classes.FormGroup}>
              <label className={classes.FormLabel}>Time Zone</label>
              {/* <TimeZone countries={countries} settimezone={settimezone} timezone={timezone} settimezoneerror={settimezoneerror} /> */}
              <Input placeholder="TimeZone" variant="soft" className={classes.FormInput} value={"Asia/Singapore"} disabled={true} />
              {
                timezoneerror && (
                  <p className='error-msg'>Timezone is required.</p>
                )
              }
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ flexGrow: 1 }}>
          <Grid lg={6} md={12} xs={12}>
            <div className={classes.FormGroup}>
              <label className={classes.FormLabel}>End Date</label>
              <DateInput
                start_date={end_date}
                dateValue={end_date}
                onBinding={bindingHandler2}
                allowafter={1}
                disabled={archive == true ? true : false}
              />
              {
                end_dateerror && (
                  <p className='error-msg'> Date is required.</p>
                )
              }
            </div>
          </Grid>
          <Grid lg={6} md={12} xs={12}>
            <div className={classes.FormGroup}>
              <label className={classes.FormLabel}>Time</label>
              <TimeInput

                dateValue={end_time}
                onBinding={bindingTimeHandler2}
                disabled={archive == true ? true : false}
              />
              {
                end_timeerror && (
                  <p className='error-msg'>Time is required.</p>
                )
              }
            </div>
          </Grid>
        </Grid>
        <div className={classes.FormGroup}>
          <label className={classes.FormLabel}>Event Banner</label>
          <FileInput FileIcon={FileIconImage} Text={["Drop Image/Video or ", <span>Click Here</span>, " Banner size must not exceed 1000*500"]} editgetimage={editgetimage} multiimage={multiimage} setmultimage={setmultimage} setmultimagelength={setmultimagelength} seteditgetimage={seteditgetimage} setmultimageerror={setmultimageerror} editopen={editopen} disabled={archive == true ? true : false} />
          {
            multiimageerror && (
              <p className='error-msg'>Banner is required.</p>
            )
          }
        </div>
        <div className={classes.FormGroup}>
          <label className={classes.FormLabel}>Login Banner</label>
          <FileInputLogin FileIcon={FileIconImage} Text={["Drop Image/Video or ", <span>Click Here</span>, " Banner size must not exceed 3000*1000"]} editgetimage={editgetloginimage} multiimage={multiimagelogin} setmultimage={setmultimagelogin} setmultimagelength={setmultimageloginlength} seteditgetimage={seteditgetloginimage} setmultimageerror={setmultimageloginerror} editopen={editloginopen} disabled={archive == true ? true : false} />
          {
            multiimageloginerror && (
              <p className='error-msg'>Login Banner is required.</p>
            )
          }
        </div>

        <div className={classes.FormGroup}>
          <label className={classes.FormLabel}>Event Email Contact</label>
          <Input placeholder="event_management@example.com" variant="soft" className={classes.FormInput} value={email} onChange={(e) => {
            setemail(e.target.value)
            setemailerror(false)
            setemailformateerror(false)
          }}
            disabled={archive == true ? true : false}
          />
          {
            emailerror && (
              <p className='error-msg'>Email is required.</p>
            )
          }
          {
            emailformateerror && emailerror == false && (
              <p className='error-msg'>Please Enter valid Email.</p>
            )
          }
        </div>
        <Grid lg={4} md={12} xs={12}>
          <div className={classes.FormGroup}>
            <label className={classes.FormLabel}>Badge Height</label>
            {/* <TimeZone countries={countries} settimezone={settimezone} timezone={timezone} settimezoneerror={settimezoneerror} /> */}
            <Input placeholder="height" type="number" variant="soft" className={classes.FormInput} value={height} onChange={(e) => {

              setheight(e.target.value)
              setheighterror(false)

            }}
              onKeyDown={handleKeyDown}
              onInput={(e) => {
                if (e?.target?.value != "") {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()

                }
              }}
            />
            {
              heighterror && (
                <p className='error-msg'>Height is required.</p>
              )
            }
          </div>
        </Grid>
        <Grid lg={4} md={12} xs={12}>
          <div className={classes.FormGroup}>
            <label className={classes.FormLabel}>Badge Width</label>
            {/* <TimeZone countries={countries} settimezone={settimezone} timezone={timezone} settimezoneerror={settimezoneerror} /> */}
            <Input placeholder="width" type="number" variant="soft" className={classes.FormInput} value={width} onChange={(e) => {
              setwidth(e.target.value)
              setwidtherror(false)
            }}
              onKeyDown={handleKeyDown}
              onInput={(e) => {
                if (e?.target?.value != "") {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()

                }
              }}
            />
            {
              widtherror && (
                <p className='error-msg'>Width is required.</p>
              )
            }
          </div>
        </Grid>
        <div className={classes.FormGroup}>
          <label className={classes.FormLabel}>Badge Content</label>
          <TextEditor setdescription={setcontent} description={content != undefined ? content : ""} setdescriptionerror={setcontenterror} />
          {
            contenterror && (
              <p className='error-msg'>Content is required.</p>
            )
          }
          {/* {
            contenterror?.length > 20000 && (
              <p className='error-msg'>Contenterror length is not more than 20,000 character.</p>
            )
          } */}
        </div>
        <div className={classes.FormGroup}>
          <Checkbox label="Participants Check-in" checked={checkIn == 1 ? true : false} onChange={(e) => handlecheckChange(e)} disabled={archive == true ? true : false} />
        </div>


        {archive != true && <div className='form-container-footer'>
          <ButtonPrimary label="Save" disabledcheck={true} handleClick={() => {
            handleClick(1)
            setshavecheck(1)
          }} />
        </div>}
        <div className='form-container-footer'>
          <ButtonPrimary label="Next Step" disabledcheck={disabledcheck} handleClick={() => {
            handleClick(2)
            setshavecheck(2)
          }} />
        </div>
      </div>
    </>
  )
}

export default EventInfo
