import axios from "axios";
import { APIURL } from "../../../../APIHandler/APIURL"
import { Headers } from "../../../../APIHandler/HeaderAPI";
import { reloadHandler } from "../../../../utlis/utlis";
import { Admin_Delete_Partcipant_failure, Admin_Delete_Partcipant_request, Admin_Delete_Partcipant_success } from "../../../Reducer/Admin/Particants/DeletePartcipantSlice";
import { PartcipantsProgramListingAPI } from "../ParticipantProgramLisiting/ParticipantProgram";
import { PartcipantsAPI } from "../Partcipants/Partcipants";
import { ArchiveEvent } from "../ArchiveEvents/ArchiveEvent";

export const DeletePartcipantsActivitesAPI = (payload) => async (dispatch, getState) => {
    const admineventid=localStorage.getItem("admineventid")
    const auth_token = localStorage.getItem("auth_token")
    const programactivitesID = localStorage.getItem("programactivitesID")
    const paginationnumber = localStorage.getItem("paginationnumber")
    dispatch(Admin_Delete_Partcipant_request())
    const participantpage = localStorage.getItem("participantpage")

    try {
        const { data } = await axios.delete(`${APIURL.deletepartcipantsactivities}/${payload}`,{
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
               "event-slug":Headers?.["event-slug"],
                "Content-type": "application/json",
                Authorization: `Bearer ${auth_token}`
            },
        })
        dispatch(Admin_Delete_Partcipant_success(data))
        dispatch(PartcipantsProgramListingAPI(programactivitesID))
        dispatch(PartcipantsAPI(programactivitesID,participantpage,paginationnumber))
        dispatch(ArchiveEvent())
    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(Admin_Delete_Partcipant_failure(error?.response?.status))
    }
}


  