import React from 'react';
import {Style} from "./Style";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import IconButton from '@mui/material/IconButton';
import ColorTheme from '../../Common/CommonCss/ColorTheme';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router';

const Header = (Props) => {
  const {EventTitle} = Props
  const navigation=useNavigate()
  const url=window.location.href;
  const urlcheck=url?.includes("/participants-info")?true:false
  const urlattendance=url?.includes("/attendance")?true:false
  const urlattendancecheckerror=url?.includes("/check-in-error")?true:false
  return (
    <>
    <Stack className='header' direction="row" spacing={2} sx={Style.Header}>
        <IconButton aria-label="Back" size="small" sx={{padding: '0',}} onClick={()=>{
          if(urlcheck||urlattendancecheckerror){
            navigation("/")
          }else{

          navigation(-1)
          }
          }}>
            <KeyboardBackspaceIcon sx={{color: ColorTheme.textColor}} />
        </IconButton>
      {urlattendance!=true &&  <Typography level="h2" sx={Style.HeaderTitle} >{EventTitle}</Typography>
       } <IconButton aria-label="Back" size="small" sx={{visibility: 'hidden'}}>
          <KeyboardBackspaceIcon sx={{visibility: 'hidden'}} />
        </IconButton>
    </Stack>
    </>
  )
}

export default Header