import React from 'react'
import { useStyles } from "../../Common/FormStyle"
import Input from '@mui/joy/Input';
import SearchIcon from '@mui/icons-material/Search';

const SearchBar = (props) => {
  const {search,setsearch, setpickupsearchcheck}=props;
    const classes = useStyles();
  return (
    <>
    <Input startDecorator={<SearchIcon />} placeholder="Search" variant="soft" className={`${classes.FormInput} search-input`} value={search} onChange={(e)=>{setsearch(e.target.value)
    setpickupsearchcheck(true)
    }}/>
    </>
  )
}

export default SearchBar