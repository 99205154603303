import React, { useEffect, useRef, useState } from 'react'
import AdminHeader from '../../Components/Header/Header'
import ButtonTab from '../../Components/ButtonTab/ButtonTab'
import EventInfoIcon from '../../../Images/admin/event-info-icon.svg'
import AddProgramIcon from '../../../Images/admin/add-program-icon.svg'
import AddSpeakerIcon from '../../../Images/admin/add-speaker-icon.svg'
import RegistrationImportIconActive from '../../../Images/admin/registration-import-icon-active.svg'
import { ReactComponent as Arrow } from '../../../Images/admin/arrow-right.svg'
import { useStyles } from "../../Common/FormStyle"
import Divider from '@mui/joy/Divider';
import Grid from '@mui/joy/Grid'
import { Helmet } from 'react-helmet'
import ButtonWithIcon from '../../Components/ButtonWithIcon/ButtonWithIcon'
import { ReactComponent as FileIcon } from '../../../Images/admin/file-icon.svg'
import ButtonPrimary from '../../Components/ButtonPrimary/ButtonPrimary'
import ButtonSecondary from '../../Components/ButtonSecondary/ButtonSecondary'
import Input from '@mui/joy/Input';
import PreviewTable from '../../Components/PreviewTable/PreviewTable'
import { ReactComponent as EventIcon } from '../../../Images/admin/event-icon.svg'
import { useDispatch, useSelector } from 'react-redux'
import { AdminParticipantExportAPI, AdminSampleExportAPI } from '../../../redux/Action/Admin/SampleExport/SampleExport'
import Csvbutton from '../../Components/CsvButton/CsvButton'
import { useNavigate, useParams } from 'react-router-dom'
import AdmLoader from '../../Components/AdmLoader/AdmLoader'
import { PartcipantsAPI } from '../../../redux/Action/Admin/Partcipants/Partcipants'
import TabHeader from '../../Components/TabHeader/TabHeader'
import Snacbar from '../../../Components/Snackbar/Snacbar'
import { Clear_Import_Csv_Check } from '../../../redux/Reducer/Admin/Sampleexport/ImportCSVSlice'
import ImportexportSoket from '../../../Soket/ImportexportSoket'
import Box from '@mui/material/Box';
import LinearProgress from '@mui/joy/LinearProgress';

import { Clear_ImportLastStatusCheck } from '../../../redux/Reducer/ImportLaststatusCheckSlice'
import { Typography } from '@mui/material'
const RagistrationImport = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const admineventid = localStorage.getItem("admineventid")
    const navigate = useNavigate();
    const event_slug = localStorage.getItem("event_slug")
    const slugPath =  '';
    const ImportCsvSlice = useSelector(state => state?.ImportCsvSlice)
    const AdminSampleExportSlice = useSelector(state => state?.AdminSampleExportSlice)
    const PartcipantSlice = useSelector(state => state?.PartcipantSlice)
    const { id } = useParams();
    const [pagelength, setpagelength] = useState(1);
    const [open, setOpen] = useState(false);
    const ImportExportSoketSlice = useSelector(state => state?.ImportExportSoketSlice)
    const PerecentageCheckSlice = useSelector(state => state?.PerecentageCheckSlice?.value)
    const ImportExportErrorSoketSlice = useSelector(state => state?.ImportExportErrorSoketSlice)
    const [dataimportcheck, setdataimportcheck] = useState(false);
    const [successmsg, setsuccessmsg] = useState(false);
    console.log("ImportExportErrorSoketSlice", ImportExportErrorSoketSlice, ImportCsvSlice)
    const archive = JSON.parse(localStorage.getItem("archive"));
    const typecheck = localStorage.getItem("typecheck")

    const messagesEndRef = useRef(null);
    const handleExport = () => {
        if (id) {

            dispatch(AdminSampleExportAPI(id))
        } else {

            dispatch(AdminSampleExportAPI(admineventid))
        }
        // AdminSampleExportAPI
    }
    const handleParticipantExport = () => {
        if (id) {

            dispatch(AdminParticipantExportAPI(id))
        } else {

            dispatch(AdminParticipantExportAPI(admineventid))
        }
        // AdminSampleExportAPI
    }
    const handleBack = () => {
        navigate(-1)
    }

    useEffect(() => {
        if (id) {

            dispatch(PartcipantsAPI(id))
        } else {

            dispatch(PartcipantsAPI(admineventid))
        }
    }, [])
    useEffect(() => {
        if (id) {

            dispatch(PartcipantsAPI(id, pagelength))
        } else {

            dispatch(PartcipantsAPI(admineventid, pagelength))
        }
    }, [pagelength])

    console.log("ImportCsvSlice", ImportCsvSlice)
    useEffect(() => {

        if (ImportExportErrorSoketSlice?.value?.some((data) => data?.extra_status == "End")) {
            setdataimportcheck(false)
            dispatch(Clear_Import_Csv_Check())
            setsuccessmsg(true)
            setTimeout(() => {

                setsuccessmsg(true)
            }, 3000)
            setOpen(true)
            setTimeout(() => {

                dispatch(Clear_ImportLastStatusCheck())
            }, 3000)
        }
    }, [ImportExportErrorSoketSlice])

    // useEffect(()=>{
    // if(ImportCsvSlice?.value?.message){

    // }
    // },[])
    useEffect(() => {
        messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
        // if (messagesEndRef.current) {
        //   messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        // }
    }, [ImportExportErrorSoketSlice]);
    return (
        <>
            {
                (ImportCsvSlice?.loading == true || AdminSampleExportSlice?.loading || PartcipantSlice?.loading == true) && (
                    <AdmLoader />
                )
            }

            {
                ImportCsvSlice?.value?.message && (
                    <Snacbar
                        open={true}
                        setOpen={setOpen}
                        messagetype={"success"}
                        message={'We have saved the file successfully. Now the import is in progress. Please wait.....'}
                    />

                )
            }
            {
                successmsg == true && (
                    <Snacbar
                        open={successmsg}
                        setOpen={setsuccessmsg}
                        messagetype={"success"}
                        message={'Import is done successfully.'}
                    />

                )
            }
            <Helmet>


                <title>Create New Event | Ticketing</title>
                {/* <meta name="description" content="Ticketing application" /> */}
            </Helmet>
            <ImportexportSoket />
            <div className='admin-main'>
                <AdminHeader Title="Event" PageName={`${typecheck == "new" ? 'Create New Event' : 'Edit New Event'}`} PageIcon={<EventIcon />} />

                <div className='container sec-bottom-sapce'>
                    <div className='box'>
                        <TabHeader active="step5" />
                        <Divider sx={{ backgroundColor: '#ECF0F3' }} />
                        <div className='form-container'>
                            <div className={classes.FormTitleContainer}>
                                {archive != true && <Grid container direction="row" spacing={2} sx={{ flexGrow: 1 }}>
                                    <Grid className="col" sx={{ flex: 1 }}>
                                        <h2 className={classes.FormTitle}>Import Form</h2>
                                    </Grid>
                                    <Grid className="col">
                                        <Grid container flexDirection="flex-end" spacing={1} sx={{
                                            flexDirection: 'row',
                                            "@media(max-width: 600px)": {
                                                flexDirection: 'column',
                                            },
                                            "@media(max-width:387px)": {
                                                flexDirection: 'row',
                                                marginTop: "-40px",
                                                marginBottom: "30px"
                                            }
                                        }}>
                                            <Grid sx={{ flex: '1' }}>
                                                <Csvbutton />
                                            </Grid>
                                            <Grid sx={{ flex: '1' }}>
                                                <ButtonWithIcon label="Sample Export" icon={<FileIcon />} handleClick={() => handleExport()} />
                                            </Grid>
                                            <Grid sx={{ flex: '1' }}>
                                                <ButtonWithIcon label="Participant Export" icon={<FileIcon />} handleClick={() => handleParticipantExport()} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>}
                            </div>

                            {/* <div className={classes.FormGroup}>
                                <label className={classes.FormLabel}>Title</label>
                                <Input variant="soft" disabled className={classes.FormInput} value="" />
                            </div> */}
                            {ImportCsvSlice?.value?.message && <Box sx={{ width: '100%', marginBottom: "20px" }}>
                                <p style={{ display: "flex", justifyContent: "flex-end" }}>{PerecentageCheckSlice}% Completed</p>
                                <LinearProgress determinate value={PerecentageCheckSlice} />

                            </Box>
                            }


                            <div className={classes.ErrorBox} ref={messagesEndRef}>
                                {/* <ul>
                                    {ImportCsvSlice?.error?.message && <p
                                        dangerouslySetInnerHTML={{
                                            __html: ImportCsvSlice?.error?.message
                                        }}
                                    >

                                    </p>}
                                </ul> */}
                                {/* <ul> */}
                                {ImportExportErrorSoketSlice?.value?.map((data) => {

                                    return <>

                                        <p>
                                            {data?.type == "error" && <>{data?.row} &nbsp; {data?.data}
                                            </>
                                            }
                                        </p>

                                    </>


                                })}
                                {/* </ul> */}
                                {/* <div ref={messagesEndRef} /> */}
                            </div>




                            <h2 className={classes.FormTitle}>Import Preview</h2>

                            <PreviewTable PartcipantSlice={PartcipantSlice} setpagelength={setpagelength} pagelength={pagelength} />



                            <div className='form-container-footer'>
                                <ButtonSecondary label="Back" handleClick={() => handleBack()} />
                                <ButtonPrimary label="Done" disabledcheck={true} handleClick={() => { navigate(`/admin${slugPath}/dashboard`) }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RagistrationImport