import React, { useEffect, useState } from 'react'
import { useStyles } from './Style'
import Logo from '../../../Images/admin/adobe-logo.png'
import CircularProgress from '@mui/joy/CircularProgress';
import AdminTheme from '../../Common/AdminTheme';

const StatsCard = (props) => {
    const { datalist } = props;
    const classes = useStyles();
    // console.log("datalistdatalist", datalist)
    const [total_counts, settotal_count] = useState('')
    const [used_counts, setused_count] = useState('')
    const company_data=JSON.parse(localStorage.getItem("company_data"))
    // useEffect(() => {
    //     if (datalist?.length > 0) {
    //         const initialValue = 0;
    //         const total_count = datalist?.reduce(
    //             (accumulator, currentValue) => accumulator + +currentValue?.total_count,
    //             initialValue,
    //         );
    //         settotal_count(total_count)
    //         const used_count = datalist?.reduce(
    //             (accumulator, currentValue) => accumulator + +currentValue?.used_count,
    //             initialValue,
    //         );
    //         setused_count(used_count)
    //         //   console.log("sumWithInitialsumWithInitial",sumWithInitial)

    //     }
    // }, [datalist])
    // console.log("datalist",datalist)
    return (
        <>
           {<div className={classes.StatsCard}>
                <div className={classes.SCLeft}>
                    <img src={company_data?.logo ? company_data?.logo: Logo} alt='' />
                    <h3>{company_data?.app_name}</h3>
                    {/* <p></p>
                    <p></p> */}
                </div>
                <div className={classes.SCRight}>
                    <CircularProgress className={classes.SCProgress}
                        sx={{
                            "--CircularProgress-size": "123px",
                            "--CircularProgress-trackThickness": "3px",
                            "--CircularProgress-progressThickness": "10px",
                            "--CircularProgress-trackColor": "rgba(181, 181, 195, 0.3)",
                            "--CircularProgress-progressColor": AdminTheme.themeColor,
                            '@media (max-width: 991px) and (min-width: 900px)': {
                                "--CircularProgress-size": "100px",
                                "--CircularProgress-trackThickness": "2.5px",
                                "--CircularProgress-progressThickness": "6px",

                            }
                        }}
                        determinate value={25}
                    >
                        <div class="">
                            <h6>{datalist?.total_attend?datalist?.total_attend:0}/{datalist?.total_user?datalist?.total_user:0}</h6>
                            <p>Check-in</p>
                        </div>
                    </CircularProgress>
                </div>
            </div>}
        </>
    )
}

export default StatsCard