import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: [],
  loading: false,
}

export const ImportExportErrorSoketSlice = createSlice({
  name: 'ImportExportErrorSoket',
  initialState,
  reducers: {
   
    ImportExportErrorSoket_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      
    }
  }
})

// Action creators are generated for each case reducer function
export const {ImportExportErrorSoket_success } = ImportExportErrorSoketSlice.actions

export default ImportExportErrorSoketSlice.reducer