import { makeStyles } from '@mui/styles';
import ColorTheme from '../../Common/CommonCss/ColorTheme';
export const useStyles = makeStyles({

    EventCard: {
        background: "#fff",
        boxShadow: ColorTheme.cardShadow,
        borderRadius: ColorTheme.cardRadius,
        // marginBottom: "15px",
        padding: "15px 15px 0px 15px",
        "& figure": {
            width: "100%",
            height: "150px",
            margin: "0",
            borderRadius: "5px",
            lineHeight: "0",
            overflow: "hidden",
            "@media(max-width: 340px)":{
                height: "110px",
            }
        },
        "& .bottom": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "5px 0px",
            "& .lft": {
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "18px",
                color: ColorTheme.textColor,
                fontFamily: ColorTheme.publicSans,
                textTransform: "capitalize"
            }
        },
        "&.disable": {
            background: "#dedcdc",
            boxShadow: "none",
            opacity: "0.5",
        }
    }
    
});