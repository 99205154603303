import { makeStyles } from '@mui/styles';
import AdminTheme from '../../Common/AdminTheme';

export const useStyles = makeStyles({

    ParticipantCard: {
        display: "flex",
        alignItems: "center",
        marginBottom: "22px",
        "& .MuiAvatar-root": {
            width: "50px",
            height: "50px",
            borderRadius: "6px",
            marginRight: "15px"
        },
        "& h6": {
            fontSize: "14px",
            lineHeight: "22px",
            fontFamily: AdminTheme.poppins,
            fontWeight: "600",
            color: AdminTheme.headingColor,
            marginTop: "0",
            marginBottom: "0px",
        },
        "& p": {
            fontSize: "13px",
            lineHeight: "20px",
            fontFamily: AdminTheme.poppins,
            fontWeight: "500",
            color: AdminTheme.lightColor,
            marginTop: "0",
            marginBottom: "0px",
        },
    },

});