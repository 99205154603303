import { makeStyles } from '@mui/styles';
import AdminTheme from '../../Common/AdminTheme';
export const useStyles = makeStyles({
    
    ImportFormBtn:{
        position: "relative",
        height: "40px",
        borderRadius: "6px",
        fontSize: "12px",
        fontWeight: "600",
        lineHeight: "18px",
        fontFamily: AdminTheme.poppins,
        background: AdminTheme.btnBgColor,
        color: AdminTheme.btnTextColor,
        flexShrink: "0",
        '&:hover': {
            color: AdminTheme.btnTextColor,
            backgroundColor: AdminTheme.btnBgColor,
            boxShadow: AdminTheme.btnHoverShadow,
        },
        "& .file-btn": {
            display: "flex",
            padding: "11px 20px",
            "& svg":{
                height: "19px",
                marginRight: "10px",
                flexShrink: "0",
            },
        },
        "& label":{
            position: "absolute",
            top: "0",
            left: "0",
            border: "none",
            // maxWidth: "unset",
            minWidth: "unset",
            width: "100%",
            height: "100%",
            padding: "0",
            borderRadius: "0",
            "& svg, & div": {
                display: "none"
            }
        }
    
    },
    
});