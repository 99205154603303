import React, { useEffect } from 'react'
import TicketStatus from '../../Components/TicketStatus/TicketStatus';
import Typography from '@mui/joy/Typography';
import EventTickets from '../../Components/EventTickets/EventTickets';
import { CommonStyle } from '../../Common/CommonCss/CommonStyle';
import { useDispatch, useSelector } from 'react-redux';
import { VoucherAPI } from '../../redux/Action/Vouchers/Vouchers';
import { useNavigate } from 'react-router-dom';
import Header from '../../Components/Header/Header';

const Ticket = () => {
  const navigate=useNavigate()
  const dispatch=useDispatch()

  // console.log("VouchersSlice",VouchersSlice)
  useEffect(()=>{
    localStorage.setItem("staffcheckkey",true)
    dispatch(VoucherAPI())
  },[])
  return (
    <>
    <div className='main'>
    <Header EventTitle="Programs"/>
    <div className='container' >
        <TicketStatus />
        <Typography level="h2" sx={CommonStyle.TitleStyle} 
        >Vouchers</Typography>
        <EventTickets />
        {/* <Typography level="h2" sx={CommonStyle.TitleStyle} 
        >Pre-Registered Event</Typography>
        <EventTickets /> */}
    </div>
    </div>
    </>
  )
}

export default Ticket