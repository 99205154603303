import { makeStyles } from '@mui/styles';
import ColorTheme from '../../Common/CommonCss/ColorTheme';
export const useStyles = makeStyles({
    
    BtnSqr:{
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textDecoration: "none",
        borderStyle: "solid",
        borderColor: `${ColorTheme.orange} !important`,
        borderRadius: "20px !important"
    },
    BtnSqrText:{
        color: ColorTheme.orange,
        fontFamily: ColorTheme.publicSans,
        marginBottom: "0",
        marginTop: "0",
        textAlign: "center",
        textTransform: "none !important"
    },
    BtnSqrIcon: {
        flexShrink: "0",
        "& svg": {
            width: "100%",
            height: "100%",
            "& path": {
                fill: ColorTheme.orange,
            }
        }
    },

    "@media(min-width: 768px) and (max-width: 1024px)": {
        BtnSqr:{
            borderWidth: "1.5px !important",
            width: "120px",
            height: "120px",
        },
        BtnSqrText:{
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: "500",
        },
        BtnSqrIcon: {
            width: "24px",
            height: "24px",
            marginBottom: "12px",
        },
    },
    "@media(min-width: 1025px)": {
        BtnSqr:{
            borderWidth: "2px !important",
            width: "150px",
            height: "150px",
        },
        BtnSqrText:{
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: "600",
        },
        BtnSqrIcon: {
            width: "32px",
            height: "32px",
            marginBottom: "16px",
        },
    },
    "@media(max-width: 767px) and (min-width: 341px)": {
        BtnSqr:{
            width: "90px",
            height: "90px",
        },
        BtnSqrIcon: {
            width: "20px",
            height: "20px",
            marginBottom: "12px",
        },
        BtnSqrText:{
            fontSize: "12px",
            lineHeight: "14px",
            fontWeight: "500",
        }
    },
    "@media(max-width: 340px)": {
        BtnSqr:{
            width: "80px",
            height: "80px",
            borderRadius: "16px !important"
        },
        BtnSqrIcon: {
            width: "18px",
            height: "18px",
            marginBottom: "10px",
        },
        BtnSqrText:{
            fontSize: "11px",
            lineHeight: "13px",
            fontWeight: "500",
        }
    },
    
});