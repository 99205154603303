import axios from "axios";
import { APIURL } from "../../../../APIHandler/APIURL"
import { Headers } from "../../../../APIHandler/HeaderAPI";
import { reloadHandler } from "../../../../utlis/utlis";
import { Delete_Activites_failure, Delete_Activites_request, Delete_Activites_success } from "../../../Reducer/Admin/CreateActivities/DeleteActivitySlice";
import { GetActivitiesAPI } from "./GetActivities";

export const DeleteActivitiesAPI = (payload,id) => async (dispatch, getState) => {
    // const eventItemId=localStorage.getItem("eventItemId")
    const auth_token = localStorage.getItem("auth_token")
    const admineventid = localStorage.getItem("admineventid")
    dispatch(Delete_Activites_request())
    try {
        const { data } = await axios.delete(`${APIURL.admindeleteactivity}/${payload}`,{
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
                "Content-type": "application/json",
              "event-slug":Headers?.["event-slug"],
                Authorization: `Bearer ${auth_token}`
            },
        })
        dispatch(Delete_Activites_success(data))
        if(id){

            dispatch(GetActivitiesAPI(id))
        }else{

            dispatch(GetActivitiesAPI(admineventid))
        }
    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(Delete_Activites_failure(error?.response?.status))
    }
}


  