import { makeStyles } from '@mui/styles';
import AdminTheme from '../../Common/AdminTheme';
export const useStyles = makeStyles({
    
    FileInputBox:{
        background: "#F3F6F9",
        position: "relative",
        borderRadius: "5px",
        "& label":{
            border: "none",
            maxWidth: "unset",
            minWidth: "unset",
            width: "100%",
            height: "100%",
            display: "block",
            position: "absolute",
            left: "0",
            top: "0",
            padding: "0",
            "& svg": {
                display: "none",
            },
            "& div": {
                display: "none",
            }
        }
    },

    FileInputLabel: {
        textAlign: "center",
        padding: "20px 10px",
        "& p": {
            fontFamily: AdminTheme.poppins,
            fontSize: "11px",
            fontWeight: "400",
            lineHeight: "16px",
            marginBottom: "0px",
            marginTop: "0px",
            textAlign: "center",
            color: '#464E5F',
            "& span":{
                color: AdminTheme.themeColor
                }
            }
    },
    FileInputIcon: {
        height: "40px",
        marginBottom: "10px",
    },


    FileInputBoxSingle: {
        "& .after-uploaded-file": {
            textAlign: "right",
        },
        "& .iframe-container": {
            marginBottom: "20px",
            height: "600px",
            "& iframe": {
                width: "100%",
                height: "100%",
                border: "none",
            },
        },
        "& .file-uploaded": {
            display: "inline-flex",
            justifyContent: "center",
            alignItem: "center",
            minWidth: "110px",
            minHeight: "43px",
            borderRadius: "4px",
            fontSize: "13px",
            fontWeight: "600",
            lineHeight: "19px",
            fontFamily: AdminTheme.poppins,
            background: AdminTheme.SecondaryBtnBgColor,
            color: AdminTheme.themeColor,
            boxShadow: "none",
            textTransform: "none",
            "& label": {
                minWidth: "unset",
            },
            '&:hover': {
                color: AdminTheme.themeColor,
                backgroundColor: AdminTheme.SecondaryBtnBgColor,
                boxShadow: AdminTheme.btnHoverShadow,
            },
        },

    },

    FileInputBoxMultiple: {
        "& .after-uploaded-file": {
            textAlign: "right",
        },
        "& .img-container": {
            border: "1px solid",
            borderColor: AdminTheme.inputBg,
            marginBottom: "20px",
            padding: "5px",
            maxHeight: "600px",
            overflowY: "auto",
        },
        "& .file-uploaded": {
            display: "inline-flex",
            justifyContent: "center",
            alignItem: "center",
            minWidth: "110px",
            minHeight: "43px",
            borderRadius: "4px",
            fontSize: "13px",
            fontWeight: "600",
            lineHeight: "19px",
            fontFamily: AdminTheme.poppins,
            background: AdminTheme.SecondaryBtnBgColor,
            color: AdminTheme.themeColor,
            boxShadow: "none",
            textTransform: "none",
            "& label": {
                minWidth: "unset",
            },
            '&:hover': {
                color: AdminTheme.themeColor,
                backgroundColor: AdminTheme.SecondaryBtnBgColor,
                boxShadow: AdminTheme.btnHoverShadow,
            },
        },
        "& .rw": {
            display: "flex",
            flexWrap: "wrap",
            margin: "0 -5px",
            alignItem: "center",
            "& .cl": {
                padding: "0px 5px",
                margin: "0",
                marginRight: "15px",
                "& .uploaded-img-card": {
                    background: "#fff",
                    borderRadius: "5px",
                    width: "90px",
                    height: "70px",
                    "& img": {
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                        objectPosition: "center",
                        lineHeight: "0"
                    }
                }
            },
            "& .cl-right": {
                textAlign: "left",
                flex: '1',
                margin: "auto auto auto 0",
                "& h6": {
                    fontFamily: AdminTheme.poppins,
                    color: '#464E5F',
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "21px",
                    margin: "0",
                    marginBottom: "4px"
                },
                "& .MuiButtonBase-root": {
                    boxShadow: "none",
                    fontFamily: AdminTheme.poppins,
                    background: AdminTheme.btnBgColor,
                    color: AdminTheme.btnTextColor,
                },
                "& .input-group": {
                    maxWidth: "calc(100% - 30px)",
                },
                "& .MuiInput-root": {
                    boxShadow: "none",
                    border: "1px solid #DBDCDC",
                    minHeight: "unset",
                    height: "28px",
                    fontSize: "11px",
                    lineHeight: "16px",
                    fontWeight: "400",

                },
                "& .icon svg": {
                    color: "#464E5F",
                }
            }
        },
        "& .img-with-add-url-btn": {
            padding: "10px 20px",
            marginTop: "10px",
            backgroundColor: "#F3F6F9",
            position: "relative",
            "& .btn-remove": {
                position: "absolute",
                top: "5px",
                right: "5px",
            }
        }
    },
    





});