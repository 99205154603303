import React from 'react';
import { FileUploader } from "react-drag-drop-files";
import { useStyles } from "./Style";
import { ReactComponent as FileIcon } from '../../../Images/admin/file-icon.svg'
import { useDispatch } from 'react-redux';
import { AdminSampleImportAPI } from '../../../redux/Action/Admin/SampleExport/SampleImport';
import { useParams } from 'react-router-dom';

const fileTypes = ["CSV"];

function Csvbutton({ onChange }) {
  const dispatch = useDispatch()
  const { id } = useParams();
  const handleChange = (event) => {
    const formData = new FormData();
    formData.append('import_file', event[0]);
    if (id) {

      dispatch(AdminSampleImportAPI(formData, id))
    } else {
      dispatch(AdminSampleImportAPI(formData))

    }
  };


  const classes = useStyles();

  return (
    <div className={`${classes.ImportFormBtn} import-form-btn`}>
      <FileUploader
        handleChange={handleChange}
        name="file"
        types={fileTypes}
        multiple={true}
      />
      <div className="file-btn"><FileIcon />Import Form</div>
    </div>
  );
}

export default Csvbutton;
