import { makeStyles } from '@mui/styles';
import AdminTheme from '../../Common/AdminTheme';

export const useStyles = makeStyles({

    SpeakerDecCard: {
        borderRadius: "15px",
        padding: "20px",
        boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.1)",
        "& .sdc-inner": {
            display: "flex",
        }
    },
    Pic: {
        width: "120px",
        height: "120px",
        borderRadius: "12px",
        flexShrink: "0",
        overflow: "hidden"
    },
    SDCRight: {
        flex: "1",
        paddingLeft: "20px",
        "& .title-rw": {
            display: "flex",
            justifyContent: "space-between",
            "& h4": {
                flex: "1",
                fontSize: "19px",
                lineHeight: "28px",
                fontWeight: "600",
                color: "#464E5F",
                marginTop: "0",
                marginBottom: "0px",
                fontFamily: AdminTheme.poppins,
                wordBreak:"break-all",
            },
            "& .actions": {
                marginTop: "-4px",
                "& .MuiIconButton-root": {
                    background: AdminTheme.inputBg,
                    color: AdminTheme.btnBgColor,
                    width: "32px",
                    height: "32px",
                    borderRadius: "6px",
                    marginLeft: "10px",
                    "& svg": {
                        fontSize: "24px"
                    }
                }
            },
        },
        "& h6": {
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: "600",
            color: "#464E5F",
            marginTop: "0",
            marginBottom: "10px",
            fontFamily: AdminTheme.poppins,
            wordBreak:"break-all",
        },
        "& p": {
            fontSize: "13px",
            lineHeight: "19px",
            fontWeight: "400",
            color: "#434349",
            marginTop: "0",
            marginBottom: "0px",
            fontFamily: AdminTheme.poppins,
            wordWrap: "break-word",
            wordBreak:"break-all"
        },
        "& .dec": {
            "& *": {
                fontFamily: AdminTheme.poppins,
                wordBreak:"break-all",
                color: "#434349",
            },
            "& h1, & h2, & h3, & h4, & h5, & h6":{
                color: "#464E5F",
            },
        }
    },

    // SDCFooter: {
    //     display: "flex",
    //     justifyContent: "flex-end",
    //     gap: "20px",
    //     "& .MuiButtonBase-root": {
    //         margin: "0"
    //     },
    //     '@media(min-width: 501px)': {
    //         marginTop: "-44px",
    //     }
    // },

    '@media(max-width: 800px)': {
        SpeakerDecCard: {
            padding: "15px",
            borderRadius: "10px",
        },
        Pic: {
            width: "100px",
            maxHeight: "100px",
            borderRadius: "10px",
        },
        // SDCFooter: {
        //     gap: "15px",
        // },
        SDCRight: {
            paddingLeft: "15px",
        }
    },
    '@media(max-width: 500px)': {
        Pic: {
            width: "90px",
            maxHeight: "90px",
            borderRadius: "10px",
        },
        // SDCFooter: {
        //     marginTop: "10px",
        //     gap: "10px",
        //     "& button": {
        //         width: "100%"
        //     }
        // },
        SDCRight: {
            paddingLeft: "10px",
            "& .title-rw": {
                "& .actions": {
                    marginTop: "-4px",
                    "& .MuiIconButton-root": {
                        background: AdminTheme.inputBg,
                        color: AdminTheme.btnBgColor,
                        width: "24px",
                        height: "24px",
                        borderRadius: "4px",
                        marginLeft: "5px",
                        "& svg": {
                            fontSize: "18px"
                        }
                    }
                }
            },
        }
    },
    '@media(max-width: 400px)': {
        Pic: {
            width: "80px",
            maxHeight: "80px",
            borderRadius: "8px",
        },
    }

});