import React, { useState } from 'react'
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import ColorTheme from '../../Common/CommonCss/ColorTheme';
import Stack from '@mui/material/Stack';
import { useStyles } from "./Style";
import IconButton from '@mui/material/IconButton';
import SyncIcon from '@mui/icons-material/Sync';

const TicketStatus = () => {
    const classes = useStyles();
    const auth_name = localStorage.getItem("auth_name")
    const userdetails = JSON.parse(localStorage.getItem("userdetails"))

    const [animation, setAnimation] = useState("");

    const onClickChange = () => {
        setAnimation("rotate");
        window.location.reload();
    }

    return (
        <>
            <Card variant="plain" classesName={classes.StatusCard} sx={{
                marginTop: "10px",
                background: "#fff",
                borderRadius: ColorTheme.cardRadius,
                boxShadow: ColorTheme.cardShadow,
                padding: "15px"
            }}>
                <CardContent>
                    <Stack className={classes.StatusHeader} direction="row" spacing={0} sx={{ justifyContent: 'space-between', marginBottom: "15px" }}>
                        <div className='cl'>
                            <div className="h2 text-truncate">Hello, <strong>{auth_name}</strong></div>
                        </div>
                        <IconButton className={animation} aria-label="Refresh" size="small" sx={{ transform: 'translate(5px, -5px)' }} onClick={() => onClickChange()}>
                            <SyncIcon sx={{ color: ColorTheme.textColor }} />
                        </IconButton>
                    </Stack>

                    <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between', marginBottom: '10px' }}>
                        <h3 className={classes.TextLeft}>Status</h3>
                        <h3 className={classes.TextRight}>Valid</h3>
                    </Stack>
                    <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between' }}>
                        <h3 className={classes.TextLeft}>Start Date </h3>
                        <h3 className={classes.TextRight}>{userdetails?.data?.start_date} , {userdetails?.data?.start_time}</h3>
                    </Stack>
                    <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between' }}>
                        <h3 className={classes.TextLeft}>End Date </h3>
                        <h3 className={classes.TextRight}>{userdetails?.data?.end_date} , {userdetails?.data?.end_time}</h3>
                    </Stack>
                </CardContent>
            </Card>
        </>
    )
}

export default TicketStatus