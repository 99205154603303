import * as React from 'react';
import { useStyles } from './Style'
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';
import AddParticipantModal from '../AddParticipantModal/AddParticipantModal';
import ParticipantCard from '../ParticipantCard/ParticipantCard';
import ProgressBar from '../ProgressBar/ProgressBar';
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import Pic from '../../../Images/admin/picture.png'
import { useDispatch, useSelector } from 'react-redux';
import Select, { selectClasses } from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import AdminTheme from '../../Common/AdminTheme';
import { PartcipantsAPI, PartcipantsSearchAPI } from '../../../redux/Action/Admin/Partcipants/Partcipants';
import { PartcipantsProgramListingAPI } from '../../../redux/Action/Admin/ParticipantProgramLisiting/ParticipantProgram';
import { PartcipantsActivitesAPI } from '../../../redux/Action/Admin/PartcipantActivites/PartcipantActivities';
import { DeletePartcipantsActivitesAPI } from '../../../redux/Action/Admin/PartcipantActivites/DeletePartcipantActivites';
import { EventDashboardAPI } from '../../../redux/Action/Admin/EventList/EventDashboardList';
import { BulkDeleteProgramAPI } from '../../../redux/Action/Admin/ParticipantProgramLisiting/BulkDeleteProgram';
import SearchBar from '../SearchBar/SearchBar';
import { useState } from 'react';
import { useEffect } from 'react';
import AdmLoader from '../AdmLoader/AdmLoader';
import { Clear_ResetPassword_Check } from '../../../redux/Reducer/ResetPassword/ResetPAsswordSlice';
import Snacbar from '../../../Components/Snackbar/Snacbar';
import DeletePopup from '../DeletePopup/DeletePopup';
import { GetEditEventListAPI } from '../../../redux/Action/Admin/getEditEventList/GetEditEventList';
import { useNavigate } from 'react-router-dom';
import { ArchiveEvent } from '../../../redux/Action/Admin/ArchiveEvents/ArchiveEvent';
import { EventDeleteManagerAPI, EventGetEditManagerListAPI, EventManagerListAPI } from '../../../redux/Action/Admin/EventManager.js/EventManager';


function AuthorAction({ eventid }) {
  const [open, setOpen] = React.useState(false);
  const [opendelete, setOpendelete] = React.useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const programactivitesID = localStorage.getItem("programactivitesID")
  const navigate = useNavigate()
  const [DeleteID, setDeleteId] = useState()
  const getEditEventListSlice = useSelector(state => state?.getEditEventListSlice)
  const EventGetEditManagerSlice = useSelector(state => state?.EventGetEditManagerSlice)

  const handleEdit = (e) => {
    // console.log("ee", eventid, open)
    localStorage.setItem("eventmanagerid", eventid)
    dispatch(EventGetEditManagerListAPI(eventid))
  }
  // useEffect(() => {
  //   if (getEditEventListSlice?.value?.success == true) {
  //     navigate(`/admin/events/create-event/event-information/${getEditEventListSlice?.value?.data?.id}`)
  //   }
  //   // console.log("getEditEventListSlice", getEditEventListSlice)
  // }, [getEditEventListSlice])
  const handleDelete = (e) => {
    setOpendelete(true)
  }
  const handleDeleteevent = () => {
    dispatch(EventDeleteManagerAPI(eventid))
    // eventid
    setOpendelete(false);
  }
  return (
    <>
      <div className={classes.ActionRow}>
        <IconButton aria-label="edit" onClick={(e) => handleEdit(e)}>
          <EditNoteRoundedIcon />
        </IconButton>
        <IconButton aria-label="delete" onClick={(e) => handleDelete(e)}>
          <DeleteIcon />
        </IconButton>
      </div>
      <DeletePopup setOpen={setOpendelete} open={opendelete} handleDelete={() => handleDeleteevent()} />

      <AddParticipantModal open={open} setOpen={setOpen} eventid={eventid} />


    </>
  );
}



function createData(id, authors, checkIn, foodVoucherLeft, gameBooth, progress, action) {
  return {
    id,
    authors,
    checkIn,
    foodVoucherLeft,
    gameBooth,
    progress,
    action,
  };
}

const rows = [
  createData(1,
    <ParticipantCard
      Image={Pic}
      Name="Janice wang"
      Validity="16 Dec, 2024 to 19 Dec, 2024"
    />,
    '1/5',
    '5/5',
    '0/5',
    <ProgressBar Value="25" />,
    // <AuthorAction />
  ),
  createData(2,
    <ParticipantCard
      Image={Pic}
      Name="Janice wang"
      Validity="16 Dec, 2024 to 19 Dec, 2024"
    />,
    '2/5',
    '4/5',
    '0/5',
    <ProgressBar Value="25" />,
    // <AuthorAction />
  ),
  createData(3,
    <ParticipantCard
      Image={Pic}
      Name="Janice wang"
      Validity="16 Dec, 2024 to 19 Dec, 2024"
    />,
    '3/5',
    '3/5',
    '0/5',
    <ProgressBar Value="100" />,
    // <AuthorAction />
  ),
  createData(4,
    <ParticipantCard
      Image={Pic}
      Name="Janice wang"
      Validity="16 Dec, 2024 to 19 Dec, 2024"
    />,
    '4/5',
    '2/5',
    '0/5',
    <ProgressBar Value="50" />,
    // <AuthorAction />
  ),
  createData(5,
    <ParticipantCard
      Image={Pic}
      Name="Janice wang"
      Validity="16 Dec, 2024 to 19 Dec, 2024"
    />,
    '5/5',
    '1/5',
    '0/5',
    <ProgressBar Value="90" />,
    // <AuthorAction />
  ),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  // console.log("array", array)
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}



function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const ProgramListingSlice = useSelector(state => state?.ProgramListingSlice)
  const ProgramListing = ProgramListingSlice?.value?.data?.map((data) => ({
    id: data?.title,
    numeric: false,
    label: data?.title,
  }))
  const headCells = [
    {
      id: 'Name',
      numeric: false,
      label: 'Name',
    },
    {
      id: 'Email',
      numeric: true,
      label: 'Email',
    },
    {
      id: 'event_name',
      numeric: true,
      label: 'Event Name',
    },
    {
      id: 'action',
      numeric: true,
      label: 'Action',
    },



  ];

  // console.log("ProgramListingSlice", headCells)
  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? 'right' : 'left'}
            align='center'
            padding={headCell.disablePadding ? 'none' : 'normal'}
          // sortDirection={orderBy === headCell.id ? order : false}
          >
            {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            > */}
            {headCell.label}
            {/* {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null} */}
            {/* </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, Data, selected, page, pagecheck, setPage, setSelected } = props;
  const classes = useStyles();
  const dispatch = useDispatch()
  const [selectedvalue, setselectedvalue] = React.useState("")
  const ProgramListingSlice = useSelector(state => state?.ProgramListingSlice)
  const [search, setsearch] = useState("");
  const [open, setOpen] = useState(false)
  const [pickupsearchcheck, setpickupsearchcheck] = useState(false)
  const SingleDeleteEventSlice = useSelector(state => state?.SingleDeleteEventSlice)
  const AdminBulkDeleteEventSlice = useSelector(state => state?.AdminBulkDeleteEventSlice)

  return (
    <div className={classes.ParticipantTableHeader}>
      <div className={classes.ParticipantTableHeaderLeft}>

        <h4 id="tableTitle">All Event Manager</h4>

      </div>
    

    </div>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EventManagerTable() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('checkIn');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [pagecheck, setPagecheck] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const AdminEventListSlice = useSelector(state => state?.AdminEventListSlice)
  const PartcipantSlice = useSelector(state => state?.PartcipantSlice)
  const ResetPasswordSlice = useSelector(state => state?.ResetPasswordSlice)
  const [success, setsuccess] = useState(false)
  const EventManagerPostSlice = useSelector(state => state?.EventManagerPostSlice)
  const EventGetEditManagerSlice = useSelector(state => state?.EventGetEditManagerSlice)
  console.log("EventManagerPostSlice", EventManagerPostSlice)
  const [open, isopen] = useState(false)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const [Data, setdata] = React.useState([])
  const [Datalength, setdatalength] = React.useState([])
  const [Datas, setdatas] = React.useState([])
  const dispatch = useDispatch()
  const programactivitesID = localStorage.getItem("programactivitesID")
  const SingleDeleteEventSlice = useSelector(state => state?.SingleDeleteEventSlice)
  const ArchiveEventSlice = useSelector(state => state?.ArchiveEventSlice)
  const EventManagerListSlice = useSelector(state => state?.EventManagerListSlice)

  React.useEffect(() => {
    if (EventManagerListSlice?.value?.data != undefined) {
      // console.log("adminEvent", PartcipantSlice)
      const filtereventidnull=EventManagerListSlice?.value?.data?.user?.filter((data)=>data?.event_id!=null)
      setdata(filtereventidnull)
      setdatalength(EventManagerListSlice?.value?.data?.pagination?.count)
    } else if (EventManagerListSlice?.error?.message) {
      setdata([])
      setdatalength([])
    }
  }, [EventManagerListSlice])
  console.log("EventManagerListSlice", EventManagerListSlice)
  // console.log("Datalength?.pagination?.count", Data)
  React.useEffect(() => {
    if (EventManagerListSlice?.value?.data != undefined) {
      // console.log("adminEvent", AdminEventListSlice)
      setdatas(EventManagerListSlice?.value?.data?.user)
    }
  }, [EventManagerListSlice])
  React.useEffect(() => {
    // setselectedvalue(Data[0]?.id)
    // dispatch(PartcipantsProgramListingAPI(Data[0]?.id))
    // dispatch(EventDashboardAPI(Data[0]?.id))
    // localStorage.setItem("programactivitesID", Data[0]?.id)
    if (EventManagerListSlice && EventManagerListSlice !== null && EventManagerListSlice !== undefined && EventManagerListSlice !== "undefined") {
      // localStorage.setItem("participantpage", page + 1)
      dispatch(EventManagerListAPI(page+1))
    }
  }, [page, programactivitesID])

  // console.log("PartcipantSlice", PartcipantSlice)

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = PartcipantSlice?.value?.data?.user?.map((n) => n.user_id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPagecheck(true)
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPagecheck(true)
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(Data, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, Data],
  );

  const classes = useStyles();
  useEffect(() => {
    if (ResetPasswordSlice?.value?.message) {

      setsuccess(true);
      // setOpentrue(false);

      // setnewPassworderror("")
      // setConfirmPassword("")
      setTimeout(() => {
        dispatch(Clear_ResetPassword_Check())
      }, 3000)
    }
  }, [ResetPasswordSlice])

  useEffect(() => {
    dispatch(EventManagerListAPI())
    dispatch(ArchiveEvent())
  }, [])

  useEffect(() => {
    if (EventGetEditManagerSlice?.value?.message || EventManagerPostSlice?.value?.message) {
      isopen(true)
    }
  }, [EventGetEditManagerSlice])


  console.log("EventManagerPostSlice",EventManagerPostSlice)
  return (
    <>{
      EventManagerListSlice?.loading == true && (
        <AdmLoader />
      )
    }
      {/* {
        (EventGetEditManagerSlice?.value?.message) && (
          <Snacbar
            open={open}
            setOpen={isopen}
            messagetype={"success"}
            message={(EventGetEditManagerSlice?.value?.message)}
          />

        )
      } */}
      {
        (EventManagerPostSlice?.value?.message) && (
          <Snacbar
            open={open}
            setOpen={isopen}
            messagetype={"success"}
            message={(EventManagerPostSlice?.value?.message)}
          />

        )
      }
      {
        (ResetPasswordSlice?.value?.message) && (
          <Snacbar
            open={success}
            setOpen={setsuccess}
            messagetype={"success"}
            message={(ResetPasswordSlice?.value?.message)}
          />

        )
      }
      <div className={classes.ParticipantTable}>
        <EnhancedTableToolbar numSelected={selected.length} Data={Datas} selected={selected} page={page} setPage={setPage} pagecheck={pagecheck} setSelected={setSelected} />
        {Data?.length > 0 ? (
          <>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={PartcipantSlice?.value?.data?.user?.length}
                />
                <TableBody>
                  {Data?.map((row, index) => {
                    const isItemSelected = isSelected(row.user_id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover

                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.user_id}
                        selected={isItemSelected}
                        sx={{ cursor: 'pointer' }}
                      >
                        {/* <TableCell padding="checkbox"
                          onClick={(event) => handleClick(event, row.user_id)}
                        >
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell> */}
                        <TableCell
                          // component="th"
                          id={labelId}
                          scope="row"
                          align="left"
                        >
                          {row?.name}
                        </TableCell>
                        <TableCell
                          // component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          align="center"
                        >
                          {row?.email}
                        </TableCell>
                        <TableCell
                          // component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          align="center"
                        >
                          {row?.event_name}
                        </TableCell>
                        {/* <TableCell align="center">{row?.checked_in}</TableCell> */}

                        <TableCell align="center"><AuthorAction eventid={row?.user_id} /></TableCell>
                      </TableRow>
                    );
                  })}
                  {/* {emptyRows > 0 && (
                    <TableRow>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={Datalength}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />

          </>
        ) : <>
          <div className={classes.PlaceHolder}>
            No Event Manager.
          </div>
        </>}
      </div>
    </>
  );
}