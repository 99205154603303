


import React from 'react'

const InnerDimension = () => {
    const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
 
}

export default InnerDimension