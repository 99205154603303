import axios from "axios";
import { APIURL } from "../../../../APIHandler/APIURL"
import { Headers } from "../../../../APIHandler/HeaderAPI";
import { reloadHandler } from "../../../../utlis/utlis";
import { Add_Participant_Activities_failure, Add_Participant_Activities_request, Add_Participant_Activities_success } from "../../../Reducer/Admin/Addpartcipantactivities/Addpartcipantactivities";
import { PartcipantsProgramListingAPI } from "../ParticipantProgramLisiting/ParticipantProgram";
import { PartcipantsAPI } from "../Partcipants/Partcipants";
import { PartcipantsActivitesAPI } from "../PartcipantActivites/PartcipantActivities";
import { EventDashboardAPI } from "../EventList/EventDashboardList";

export const AddpartcipantactivitiesAPI = (payload,eventid,programactivitesID) => async (dispatch, getState) => {
    // const eventItemId=localStorage.getItem("eventItemId")
    const auth_token = localStorage.getItem("auth_token")
    const programactivitesID = localStorage.getItem("programactivitesID")
    const participantpage = localStorage.getItem("participantpage")
  const paginationnumber=localStorage.getItem("paginationnumber")
   
    dispatch(Add_Participant_Activities_request())
    try {
        const { data } = await axios.post(`${APIURL.Addpartcipantactivities}`, payload,{
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
                "event-slug":Headers?.["event-slug"],
                "Content-type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${auth_token}`
            },
        })
        localStorage.setItem("admineventid",JSON.stringify(data?.data?.id))
        dispatch(Add_Participant_Activities_success(data))
        dispatch(PartcipantsProgramListingAPI(programactivitesID))
        dispatch(PartcipantsAPI(programactivitesID,participantpage,paginationnumber))
        dispatch(EventDashboardAPI(programactivitesID))
        dispatch(PartcipantsActivitesAPI(eventid, programactivitesID))
    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(Add_Participant_Activities_failure(error?.response?.data))
    }
}


  