import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
}

export const IncreasePartcipantQuantitySlice = createSlice({
  name: 'IncreasePartcipantQuantity',
  initialState,
  reducers: {
    Increase_Partcipant_Quantity_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    Increase_Partcipant_Quantity_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};

    },
    Increase_Partcipant_Quantity_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_Increase_Partcipant_Quantity_Check: (state, action) => {
      state.value = {}
      state.error = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const {Clear_Increase_Partcipant_Quantity_Check, Increase_Partcipant_Quantity_failure, Increase_Partcipant_Quantity_success, Increase_Partcipant_Quantity_request } = IncreasePartcipantQuantitySlice.actions

export default IncreasePartcipantQuantitySlice.reducer