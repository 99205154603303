import { makeStyles } from '@mui/styles';
import AdminTheme from '../../Common/AdminTheme';
export const useStyles = makeStyles({
    
    QRRow: {
        display: "flex",
        gap: "64px",
        alignItems: "center"
    },


    RadioButtonCard: {
        "& h6":{
            fontSize: "14px",
            fontWeight: "700",
            lineHeight: "21px",
            fontFamily: AdminTheme.poppins,
            color: "#464E5F",
            marginBottom: "16px"
        },
    },
    

    RadioButtonText: {
        flex: "1",
        justifyContent: "flex-end",
        marginLeft: "0 !important",
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "21px",
        fontFamily: AdminTheme.poppins,
        color: "#464E5F"
    },
    RadioButtonMark: {
        color: `${AdminTheme.themeColor} !important`
    },

    QRCodeImage: {
        width: "100px",
        height: "100px",
        flexShrink: "0",
        "& img": {
            width: "100%",
            height: "100%",
            objectFit: "contain",
            lineHeight: "0"
        }
    }


});