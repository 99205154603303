import React, { useEffect, useState } from 'react';
import { useStyles } from "./Style";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import AdminTheme from '../../Common/AdminTheme';
import TablePagination from '@mui/material/TablePagination';
import { useSelector } from 'react-redux';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F3F6F9",
    color: "#434349",
    border: "0",
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "18px",
    fontFamily: AdminTheme.poppins,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "18px",
    fontFamily: AdminTheme.poppins,
    color: "#434349",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: "#fff",
  },
  '&:nth-of-type(even)': {
    backgroundColor: "rgba(243, 246, 249, 0.5)",
  },
  // hide last border
  '& td, & th': {
    border: 0,
  },
}));

function createData(name, signIn, registrationDate, registrationTime) {
  return { name, signIn, registrationDate, registrationTime };
}

const rows = [
  createData('Sandy', 'Y', '12/01/2024', '10:15AM'),
  createData('Janice', 'N', '12/01/2024', '10:15AM'),
  createData('Sandy', 'Y', '12/01/2024', '10:15AM'),
  createData('Janice', 'N', '12/01/2024', '10:15AM'),
  createData('Sandy', 'Y', '12/01/2024', '10:15AM'),
  createData('Janice', 'N', '12/01/2024', '10:15AM'),
];


const PreviewTable = (props) => {
  const { PartcipantSlice, pagelength, setpagelength } = props;
  const classes = useStyles();
  // console.log("PartcipantSlice",PartcipantSlice)
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const ImportExportSoketSlice = useSelector(state => state?.ImportExportSoketSlice)
  const [listdata, setlistdata] = useState([])
  const [count, setcount] = useState(0)


  useEffect(() => {
    if (PartcipantSlice?.value?.data?.user != undefined) {
      setlistdata(PartcipantSlice?.value?.data?.user)
      setcount(PartcipantSlice?.value?.data?.pagination?.count)
    }
  }, [PartcipantSlice])
  useEffect(() => {
    if (PartcipantSlice?.value?.data?.user != undefined && ImportExportSoketSlice?.value != undefined) {

      setlistdata([...PartcipantSlice?.value?.data?.user, ...ImportExportSoketSlice?.value])
      setcount(PartcipantSlice?.value?.data?.pagination?.count + ImportExportSoketSlice?.value?.length)
    }
  }, [ImportExportSoketSlice])
  console.log("ImportExportSoketSlice", count, listdata, ImportExportSoketSlice?.value)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setpagelength(newPage + 1)
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setpagelength(1)
  };
  return (
    <>
      <div className={classes.PreviewTableCard}>
        <TableContainer>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Participant Name</StyledTableCell>
                <StyledTableCell align="center">Username</StyledTableCell>
                <StyledTableCell align="center">Registration date</StyledTableCell>
                <StyledTableCell align="center">Registration time</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listdata?.slice(0,20).map((row) => (
                <StyledTableRow key={row.name}>
                  <StyledTableCell component="th" scope="row">{row?.name}</StyledTableCell>
                  <StyledTableCell align="center">{row?.email}</StyledTableCell>
                  <StyledTableCell align="center">{row?.register_date}</StyledTableCell>
                  <StyledTableCell align="center">{row?.register_time}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  )
}

export default PreviewTable