import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
  error:{}
}

export const CreateSpeakerSlice = createSlice({
  name: 'CreateSpeaker',
  initialState,
  reducers: {
    Create_Speaker_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    Create_Speaker_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};

    },
    Create_Speaker_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_Create_Speaker_Check: (state, action) => {
      state.value = {}
      state.error = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const {Create_Speaker_request, Create_Speaker_success, Create_Speaker_failure, Clear_Create_Speaker_Check } = CreateSpeakerSlice.actions

export default CreateSpeakerSlice.reducer