import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import AdminTheme from '../../Common/AdminTheme';
import { MenuItem } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetSpeakerAPI } from '../../../redux/Action/Admin/Speakers/GetSpeakers';
import { useParams } from 'react-router-dom';

const options = [
  { value: '1', label: 'Eric', src: '/static/images/avatar/1.jpg' },
  { value: '2', label: 'Smith', src: '/static/images/avatar/2.jpg' },
  { value: '3', label: 'Erika', src: '/static/images/avatar/3.jpg' },
];


const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

export default function SpeakerSelect(props) {
  const [personName, setPersonName] = React.useState([]);
  const theme = useTheme();
  const {speaker,setspeaker,setspeakererror}=props;
  const dispatch=useDispatch();
  const admineventid = localStorage.getItem("admineventid")
  const GetspeakersSlice = useSelector(state => state?.GetspeakersSlice)
 const {id}=useParams();
  const handleChange = (event, newValue) => {
    // console.log(`You have choosen "${newValue}"`);
    setspeaker(newValue);
    setspeakererror(false)
  };
  useEffect(() => {
    if(id){

      dispatch(GetSpeakerAPI(id))
    }else{

      dispatch(GetSpeakerAPI(admineventid))
    }
  }, [])
  // console.log("speakerspeaker",speaker)

  return (
    
    <Select
      // defaultValue={["1"]}
      multiple
      indicator={<ArrowDropDownOutlinedIcon />}
      // renderValue={renderValue}
      value={speaker!=null &&speaker!=undefined && speaker?.length>0 && speaker!=""?speaker:[]}
      onChange={handleChange}
      sx={{
        '--ListItemDecorator-size': '44px',
        minWidth: 240,
        fontSize: "14px",
        fontWeight: "600",
        lineHeight: "21px",
        color: "#434349",
        fontFamily: AdminTheme.poppins,
        minHeight: "42px",
        borderRadius: "4px",
        background: "#F3F6F9",
        padding: "8px 14px 8px 10px",
        border: "none",
        "&::placeholder": {
          color: "#D6D6E0",
          fontWeight: "400",
        },
        "& .MuiSelect-indicator": {
          transition: '0.2s',
        },
        "& .Mui-expanded": {
          transform: 'rotate(-180deg)',
        }
      }}
    >
      {GetspeakersSlice?.value?.data?.map((name) => (
        <Option value={name?.id}>{name?.name}</Option>

      ))}
      {GetspeakersSlice?.value?.data?.length==0 &&
        <Option value="">No Speaker</Option>

      }
      {/* {names.map((name) => (
        <MenuItem
          key={name}
          value={name}
        // style={getStyles(name, personName, theme)}
        >
          {name}
        </MenuItem>
      ))} */}
    </Select>
  );
}

