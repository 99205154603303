import { makeStyles } from '@mui/styles';
import ColorTheme from '../../Common/CommonCss/ColorTheme';
import InnerDimension from '../../Components/InnerDimension/InnerDimension';


export const useStyles = makeStyles({

    ScannerContainer: {
        position: "relative",
        lineLeight: "0",
        // height: `${InnerDimension().height}px`,
        height: "100dvh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        "& .vd-container div": {
            paddingTop: "0px !important",
            position: "static !important",
        }
    },

    // "@media(max-height: 575px)": {
    //     ScannerContainer: {
    //     "& .scanner-container video": {
    //         display: "none"
    //         }
    //     }
    // },








    ScannerWindow: {
        // width: QRTheme.WindowWidth,
        // height: QRTheme.WindowWidth,
        "&:after": {
            borderTopColor: ColorTheme.QrAngleColor,
            borderLeftColor: ColorTheme.QrAngleColor,
        },
        "&:before": {
            borderTopColor: ColorTheme.QrAngleColor,
            borderRightColor: ColorTheme.QrAngleColor,
        },
        "& .inner": {
            "&:after": {
                borderRightColor: ColorTheme.QrAngleColor,
                borderBottomColor: ColorTheme.QrAngleColor,
            },
            "&:before": {
                borderBottomColor: ColorTheme.QrAngleColor,
                borderLeftColor: ColorTheme.QrAngleColor,
            },
        },
        "& .line": {
            backgroundColor: ColorTheme.QrAngleColor,
        }
    }
    
});