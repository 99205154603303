// resetpassword

import axios from "axios";
import { APIURL } from "../../../APIHandler/APIURL"
import { Headers } from "../../../APIHandler/HeaderAPI";
import { reloadHandler } from "../../../utlis/utlis";
import { ResetPassword_failure, ResetPassword_request, ResetPassword_success } from "../../Reducer/ResetPassword/ResetPAsswordSlice";

export const ResetPasswordAPI = (id,payload) => async (dispatch, getState) => {
    dispatch(ResetPassword_request())
    // const eventItemId=localStorage.getItem("eventItemId")
    const auth_token = localStorage.getItem("auth_token")
  
    try {
        const { data } = await axios.put(`${APIURL.resetpassword}/${id}`, payload,{
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
              "event-slug":Headers?.["event-slug"],
                "Content-type": "application/json",
                Authorization: `Bearer ${auth_token}`
            },
        })
        dispatch(ResetPassword_success(data))

    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(ResetPassword_failure(error?.response?.data))

    }
}