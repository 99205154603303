import React from 'react'
import { useStyles } from "./Style";
import QrCodeOutlinedIcon from '@mui/icons-material/QrCodeOutlined';
import ColorTheme from '../../Common/CommonCss/ColorTheme';
import { useState } from 'react';
import { useEffect } from 'react';
const EventCardStaff = (props) => {
    const classes = useStyles();
    const {EventPic, EventTitle,data} = props;
    const [start_datecheck, setstart_datecheck] = useState(false)
    const [end_datecheck, setend_datecheck] = useState(false)
 
    return (
        <>
            <div className={classes.EventCard}>
                <figure>
                    <img src={EventPic} alt='' />
                    <QrCodeOutlinedIcon className={classes.QRIcon} sx={{color: ColorTheme.textColor}} />
                </figure>
                <div className={`${classes.EventCardFooter} text-truncate-2`}>
                    {EventTitle?.slice(0,30)} {EventTitle?.length>30?"...":""}
                </div>
            </div>
        </>
    )
}

export default EventCardStaff;
