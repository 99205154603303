import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
}

export const DuplicateListSlice = createSlice({
  name: 'DuplicateList',
  initialState,
  reducers: {
    DuplicateList_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    DuplicateList_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};

    },
    DuplicateList_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_DuplicateList_Check: (state, action) => {
      state.value = {}
      state.error = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const {DuplicateList_request, DuplicateList_success, DuplicateList_failure, Clear_DuplicateList_Check } = DuplicateListSlice.actions

export default DuplicateListSlice.reducer