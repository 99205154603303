import { makeStyles } from '@mui/styles';
import ColorTheme from '../../Common/CommonCss/ColorTheme';
export const useStyles = makeStyles({
    Input:{
        borderRadius: "10px !important",
        backgroundColor: `${ColorTheme.orange5} !important`,
        borderColor: `${ColorTheme.orange5} !important`,
        "& focus":{
            borderColor: `${ColorTheme.orange} !important`
        }
    },

})