import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
}

export const AdminCreateActivitiesSlice = createSlice({
  name: 'AdminCreateActivities',
  initialState,
  reducers: {
    Admin_Create_Activities_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    Admin_Create_Activities_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};

    },
    Admin_Create_Activities_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_Admin_Create_Activities_Check: (state, action) => {
      state.value = {}
      state.error = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const {Clear_Admin_Create_Activities_Check, Admin_Create_Activities_failure, Admin_Create_Activities_success, Admin_Create_Activities_request } = AdminCreateActivitiesSlice.actions

export default AdminCreateActivitiesSlice.reducer