import React from 'react'
import { useStyles } from "./Style";
import HeaderBg from '../../../Images/admin/header.png'
import QR from '../../../Images/admin/qr.svg'
import ButtonSecondary from '../ButtonSecondary/ButtonSecondary';
import ButtonPrimary from '../ButtonPrimary/ButtonPrimary';
import Grid from '@mui/joy/Grid';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ScheduleIcon from '@mui/icons-material/Schedule';

import Avatar from '@mui/material/Avatar';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import { ReactComponent as WebLinkIcon } from '../../../Images/admin/weblink-icon.svg';
import { ReactComponent as AttachmentIcon } from '../../../Images/admin/attachment-icon.svg';
import { useDispatch } from 'react-redux';
import { DeleteActivitiesAPI } from '../../../redux/Action/Admin/CreateActitvites/DeleteActivity';
import { GetEditActivitiesAPI } from '../../../redux/Action/Admin/CreateActitvites/GetEditActivity';
import { Link as Linkscroll } from "react-scroll";
import { useParams } from 'react-router-dom';

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ActivitiesInfo = (props) => {
    const { data, seteditid, setaddactivites, seteditopen } = props;
    const classes = useStyles();
    const { id } = useParams();
    const dispatch = useDispatch();
    const archive = JSON.parse(localStorage.getItem("archive"));

    // console.log("datavv", data)
    const handleDelete = (ids) => {
        if (id) {

            dispatch(DeleteActivitiesAPI(ids, id))
        } else {

            dispatch(DeleteActivitiesAPI(ids))
        }
    }
    const handleEdit = (id) => {
        dispatch(GetEditActivitiesAPI(id))
        setaddactivites(true)
        seteditopen(true)
        seteditid(id)
    }
    const addTargetBlankToAnchors = (html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const anchors = doc.querySelectorAll('a');
    
        anchors.forEach(anchor => {
          anchor.setAttribute('target', '_blank');
        });
    
        return doc.body.innerHTML;
      };
    return (
        <>
            <Accordion className={classes.InfoCard}>
                <AccordionSummary aria-controls="panel1-content" id="panel1-header" sx={{ margin: "0" }}>
                    <div className={classes.InfoCardHeader}>
                        <img src={data?.banners[0]?.image_url} alt='' className='cover' />
                        <div className={classes.QrIcon}>
                            <img src={QR} alt='QR' className='contain' />
                        </div>
                    </div>
                    <div className={classes.TitleRw}>
                        <h3>{data?.title}</h3>
                        <ExpandMoreIcon className='expand-icon' />
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className={classes.InfoCardBody}>
                        <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                            <Grid xs={6} md={3}>
                                <div className={classes.TextP}>
                                    <CalendarTodayIcon /> Start date
                                </div>
                                <div className={classes.TextH6}>{data?.start_date}</div>
                            </Grid>
                            <Grid xs={6} md={3}>
                                <div className={classes.TextP}>
                                    <CalendarTodayIcon /> End date
                                </div>
                                <div className={classes.TextH6}>{data?.end_date}</div>
                            </Grid>
                            <Grid xs={6} md={3}>
                                <div className={classes.TextP}>
                                    <ScheduleIcon /> Start time
                                </div>
                                <div className={classes.TextH6}>{data?.start_time}</div>
                            </Grid>
                            <Grid xs={6} md={3}>
                                <div className={classes.TextP}>
                                    <ScheduleIcon /> End time
                                </div>
                                <div className={classes.TextH6}>{data?.end_time}</div>
                            </Grid>
                        </Grid>

                        <div className={`${classes.SpeakerList} ${classes.Mt2}`}>
                         {
                            data?.organiser?.length>0 && (

                            <h4 className={classes.InfoCardH4}>Speakers</h4>
                            )
                         }
                            <List className={classes.ListHorizontal} orientation="horizontal">
                                {
                                    data?.organiser?.map((data) => {
                                        return <ListItem>
                                            <ListItemDecorator>
                                                <Avatar variant="rounded" src={data?.image_url} />
                                            </ListItemDecorator>
                                           {data?.name}
                                        </ListItem>
                                    })
                                }

                               
                            </List>
                        </div>

                        <div className={`${classes.Description} ${classes.Mt2}`}>
                            <h4 className={classes.InfoCardH4}>Description</h4>
                            <div className={classes.DescContent}
                                dangerouslySetInnerHTML={{
                                    __html: addTargetBlankToAnchors(data?.description)
                                }}
                            ></div>
                        </div>

                      {data?.pdf!="" &&  <div className={`${classes.Attachment} ${classes.Mt2}`}>
                            <h4 className={classes.InfoCardH4}>Attachment</h4>
                            <List className={classes.ListHorizontal} orientation="horizontal">
                                
                                <ListItem>
                                    <ListItemDecorator>
                                        <AttachmentIcon />
                                    </ListItemDecorator>
                                    Activity.pdf
                                </ListItem>
                            </List>
                        </div>}

                    </div>
                   {archive!=true && <div className={classes.InfoCardFooter}>
                        <div>
                            <Linkscroll
                                activeClass="active"
                                to="viewmores"
                                spy={true}
                                smooth={true}
                                offset={50}
                                duration={300}
                            >
                                <ButtonSecondary label="Edit" handleClick={() => {handleEdit(data?.id)
                                localStorage.setItem("editactivityid",data?.id)
                                }} />
                            </Linkscroll>
                        </div>
                        <div>
                            <ButtonPrimary disabledcheck={true} label="Delete" handleClick={() => handleDelete(data?.id)} />
                        </div>
                    </div>}
                </AccordionDetails>
            </Accordion>

















            <div className={classes.InfoCard} style={{ display: 'none' }}>
                <div className={classes.InfoCardHeader}>
                    <img src={data?.banners[0]?.image_url} alt='' className='cover' />
                    <div className={classes.QrIcon}>
                        <img src={QR} alt='QR' className='contain' />
                    </div>
                </div>
                <div className={classes.InfoCardBody}>
                    <h3>Activities Information</h3>
                    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                        <Grid xs={6} md={3}>
                            <div className={classes.TextP}>
                                <CalendarTodayIcon /> Start date
                            </div>
                            <div className={classes.TextH6}>{data?.start_date}</div>
                        </Grid>
                        <Grid xs={6} md={3}>
                            <div className={classes.TextP}>
                                <CalendarTodayIcon /> End date
                            </div>
                            <div className={classes.TextH6}>{data?.end_date}</div>
                        </Grid>
                        <Grid xs={6} md={3}>
                            <div className={classes.TextP}>
                                <ScheduleIcon /> Start time
                            </div>
                            <div className={classes.TextH6}>{data?.start_time}</div>
                        </Grid>
                        <Grid xs={6} md={3}>
                            <div className={classes.TextP}>
                                <ScheduleIcon /> End time
                            </div>
                            <div className={classes.TextH6}>{data?.end_time}</div>
                        </Grid>
                    </Grid>

                    <div className={`${classes.SpeakerList} ${classes.Mt2}`}>
                        <h4 className={classes.InfoCardH4}>Speakers</h4>
                        <List className={classes.ListHorizontal} orientation="horizontal">
                            {/* {
                            data?.organiser?.map((data)=>{

                            })
                         } */}
                            <ListItem>
                                <ListItemDecorator>
                                    <Avatar variant="rounded" src="/static/images/avatar/1.jpg" />
                                </ListItemDecorator>
                                Mabel Boyle
                            </ListItem>
                            <ListItem>
                                <ListItemDecorator>
                                    <Avatar variant="rounded" src="/static/images/avatar/2.jpg" />
                                </ListItemDecorator>
                                Boyd Burt
                            </ListItem>
                            <ListItem>
                                <ListItemDecorator>
                                    <Avatar variant="rounded" src="/static/images/avatar/3.jpg" />
                                </ListItemDecorator>
                                Adam Tris
                            </ListItem>
                        </List>
                    </div>

                    <div className={`${classes.Description} ${classes.Mt2}`}>
                        <h4 className={classes.InfoCardH4}>Description</h4>
                        <div className={classes.DescContent}
                            dangerouslySetInnerHTML={{
                                __html: data?.description
                            }}
                        ></div>
                    </div>

                    <div className={`${classes.Attachment} ${classes.Mt2}`}>
                        <h4 className={classes.InfoCardH4}>Attachment</h4>
                        <List className={classes.ListHorizontal} orientation="horizontal">
                            <ListItem>
                                <ListItemDecorator>
                                    <WebLinkIcon />
                                </ListItemDecorator>
                                www.hipster-event.com
                            </ListItem>
                            <ListItem>
                                <ListItemDecorator>
                                    <AttachmentIcon />
                                </ListItemDecorator>
                                User-Manual.pdf
                            </ListItem>
                        </List>
                    </div>

                </div>
                <div className={classes.InfoCardFooter}>
                    <div>
                        <Linkscroll
                            activeClass="active"
                            to="viewmores"
                            spy={true}
                            smooth={true}
                            offset={50}
                            duration={300}
                        >
                            <ButtonSecondary label="Edit" handleClick={() => handleEdit(data?.id)} />
                        </Linkscroll>
                    </div>
                    <div>
                        <ButtonPrimary label="Delete" disabledcheck={true} handleClick={() => handleDelete(data?.id)} />
                    </div>
                </div>

            </div>

        </>
    )
}

export default ActivitiesInfo