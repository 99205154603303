import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
}

export const GetIdspeakersSlice = createSlice({
  name: 'GetIdspeakers',
  initialState,
  reducers: {
    Get_ID_speakers_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    Get_ID_speakers_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};

    },
    Get_ID_speakers_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_Get_ID_speakers_Check: (state, action) => {
      state.value = {}
      state.error = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const {Get_ID_speakers_request, Get_ID_speakers_success, Get_ID_speakers_failure, Clear_Get_ID_speakers_Check } = GetIdspeakersSlice.actions

export default GetIdspeakersSlice.reducer