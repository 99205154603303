import { makeStyles } from '@mui/styles';
import ColorTheme from "../../Common/CommonCss/ColorTheme";
export const useStyles = makeStyles({
    loaderContainer: {
        width: '100vw',
        height: '100dvh',
        position: 'fixed',
        zIndex: '9999',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: '0',
        left: '0',
        background: 'rgba(0, 0, 0, 0.5) !important',
        backdropFilter: "blur(4px)",
        "& > div": {
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }
    },
    loaderContainerInner: {
        width: "60px",
        height: "60px",
        background: "#fff",
        borderRadius: "100%",
        boxShadow: "0px 0px 16px 2px rgba(0, 0, 0, 0.2)"
        
    },
    loaderText: {
        fontSize: '16px',
        fontWeight: '700',
        marginTop: '5px',
        fontFamily: ColorTheme.publicSans,
        letterSpacing: "0.5px",
        color: `${ColorTheme.orange} !important`,
    }
})
