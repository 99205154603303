import axios from "axios";
import { APIURL } from "../../../../APIHandler/APIURL"
import { Headers } from "../../../../APIHandler/HeaderAPI";
import { reloadHandler } from "../../../../utlis/utlis";
import { Admin_Event_List_failure, Admin_Event_List_request, Admin_Event_List_success } from "../../../Reducer/Admin/EventList/EventListSlice";
import { AdminEventListAPI } from "./EventList";
import { Admin_Single_Delete_Event_failure, Admin_Single_Delete_Event_request, Admin_Single_Delete_Event_success } from "../../../Reducer/BulkDeleteEvent/SingleEventDelete";
import { EventDashboardAPI } from "./EventDashboardList";
import { ArchiveEvent } from "../ArchiveEvents/ArchiveEvent";

export const AdminDeleteEventAPI = (payload) => async (dispatch, getState) => {
    const admineventid=localStorage.getItem("admineventid")
    const auth_token = localStorage.getItem("auth_token")
    dispatch(Admin_Single_Delete_Event_request())
    try {
        const { data } = await axios.delete(`${APIURL.admineventdeletelist}/${payload}`,{
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
               "event-slug":Headers?.["event-slug"],
                "Content-type": "application/json",
                Authorization: `Bearer ${auth_token}`
            },
        })
        dispatch(Admin_Single_Delete_Event_success(data))
        dispatch(AdminEventListAPI())
        dispatch(EventDashboardAPI(admineventid))
        dispatch(ArchiveEvent())
    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(Admin_Single_Delete_Event_failure(error?.response?.data))
    }
}
export const AdminDeleteArchiveEventAPI = (payload) => async (dispatch, getState) => {
    const admineventid=localStorage.getItem("admineventid")
    const auth_token = localStorage.getItem("auth_token")
    dispatch(Admin_Single_Delete_Event_request())
    try {
        const { data } = await axios.delete(`${APIURL.deletearchiveevent}/${payload}`,{
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
               "event-slug":Headers?.["event-slug"],
                "Content-type": "application/json",
                Authorization: `Bearer ${auth_token}`
            },
        })
        dispatch(Admin_Single_Delete_Event_success(data))
        dispatch(AdminEventListAPI())
        dispatch(EventDashboardAPI(admineventid))
        dispatch(ArchiveEvent())
    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(Admin_Single_Delete_Event_failure(error?.response?.data))
    }
}


  