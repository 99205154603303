import { makeStyles } from '@mui/styles';
import AdminTheme from '../../Common/AdminTheme';

export const useStyles = makeStyles({

    FormGroup :{
        marginBottom: "27px"
    },
    FormLabel :{
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "21px",
        color: "#464E5F",
        fontFamily: AdminTheme.poppins,
        marginTop: "0px",
        marginBottom: "5px",
        "& span": {
            color: "#EA0000",
        }
    },

    APModal: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: "774px",
        width: "100%",
        background: "#fff",
        padding: "50px 30px",
        borderRadius: "12px",
        maxHeight: "calc(100dvh - 140px)",
        overflowY: "auto",
        "& .input-container": {
            "& .MuiGrid-item": {
                minWidth: "100px"
            },
            "& .btn-with-icon": {
                marginTop: "20px",
                minHeight: "46px",
                padding: "4px 4px",
                width: "100%",
                "& svg": {
                    marginLeft: "-2px",
                    paddingRight: "2px"
                }
            },
            
        },
        '@media (max-width: 900px)' : {
            maxWidth: "600px",
            width: "80%"
        }
    },

    CloseBtn: {
        position: "absolute",
        right: "15px",
        top: "15px",
        cursor: "pointer"
    },

    APModalBody: {
        maxWidth: "536px",
        margin: "auto",
        "& .title-rw": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "34px",
        },
        "& h4": {
            fontSize: "18px",
            lineHeight: "22px",
            fontFamily: AdminTheme.poppins,
            fontWeight: "500",
            color: AdminTheme.headingColor,
            marginTop: "0",
            marginBottom: "0px",
        }
    },

    ActiviteisTable: {
        "& table": {
            "& th, & td":{
                fontFamily: AdminTheme.poppins,
                fontSize: "12px",
                lineHeight: "18px",
                color: "#434349",
                padding: "12px 11px"
            },
            "& th":{
                background: AdminTheme.inputBg,
                fontWeight: "600"
            },
            "& td":{
                fontWeight: "400"
            },
            "& tr:nth-child(even) td":{
                background: AdminTheme.inputBg50
            },
            "& .inc-dec-btn": {
                margin: "auto"
            }
        }
    },

    DelBtn: {
        background: `${AdminTheme.inputBg50} !important`,
        width: "26px",
        height: "26px",
        "& .MuiSvgIcon-root": {
            color: AdminTheme.btnBgColor
        }
    },

    BtnLink: {
        fontFamily: AdminTheme.poppins,
        border: "none",
        padding: "0",
        outline: "0",
        color: AdminTheme.btnBgColor,
        background: "transparent",
        textDecoration: "underline",
        fontSize: "inherit",
        lineHeight: "inherit",
        fontWeight: "600",
        cursor: "pointer"
    },



    '@media (max-width: 440px)' : {
        APModal: {
            padding: "50px 20px",
            width: "85%",
            "& .input-container": {
                marginLeft: "-8px",
                width: "calc(100% + 8px)",
                "& .MuiGrid-item": {
                    paddingLeft: "8px",
                },
                }
            }
        },

    '@media (max-width: 380px)' : {
        APModal: {
            width: "90%",
            padding: "50px 16px",
            "& .input-container": {
                marginLeft: "-4px",
                width: "calc(100% + 4px)",
                "& .MuiGrid-item": {
                    paddingLeft: "4px",
                },
                }
        }
    },
    '@media (max-width: 360px)' : {
        APModal: {
            width: "92%",
            padding: "50px 10px",
            "& .input-container": {
                marginLeft: "-3px",
                width: "calc(100% + 3px)",
                "& .MuiGrid-item": {
                    paddingLeft: "3px",
                },
                }
        }
    }

});