import { makeStyles } from '@mui/styles';
import AdminTheme from '../../Common/AdminTheme';

export const useStyles = makeStyles({


    FormGroup :{
        marginBottom: "27px"
    },

    FormLabel :{
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "21px",
        color: "#464E5F",
        fontFamily: AdminTheme.poppins,
        marginTop: "0px",
        marginBottom: "5px",
        "& span": {
            color: "#EA0000",
        }
    },

    FormInput: {
        fontSize: "14px",
        fontWeight: "600",
        lineHeight: "21px",
        color: "#434349 !important",
        fontFamily: AdminTheme.poppins,
        height: "42px !important",
        borderRadius: "4px !important",
        background: `${AdminTheme.inputBg} !important`,
        padding: "0px 20px !important",
        boxShadow: "none !important",
        "& ::placeholder": {
            color: "#D6D6E0 !important",
            fontWeight: "400",
        },
        "&.Mui-focused:before": {
            boxShadow: `0px 0px 0px 1.5px ${AdminTheme.themeColor} !important`
        },
    },



    ResetPWModal: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: "400px",
        width: "100%",
        background: "#fff",
        padding: "60px",
        borderRadius: "12px",
        "& .modal-title": {
            fontSize: "24px",
            fontWeight: "500",
            lineHeight: "32px",
            textTransform: "capitalize",
            fontFamily: AdminTheme.poppins,
            color: AdminTheme.headingColor,
            marginBottom: "40px",
            marginTop: "0",
            textAlign: "center"

        },
        "& .btn-primary": {
            width: "100%",
            marginTop: "40px"
        },
        "& .input-container": {
            "& .MuiGrid-item": {
                minWidth: "100px"
            },
            "& .btn-with-icon": {
                marginTop: "20px",
                minHeight: "46px",
                padding: "4px 4px",
                width: "100%",
                "& svg": {
                    marginLeft: "-2px",
                    paddingRight: "2px"
                }
            },
            
        },
        "@media(max-width: 575px)": {
            maxWidth: "80%",
            padding: "40px"
        },
        "@media(max-width: 480px)": {
            maxWidth: "60%",
            padding: "40px"
        }
    },


    ResetPWModalBody: {
        position: "relative",
        "& .modal-title": {
            fontSize: "24px",
            fontWeight: "500",
            lineHeight: "32px",
            textTransform: "capitalize",
            fontFamily: AdminTheme.poppins,
            color: AdminTheme.headingColor,
            marginBottom: "60px",
            marginTop: "0",
            textAlign: "center"

        },
        "& .btn-primary": {
            width: "100%",
            marginTop: "30px"
        },
    },

    CloseBtn: {
        position: "absolute",
        right: "15px",
        top: "15px",
        cursor: "pointer",
        
    },

    eyeIcon: {
        opacity: "0.5",
        "&:hover": {
            opacity: "1"
        }
    },


    BtnLink: {
        fontFamily: AdminTheme.poppins,
        border: "none",
        padding: "0",
        outline: "0",
        color: AdminTheme.btnBgColor,
        background: "transparent",
        textDecoration: "underline",
        fontSize: "inherit",
        lineHeight: "inherit",
        fontWeight: "500",
        cursor: "pointer"
    },
});