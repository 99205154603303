import { makeStyles } from '@mui/styles';
import InnerDimension from '../../Components/InnerDimension/InnerDimension';
import { padding } from '@mui/system';
export const useStyles = makeStyles({

    CheckInError: {
        "& .header": {
            marginBottom: "0px",
        },
        "& .custom-snackbar": {
            padding: "0px 15px",
            marginTop: "20px",
        },
        "& .MuiSnackbar-root": {
            position: "static !important"
        }
    },
    CheckInErrorContent: {
        // height: `calc(${InnerDimension().height}px - 94px)`,
        height: `calc(100dvh - 94px)`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "0px 25px",
        "@media(max-width:340px)":{
            // height: `calc(${InnerDimension().height}px - 74px)`,
            height: `calc(100dvh - 74px)`,
        }
    },

    IconError: {
        width: '197px',
        height: '197px',
        borderRadius: '100%',
        background: '#E6EAED',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 'auto'
    },
    btnContainer: {
        marginTop: 'auto',
        marginBottom: '30px',
        width: "100%"
    },

    
});