import axios from "axios";
import { APIURL } from "../../../../APIHandler/APIURL"
import { Headers } from "../../../../APIHandler/HeaderAPI";
import { reloadHandler } from "../../../../utlis/utlis";
import { Partcipant_Activites_failure, Partcipant_Activites_request, Partcipant_Activites_success } from "../../../Reducer/Admin/ParticipantActivitesList/PartcipantActivitiesList";

export const PartcipantsActivitesAPI = (payload,id) => async (dispatch, getState) => {
    const admineventid=localStorage.getItem("admineventid")
    const auth_token = localStorage.getItem("auth_token")
    dispatch(Partcipant_Activites_request())
    try {
        const { data } = await axios.get(`${APIURL.partcipantsactivities}/${id}/${payload}`,{
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
               "event-slug":Headers?.["event-slug"],
                "Content-type": "application/json",
                Authorization: `Bearer ${auth_token}`
            },
        })
        dispatch(Partcipant_Activites_success(data))
    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(Partcipant_Activites_failure(error?.response?.status))
    }
}


  