import { makeStyles } from '@mui/styles';
import AdminTheme from '../../Common/AdminTheme';
export const useStyles = makeStyles({
    RichEditor: {
        "& .ck.ck-toolbar": {
            background: "#EEF0F8",
            borderTopLeftRadius: "4px !important",
            borderTopRightRadius: "4px !important",
            border: "none",
        },
        "& .ck-editor__editable": {
            borderBottomLeftRadius: "4px !important",
            borderBottomRightRadius: "4px !important",
            minHeight: "calc(198px - 42px)",
            padding: "10px 20px",
            fontFamily: AdminTheme.poppins,
        },
        "& .ck-editor__editable:not(.ck-focused)": {
            borderColor: "#EEF0F8 !important",
        },
        "& .ck-editor__editable .ck-focused": {
            borderColor: `${AdminTheme.themeColor} !important`,
        },

        "& .ck-placeholder::before": {
            fontFamily: AdminTheme.poppins,
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "21px",
            letterSpacing: "0px",
            color: "#D6D6E0 !important",
            marginTop: "0 !important",
            marginBottom: "0 !important",
        },
        "& .ck-editor__editable p[data-placeholder='Write here']": {
            marginTop: "0 !important",
            marginBottom: "0 !important",
        },
        "& .ck.ck-dropdown__panel": {
            borderColor: `${AdminTheme.inputBg} !important`,
            boxShadow: `${AdminTheme.cardShadow} !important`,
            "& .ck.ck-button" :{
                boxShadow: "none !important",
            }
        },

        "& .ck.ck-button, & a.ck.ck-button, & .ck.ck-list__item": {
            border: "none !important",
            outline: "none"
        },
        "& .ck.ck-button.ck-on, & a.ck.ck-button.ck-on": {
            color: AdminTheme.themeColor,
            background: "#fff",
            outline: "none"
        },
        "& .ck.ck-button.ck-on:not(.ck-disabled):hover, & a.ck.ck-button.ck-on:not(.ck-disabled):hover": {
            background: "#fff",
        },
        "& .ck.ck-button:active, & .ck.ck-button:focus, & a.ck.ck-button:active, & a.ck.ck-button:focus": {
            border: "none !important",
            boxShadow: `0px 0px 0px 1px ${AdminTheme.themeColor} !important`,
            outline: "none",
        }

        // "& .ck-editor__editable p[data-placeholder='Write here']": {
        //     fontFamily: AdminTheme.poppins,
        //     fontSize: "14px",
        //     fontWeight: "400",
        //     lineHeight: "21px",
        //     letterSpacing: "0px",
        //     color: "#D6D6E0",
        //     paddingLeft: "14px",
        // }
    }
});