// bulkdeleteparticipants
import axios from "axios";
import { APIURL } from "../../../APIHandler/APIURL"
import { Bulk_Delete_Participant_failure, Bulk_Delete_Participant_request, Bulk_Delete_Participant_success } from "../../Reducer/Admin/ParticipantActivitesList/BulkDeleteParticipant";
import { PartcipantsProgramListingAPI } from "../Admin/ParticipantProgramLisiting/ParticipantProgram";
import { EventDashboardAPI } from "../Admin/EventList/EventDashboardList";
import { PartcipantsAPI } from "../Admin/Partcipants/Partcipants";
import { Headers } from "../../../APIHandler/HeaderAPI";
import { AdminBulkcheckinEvent_failure, AdminBulkcheckinEvent_request, AdminBulkcheckinEvent_success } from "../../Reducer/AdminBluckCheckin/AdminBluckCheckinSlice";

export const BulkcheckinProgramAPI = (payload,newValue) => async (dispatch, getState) => {
    const admineventid=localStorage.getItem("admineventid")
    const auth_token = localStorage.getItem("auth_token")
    const paginationnumber = localStorage.getItem("paginationnumber")
    dispatch(AdminBulkcheckinEvent_request())
    try {
        const { data } = await axios.post(`${APIURL.bulkcheckinexport}`,payload,{
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
              "event-slug":Headers?.["event-slug"],
                "Content-type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${auth_token}`
            },
        })
        dispatch(AdminBulkcheckinEvent_success(data))
        dispatch(PartcipantsProgramListingAPI(newValue))
        dispatch(EventDashboardAPI(newValue))
        dispatch(PartcipantsAPI(newValue,1,paginationnumber))
    } catch (error) {
        // reloadHandler(error?.response?.status)
        dispatch(AdminBulkcheckinEvent_failure(error?.response?.status))
    }
}
export const BulkremovecheckinProgramAPI = (payload,newValue) => async (dispatch, getState) => {
    const admineventid=localStorage.getItem("admineventid")
    const auth_token = localStorage.getItem("auth_token")
    const paginationnumber = localStorage.getItem("paginationnumber")
    dispatch(AdminBulkcheckinEvent_request())
    try {
        const { data } = await axios.post(`${APIURL.bulkremovecheckinadmin}`,payload,{
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
              "event-slug":Headers?.["event-slug"],
                "Content-type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${auth_token}`
            },
        })
        dispatch(AdminBulkcheckinEvent_success(data))
        dispatch(PartcipantsProgramListingAPI(newValue))
        dispatch(EventDashboardAPI(newValue))
        dispatch(PartcipantsAPI(newValue,1,paginationnumber))
    } catch (error) {
        // reloadHandler(error?.response?.status)
        dispatch(AdminBulkcheckinEvent_failure(error?.response?.status))
    }
}


  


export const SinglecheckinProgramAPI = (user_id) => async (dispatch, getState) => {
    const admineventid=localStorage.getItem("admineventid")
    const auth_token = localStorage.getItem("auth_token")
    const paginationnumber = localStorage.getItem("paginationnumber")
    dispatch(AdminBulkcheckinEvent_request())
    // console.log("kjhghfgklkhgf")
    const event_id=localStorage.getItem("programactivitesID")
    try {
        // console.log("kjhghfgklkhgftry")
        const { data } = await axios.get(`${APIURL.singlecheckinadmin}/${event_id}/${user_id}`,{
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
              "event-slug":Headers?.["event-slug"],
                "Content-type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${auth_token}`
            },
        })
        dispatch(AdminBulkcheckinEvent_success(data))
        dispatch(PartcipantsProgramListingAPI(event_id))
        dispatch(EventDashboardAPI(event_id))
        dispatch(PartcipantsAPI(event_id,1,paginationnumber))
    } catch (error) {
        console.log("kjhghfgklkhgfcatch")
        // reloadHandler(error?.response?.status)
        dispatch(AdminBulkcheckinEvent_failure(error?.response?.status))
    }
}
export const SinglenotcheckinProgramAPI = (user_id) => async (dispatch, getState) => {
    const admineventid=localStorage.getItem("admineventid")
    const auth_token = localStorage.getItem("auth_token")
    const paginationnumber = localStorage.getItem("paginationnumber")
    dispatch(AdminBulkcheckinEvent_request())
    // console.log("kjhghfgklkhgf")
    const event_id=localStorage.getItem("programactivitesID")
    try {
        // console.log("kjhghfgklkhgftry")
        const { data } = await axios.get(`${APIURL.singlenotcheckinadmin}/${event_id}/${user_id}`,{
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
              "event-slug":Headers?.["event-slug"],
                "Content-type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${auth_token}`
            },
        })
        dispatch(AdminBulkcheckinEvent_success(data))
        dispatch(PartcipantsProgramListingAPI(event_id))
        dispatch(EventDashboardAPI(event_id))
        dispatch(PartcipantsAPI(event_id,1,paginationnumber))
    } catch (error) {
        console.log("kjhghfgklkhgfcatch")
        // reloadHandler(error?.response?.status)
        dispatch(AdminBulkcheckinEvent_failure(error?.response?.status))
    }
}