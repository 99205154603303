import { makeStyles } from '@mui/styles';
import AdminTheme from '../../Common/AdminTheme';
export const useStyles = makeStyles({
   
    StatsCard: {
        background: "#fff",
        borderRadius: "12px",
        border: "1px solid #F2EFEE",
        display: "flex",
        maxWidth: "370px",
        padding: "15px 15px 20px 15px",
        gap: "15px",
        alignItems: "center",
        height: "142px"
    },
    SCLeft: {
        flex: "1",
        "& img": {
            height: "26px",
            marginBottom: "20px",
        },
        "& h3": {
            fontSize: "18px",
            lineHeight: "22px",
            fontWeight: "600",
            color: "#000",
            fontFamily: AdminTheme.poppins,
            marginBottom: "15px",
            marginTop: "0"
        },
        "& p": {
            fontSize: "14px",
            lineHeight: "16px",
            fontWeight: "400",
            color: "#B5B5C3",
            fontFamily: AdminTheme.poppins,
            marginBottom: "0px",
            marginTop: "0"
        },
    },
    SCProgress: {
        textAlign: "center",
        "& h6": {
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: "600",
            color: "#464E5F",
            fontFamily: AdminTheme.poppins,
            marginBottom: "0px",
            marginTop: "0",
        },
        "& p": {
            fontSize: "12px",
            lineHeight: "18px",
            fontWeight: "400",
            color: "#B5B5C3",
            fontFamily: AdminTheme.poppins,
            marginBottom: "0px",
            marginTop: "0",
        }
    },

    '@media (max-width: 900px)' : {
        StatsCard: {
            maxWidth: "100%",
            padding: "20px 20px 30px 20px",
        }

      }
});