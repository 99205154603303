import React, { useEffect, useState } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useStyles } from "./Style";
import Participant from '../../Components/Participant/Participant';
import ParticipantPic1 from '../../Images/participant-1.png'
import ParticipantPic2 from '../../Images/participant-2.png'
import { NavLink } from 'react-router-dom';
import ColorTheme from '../../Common/CommonCss/ColorTheme';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
const ParticipantSlider = (props) => {
  const classes = useStyles();
  const { organiserdata } = props;
  const [speakerid, setspeakerid] = useState("")
  const [description, setdescription] = useState("")
  const [navigationlink, setnavigationlink] = useState("")
  // var settings = {
  //   dots: false,
  //   arrows: false,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 4,
  //   slidesToScroll: 1,
  //   margin: 16,
  //   centerPadding: 20,
  //   centerMode: true
  // };
  useEffect(() => {
    if (organiserdata[0]?.id != undefined) {
      setspeakerid(organiserdata[0]?.id)
      setdescription(organiserdata[0]?.description)
      setnavigationlink(organiserdata[0]?.profile_url)
    }
  }, [organiserdata])
  const handleClicknavigation = () => {

  }
  const [readcheck, setreadcheck] = useState(true);
  const [text, setText] = useState("Read more");
  const onClickChange = () => {
    setreadcheck(!readcheck)
    // var currentVal = truncate==="text-truncate-4" ? "" : "text-truncate-4";
    // setTruncate(currentVal);
    var currentText = text === "Read more" ? "Read less" : "Read more";
    setText(currentText)
  }
  const navigationlinkcheck = navigationlink?.includes("http") ? navigationlink : `https://${navigationlink}`
  return (
    <>
      <div className={`${classes.ParticipantSlider} participant-slider`}>
        {
          organiserdata?.slice(0, 8)?.map((data) => {
            return <div className="participant-card" onClick={() => {
              setspeakerid(data?.id)
              setdescription(data?.description)
              setnavigationlink(data?.profile_url)
            }}>
              {/* <NavLink to={data?.profile_url}   target="_blank"> */}

              <Participant ParticipantPic={data?.image_url} ParticipantName={data?.name} description={data?.description} speakerid={speakerid} dataid={data?.id} />
              {/* </NavLink> */}
            </div>
          })
        }
      </div>
      <div className={classes.Description}>
        <div className={`${classes.Para}`}
          dangerouslySetInnerHTML={{
            __html: readcheck ? description?.slice(0, 400) : description
          }}
        />

        
        {description?.length > 400 &&
          <span className={classes.ReadMore} onClick={() => onClickChange()}>{text}</span>
        }
      </div>
      <a
        href={navigationlinkcheck}
        target="_blank"
        rel="noreferrer"
        style={{
          marginLeft: "5%",
          color: ColorTheme.textColor,
          fontFamily: ColorTheme.publicSans,
          fontSize: "16px",
          fontWeight: "600",
          textTransform: "capitalize",
          cursor: "pointer"
        }}

      >See Profile </a>
      {/* <Slider {...settings} className={`${classes.ParticipantSlider} home-slider`}>
        {
          organiserdata?.map((data) => {
            return <div className={classes.SlideCard}>
              <Participant ParticipantPic={data?.image_url} ParticipantName={data?.name} />
            </div>
          })
        }
      </Slider> */}
    </>
  )
}

export default ParticipantSlider