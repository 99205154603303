import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
}

export const SingleDeleteEventSlice = createSlice({
  name: 'SingleDeleteEventSlice',
  initialState,
  reducers: {
    Admin_Single_Delete_Event_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    Admin_Single_Delete_Event_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};

    },
    Admin_Single_Delete_Event_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_Admin_Single_Delete_Event_Check: (state, action) => {
      state.value = {}
      state.error = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const {Admin_Single_Delete_Event_request, Admin_Single_Delete_Event_success, Admin_Single_Delete_Event_failure, Clear_Admin_Single_Delete_Event_Check } = SingleDeleteEventSlice.actions

export default SingleDeleteEventSlice.reducer