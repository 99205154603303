import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useStyles } from "../../Common/FormStyle"
import AdminHeader from '../../Components/Header/Header'
import { ReactComponent as EventIcon } from '../../../Images/admin/event-icon.svg'
import ButtonTab from '../../Components/ButtonTab/ButtonTab'
import EventInfoIcon from '../../../Images/admin/event-info-icon.svg'
import AddProgramIcon from '../../../Images/admin/add-program-icon.svg'
import RegistrationImportIcon from '../../../Images/admin/registration-import-icon.svg'
import AddSpeakerIconActive from '../../../Images/admin/add-speaker-icon-active.svg'
import { ReactComponent as Arrow } from '../../../Images/admin/arrow-right.svg'
import { ReactComponent as ArrowActive } from '../../../Images/admin/arrow-right-active.svg'
import Divider from '@mui/joy/Divider';
import Grid from '@mui/joy/Grid'
import { ReactComponent as PodiumIcon } from '../../../Images/admin/podium.svg'
import ButtonPrimary from '../../Components/ButtonPrimary/ButtonPrimary'
import ButtonWithIcon from '../../Components/ButtonWithIcon/ButtonWithIcon'
import ButtonSecondary from '../../Components/ButtonSecondary/ButtonSecondary'
import TextEditor from '../../Components/TextEditor/TextEditor'
import Input from '@mui/joy/Input';
import ImageUpload from '../../Components/ImageUpload/ImageUpload'
import SpeakerCard from '../../Components/SpeakerCard/SpeakerCard'
import { useDispatch, useSelector } from 'react-redux'
import { CreateSpeakerAPI } from '../../../redux/Action/Admin/Speakers/createSpeakers'
import { GetSpeakerAPI } from '../../../redux/Action/Admin/Speakers/GetSpeakers'
import { useNavigate, useParams } from 'react-router-dom'
import AdmLoader from '../../Components/AdmLoader/AdmLoader'
import { EditSpeakerAPI } from '../../../redux/Action/Admin/Speakers/EditSpeakerss'
import TabHeader from '../../Components/TabHeader/TabHeader'
import Snacbar from '../../../Components/Snackbar/Snacbar'
import { Clear_Admin_Create_Event_Check } from '../../../redux/Reducer/Admin/CreateEvents/CreateEvents'
import { Clear_Create_Speaker_Check } from '../../../redux/Reducer/Admin/Speakers/craetespeakerSlice'


const AddSpeaker = () => {
  // CreateSpeakerAPI
  const classes = useStyles();

  const [name, setname] = useState("");
  const [nameerror, setnameerror] = useState(false);
  const [jobtitle, setjobtitle] = useState("");
  const [jobtitlerror, setjobtitlerror] = useState(false);
  const [profileurl, setprofileurl] = useState("");
  const [profileurlerror, setprofileurlerror] = useState(false);
  const [description, setdescription] = useState("");
  const [descriptionerror, setdescriptionerror] = useState(false);
  const [openform, setopenform] = useState(false)
  const [image, setimage] = useState("");
  const [imagerror, setimagerror] = useState(false);
  const [checkeditoradd, setcheckeditoradd] = useState(false);
  const admineventid = localStorage.getItem("admineventid")
  const GetspeakersSlice = useSelector(state => state?.GetspeakersSlice)
  const CreateSpeakerSlice = useSelector(state => state?.CreateSpeakerSlice)
  const DeletespeakersSlice = useSelector(state => state?.DeletespeakersSlice)
  const GetIdspeakersSlice = useSelector(state => state?.GetIdspeakersSlice)
  const [editid, seteditid] = useState('')
  const { id } = useParams();
  const event_slug=localStorage.getItem("event_slug")
  const slugPath =  '';
  const archive = JSON.parse(localStorage.getItem("archive"));
 
  const [open, setOpen] = useState(false);
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const typecheck=localStorage.getItem("typecheck")

  // console.log("image", typeof image)
  const handleClick = () => {
    const formData = new FormData();
    if (typeof image == "object") {

      formData.append('image', image);
    }
    formData.append('name', name);
    formData.append('event_id', id ? id : admineventid);
    formData.append('description', description);
    formData.append('job_title', jobtitle);
    formData.append('profile_url', profileurl);
    if (name == "" && description == "" && jobtitle == "" && image == "" && profileurl == "") {
      setimagerror(true)
      setdescriptionerror(true)
      setjobtitlerror(true)
      setnameerror(true)
      setprofileurlerror(true)


    } else if (name == "" || description == "" || jobtitle == "" || image == "" || profileurl == "" || description?.length > 20000) {

      if (name == "") {
        setnameerror(true)
      }
      if (description == "") {
        setdescriptionerror(true)
      }
      if (jobtitle == "") {
        setjobtitlerror(true)
      }

      if (image == "") {
        setimagerror(true)
      }
      if (profileurl == "") {
        setprofileurlerror(true)
      }

    }
    else {
      if (id) {
        if (checkeditoradd) {
          setopenform(false)
          dispatch(EditSpeakerAPI(formData, id, editid))
          setname("")
          setjobtitle("")
          setdescription("")
          setimage("")
        } else {

          setopenform(false)
          dispatch(CreateSpeakerAPI(formData, id))
          setname("")
          setjobtitle("")
          setdescription("")
          setimage("")

        }
      } else {
        if (checkeditoradd) {
          setopenform(false)
          dispatch(EditSpeakerAPI(formData, null, editid))
          setname("")
          setjobtitle("")
          setdescription("")
          setimage("")
        } else {

          setopenform(false)
          dispatch(CreateSpeakerAPI(formData))
          setname("")
          setjobtitle("")
          setdescription("")
          setimage("")

        }
      }
      // console.log("name", name, "jobtitle", jobtitle, "description", description, "image", image)
    }
  }


  useEffect(() => {
    // console.log("GetIdspeakersSlice", GetIdspeakersSlice)
    if (GetIdspeakersSlice?.value?.data) {
      setname(GetIdspeakersSlice?.value?.data?.name)
      setjobtitle(GetIdspeakersSlice?.value?.data?.job_title)
      setimage(GetIdspeakersSlice?.value?.data?.image)
      setdescription(GetIdspeakersSlice?.value?.data?.description)
      setprofileurl(GetIdspeakersSlice?.value?.data?.profile_url)
    }
  }, [GetIdspeakersSlice])




  useEffect(() => {
    dispatch(Clear_Admin_Create_Event_Check())
    if (id) {

      dispatch(GetSpeakerAPI(id))
    } else {

      dispatch(GetSpeakerAPI(admineventid))
    }
  }, [])


  // console.log("GetspeakersSlice", openform)
  const handlenavigate = () => {
    if (id) {

      navigate(`/admin${slugPath}/events/create-event/add-program-activities/${id}`)
    } else {
      navigate(`/admin${slugPath}/events/create-event/add-program-activities`)

    }
  }
  useEffect(() => {
    if (CreateSpeakerSlice?.error?.message) {
      setOpen(true)
    } else if (CreateSpeakerSlice?.value?.message) {
      setOpen(true)
      setTimeout(() => {
        dispatch(Clear_Create_Speaker_Check())
      }, 3000)

    }
  }, [CreateSpeakerSlice])
  // console.log("CreateSpeakerSlice", CreateSpeakerSlice)
  const handleBack = () => {
    if (id) {

      navigate(-1)
    } else {


      navigate(`/admin${slugPath}/events/create-event/event-information/${admineventid}`)
    }
  }

  return (
    <>
      {
        (CreateSpeakerSlice?.error?.message) && (
          <Snacbar
            open={open}
            setOpen={setOpen}
            messagetype={"error"}
            message={(CreateSpeakerSlice?.error?.message)}
          />

        )
      }
      {
        (CreateSpeakerSlice?.value?.message) && (
          <Snacbar
            open={open}
            setOpen={setOpen}
            messagetype={"success"}
            message={(CreateSpeakerSlice?.value?.message)}
          />

        )
      }
      {
        (DeletespeakersSlice?.loading || CreateSpeakerSlice?.loading || GetspeakersSlice?.loading) && (
          <AdmLoader />
        )
      }
      <Helmet>
        <title>Create New Event | Ticketing</title>
        {/* <meta name="description" content="Ticketing application" /> */}
      </Helmet>
      <div className='admin-main'>
        <AdminHeader Title="Event" PageName={`${typecheck=="new"?'Create New Event':'Edit New Event'}`} PageIcon={<EventIcon />} />
        <div className='container sec-bottom-sapce'>
          <div className='box'>
            <TabHeader active="step2" />
            <Divider sx={{ backgroundColor: '#ECF0F3' }} />
            <div className='form-container'>
              <Grid container spacing={2} sx={{}}>
                <Grid sx={{ flex: 1 }}>
                  <h2 className={classes.FormTitle}>Speaker Information</h2>
                </Grid>
                {openform == false && archive!=true && <Grid id="editspeaker">
                  <ButtonWithIcon label="Add Speakers" icon={<PodiumIcon />} handleClick={() => {
                    setname("")
                    setjobtitle("")
                    setimage("")
                    setprofileurl("")
                    setdescription("")
                    setopenform(true)
                    setcheckeditoradd(false)
                  }} />
                </Grid>}
              </Grid>

              {
                openform == true && (

                  <>
                    <div className='speaker-info-form'>
                      <Grid className="rw" container spacing={2} sx={{ flexGrow: 1 }}>
                        <Grid sx={{ flex: 1 }}>
                          <div className={classes.FormGroup}>
                            <label className={classes.FormLabel}>Name<span>*</span></label>
                            <Input placeholder="Name" variant="soft" className={classes.FormInput} value={name} onChange={(e) => {
                              if (e.target.value.length <= 40) {
                                setnameerror(false)
                                setname(e.target.value)
                              }
                            }} />
                            {
                              nameerror && (
                                <p className='error-msg'>Name is required.</p>
                              )
                            }
                          </div>
                          <div className={classes.FormGroup}>
                            <label className={classes.FormLabel}>Job Title<span>*</span></label>
                            <Input placeholder="Job Title" variant="soft" className={classes.FormInput} value={jobtitle} onChange={(e) => {
                              if (e.target.value.length <= 50) {
                                setjobtitlerror(false)
                                setjobtitle(e.target.value)
                              }
                            }} />
                            {
                              jobtitlerror && (
                                <p className='error-msg'>Job Title is required.</p>
                              )
                            }
                          </div>
                          <div className={classes.FormGroup}>
                            <label className={classes.FormLabel}>Profile Url<span>*</span></label>
                            <Input placeholder="Profile Url" variant="soft" className={classes.FormInput} value={profileurl} onChange={(e) => {
                              setprofileurlerror(false)
                              setprofileurl(e.target.value)
                            }} />
                            {
                              profileurlerror && (
                                <p className='error-msg'>Profile Url is required.</p>
                              )
                            }
                          </div>
                        </Grid>
                        <Grid className={`${classes.FormGroup} img-upload-container`}>
                          <ImageUpload setimage={setimage} image={image} setimagerror={setimagerror} GetIdspeakersSlice={GetIdspeakersSlice} />
                          {
                            imagerror && (
                              <p className='error-msg'>Image is required.</p>
                            )
                          }
                        </Grid>
                      </Grid>
                      <div className={classes.FormGroup}>
                        <label className={classes.FormLabel}>Description</label>
                        <TextEditor setdescription={setdescription} description={description}
                          setdescriptionerror={setdescriptionerror}

                        />
                        {
                          descriptionerror && (
                            <p className='error-msg'>Description is required.</p>
                          )
                        }
                        {
                          description?.length > 20000 && (
                            <p className='error-msg'>Description length is not more than 20,000 character.</p>
                          )
                        }
                      </div>

                      <div className='text-right'>
                        <ButtonPrimary label="Save" disabledcheck={true} handleClick={(e) => handleClick(e)} />
                      </div>
                    </div>
                  </>
                )
              }

              {openform == false && <div className={`${openform == true ? "scrollable" : ""} speaker-container my-auto`}>
                {GetspeakersSlice?.value?.data?.map((data) => {
                  return <SpeakerCard image={data?.image} description={data?.description} key={data?.event} event={data?.id} job_title={data?.job_title} name={data?.name} setopenform={setopenform} setcheckeditoradd={setcheckeditoradd} seteditid={seteditid} />
                })}
              </div>}


              <div className='form-container-footer'>
                <ButtonSecondary label="Back" handleClick={() => handleBack()} />
                <ButtonPrimary label="Next Step" disabledcheck={true} handleClick={() => handlenavigate()} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddSpeaker