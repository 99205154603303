import { React, useEffect, useState } from 'react'
import { useStyles } from './Style'
import Button from '@mui/material/Button';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import { useDispatch } from 'react-redux';
import { Increment_Decrement_success } from '../../../redux/Reducer/IncrementDecrement';

const IncDecButton = (props) => {
  const { size, bg, counter, setCounter, totalcount, setchecktruefalse } = props;
  const classes = useStyles();
  const [counters, setCounters] = useState(1);
  const dispatch = useDispatch()
  useEffect(() => {
    if (totalcount != undefined && totalcount != null) {
      setCounters(totalcount)
      setCounter(totalcount)
    }
  }, [])

  // console.log("counter", counter)
  const handleDecrese = () => {
    if (counter == 1) {
      return
    }
    if (setchecktruefalse != null && setchecktruefalse != undefined) {

      setchecktruefalse(true)
    }
    if (setCounter != null && setCounter != undefined) {
      setCounter(counter - 1);
    }
    dispatch(Increment_Decrement_success(counters - 1))
    setCounters(counters - 1);
  }
  // console.log("counterscounters", counters)
  return (
    <div Varient={size} Background={bg} className={`${classes.IncDecButtonRow} inc-dec-btn`}>
      <Button
        // disabled={counter <= 0}
        onClick={() => {
          handleDecrese()
        }}
      >
        <RemoveRoundedIcon />
      </Button>
      <Button disabled>{counters}</Button>
      <Button
        onClick={() => {
          dispatch(Increment_Decrement_success(counters + 1))
          if (setCounter != undefined && setCounter != null) {

            setCounter(counter + 1);
          }
          if (setchecktruefalse != undefined && setchecktruefalse != null) {

            setchecktruefalse(true)
          }
          setCounters(counters + 1);
        }}
      >
        <AddRoundedIcon />
      </Button>
    </div>
  );

}

export default IncDecButton