import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: "",
  loading: false,
}

export const EndDateSlice = createSlice({
  name: 'EndDateSlice',
  initialState,
  reducers: {
   
    End_Date_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      
    },
  
  }
})

// Action creators are generated for each case reducer function
export const {End_Date_success } = EndDateSlice.actions

export default EndDateSlice.reducer