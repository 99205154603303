import { makeStyles } from '@mui/styles';
import AdminTheme from '../../Common/AdminTheme';
export const useStyles = makeStyles({
    
    InfoCard: {
        background: "#fff",
        boxShadow: "0 4px 20px 0 rgba(0, 0, 0, 0.1) !important",
        borderRadius: "15px !important",
        marginTop: "20px",
        overflow: "hidden",
        "& .MuiAccordionSummary-root, & .MuiAccordionSummary-content": {
            flexDirection: "column",
            width: "100%",
            padding: "0",
            margin: "0 !important"
        },
        "& .MuiAccordionDetails-root": {
            padding: "0px 20px",
        },
        "& .MuiAccordion-root": {
            margin: "0 auto !important"
        },
        "&.Mui-expanded": {
            "& .expand-icon": {
                transform: "rotate(180deg)",
            }
        }


    },
    InfoCardHeader: {
        height: "200px",
        position: "relative",
        overflow: "hidden",
    },
    QrIcon: {
        width: "80px",
        height: "80px",
        position: "absolute",
        left: "20px",
        bottom: "20px",
        zIndex: "3",
        background: "#fff",
        borderRadius: "5px"
    },

    TitleRw: {
        display: "flex",
        padding: "10px 20px",
        "& h3": {
            fontSize: "20px",
            fontWeight: "500",
            lineHeight: "30px",
            fontFamily: AdminTheme.poppins,
            color: AdminTheme.headingColor,
            marginTop: "0px",
            marginBottom: "0px",
            flex: "1",
        },
        "& .expand-icon": {
            color: "#434349",
        }
    },

    TextP: {
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "18px",
        fontFamily: AdminTheme.poppins,
        color: "#434349",
        display: "flex",
        alignItems: "center",
        gap: "5px",
        "& svg":{
            width: "11px",
            height: "12px"
        }
    },
    TextH6: {
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "21px",
        fontFamily: AdminTheme.poppins,
        color: AdminTheme.headingColor,
    },

    InfoCardFooter: {
        display: "flex",
        justifyContent: "flex-end",
        gap: "20px",
        alignItems: "center",
        padding: "20px 0"
    },

    InfoCardH4: {
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "24px",
        fontFamily: AdminTheme.poppins,
        color: AdminTheme.headingColor,
        marginTop: "0",
        marginBottom: "10px"
    },
    Mt2: {
        marginTop: "10px"
    },

    ListHorizontal: {
        padding: "0 !important",
        flexWrap: "wrap",
        "& .MuiListItem-root": {
            padding: "0",
            gap: "0",
            marginRight: "15px",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "21px",
            fontFamily: AdminTheme.poppins,
            color: AdminTheme.headingColor,
        },
        "& .MuiListItemDecorator-root": {
            minWidth: "unset",
            marginRight: "5px"
        }
    },


    SpeakerList: {
        "& .MuiAvatar-root": {
            width: "28px",
            height: "28px",
            background: "rgba(70, 78, 95, 0.1)",
            color: "#464E5F"
        },   
    },

    DescContent: {
        fontFamily: AdminTheme.poppins,
        color: "#434349",
        fontSize: "13px",
        lineHeight: "19px",
        fontWeight: "400",
        "& p": {
            marginTop: "0px",
        }
    },

    "@media(max-width: 600px)": {
        InfoCardHeader: {
            height: "180px",
        },
        QrIcon: {
            width: "60px",
            height: "60px",
            left: "15px",
            bottom: "15px",
            borderRadius: "4px"
        },
        InfoCard: {
            "& h3": {
                fontSize: "18px",
                lineHeight: "28px",
            },
            "& .MuiAccordionSummary-root, & .MuiAccordionDetails-root": {
                padding: "0 15px",
            }
        },
        InfoCardH4: {
            fontSize: "14px",
            lineHeight: "22px",
            marginBottom: "8px"
        },
        InfoCardFooter: {
            gap: "10px",
            padding: "15px 0"
        },
    },
    "@media(max-width: 400px)": {
        InfoCardHeader: {
            height: "140px",
        },
        QrIcon: {
            width: "48px",
            height: "48px",
            left: "10px",
            bottom: "10px",
        },
        SpeakerList: {
            "& .MuiAvatar-root": {
                width: "24px",
                height: "24px",
            },   
        },
    }


});