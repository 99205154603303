import React from 'react'
// import { Link } from 'react-router-dom'
import { useStyles } from "./Style"
import { Button } from '@mui/material';
import { ReactComponent as Img } from '../../Images/icons/qr-icon.svg';

export const ButtonSquared = (props) => {
  const { label, icon, check,handleClick } = props;
  const classes = useStyles();

  return (
    <>
      <Button variant={check?"":"outlined"} className={`${classes.BtnSqr} btn-box`} disabled={check?true:""} sx={{
        background: check ? "#dedcdc" : "",
        opacity: check ? "0.5" : "",
        border: check ? "none" : ""
      }} 
onClick={()=>handleClick()}      
      >
        <div className={`${classes.BtnSqrIcon} btn-box-icon`}>
          {icon}
        </div>
        <p className={`${classes.BtnSqrText} btn-box-text`}>{label}</p>
      </Button>
    </>
  )
}
