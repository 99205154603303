import React, { useEffect, useState } from 'react'
import AdminHeader from '../../Components/Header/Header'
import ButtonTab from '../../Components/ButtonTab/ButtonTab'
import EventInfoIcon from '../../../Images/admin/event-info-icon.svg'
import AddProgramIconActive from '../../../Images/admin/add-program-icon-active.svg'
import RegistrationImportIcon from '../../../Images/admin/registration-import-icon.svg'
import AddSpeakerIcon from '../../../Images/admin/add-speaker-icon.svg'
import { ReactComponent as Arrow } from '../../../Images/admin/arrow-right.svg'
import { ReactComponent as ArrowActive } from '../../../Images/admin/arrow-right-active.svg'
import Input from '@mui/joy/Input';
import dayjs from 'dayjs';
import { useStyles } from "../../Common/FormStyle"
import Divider from '@mui/joy/Divider';
import TextEditor from '../../Components/TextEditor/TextEditor'
import Grid from '@mui/joy/Grid'
import DateInput from '../../Components/DatePicker/DateInput'
import TimeInput from '../../Components/TimePicker/TimeInput'
import { Helmet } from 'react-helmet'
import ButtonWithIcon from '../../Components/ButtonWithIcon/ButtonWithIcon'
import { ReactComponent as FileIcon } from '../../../Images/admin/file-icon.svg'
import ButtonPrimary from '../../Components/ButtonPrimary/ButtonPrimary'
import SpeakerSelect from '../../Components/SpeakerSelect/SpeakerSelect'
import FileInput from '../../Components/FileInput/FileInput'
import FileIconImage from '../../../Images/admin/photo-video-icon.svg'
import PaperClipIcon from '../../../Images/admin/paperclip-icon.svg'
import ButtonSecondary from '../../Components/ButtonSecondary/ButtonSecondary'
import QRCode from '../../Components/QRCode/QRCode'
import ActivitiesInfo from '../../Components/ActivitiesInfo/ActivitiesInfo'
import Paper from '@mui/material/Paper';
import { ReactComponent as EventIcon } from '../../../Images/admin/event-icon.svg'
import { useDispatch, useSelector } from 'react-redux'
import { AdminCreateActivitiesAPI } from '../../../redux/Action/Admin/CreateActitvites/CreateActitivties'
import { useNavigate, useParams } from 'react-router-dom'
import { supplyDateFormat } from '../../../utlis/utlis'
import { Start_Date_success } from '../../../redux/Reducer/startdateSlice'
import FileInputsingle from '../../Components/FileInput/FileInputSingle'
import { GetActivitiesAPI } from '../../../redux/Action/Admin/CreateActitvites/GetActivities'
import AdmLoader from '../../Components/AdmLoader/AdmLoader'
import TabHeader from '../../Components/TabHeader/TabHeader'
import Snacbar from '../../../Components/Snackbar/Snacbar'
import { Clear_Create_Speaker_Check } from '../../../redux/Reducer/Admin/Speakers/craetespeakerSlice'
import { AdminEditActivitiesAPI } from '../../../redux/Action/Admin/CreateActitvites/EditActivites'
import { End_Date_success } from '../../../redux/Reducer/EndDateSlice'
import { Clear_Admin_Create_Activities_Check } from '../../../redux/Reducer/Admin/CreateActivities/CreateActivities'
import { Clear_Edit_Activites_Check } from '../../../redux/Reducer/Admin/CreateActivities/EditActivity'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { ReaarangeActivitesAPI } from '../../../redux/Action/Admin/ReaarangeActivites.js/RearrangeActivity'
import { PerecentageCheck_success } from '../../../redux/Reducer/PercentageCheckSlice'
const AddProgram = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [name, setname] = useState("");
  const [errorname, seterrorname] = useState("");
  const [email, setemail] = useState("");
  const [emailerror, setemailerror] = useState("");
  const [start_date, setstart_date] = useState("");
  const [start_dateerror, setstart_daterror] = useState("");
  const [end_date, setend_date] = useState("");
  const [end_dateerror, setend_dateerror] = useState("");
  const [start_time, setstart_time] = useState("");
  const [start_timeerror, setstart_timeerror] = useState("");
  const [end_time, setend_time] = useState("");
  const [end_timeerror, setend_timeerror] = useState("");
  const [description, setdescription] = useState("");
  const [descriptionerror, setdescriptionerror] = useState("");
  const [timezone, settimezone] = useState("")
  const navigate = useNavigate();
  const event_slug=localStorage.getItem("event_slug")
  const slugPath = '';
 
  const [multiimage, setmultimage] = useState([]);
  const [multiimagelength, setmultimagelength] = useState([]);
  const [multiimageerror, setmultimageerror] = useState(false);
  const [pdf, setpdf] = useState([]);
  const [pdferror, setpdferror] = useState(false);
  const [speaker, setspeaker] = useState([])
  const [speakererror, setspeakererror] = useState(false)
  const [is_qr_enable, setis_qr_enable] = useState(0);
  const [is_qr_enableerror, setis_qr_enableerror] = useState(false);
  const GetActivitesSlice = useSelector(state => state?.GetActivitesSlice)
  const AdminCreateActivitiesSlice = useSelector(state => state?.AdminCreateActivitiesSlice)
  const [checknavigation, setnavigation] = useState(false)
  const admineventid = localStorage.getItem("admineventid")
  const [addactivites, setaddactivites] = useState(false);
  const GetEditActivitesSlice = useSelector(state => state?.GetEditActivitesSlice)
  const [open, setopen] = useState(false);
  const { id } = useParams();
  const [editopen, seteditopen] = useState(false);
  const [addopen, seteaddopen] = useState(false);
  const [editgetimage, seteditgetimage] = useState([])
  const [editgetpdf, seteditgetpdf] = useState([])
  const [editid, seteditid] = useState("")

  const eventstarttime = localStorage.getItem("eventstarttime")
  const eventendtime = localStorage.getItem("eventendtime")
  const EventStart_date = localStorage.getItem("Start_date")
  const EventEnd_date = localStorage.getItem("End_date")
  const EditActivitesSlice = useSelector(state => state?.EditActivitesSlice)
  const [dragdrop, setdragdrop] = useState([])
  const archive = JSON.parse(localStorage.getItem("archive"));
  const typecheck=localStorage.getItem("typecheck")
  const [loadingcheck,setloadingcheck]=useState(false);
  const startdatetime = () => {

    const minDateTimeString = `${EventStart_date}T${eventstarttime}`;
    // start_date?.dob
    // const mindatestartcheck=start_date?.dob
    // Parse the combined string into a Day.js object
    const minDateTime = dayjs(minDateTimeString);
    // Create a new Date object
    return minDateTime
  }

  // console.log("start_date",start_date)
  const enddatetime = () => {

    const maxDateTimeString = `${EventEnd_date}T${eventendtime}`;

    // Parse the combined string into a Day.js object
    const maxDateTime = dayjs(maxDateTimeString);
    // Create a new Date object
    return maxDateTime
  }


  useEffect(() => {
    if (GetEditActivitesSlice?.value?.data != undefined) {

      setname(GetEditActivitesSlice?.value?.data?.title)
      setstart_date({ dob: GetEditActivitesSlice?.value?.data?.start_date })
      setend_date({ dob: GetEditActivitesSlice?.value?.data?.end_date })
      // console.log("getEdit", GetEditActivitesSlice?.value?.data?.start_time)
      setstart_time(GetEditActivitesSlice?.value?.data?.start_time)
      setend_time(GetEditActivitesSlice?.value?.data?.end_time)
      setdescription(GetEditActivitesSlice?.value?.data?.description)
      seteditgetimage(GetEditActivitesSlice?.value?.data?.banners)
      setmultimagelength(GetEditActivitesSlice?.value?.data?.banners)
      seteditgetpdf(GetEditActivitesSlice?.value?.data?.pdf)
      // setpdf(GetEditActivitesSlice?.value?.data?.pdf)
      setis_qr_enable(GetEditActivitesSlice?.value?.data?.is_qr_enable)
      // is_qr_enable

      const arrayspeaker = []
      const speaker = GetEditActivitesSlice?.value?.data?.organiser?.map((data) => {
        return arrayspeaker?.push(data?.id)
      })
      setspeaker(arrayspeaker)
    }
    // setstart_date((pre) => ({
    //   ...pre,
    //   dob: supplyDateFormat(GetEditActivitesSlice?.value?.data?.start_date[""]),
    // }));
    // dispatch(AdminCreateActivitiesAPI())
  }, [GetEditActivitesSlice])

  // console.log("multiimage", multiimage, multiimagelength, multiimagelength?.length)
  // console.log("speaker",speaker)
  const handleClick = () => {

    // multiimage.forEach((item, index) => {
    //   if (item.image) {
    //   }
    // });
    // dispatch(AdminCreateActivitiesAPI(formData))
    // console.log("multiimage", formData,multiimage)
    if (name === "" && start_date === "" && end_date === "" && start_time === "" && end_time === "" && description == "") {
      // Handle case when all variables are empty
      // setis_qr_enableerror(true)
      seterrorname(true)
      setstart_daterror(true)
      setstart_timeerror(true)
      setemailerror(true)
      setend_dateerror(true)
      setend_timeerror(true)
      setdescriptionerror(true)
      // setspeakererror(true)
      setpdferror(true)

    } else if (
      (name === "" || start_date === "" || end_date === "" || end_date == null || start_time === "" || end_time === "" || description == "" || description?.length > 20000 || multiimagelength?.length == 0)

    ) {
      if (name == "") {
        seterrorname(true)
      }
      if (start_date == "") {
        setstart_daterror(true)
      }
      if (start_time == "") {
        setstart_timeerror(true)
      }
      // if (pdf == "") {
      //   setpdferror(true)
      // }
      if (email == "") {
        setemailerror(true)
      }
      if (end_date == "" || end_date == null) {
        setend_dateerror(true)
      }
      if (end_time == "") {
        setend_timeerror(true)
      }
      // if (speaker == "") {
      //   setspeakererror(true)
      // }
      if (multiimagelength?.length == 0) {
        setmultimageerror(true)
      }

      // Handle other specific cases
    } else {
      const formData = new FormData();
      formData.append('title', name);
      formData.append('start_date', start_date?.dob);
      formData.append('end_date', end_date?.dob);
      // console.log("objobj", name, "s", start_date, "e", end_date, "st", start_time, "et", end_time, "d")
      formData.append('start_time', start_time?.split(" ")[0]);
      formData.append('end_time', end_time?.split(" ")[0]);
      formData.append('description', description);
      formData.append('is_qr_enable', is_qr_enable == true ? 1 : 0);
      formData.append('pdf', pdf);
      formData.append('event_id', id ? id : admineventid);

      // formData.append('banners[]', multiimage);

      multiimage?.forEach((item, index) => {
        if (item) {
          formData.append(`banners[]`, item);
        }
      });
      speaker?.forEach((item, index) => {
        if (item) {
          formData.append('speakers[]', item);

          // formData.append(`banners[]`, item);
        }
      });
      // console.log("multiimage", multiimage)
      const obj = {
        title: name,
        start_date: start_date?.dob,
        end_date: end_date?.dob,
        start_time: start_time?.split(" ")[0],
        end_time: end_time?.split(" ")[0],
        description: description,
        is_qr_enable: 1,
        pdf: pdf,
        event_id: id ? id : admineventid,
        organisers: [speaker],
        banner: multiimage,
      }
      if (editopen == true) {
        dispatch(AdminEditActivitiesAPI(formData, seteditopen, setaddactivites, id ? id : admineventid, editid))
        // seteditopen(false)
        // setaddactivites(false)

      } else {
        // seteaddopen(false)
        dispatch(AdminCreateActivitiesAPI(formData, seteaddopen, setaddactivites, id))
      }
      // setTimeout(() => {
      //   setname("")
      //   setemail("")
      //   setstart_date("")
      //   setend_date("")
      //   setstart_time("")
      //   setend_time("")
      //   setdescription("")
      //   setmultimage([])
      //   setmultimagelength([])
      //   setpdf([])
      //   setspeaker("")
      //   setis_qr_enable("")
      // }, 1000)


    }
  }

  useEffect(() => {
    if (AdminCreateActivitiesSlice?.value?.message || EditActivitesSlice?.value?.message) {
      setname("")
      setemail("")
      setstart_date("")
      setend_date("")
      setstart_time("")
      setend_time("")
      setdescription("")
      setmultimage([])
      setmultimagelength([])
      setpdf([])
      setspeaker("")
      setis_qr_enable("")
    }
  }, [AdminCreateActivitiesSlice, EditActivitesSlice])


  const StartDateSlice = useSelector(state => state?.StartDateSlice?.value)
  const EndDateSlice = useSelector(state => state?.EndDateSlice?.value)

  useEffect(() => {
    if (StartDateSlice != "") {
      setend_date(null)
      setend_time("")
      setstart_time("")
    }
  }, [StartDateSlice])
  useEffect(() => {
    if (EndDateSlice != "") {

      setend_time("")

    }
  }, [EndDateSlice])


  // console.log("GetEditActivitesSlice", end_date, "gfgd", end_time)

  const handlenavigate = () => {
    if (id) {
      if (checknavigation == true) {
        dispatch(Clear_Create_Speaker_Check())
        navigate(`/admin${slugPath}/events/create-event/add-staff/${id}`)
      } else {
        handleClick()
      }
    } else {


      if (checknavigation == true) {
        dispatch(Clear_Create_Speaker_Check())
        navigate(`/admin${slugPath}/events/create-event/add-staff`)
      } else {
        handleClick()
      }
    }
  }





  const bindingHandler = (date) => {
    setstart_date((pre) => ({
      ...pre,
      dob: supplyDateFormat(date[""]),
    }));
    setstart_daterror(false)
    dispatch(Start_Date_success(supplyDateFormat(date[""])))

  }
  const bindingHandler2 = (date) => {
    setend_date((pre) => ({
      ...pre,
      dob: supplyDateFormat(date[""]),
    }));
    // setdoberor(false)
    setend_dateerror(false)
    dispatch(End_Date_success(supplyDateFormat(date[""])))
  }


  const bindingTimeHandler = (date) => {
    // setvalidationdob(true)
    const newdate = new Date()

    const timeformate = date[""]?.toLocaleString('en-IN', {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
      hourCycle: 'h23'

    });
    // console.log("date", timeformate)
    setstart_time(timeformate)

    setstart_timeerror(false)
    // console.log("timeset", timeformate)
    // console.log("dateofbirth", date[""], supplyDateFormat(date[""]))
    // setdob((pre) => ({
    //     ...pre,
    //     dob: supplyDateFormat(date[""]),
    // }));
  };
  const bindingTimeHandler2 = (date) => {
    // setvalidationdob(true)
    const newdate = new Date()

    const timeformate = date[""]?.toLocaleString('en-IN', {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
      hourCycle: 'h23'

    });
    // console.log("date", timeformate)
    setend_time(timeformate)
    setend_timeerror(false)
    // console.log("timeset", timeformate)
    // console.log("dateofbirth", date[""], supplyDateFormat(date[""]))
    // setdob((pre) => ({
    //     ...pre,
    //     dob: supplyDateFormat(date[""]),
    // }));
  };


  // console.log("GetActivitesSlice", GetActivitesSlice)

  useEffect(() => {
    if (id) {

      dispatch(GetActivitiesAPI(id))
    } else {

      dispatch(GetActivitiesAPI(admineventid))
    }
  }, [])


  useEffect(() => {
    if (GetActivitesSlice?.value?.data?.length > 0) {
      setnavigation(true)
    } else if (GetActivitesSlice?.value?.data?.length == 0) {
      setnavigation(false)
    }
  }, [GetActivitesSlice])

  useEffect(() => {
    if (AdminCreateActivitiesSlice?.value?.message || AdminCreateActivitiesSlice?.error?.message || EditActivitesSlice?.value?.message || EditActivitesSlice?.error?.message) {
      setopen(true)
      setTimeout(() => {
        // console.log("AdminCreateActivitiesSlice", AdminCreateActivitiesSlice)
        dispatch(Clear_Create_Speaker_Check())
        dispatch(Clear_Admin_Create_Activities_Check())
        dispatch(Clear_Edit_Activites_Check())
      }, 3000)
      // console.log("AdminCreateActivitiesSlice", EditActivitesSlice)
    }
  }, [AdminCreateActivitiesSlice, EditActivitesSlice])

  const handleOnDragEnd = (result) => {
    // console.log("result", result)
    if (!result.destination) return; // Dropped outside the list

    const items = Array.from(dragdrop);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    const itemsidmap=items?.map((data)=>data?.id)
    // console.log("itemsidmap", itemsidmap?.map(String).join(','))
    const eventid=id?id:admineventid
    const formData= new FormData();
    formData.append('program_ids',itemsidmap?.map(String).join(','))
    dispatch(ReaarangeActivitesAPI(eventid,formData))
    setdragdrop(items);
  };

  useEffect(() => {
    if (GetActivitesSlice?.value?.data != undefined) {

      setdragdrop(GetActivitesSlice?.value?.data)
    }
  }, [GetActivitesSlice])
  useEffect(()=>{
if(AdminCreateActivitiesSlice?.loading == true || GetActivitesSlice?.loading == true){
  setloadingcheck(true)
}else{
  setTimeout(()=>{
    setloadingcheck(false)
  },500)
}
  
  },[AdminCreateActivitiesSlice,GetActivitesSlice])
  useEffect(() => {
    dispatch(PerecentageCheck_success(0))
  }, [])
  return (
    <>
      {
        (loadingcheck==true) && (
          <AdmLoader />
        )
      }
      {
        (AdminCreateActivitiesSlice?.value?.message || AdminCreateActivitiesSlice?.error?.message) && (
          <Snacbar
            open={open}
            setOpen={setopen}
            messagetype={AdminCreateActivitiesSlice?.error?.message ? "error" : "success"}
            message={(AdminCreateActivitiesSlice?.value?.message || AdminCreateActivitiesSlice?.error?.message)}
          />

        )
      }
      {
        (EditActivitesSlice?.value?.message || EditActivitesSlice?.error?.message) && (
          <Snacbar
            open={open}
            setOpen={setopen}
            messagetype={EditActivitesSlice?.error?.message ? "error" : "success"}
            message={(EditActivitesSlice?.value?.message || EditActivitesSlice?.error?.message)}
          />

        )
      }
      <Helmet>
        <title>Create New Event | Ticketing</title>
        {/* <meta name="description" content="Ticketing application" /> */}
      </Helmet>
      <div className='admin-main'>
        <AdminHeader Title="Event" PageName={`${typecheck=="new"?'Create New Event':'Edit New Event'}`} PageIcon={<EventIcon />} />

        <div className='container sec-bottom-sapce'>
          <div className='box'>
            <TabHeader active="step3" />
            <Divider sx={{ backgroundColor: '#ECF0F3' }} />
            <div className='form-container'>
              {addactivites == false && editopen == false &&
                <Grid container spacing={2}>
                  <Grid sx={{ flex: 1 }}>
                    <h2 className={classes.FormTitle}>Activities Information</h2>
                  </Grid>
                 {archive!=true && <Grid id="viewmores">
                    <ButtonWithIcon label="Add Activity" icon={<FileIcon />} handleClick={() => {
                      setname("")
                      setstart_date("")
                      setend_date("")

                      setstart_time("")
                      setend_time("")
                      setdescription("")
                      seteditgetimage("")
                      setmultimagelength("")
                      seteditgetpdf("")
                      // setpdf(GetEditActivitesSlice?.value?.data?.pdf)
                      setis_qr_enable(false)
                      // is_qr_enable


                      setspeaker([])
                      setaddactivites(true)
                    }} />
                  </Grid>}
                </Grid>
              }
              {
                addactivites == true && (
                  <>
                    <Paper elevation={0} sx={{ background: 'transparent', height: '68px' }} />

                    <h2 className={classes.FormTitle}>Add new activity </h2>

                    <div className={classes.FormGroup}>
                      <label className={classes.FormLabel}>Session Title<span>*</span></label>
                      <Input placeholder="Event Name" variant="soft" className={classes.FormInput} value={name} onChange={(e) => {
                        if (e.target.value.length <= 100) {
                          setname(e.target.value)
                          seterrorname(false)
                          setname(e.target.value)
                          seterrorname(false)
                        }
                      }} />
                      {
                        errorname && (
                          <p className='error-msg'>Name is required.</p>
                        )
                      } </div>
                    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                      <Grid lg={6} md={12} xs={12}>
                        <div className={classes.FormGroup}>
                          <label className={classes.FormLabel}>Start Date</label>
                          <DateInput
                            start_date={start_date}
                            setstart_time={setstart_time}
                            dateValue={start_time}
                            onBinding={bindingHandler}
                            programcheclk={true}
                          />
                          {
                            start_dateerror && (
                              <p className='error-msg'>Date is required.</p>
                            )
                          }
                        </div>
                      </Grid>
                      <Grid lg={6} md={12} xs={12}>
                        <div className={classes.FormGroup}>
                          <label className={classes.FormLabel}>Time</label>
                          <TimeInput
                            checkstart={true}
                            eventstarttime={eventstarttime}
                            startdateValue={start_date}
                            start_time={start_time}
                            setend_time={setstart_time}
                            dateValue={start_time}

                            onBinding={bindingTimeHandler}
                            startdatetime={startdatetime()}
                          />
                          {
                            start_timeerror && (
                              <p className='error-msg'>Time is required.</p>
                            )
                          }
                        </div>
                      </Grid>
                      <Grid lg={6} md={12} xs={12}>
                        <div className={classes.FormGroup}>
                          <label className={classes.FormLabel}>End Date</label>
                          <DateInput
                            start_date={end_date}
                            dateValue={end_date}
                            onBinding={bindingHandler2}
                            allowafter={2}
                            startdatefieldcheck={start_date?.dob != "" && start_date?.dob != null && start_date?.dob != undefined ? start_date?.dob : null}
                            programcheclk={true}
                          />
                          {
                            end_dateerror && (
                              <p className='error-msg'> Date is required.</p>
                            )
                          }
                        </div>
                      </Grid>
                      <Grid lg={6} md={12} xs={12}>
                        <div className={classes.FormGroup}>
                          <label className={classes.FormLabel}>Time</label>
                          <TimeInput
                            startdateValue={start_date}
                            enddatevalue={end_date}
                            samedatestartend={start_date?.dob == end_date?.dob ? true : false}
                            checkendtime={true}
                            endstart_timecheck={start_time}
                            startdatetime={startdatetime()}
                            enddatetime={enddatetime()}
                            setend_time={setend_time}
                            dateValue={end_time}
                            eventendtime={eventendtime}
                            onBinding={bindingTimeHandler2}
                          />
                          {
                            end_timeerror && (
                              <p className='error-msg'>Time is required.</p>
                            )
                          }
                        </div>
                      </Grid>
                    </Grid>

                    <div className={classes.FormGroup}>
                      <label className={classes.FormLabel}>Speaker
                        {/* <span>*</span> */}
                      </label>
                      <SpeakerSelect speaker={speaker} setspeaker={setspeaker} setspeakererror={setspeakererror} />
                      {
                        speakererror && (
                          <p className='error-msg'>Speaker is required.</p>
                        )
                      }
                    </div>

                    <div className={classes.FormGroup}>
                      <label className={classes.FormLabel}>Description</label>
                      <TextEditor setdescription={setdescription} description={description} setdescriptionerror={setdescriptionerror} />
                      {
                        descriptionerror && (
                          <p className='error-msg'>Description is required.</p>
                        )
                      }
                      {
                        description?.length > 20000 && (
                          <p className='error-msg'>Description length is not more than 20,000 character.</p>
                        )
                      }

                    </div>

                    <div className={classes.FormGroup}>
                      <label className={classes.FormLabel}>Program Banner</label>
                      <FileInput FileIcon={FileIconImage} Text={["Drop Image ", <span>Click Here</span>, " Banner size must not exceed 1000*500."]} editgetimage={editgetimage} multiimage={multiimage} setmultimage={setmultimage} setmultimagelength={setmultimagelength} setmultimageerror={setmultimageerror} editopen={editopen} />
                      {
                        multiimageerror && (
                          <p className='error-msg'>Banner is required.</p>
                        )
                      }
                    </div>

                    <div className={classes.FormGroup}>
                      <label className={classes.FormLabel}>Attachment</label>
                      <FileInputsingle FileIcon={PaperClipIcon} Text={["Drop Pdf here or ", <span>Click Here</span>, " to upload"]} pdf={pdf} setpdf={setpdf} setpdferror={setpdferror} editopen={editopen} editgetimage={editgetpdf} />
                      {/* {
                        pdferror && (
                          <p className='error-msg'>PDF is required.</p>
                        )
                      } */}
                    </div>

                    <div className={classes.FormGroup}>
                      <QRCode setis_qr_enable={setis_qr_enable} setis_qr_enableerror={setis_qr_enableerror} is_qr_enable={is_qr_enable} />
                      {
                        is_qr_enableerror && (
                          <p className='error-msg'>Qr is required.</p>
                        )
                      }
                    </div>
                    <div className='text-right'>
                      <ButtonPrimary label="Save" disabledcheck={true} handleClick={() => handleClick()} />
                    </div>
                  </>
                )
              }
              {(addactivites == false && editopen == false) &&
                <>
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId='characters'>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          className={`${addactivites ? "scrollable" : ""} info-card-container my-auto`}
                          {...provided.droppableProps}
                        >
                          {dragdrop?.map((data, index) => (
                            <Draggable key={data?.id} draggableId={String(data?.id)} index={index}>
                              {(provided) => (

                                <div
                                  ref={provided.innerRef} // Assigning ref to the wrapping div
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <ActivitiesInfo
                                    // Pass other props to ActivitiesInfo
                                    data={data}
                                    setaddactivites={setaddactivites}
                                    seteditopen={seteditopen}
                                    seteditid={seteditid}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>

                </>
              }

              <div className='form-container-footer'>
                <ButtonSecondary label="Back" handleClick={() => {
                  dispatch(Clear_Create_Speaker_Check())
                  navigate(-1)
                }} />
                <ButtonPrimary label="Next Step" disabledcheck={dragdrop?.length>0 ?true:false} handleClick={() => handlenavigate()} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddProgram