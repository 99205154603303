import { makeStyles } from '@mui/styles';
import ColorTheme from '../../Common/CommonCss/ColorTheme';
import { lineHeight } from '@mui/system';

export const useStyles = makeStyles({

    PreventDesktop: {
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        // backgroundColor: ColorTheme.lightGrey,
        "& .cl": {
            maxWidth: "440px",
            margin: "auto"
        },
        "& h1, & p": {
            fontFamily: ColorTheme.publicSans,
        },
        "& h1": {
            fontSize: "32px",
            lineHeight: "38px",
            fontWeight: "700",
            marginTop: "30px",
            marginBottom: "15px",
            color: ColorTheme.orange,
        },
        "& p": {
            fontSize: "20px",
            lineHeight: "24px",
            fontWeight: "500",
            color: ColorTheme.textColor,
            marginBottom: "0",
            paddingBottom: "0px"
        }
    }

});