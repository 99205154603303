import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
}

export const GetAdminStaffSlice = createSlice({
  name: 'GetAdminStaff',
  initialState,
  reducers: {
    GetAdminStaff_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    GetAdminStaff_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};

    },
    GetAdminStaff_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_GetAdminStaff_Check: (state, action) => {
      state.value = {}
      state.error = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const {GetAdminStaff_request, GetAdminStaff_success, GetAdminStaff_failure, Clear_GetAdminStaff_Check } = GetAdminStaffSlice.actions

export default GetAdminStaffSlice.reducer