import { makeStyles } from '@mui/styles';
import AdminTheme from '../../Common/AdminTheme';
export const useStyles = makeStyles({

    AdminLogOutPopUp: {
        "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "540px",
        },
        "& .MuiDialogTitle-root": {
            background: "#f0f0f0",
            fontFamily: `${AdminTheme.poppins} !important`,
            padding: "15px 30px !important",
            fontSize: "16px !important",
            fontWeight: "600 !important",
        },
        "& .MuiDialogContent-root": {
            padding: "30px 30px !important",
            "& p": {
                fontFamily: `${AdminTheme.poppins} !important`,
                fontSize: "18px !important",
                fontWeight: "600 !important",
            }
        },
        "& .MuiDialogActions-root": {
            padding: "15px"
        }
    },
   
    Btn: {
        fontFamily: `${AdminTheme.poppins} !important`,
        padding: "8px 24px !important",
        fontSize: "14px !important",
        fontWeight: "600 !important",
        border: "1px solid !important",
        borderColor: `${AdminTheme.dangerColor} !important`,
        textTransform: "capitalize !important"
    },
    BtnLogout: {
        background: `${AdminTheme.dangerColor} !important`,
        color: "#fff !important",
    },
    BtnCancel: {
        color: `${AdminTheme.dangerColor} !important`
    },

    '@media (max-width: 500px)' : {
        AdminLogOutPopUp: {
            "& .MuiDialogTitle-root": {
                padding: "15px 20px !important",
                fontSize: "14px !important",
            },
            "& .MuiDialogContent-root": {
                padding: "20px 20px !important",
                "& p": {
                    fontSize: "16px !important",
                }
            },
            "& .MuiDialogActions-root": {
                padding: "15px"
            }
        },
        Btn: {
            padding: "6px 20px !important",
            fontSize: "13px !important",
        },
    }

})