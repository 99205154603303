import React, { useEffect, useState } from 'react'
import { useStyles } from "./Style";
import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import ColorTheme from '../../Common/CommonCss/ColorTheme';
import { useNavigate } from 'react-router';

const EventCard = ({ data }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [start_datecheck,setstart_datecheck]=useState(false)
    const [end_datecheck,setend_datecheck]=useState(false)
    const userdetails = JSON.parse(localStorage.getItem("userdetails"));
    const slugPath =  '';
    const handleClick = (id) => {
        navigate(`${slugPath}/program-event/${id}`)
    }
    console.log("data",data)
    useEffect(()=>{
        // const dateString = EventDetailsSlice?.value?.data?.start_date;
    
        // Convert the dateString to a Date object
        const targetDate = new Date(data?.start_date);
        
        // Get the current date
        const currentDate = new Date();
        if (data?.start_time != "" && data?.start_time != undefined && data?.start_time != "undefined") {

            const [hours, minutes, seconds] = data?.start_time?.split(":")?.map(Number);

            // Set the hours, minutes, and seconds of the targetDate
            targetDate.setHours(hours);
            targetDate.setMinutes(minutes);
            targetDate.setSeconds(seconds);
        }
        // targetDate.setHours(0, 0, 0, 0);
        // currentDate.setHours(0, 0, 0, 0);
        // console.log("vikalp",targetDate,targetDate,currentDate<targetDate,targetDate<currentDate)
        // Compare the two dates
        if (targetDate > currentDate) {
            // console.log("vikalp",targetDate > currentDate)
            setstart_datecheck(false)
        } else if (targetDate < currentDate) {
            console.log("vikalp")
            setstart_datecheck(true)
        } else {
            setstart_datecheck(true)
        }
      },[data])
      useEffect(()=>{
    
        // Convert the dateString to a Date object
        const targetDate = new Date(data?.end_date);
        
        // Get the current date
        const currentDate = new Date();
        // targetDate.setHours(0, 0, 0, 0);
        // currentDate.setHours(0, 0, 0, 0);
        if (data?.end_time != "" && data?.end_time != undefined && data?.end_time != "undefined") {

            const [hours, minutes, seconds] = data?.end_time?.split(":")?.map(Number);

            // Set the hours, minutes, and seconds of the targetDate
            targetDate.setHours(hours);
            targetDate.setMinutes(minutes);
            targetDate.setSeconds(seconds);
        }
        // Compare the two dates
        if (targetDate > currentDate) {
            setend_datecheck(true)
        } else if (targetDate < currentDate) {
            setend_datecheck(false)
        } else {
            setend_datecheck(true)
        }
      },[data])
      console.log("start_date",start_datecheck,end_datecheck)
    return (
        <>
        <div className={`${classes.EventCard} ${end_datecheck==true && start_datecheck==true ?"": "disable"}`} onClick={() => {
            if(end_datecheck==true && start_datecheck==true){

            handleClick(data?.id)
            }
            }}>
            <figure>
                <img
                    src={data?.banners[0]?.image_url}
                    srcSet={data?.banners[0]?.image_url}
                    loading="lazy"
                    alt="Not found"
                    className='cover'
                />
            </figure>
            <div className='bottom'>
                <div className='lft text-truncate'>
                {data?.title}
                </div>
                <div className='ryt'>
                    <Button variant="text" sx={{
                        margin: "auto 0 auto auto",
                        fontSize: "14px",
                        fontWeight: "500",
                        lineHeight: "16px",
                        color: ColorTheme.textColor,
                        fontFamily: ColorTheme.publicSans,
                        padding: '10px 0px',
                        minWidth: "100px",
                    }}
                        
                        endDecorator={<EastOutlinedIcon />}>
                        See More
                    </Button>
                </div>
            </div>
        </div>
        </>
    )
}

export default EventCard