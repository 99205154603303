import React, { useEffect, useState } from 'react';
import { useStyles } from './Style'
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import { useDispatch, useSelector } from 'react-redux';
import DeletePopup from '../DeletePopup/DeletePopup';
import { AdminDeleteEventAPI } from '../../../redux/Action/Admin/EventList/DeleteEvent';
import { GetEditEventListAPI } from '../../../redux/Action/Admin/getEditEventList/GetEditEventList';
import { useNavigate } from 'react-router-dom';
import { EventBulkDeleteEventAPI } from '../../../redux/Action/Admin/EventList/EventBulkDelelte';
import Loader from '../../../Components/Loader/Loader';
import AdmLoader from '../AdmLoader/AdmLoader';
import SearchBar from '../SearchBar/SearchBar';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import { EventduplicateListAPI } from '../../../redux/Action/Admin/duplicateEvent/DuplicateEvent';

function AuthorAction(props) {
    const { eventid } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false)
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const event_slug = localStorage.getItem("event_slug")
    const slugPath = '';
    const role = localStorage.getItem("role")
    const [DeleteID, setDeleteId] = useState()
    const getEditEventListSlice = useSelector(state => state?.getEditEventListSlice)
    const handleDelete = (id) => {
        // dispatch(AdminDeleteEventAPI(id))
        setDeleteId(id)
        setOpen(true)
    }
    const handleEdit = (id) => {
        localStorage.setItem("typecheck", 'edit')

        localStorage.setItem("archive", false)
        dispatch(GetEditEventListAPI(id))
    }
    useEffect(() => {
        if (getEditEventListSlice?.value?.success == true) {
            navigate(`/admin${slugPath}/events/create-event/event-information/${getEditEventListSlice?.value?.data?.id}`)
        }
        // console.log("getEditEventListSlice", getEditEventListSlice)
    }, [getEditEventListSlice])
    const handleDeleteevent = () => {
        // eventid
        dispatch(AdminDeleteEventAPI(eventid))
        setOpen(false);
    }
    const handleduplicate = () => {
        // eventid
        dispatch(EventduplicateListAPI(eventid))
        // setOpen(false);
    }
    // GetEditEventListAPI
    return (
        <>
            <div className={classes.ActionRow}>
                {role != "Event Manager" && <IconButton className='btn-edit' aria-label="dupllicate" onClick={() => handleduplicate(eventid)}>
                    <ControlPointDuplicateIcon />
                </IconButton>}
                <IconButton className='btn-edit' aria-label="edit" onClick={() => handleEdit(eventid)}>
                    <EditNoteRoundedIcon />
                </IconButton>
                {role != "Event Manager" && <IconButton className='btn-delete' aria-label="delete"
                    onClick={() => handleDelete(eventid)}
                // onClick={() => setOpen(true)}
                >
                    <DeleteIcon
                    // onClick={() => handleDelete(eventid)}
                    />
                </IconButton>}
            </div>
            <DeletePopup setOpen={setOpen} open={open} handleDelete={() => handleDeleteevent()} />
        </>
    );
}


function createData(id, authors, checkIn, foodVoucherLeft, gameBooth, progress, action) {
    return {
        id,
        authors,
        checkIn,
        foodVoucherLeft,
        gameBooth,
        progress,
        action,
    };
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
}

const headCells = [
    {
        id: 'Name',
        numeric: false,
        label: 'Name',
    },
    {
        id: 'Start_date',
        numeric: true,
        label: 'Start date',
    },
    {
        id: 'End_date',
        numeric: true,
        label: 'End date',
    },
    {
        id: 'action',
        numeric: true,
        label: 'Action',
    },


];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                    // sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                        //   onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
    const { numSelected, selected, setSelected } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const joinselected = selected?.map(String).join(',')
    const [open, setOpen] = useState(false)

    // console.log("joinselected", joinselected)
    const handleDelete = () => {
        setOpen(true)
    }
    const handleDeleteevent = () => {
        const formData = new FormData();
        formData.append(`event_ids`, joinselected);

        setSelected([])
        dispatch(EventBulkDeleteEventAPI(formData))
    }
    return (
        <div className={classes.ParticipantTableHeader}>
            <div className={classes.ParticipantTableHeaderLeft}>
                {numSelected > 0 ? (
                    <h4>{numSelected} Selected Events</h4>
                ) : (
                    <h4 id="tableTitle">All Events</h4>
                )}
            </div>
            <div className={classes.ParticipantTableHeaderRight}>
                <div className="rw">
                    {/* <div className="cl">
                        <SearchBar/>
                    </div> */}
                    {numSelected > 0 ? (
                        <div className="cl">
                            <Tooltip title="Delete">
                                <IconButton className={classes.IconBtnDelete} onClick={() => handleDelete()}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
            <DeletePopup setOpen={setOpen} open={open} handleDelete={() => {
                handleDeleteevent()
                setOpen(false);
            }} />

        </div>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default function ParticipantsTable() {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('checkIn');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const AdminEventListSlice = useSelector(state => state?.AdminEventListSlice?.value)
    const SingleDeleteEventSlice = useSelector(state => state?.SingleDeleteEventSlice)
    // console.log("selectedselected", selected)
    const [Data, setdata] = React.useState([])
    React.useEffect(() => {
        if (AdminEventListSlice?.data != undefined) {
            // console.log("adminEvent", AdminEventListSlice?.data)
            setdata(AdminEventListSlice?.data)
        }
    }, [AdminEventListSlice])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    // console.log("AdminEventListSlice", AdminEventListSlice)

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = AdminEventListSlice?.data?.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        // setSelected([])
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    //   const emptyRows =
    //     page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(Data, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage, Data],
    );
    // console.log("selected", selected?.length)
    const classes = useStyles();
    return (
        <>
            {
                SingleDeleteEventSlice?.loading && (
                    <AdmLoader />
                )
            }
            <div className={`${classes.ParticipantTable} ${classes.EventTable} event-table`}>
                <EnhancedTableToolbar selected={selected} setSelected={setSelected} numSelected={selected.length} />
                {visibleRows?.length > 0 ? <>
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                        >
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={AdminEventListSlice?.data?.length}
                            />
                            <TableBody>
                                {visibleRows?.map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover

                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.id}
                                            selected={isItemSelected}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            <TableCell padding="checkbox"
                                                onClick={(event) => handleClick(event, row.id)}
                                            >
                                                <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell
                                            // component="th"
                                            // id={labelId}
                                            // scope="row"
                                            // padding="none"

                                            // align="right"
                                            >
                                                {row?.name}
                                            </TableCell>
                                            <TableCell align="right">{row.start_date}</TableCell>
                                            <TableCell align="right">{row.end_date}</TableCell>
                                            <TableCell align="right"><AuthorAction eventid={row?.id} /></TableCell>

                                        </TableRow>
                                    );
                                })}
                                {/* {emptyRows > 0 && (
                <TableRow>
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={AdminEventListSlice?.data?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </> : <>
                    <div className={classes.PlaceHolder}>
                        No Event Found.
                    </div>
                
                </>}
        </div >
        </>
    );
}