// restoreevent

// resetpassword

import axios from "axios";
import { APIURL } from "../../../APIHandler/APIURL"
import { Headers } from "../../../APIHandler/HeaderAPI";
import { reloadHandler } from "../../../utlis/utlis";
import { RestoreEvent_failure, RestoreEvent_request, RestoreEvent_success } from "../../Reducer/RestoreEvent/RestoreEventSlice";
import { AdminEventListAPI } from "../Admin/EventList/EventList";
import { EventDashboardAPI } from "../Admin/EventList/EventDashboardList";
import { ArchiveEvent } from "../Admin/ArchiveEvents/ArchiveEvent";

export const RestoreEventAPI = (id) => async (dispatch, getState) => {
    dispatch(RestoreEvent_request())
    // const eventItemId=localStorage.getItem("eventItemId")
    const auth_token = localStorage.getItem("auth_token")
    const admineventid=localStorage.getItem("admineventid")
  
    try {
        const { data } = await axios.get(`${APIURL.restoreevent}/${id}`,{
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
              "event-slug":Headers?.["event-slug"],
                "Content-type": "application/json",
                Authorization: `Bearer ${auth_token}`
            },
        })
        dispatch(RestoreEvent_success(data))
        dispatch(AdminEventListAPI())
        dispatch(EventDashboardAPI(admineventid))
        dispatch(ArchiveEvent())
    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(RestoreEvent_failure(error?.response?.data))

    }
}