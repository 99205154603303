import axios from "axios";
import { APIURL } from "../../../../APIHandler/APIURL"
import { Headers } from "../../../../APIHandler/HeaderAPI";
import { reloadHandler } from "../../../../utlis/utlis";
import { Get_speakers_failure, Get_speakers_request, Get_speakers_success } from "../../../Reducer/Admin/Speakers/getSpeakersSlice";

export const GetSpeakerAPI = (payload) => async (dispatch, getState) => {
    // const eventItemId=localStorage.getItem("eventItemId")
    const auth_token = localStorage.getItem("auth_token")
    dispatch(Get_speakers_request())
    try {
        const { data } = await axios.get(`${APIURL.createspeaker}/${payload}`, {
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
               "event-slug":Headers?.["event-slug"],

                "Content-type": "application/json",
                Authorization: `Bearer ${auth_token}`
            },
        })
      
        dispatch(Get_speakers_success(data))
    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(Get_speakers_failure(error?.response?.data))
    }
}

