import React from 'react'
import { useStyles } from "./Style"

const ButtonTab = (props) => {
    const { label, icon, className } = props;
    const classes = useStyles();
  return (
    <>
    <div className={`${classes.BtnTab} admin-btn-box ${className}`}>
        <div className={`${classes.BtnTabIcon} admin-btn-box-icon`}>
          {icon}
        </div>
        <p className={`${classes.BtnTabText} admin-btn-box-text`}>{label}</p>
    </div>
    </>
  )
}

export default ButtonTab

