import { makeStyles } from '@mui/styles';
import ColorTheme from '../../Common/CommonCss/ColorTheme';
export const useStyles = makeStyles({

    BtnSecondary:{
        backgroundColor: `${ColorTheme.orange5} !important`,
        color: `${ColorTheme.orange} !important`,
        width: "100%",
        height: "48px",
        borderRadius: "10px !important",
        fontSize: "18px !important",
        fontFamily: `${ColorTheme.publicSans} !important`,
        marginTop: "auto !important",
        marginBottom: "0",
        textTransform: "none !important",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& .icn": {
            marginRight: "10px",
            width: "24px",
            height: "24px"
        }
    }


})