import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
}

export const EmailCheckSlice = createSlice({
  name: 'EmailCheck',
  initialState,
  reducers: {
    Email_Check_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    Email_Check_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};

    },
    Email_Check_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_Email_Check: (state, action) => {
      state.value = {}
      state.error = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const { Email_Check_request, Email_Check_success, Email_Check_failure, Clear_Email_Check } = EmailCheckSlice.actions

export default EmailCheckSlice.reducer