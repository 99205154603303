import React from 'react'
import Avatar from '@mui/material/Avatar';
import {useStyles} from './Style'

const ParticipantCard = (props) => {
    const classes = useStyles();
    const {Image, Name, Validity} = props;
  return (
    <>
    <div className={`${classes.ParticipantCard} participant-card`}>
        <Avatar alt={Name} src={Image} />
        <div className={classes.PCLeft}>
            <h6>{Name}</h6>
            <p>Valid for :  {Validity}</p>
        </div>
    </div>
    </>
  )
}

export default ParticipantCard