import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
}

export const QRStaffGeneratorSlice = createSlice({
  name: 'QRStaffGenerator',
  initialState,
  reducers: {
    QR_Staff_Generator_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    QR_Staff_Generator_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};

    },
    QR_Staff_Generator_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_QR_Staff_Generator_Check: (state, action) => {
      state.value = {}
      state.error = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const {  QR_Staff_Generator_request, QR_Staff_Generator_success,  QR_Staff_Generator_failure, Clear_QR_Staff_Generator_Check } = QRStaffGeneratorSlice.actions

export default QRStaffGeneratorSlice.reducer