import React, { useEffect, useState } from 'react'
import { useStyles } from "./Style";
import SliderHome from '../../Components/Slider/Slider';
import Typography from '@mui/joy/Typography';
import Grid from '@mui/joy/Grid';
import { ButtonSquared } from '../../Components/ButtonSquare/ButtonSquare'
import qrIcon from './../../Images/icons/qr-icon.svg'
import infoIcon from './../../Images/icons/info-icon.svg'
import ticketInfoIcon from './../../Images/icons/ticket-info-icon.svg'
import EventCard from '../../Components/EventCard/EventCard';
import ColorTheme from '../../Common/CommonCss/ColorTheme';
import QRButton from './QRButton';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Components/Loader/Loader';
import { EventDetailsAPI } from '../../redux/Action/EventDetails/EventDetails';
import { VoucherAPI } from '../../redux/Action/Vouchers/Vouchers';
import ButtonLogOut from '../../Components/ButtonLogOut/ButtonLogOut';
import { Clear_Scan_Activity_Check } from '../../redux/Reducer/ScanActivity/ScanActivity';
import Sidebar from '../../Components/Sidebar/Sidebar';
import Snacbar from '../../Components/Snackbar/Snacbar';
import { Clear_ChangePassword_Details } from '../../redux/Reducer/ChangePassword/ChangePassword';
import LogoutSoket from '../../Soket/LogoutSoket';
import { Clear_UserAttendance_Check } from '../../redux/Reducer/Admin/UserAttendance/UserAttendanceSlice';
import { UserAttendanceAPI } from '../../redux/Action/UserAttendance/UserAttendance';
import { PerecentageCheck_success } from '../../redux/Reducer/PercentageCheckSlice';

const Home = () => {
    const classes = useStyles();
    const userdetails = JSON.parse(localStorage.getItem("userdetails"));
    const [eventsList, seteventList] = useState([])
    const eventItemId = localStorage.getItem("eventItemId")
    const auth_name = localStorage.getItem("auth_name")
    const EventDetailsSlice = useSelector(state => state?.EventDetailsSlice)
    const VouchersSlice = useSelector(state => state?.VouchersSlice)
    const dispatch = useDispatch();
    const [open, setopen] = useState(false)
    const ChangePasswordSlice = useSelector(state => state?.ChangePasswordSlice)
    const UserAttendaceSlice = useSelector(state => state?.UserAttendaceSlice)
    const UserAttendanceSlice = useSelector(state => state?.UserAttendanceSlice)
    const [attendancemsg, setattendancemsg] = useState("")
    const [attendancemsgopen, setattendancemsgopen] = useState(false)

    useEffect(() => {
        if (EventDetailsSlice?.value?.data != undefined && EventDetailsSlice?.value?.data != null && userdetails != undefined && userdetails != null) {

            seteventList(EventDetailsSlice?.value?.data ? EventDetailsSlice?.value?.data : userdetails?.data)
        }
    }, [EventDetailsSlice])
    useEffect(() => {
        localStorage.removeItem("checkin")
        dispatch(PerecentageCheck_success(0))

        dispatch(EventDetailsAPI(eventItemId))
        dispatch(UserAttendanceAPI())
        dispatch(VoucherAPI())
        dispatch(Clear_Scan_Activity_Check())
        dispatch(Clear_UserAttendance_Check())
        localStorage.removeItem("program_id")
    }, [])
    // console.log("ChangePasswordSlice", ChangePasswordSlice)
    useEffect(() => {
        if (ChangePasswordSlice?.value?.message) {
            setopen(true)
            setTimeout(() => {
                dispatch(Clear_ChangePassword_Details())
            }, 3000)
        }
    }, [ChangePasswordSlice])
    useEffect(() => {
        if (UserAttendanceSlice?.value?.message) {
            setattendancemsg(UserAttendanceSlice?.value?.message)
            setattendancemsgopen(true)
            dispatch(Clear_UserAttendance_Check())
        }
    }, [UserAttendanceSlice])
    console.log("UserAttendaceSlice",UserAttendaceSlice)
    return (
        <>
            <LogoutSoket />
            <div className='main'>
                {
                    EventDetailsSlice?.loading == true && (
                        <Loader />
                    )
                }
                {
                    (attendancemsgopen) && (
                        <Snacbar
                            open={attendancemsg}
                            setOpen={setattendancemsgopen}
                            messagetype={"success"}
                            message={("Attendance has been successfully recorded.")}
                        />

                    )
                }
                {
                    (ChangePasswordSlice?.value?.message) && (
                        <Snacbar
                            open={open}
                            setOpen={setopen}
                            messagetype={"success"}
                            message={(ChangePasswordSlice?.value?.message)}
                        />

                    )
                }
                <div className={classes.Home}>
                    <div className={classes.UserTitleHeader}>
                        <div className='cl h1 text-truncate'>
                            Hello, <strong>{auth_name}</strong>
                        </div>
                        <div className='cl'>
                            <Sidebar />
                            {/* <ButtonLogOut /> */}
                        </div>
                    </div>
                    <SliderHome eventsList={eventsList} />
                    <div className='container'>
                        <QRButton />
                        <Typography level="h2" sx={{
                            fontSize: ColorTheme.headingFS,
                            fontWeight: ColorTheme.headingFW,
                            lineHeight: ColorTheme.headingLH,
                            color: ColorTheme.textColor,
                            fontFamily: ColorTheme.publicSans,
                            marginTop: "20px",
                            marginBottom: "10px",
                        }}
                        >Programs</Typography>

                        <Grid container spacing={2} alignItems='center' sx={{ flexGrow: 1 }}>
                            {VouchersSlice?.value?.data?.map((data) => {
                                return <Grid lg={3} md={4} sm={6} xs={12}><EventCard data={data} /></Grid>
                            })}
                        </Grid>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Home