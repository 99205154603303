import { makeStyles } from '@mui/styles';
import ColorTheme from '../../Common/CommonCss/ColorTheme';
// import InnerDimension from '../../Components/InnerDimension/InnerDimension';
export const useStyles = makeStyles({

    header: {
        background: ColorTheme.lightGrey,
    },
    loginWrapper: {
       display: "flex",
       background: ColorTheme.white,
       zIndex: "2",
       "& .title-rw": {
        display: "flex",
        "& .cl:first-child": {
            flex: "1",
        },
        "& .cl:last-child": {
            paddingTop: "3px"
        }
       },
       "& h2":{
        fontSize: "32px",
        fontWeight: "700",
        lineHeight: "38px",
        color: ColorTheme.textColor,
        textTransform: "uppercase",
        fontFamily: ColorTheme.publicSans,
        marginBottom: "30px",
        marginTop: "0"
        },
        "& .instruction-card": {
            display: "flex",
            flexDirection: "column",
            "& p": {
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "20px",
                color: ColorTheme.textColor,
                textTransform: "none",
                fontFamily: ColorTheme.publicSans,
                marginBottom: "20px",
                marginTop: "0"
            }
        }
    },

    BtnLink: {
        fontFamily: ColorTheme.publicSans,
        border: "none",
        padding: "0",
        outline: "0",
        color: ColorTheme.orange,
        textDecoration: "underline",
        fontSize: "inherit",
        lineHeight: "inherit",
        fontWeight: "600",
        cursor: "pointer"
    },

    formGroup:{
        marginBottom: "27px"
    },
    Label:{
        color: ColorTheme.textColor,
        fontFamily: ColorTheme.publicSans
    },
    Input:{
        borderRadius: "10px !important",
        backgroundColor: `${ColorTheme.orange5} !important`,
        borderColor: `${ColorTheme.orange5} !important`,
        "&.Mui-focused":{
            borderColor: `${ColorTheme.orange} !important`,
            backgroundColor: `${ColorTheme.white} !important`,
        }
    },
    eyeIcon:{
        borderRadius: "10px !important",
        color: ColorTheme.textColor,
        opacity: "0.6"
    },
    BtnTheme:{
        backgroundColor: `${ColorTheme.orange} !important`,
        color: ColorTheme.white,
        width: "100%",
        height: "48px",
        borderRadius: "10px !important",
        fontSize: "18px !important",
        fontFamily: `${ColorTheme.publicSans} !important`,
        marginTop: "auto !important",
        marginBottom: "0"
    },


    LinkForgotPW: {
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: "500",
        fontFamily: ColorTheme.publicSans,
        color: ColorTheme.orange,
        textAlign: "right",
        textDecoration: "none",
        "&:hover": {
            textDecoration: "underline"
        }
    },

    Login: {
        "& .btn-back": {
            position: "absolute",
            top: "30px",
            left: "20px",
            background: "transparent",
            color: "#fff",
            border: "none",
            display: "flex",
            alignItems: "center",
            gap: "15px",
            fontSize: "18px",
            lineHeight: "28px",
            fontWeight: "500",
            fontFamily: ColorTheme.publicSans,
            cursor: "pointer",
            zIndex: "2",
        },
    },

    "@media(min-width: 576px)": {
        Login: {
            display: "flex",
            minHeight: "100vh",
            width: "100%",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            "&:after":{
                content: "''",
                position: "absolute",
                left: "0",
                top: "0",
                bottom: "0",
                right: "0",
                background: "linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 70%)",
            }
        },
        header: {
            display: "none"
        },
        loginWrapper: {
            flex: "1",
            margin: "auto auto",
            height: "580px",
            borderRadius: "40px",
            padding: "60px 40px 80px 40px",
            width: "100%",
            maxWidth: "480px",
            background: "rgba(255,255,255,0.85)",
            boxShadow: ColorTheme.cardShadow,
        }
    },
    "@media(min-width: 1025px)": {
        Login: {
            justifyContent: "flex-end",
        },
        loginWrapper: {
            margin: "auto 0",
            transform: "translateX(-60px)",
            left: "auto",
        }
    },
    "@media(max-width: 1367px)": {
        loginWrapper: {
            height: "420px"
        }
    },
    "@media(max-width: 575px)": {
        Login: {
            backgroundImage: "none !important",
            "& .btn-back": {
                position: "absolute",
                top: "15px",
                left: "10px",
            },
        },
        header: {
            height: "220px",
            position: "relative",
            "&:after":{
                content: "''",
                position: "absolute",
                left: "0",
                top: "0",
                bottom: "0",
                right: "0",
                background: "linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 70%)",
            }
        },
        
        loginWrapper: {
            maxWidth: ColorTheme.containerWidth,
            // height: `calc(${InnerDimension().height}px - 220px - 40px)`,
            height: `calc(100dvh - 220px - 40px)`,
            marginTop: "-40px",
            position: 'relative',
            padding: "40px 20px 30px 20px",
            borderTopLeftRadius: "30px",
            borderTopRightRadius: "30px",
            marginLeft: "auto",
            marginRight: "auto",
            "& h2":{
                fontSize: "26px",
                lineHeight: "32px",
            }
        }

    },
    "@media(max-height: 575px)": {
        loginWrapper: {
            height: "auto",
            margin: "15px auto"
        }
    },
    "@media(max-width: 340px)": {
        loginWrapper: {
            "& h2":{
                fontSize: "24px",
                lineHeight: "28px",
            }
        }

    }
    
});