import * as React from 'react';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import AdminTheme from '../../Common/AdminTheme';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));


export default function ProgressBar(props) {
  const {Value} = props;
  return (
    <>
    <Typography variant="h6" sx={{
      fontSize: "12px",
      lineHeight: "18px",
      fontWeight: "600",
      fontFamily: AdminTheme.poppins,
      color: AdminTheme.lightColor,
      marginBottom: "7px",
      textAlign: "left"
    }}>
        {Value}
      </Typography>
    <BorderLinearProgress variant="determinate" value={Value} />
    </>
  );
}