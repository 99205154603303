import React, { useState } from 'react'
import { useStyles } from "./Style";
import qrCodeIcon from './../../Images/icons/qrcode-icon.svg'
import CheckIcon from './../../Images/icons/check-success-icon.svg'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader/Loader';
import { useEffect } from 'react';
import { Stack } from '@mui/system';

const EventTickets = () => {
    const classes = useStyles();
    const userdetails = JSON.parse(localStorage.getItem("userdetails"))
    const VouchersSlice = useSelector(state => state?.VouchersSlice)
    const navigate = useNavigate()
    const [start_datecheck, setstart_datecheck] = useState(false)
    const [end_datecheck, setend_datecheck] = useState(false)
    // const userdetails = JSON.parse(localStorage.getItem("userdetails"));
    const slugPath ='';
    console.log("VouchersSlice", VouchersSlice)
    const handleClick = (id) => {
        localStorage.setItem("program_id", id)
        navigate(`${slugPath}/qrscanner/${id}`)
    }

    const EventBoxList = (data) => {
        const eventStartDate = () => {
            const targetDate = new Date(data?.start_date);

            // Get the current date
            const currentDate = new Date();
            // targetDate.setHours(0, 0, 0, 0);
            // currentDate.setHours(0, 0, 0, 0);
            if (data?.start_time != "" && data?.start_time != undefined && data?.start_time != "undefined") {

                const [hours, minutes, seconds] = data?.start_time?.split(":")?.map(Number);

                // Set the hours, minutes, and seconds of the targetDate
                targetDate.setHours(hours);
                targetDate.setMinutes(minutes);
                targetDate.setSeconds(seconds);
            }
            // Compare the two dates
            if (targetDate > currentDate) {
                return false
            } else if (targetDate < currentDate) {
                return true
            } else {
                return true
            }

        }
        const EndDateCheck = () => {
            const targetDate = new Date(data?.end_date);
            // Get the current date
            const currentDate = new Date();
            // targetDate.setHours(0, 0, 0, 0);
            // currentDate.setHours(0, 0, 0, 0);
            if (data?.end_time != "" && data?.end_time != undefined && data?.end_time != "undefined") {

                const [hours, minutes, seconds] = data?.end_time?.split(":")?.map(Number);

                // Set the hours, minutes, and seconds of the targetDate
                targetDate.setHours(hours);
                targetDate.setMinutes(minutes);
                targetDate.setSeconds(seconds);
            }
            // Compare the two dates
            if (targetDate > currentDate) {
                return true
            } else if (targetDate < currentDate) {
                return false
            } else {
                return true
            }
        }
        // useEffect(()=>{
        //     // const dateString = EventDetailsSlice?.value?.data?.start_date;

        //     // Convert the dateString to a Date object
        //     const targetDate = new Date(data?.start_date);

        //     // Get the current date
        //     const currentDate = new Date();
        //     targetDate.setHours(0, 0, 0, 0);
        //     currentDate.setHours(0, 0, 0, 0);
        //     // Compare the two dates
        //     if (targetDate > currentDate) {
        //         setstart_datecheck(false)
        //     } else if (targetDate < currentDate) {
        //         setstart_datecheck(true)
        //     } else {
        //         setstart_datecheck(true)
        //     }
        //   },[data])
        //   useEffect(()=>{

        //     // Convert the dateString to a Date object
        //     const targetDate = new Date(data?.end_date);

        //     // Get the current date
        //     const currentDate = new Date();
        //     targetDate.setHours(0, 0, 0, 0);
        //     currentDate.setHours(0, 0, 0, 0);
        //     // Compare the two dates
        //     if (targetDate > currentDate) {
        //         setend_datecheck(true)
        //     } else if (targetDate < currentDate) {
        //         setend_datecheck(false)
        //     } else {
        //         setend_datecheck(true)
        //     }
        //   },[data])
        console.log("EndDateCheck", EndDateCheck(), eventStartDate())
        return <div className={`${(EndDateCheck() == true && eventStartDate() == true) ? "" : "disabledQr"}`} key={data?.id} onClick={() => {
            if (EndDateCheck() == true && eventStartDate() == true && data?.used_count!=data?.total_count ) {

                handleClick(data?.id)
            }
        }}>
            <span className={`${classes.Row} ${(EndDateCheck() == true && eventStartDate() == true && data?.used_count!=data?.total_count ) ? "" : "disabledQr"}`}>
                <div className={`${classes.ColLeft}`}>
                    <div className={classes.ColLeftInner}>
                        <span className={`${classes.EventTxt} text-truncate-2`}>{data?.title}</span>
                        <span className={classes.EventCount}>{ +data?.total_count - +data?.used_count}/{data?.total_count}</span>
                    </div>
                </div>
               {data?.used_count!=data?.total_count && <div className={classes.ColRight}>
                    <img src={qrCodeIcon} alt="" className={classes.QRCodeImg} />
                </div>}
            </span>
            <span className={` ${(EndDateCheck() == true && eventStartDate() == true && data?.used_count!=data?.total_count ) ? "" : "disabledQr"}`}>

            <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between' ,marginBottom:"5px", opacity:(EndDateCheck() == true && eventStartDate() == true && data?.used_count!=data?.total_count )?"":"0.4"}} className={`${(EndDateCheck() == true && eventStartDate() == true) ? "" : "disabledQr"}`} >
                <h3 className={classes.TextLeft}>Start Date </h3>
                <h3 className={classes.TextRight}>{data?.start_date} , {data?.start_time}</h3>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between' }} className={`${classes.BottomBorder} ${(EndDateCheck() == true && eventStartDate() == true && data?.used_count!=data?.total_count ) ? "" : "disabledQr"}`} >
                <h3 className={classes.TextLeft}>End Date</h3>
                <h3 className={classes.TextRight}>{data?.end_date} , {data?.end_time}</h3>
            </Stack>
            </span>
        </div>
    }
    return (
        <>
            {
                VouchersSlice?.loading == true && (
                    <Loader />
                )
            }
            <div className={classes.EventTicketList}>
                {
                    VouchersSlice?.value?.data?.map((data) => {
                        return <>

                            {data?.is_qr_enable == 1 &&
                                <>
                                    {EventBoxList(data)}
                                </>
                            }
                        </>
                    })
                }



            </div>
        </>
    )
}

export default EventTickets