import { makeStyles } from '@mui/styles';
import ColorTheme from '../../Common/CommonCss/ColorTheme';

export const useStyles = makeStyles({

    BtnDanger:{
        backgroundColor: `${ColorTheme.dangerColor} !important`,
        color: ColorTheme.white,
        width: "100%",
        height: "48px",
        borderRadius: "0px !important",
        fontSize: "18px !important",
        fontFamily: `${ColorTheme.publicSans} !important`,
        textTransform: "none !important"
    }

})