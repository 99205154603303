import axios from "axios";
import { APIURL } from "../../../APIHandler/APIURL"
import { Headers } from "../../../APIHandler/HeaderAPI";

export const DeleteLoginBannerAPI = (payload) => async (dispatch, getState) => {
    const auth_token = localStorage.getItem("auth_token")
    // dispatch(Dashboard_List_request())
    try {

        const { data } = await axios.delete(`${APIURL.deleteloginbanner}/${payload}`, {
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
               "event-slug":Headers?.["event-slug"],
                "Content-type": "application/json",
                Authorization: `Bearer ${auth_token}`
            },
        })
        // console.log("data", data)
        // dispatch(Dashboard_List_success(data))
    } catch (error) {
        // reloadHandler(error?.response?.status)
        // dispatch(Dashboard_List_failure(error?.response?.data))

    }
}