import { makeStyles } from '@mui/styles';
import AdminTheme from '../../Common/AdminTheme';
export const useStyles = makeStyles({

    LanguageSelectDropdown: {
        minWidth: "unset !important",
        minHeight: "unset !important",
        width: "32px",
        height: "32px",
        borderRadius: "4px",
        background: "#F3F6F9",
        padding: "0px !important",
        border: "none",
        flexShrink: "0",
        "& .MuiSelect-indicator": {
            display: "none"
        },
        "& .language-icon": {
            width: "100%",
            height: "100%",
            borderRadius: "inherit",
            "& image": {
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "center",
                lineHeight: "0",
            }
        }
    },
    LanguageLi: {
        padding: "8px 12px !important",
        background: "#fff !important",
        fontSize: "12px !important",
        fontWeight: "500 !important",
        lineHeight: "16px !important",
        color: "#434349 !important",
        fontFamily: AdminTheme.poppins,
    },
    LanguageIcon: {
        width: "22px !important",
        height: "22px !important",
        borderRadius: "4px !important",
    },


    '@media (max-width: 767px)' : {
        LanguageSelectDropdown: {
            width: "24px",
            height: "24px",
        }

    },

    '@media (max-width: 500px)' : {
        LanguageSelectDropdown: {
            width: "24px",
            height: "24px",
            borderRadius: "2px",
            "& .language-icon": {
                width: "24px",
                height: "24px",
            }
        }
    }

})