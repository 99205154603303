import axios from "axios";
import { APIURL } from "../../../../APIHandler/APIURL"
import { Headers } from "../../../../APIHandler/HeaderAPI";
import { reloadHandler } from "../../../../utlis/utlis";
import { AddAdminStaff_failure, AddAdminStaff_request, AddAdminStaff_success } from "../../../Reducer/Admin/AdminStaff/AddAdminStaffSlice";
import { GetAdminStaff_failure, GetAdminStaff_request, GetAdminStaff_success } from "../../../Reducer/Admin/AdminStaff/GetAdminStaffSlice";
import { DeleteAdminStaff_failure, DeleteAdminStaff_request, DeleteAdminStaff_success } from "../../../Reducer/Admin/AdminStaff/DeleteAdminStaffSlice";
import { Clear_GetEditAdminStaff_Check, GetEditAdminStaff_failure, GetEditAdminStaff_request, GetEditAdminStaff_success } from "../../../Reducer/Admin/AdminStaff/GetEditStaffSlice";

export const AddAdminStaffAPI = (payload, setaddactivites, id,setname,setemailstaff,setpassword) => async (dispatch, getState) => {
    // const eventItemId=localStorage.getItem("eventItemId")
    const auth_token = localStorage.getItem("auth_token")
    dispatch(AddAdminStaff_request())
    const admineventid = localStorage.getItem("admineventid")

    try {
        const { data } = await axios.post(`${APIURL.addadminstaff}/${id ? id : admineventid}`, payload, {
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
               "event-slug":Headers?.["event-slug"],
                // "Content-type": "application/json",
                "Content-type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${auth_token}`
            },
        })
        setaddactivites(false)
        dispatch(AddAdminStaff_success(data))
        setname("")
        setemailstaff("")
        setpassword("")
        dispatch(Clear_GetEditAdminStaff_Check())
        if(id){

            dispatch(GetAdminStaffAPI(id))
        }else{
            dispatch(GetAdminStaffAPI())

        }
    } catch (error) {
        reloadHandler(error?.response?.status)
        // setaddactivites(false)
        // setname("")
        // setemailstaff("")
        // setpassword("")
        dispatch(AddAdminStaff_failure(error?.response?.data))
    }
}
export const EditAdminStaffAPI = (payload, setaddactivites, id,setname,setemailstaff,setpassword,staffid) => async (dispatch, getState) => {
    // const eventItemId=localStorage.getItem("eventItemId")
    const auth_token = localStorage.getItem("auth_token")
    dispatch(AddAdminStaff_request())
    const admineventid = localStorage.getItem("admineventid")

    try {
        const { data } = await axios.put(`${APIURL.addadminstaff}/${staffid}`, payload, {
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
                "Content-type": "application/json",
                "event_slug":Headers?.event_slug,
                // "Content-type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${auth_token}`
            },
        })
        setaddactivites(false)
        dispatch(AddAdminStaff_success(data))
        setname("")
        setemailstaff("")
        setpassword("")
        if(id){

            dispatch(GetAdminStaffAPI(id))
        }else{
            dispatch(GetAdminStaffAPI())

        }
    } catch (error) {
        reloadHandler(error?.response?.status)
        // setaddactivites(false)
        // setname("")
        // setemailstaff("")
        // setpassword("")
        dispatch(AddAdminStaff_failure(error?.response?.data))
    }
}



export const GetAdminStaffAPI = ( id) => async (dispatch, getState) => {
    // const eventItemId=localStorage.getItem("eventItemId")
    const auth_token = localStorage.getItem("auth_token")
    dispatch(GetAdminStaff_request())
    const admineventid = localStorage.getItem("admineventid")

    try {
        const { data } = await axios.get(`${APIURL.addadminstaff}/${id ? id : admineventid}`, {
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
                "event_slug":Headers?.event_slug,
                // "Content-type": "application/json",
                "Content-type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${auth_token}`
            },
        })
        dispatch(GetAdminStaff_success(data))
        

    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(GetAdminStaff_failure(error?.response?.data))
    }
}
export const EditGetAdminStaffAPI = ( id) => async (dispatch, getState) => {
    // const eventItemId=localStorage.getItem("eventItemId")
    const auth_token = localStorage.getItem("auth_token")
    dispatch(GetEditAdminStaff_request())
    const admineventid = localStorage.getItem("admineventid")

    try {
        const { data } = await axios.get(`${APIURL.editgetadminstaff}/${id }`, {
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
                "event_slug":Headers?.event_slug,
                // "Content-type": "application/json",
                "Content-type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${auth_token}`
            },
        })
        dispatch(GetEditAdminStaff_success(data))
        

    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(GetEditAdminStaff_failure(error?.response?.data))
    }
}
export const DeleteAdminStaffAPI = ( id,eventid) => async (dispatch, getState) => {
    // const eventItemId=localStorage.getItem("eventItemId")
    const auth_token = localStorage.getItem("auth_token")
    dispatch(DeleteAdminStaff_request())
    const admineventid = localStorage.getItem("admineventid")

    try {
        const { data } = await axios.delete(`${APIURL.addadminstaff}/${id}`, {
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
                "event_slug":Headers?.event_slug,
                // "Content-type": "application/json",
                "Content-type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${auth_token}`
            },
        })
        dispatch(DeleteAdminStaff_success(data))
        if(eventid){

            dispatch(GetAdminStaffAPI(eventid))
        }else{
            dispatch(GetAdminStaffAPI())

        }

    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(DeleteAdminStaff_failure(error?.response?.data))
    }
}
