import axios from "axios";
import { APIURL } from "../../../../APIHandler/APIURL"
import { Headers } from "../../../../APIHandler/HeaderAPI";
import { reloadHandler } from "../../../../utlis/utlis";
import { Admin_Create_Activities_failure, Admin_Create_Activities_request, Admin_Create_Activities_success } from "../../../Reducer/Admin/CreateActivities/CreateActivities";
import { GetActivitiesAPI } from "./GetActivities";

export const AdminCreateActivitiesAPI = (payload,seteaddopen,setaddactivites,id) => async (dispatch, getState) => {
    // const eventItemId=localStorage.getItem("eventItemId")
    const auth_token = localStorage.getItem("auth_token")
    dispatch(Admin_Create_Activities_request())
    const admineventid = localStorage.getItem("admineventid")
 
    try {
        const { data } = await axios.post(`${APIURL.admincreateactivity}`, payload, {
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
               "event-slug":Headers?.["event-slug"],
                // "Content-type": "application/json",
                "Content-type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${auth_token}`
            },
        })
        setaddactivites(false)
        seteaddopen(false)
        dispatch(Admin_Create_Activities_success(data))
        if(id){

            dispatch(GetActivitiesAPI(id))
        }else{

            dispatch(GetActivitiesAPI(admineventid))
        }
    } catch (error) {
        reloadHandler(error?.response?.status)
        // setaddactivites(false)
        dispatch(Admin_Create_Activities_failure(error?.response?.data))
    }
}


