import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
}

export const ProgramDetailsSlice = createSlice({
  name: 'ProgramDetails',
  initialState,
  reducers: {
    Program_Details_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    Program_Details_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};

    },
    Program_Details_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_Program_Details_Check: (state, action) => {
      state.value = {}
      state.error = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const {  Program_Details_request, Program_Details_success,  Program_Details_failure, Clear_Program_Details_Check } = ProgramDetailsSlice.actions

export default ProgramDetailsSlice.reducer