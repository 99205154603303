// partcipants

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
  error:{}
}

export const BulkDeleteParticipantSlice = createSlice({
  name: 'BulkDeleteParticipant',
  initialState,
  reducers: {
    Bulk_Delete_Participant_request: (state, action) => {
      state.value = {};
      state.loading = true;
      state.error={}
    },
    Bulk_Delete_Participant_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};

    },
    Bulk_Delete_Participant_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_Bulk_Delete_Participant_Check: (state, action) => {
      state.value = {}
      state.error = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const {Bulk_Delete_Participant_request, Bulk_Delete_Participant_success, Bulk_Delete_Participant_failure, Clear_Bulk_Delete_Participant_Check } = BulkDeleteParticipantSlice.actions

export default BulkDeleteParticipantSlice.reducer