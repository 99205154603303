import axios from "axios";
import { APIURL } from "../../../../APIHandler/APIURL"
import { Headers } from "../../../../APIHandler/HeaderAPI";
import { reloadHandler } from "../../../../utlis/utlis";
import { Get_Edit_Activites_failure, Get_Edit_Activites_request, Get_Edit_Activites_success } from "../../../Reducer/Admin/CreateActivities/GetEditActivities";

export const GetEditActivitiesAPI = (payload) => async (dispatch, getState) => {
    // const eventItemId=localStorage.getItem("eventItemId")
    const auth_token = localStorage.getItem("auth_token")
    dispatch(Get_Edit_Activites_request())
    try {
        const { data } = await axios.get(`${APIURL.admingeteditactivity}/${payload}`,{
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
                "Content-type": "application/json",
               "event-slug":Headers?.["event-slug"],
                Authorization: `Bearer ${auth_token}`
            },
        })
        dispatch(Get_Edit_Activites_success(data))
    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(Get_Edit_Activites_failure(error?.response?.data))
    }
}


  