import axios from "axios";
import { APIURL } from "../../../../APIHandler/APIURL"
import { Headers } from "../../../../APIHandler/HeaderAPI";
import { reloadHandler } from "../../../../utlis/utlis";
import { Admin_Event_List_failure, Admin_Event_List_request, Admin_Event_List_success } from "../../../Reducer/Admin/EventList/EventListSlice";
import { Dashboard_List_failure, Dashboard_List_request, Dashboard_List_success } from "../../../Reducer/DashboardList/DashboardList";

export const EventDashboardAPI = (payload) => async (dispatch, getState) => {
    // const eventItemId=localStorage.getItem("eventItemId")
    dispatch(Dashboard_List_request())
    const auth_token = localStorage.getItem("auth_token")
    try {
        const { data } = await axios.get(`${APIURL.dashboardlist}/${payload}`,{
            headers: {
                "host-url": Headers?.["host-url"],
              "event-slug":Headers?.["event-slug"],
                "language": Headers?.language,
                "Content-type": "application/json",
                Authorization: `Bearer ${auth_token}`
            },
        })
        // console.log("vikalpdata",data?.data)
        localStorage.setItem("Eventimageurl",data?.data?.activity[0]?.banners[0]?.image_url)
        dispatch(Dashboard_List_success(data))
    } catch (error) {
        dispatch(Dashboard_List_failure(error?.response?.data))
    }
}


  