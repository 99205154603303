import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
  error:{}
}

export const EditEventListSlice = createSlice({
  name: 'EditEventList',
  initialState,
  reducers: {
    Edit_EventList_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    Edit_EventList_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};

    },
    Edit_EventList_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_Edit_EventList_Check: (state, action) => {
      state.value = {}
      state.error = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const {Clear_Edit_EventList_Check, Edit_EventList_failure, Edit_EventList_success, Edit_EventList_request } = EditEventListSlice.actions

export default EditEventListSlice.reducer