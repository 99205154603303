import React, { useEffect, useState } from 'react'
import { useStyles } from "./Style";
import Modal from '@mui/material/Modal';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Input from '@mui/joy/Input';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ButtonPrimary from '../../Components/ButtonPrimary/ButtonPrimary'
import { useDispatch, useSelector } from 'react-redux';
import { ResetPasswordAPI } from '../../../redux/Action/ResetPassword/RessetPassword';
import { validatePassword, validatePasswordminlength } from '../../../utlis/Validations';
import Loader from '../../../Components/Loader/Loader';
import { Clear_ResetPassword_Check } from '../../../redux/Reducer/ResetPassword/ResetPAsswordSlice';
import AdmLoader from '../AdmLoader/AdmLoader';


const ResetPasswordPopup = ({ eventid ,setOpentrue}) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const dispatch = useDispatch();
    // console.log("eventid", eventid)
    //   ResetPasswordAPI
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [newPassword, setnewPassword] = useState("");
    const [newPassworderror, setnewPassworderror] = useState("");
    const [ConfirmPassword, setConfirmPassword] = useState("");
    const [ConfirmPassworderror, setConfirmPassworderror] = useState("");
    const [newPassworderrorvalidation, setnewPassworderrorvalidation] = useState(false);
    const ResetPasswordSlice = useSelector(state => state?.ResetPasswordSlice)
    const handleResetPassword = (e) => {
        e.preventDefault();
        if (validatePasswordminlength(newPassword) || newPassword == "" || validatePasswordminlength(ConfirmPassword) || ConfirmPassword == "") {
            if (newPassword == "") {

                setnewPassworderror(true)
            }
            if (ConfirmPassword == "") {

                setConfirmPassworderror(true)
            }
            if (validatePassword(newPassword)) {

                setnewPassworderrorvalidation(true)
            }
        }
        else if (newPassword != "" && ConfirmPassword != "" && !validatePasswordminlength(newPassword) && !validatePasswordminlength(ConfirmPassword)) {

            const obj = {
                new_password: newPassword,
                confirm_password: ConfirmPassword
            }
            dispatch(ResetPasswordAPI(eventid, obj))
        }

    }

    useEffect(() => {
        if (ResetPasswordSlice?.value?.message) {

            setOpen(false);
            setOpentrue(false);
           
            setnewPassworderror("")
            setConfirmPassword("")
            setTimeout(() => {
                dispatch(Clear_ResetPassword_Check())
            }, 3000)
        }
    }, [ResetPasswordSlice])
    return (
        <>
            {
                ResetPasswordSlice?.loading == true && (
                    <AdmLoader />
                )
            }
            <div>
                <button onClick={handleOpen} className={classes.BtnLink}>Reset Password</button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div className={classes.ResetPWModal}>
                        <div className={classes.CloseBtn} onClick={handleClose}>
                            <CloseRoundedIcon />
                        </div>
                        <div className={classes.ResetPWModalBody}>
                            <h4 className='modal-title'>Reset Password</h4>
                            <form onSubmit={(e) => handleResetPassword(e)}>
                                <div className={classes.FormGroup}>
                                    <label className={classes.FormLabel}>New Password<span>*</span></label>
                                    <div className='input-group'>
                                        <Input type={showPassword ? "text" : "password"} placeholder="New Password" variant="soft" className={classes.FormInput} value={newPassword} onChange={(e) => {
                                            setnewPassworderror(false)
                                            setnewPassworderrorvalidation(false)
                                            setnewPassword(e.target.value)
                                        }} />
                                        <span className={`${classes.eyeIcon} icon`} onClick={() => setShowPassword(!showPassword)} >
                                            {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                        </span>
                                    </div>
                                    {
                                        newPassworderror && (
                                            <p className='error-msg'>This field is required.</p>
                                        )
                                    }
                                    {
                                        newPassworderrorvalidation && !newPassworderror && (
                                            <p className='error-msg'>Your password must be at least 4 characters.</p>
                                        )
                                    }
                                </div>
                                <div className={classes.FormGroup}>
                                    <label className={classes.FormLabel}>Confirm Password<span>*</span></label>
                                    <div className='input-group'>
                                        <Input type={showPassword ? "text" : "password"} placeholder="Confirm Password" variant="soft" className={classes.FormInput} value={ConfirmPassword} onChange={(e) => {
                                            setConfirmPassword(e.target.value)
                                            setConfirmPassworderror(false)
                                        }} />
                                        <span className={`${classes.eyeIcon} icon`} onClick={() => setShowPassword(!showPassword)} >
                                            {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                        </span>
                                    </div>
                                    {
                                        ConfirmPassworderror && (
                                            <p className='error-msg'>This field is required.</p>
                                        )
                                    }
                                    {
                                        (ConfirmPassworderror == false && newPassword != ConfirmPassword && ConfirmPassword != "") && (
                                            <p className='error-msg'>Password and confirm password does not match.</p>
                                        )
                                    }
                                </div>
                                <ButtonPrimary disabledcheck={true} label="Reset Password" check={true} />
                            </form>
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    )
}

export default ResetPasswordPopup