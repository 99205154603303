import ColorTheme from "./ColorTheme";

export const CommonStyle = {
    TitleStyle :{
        fontSize: ColorTheme.headingFS,
        fontWeight: ColorTheme.headingFW,
        lineHeight: ColorTheme.headingLH,
        color: ColorTheme.textColor,
        fontFamily: ColorTheme.publicSans,
        marginTop: "20px",
        marginBottom: "15px"
    },
    HeaderTitle :{
        fontSize: "18px",
        fontWeight: "600",
        lineHeight: "22px",
        color: ColorTheme.textColor,
        fontFamily: ColorTheme.publicSans,
        marginTop: "0px",
        marginBottom: "0px"
    }
}