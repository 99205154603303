import { makeStyles } from '@mui/styles';
import AdminTheme from '../../Common/AdminTheme';
export const useStyles = makeStyles({
    StaffCard: {
        borderRadius: "15px",
        padding: "30px",
        boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.1)",
        marginBottom: "20px",
        position: "relative",
        "& .rw": {
            display: "flex",
            marginBottom: "20px",
            "& .cl": {
                fontFamily: AdminTheme.poppins,
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: "400",
                color: "#434349",
            },
            "& .lft": {
                minWidth: "100px",
                fontWeight: "600",
                color: "#464E5F",
            },
        },
        "& .rw:first-child .ryt": {
            paddingRight: "84px"
        },
        "& .actions": {
            position: "absolute",
            right: "20px",
            top: "20px",
            "& .MuiIconButton-root": {
                background: AdminTheme.inputBg,
                color: AdminTheme.btnBgColor,
                width: "32px",
                height: "32px",
                borderRadius: "6px",
                marginLeft: "10px",
                "& svg": {
                    fontSize: "24px"
                }
            }
        }

    },
    "@media(max-width: 500px)": {
        StaffCard: {
            "& .rw": {
                flexDirection: "column",
                marginBottom: "20px",
            }
        }
    }
})