import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
}

export const DeleteActivitesSlice = createSlice({
  name: 'DeleteActivitesSlice',
  initialState,
  reducers: {
    Delete_Activites_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    Delete_Activites_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};

    },
    Delete_Activites_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_Delete_Activites_Check: (state, action) => {
      state.value = {}
      state.error = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const {Delete_Activites_request, Delete_Activites_success, Delete_Activites_failure, Clear_Delete_Activites_Check } = DeleteActivitesSlice.actions

export default DeleteActivitesSlice.reducer