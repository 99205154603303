import axios from "axios";
import { APIURL } from "../../../APIHandler/APIURL"
import { Headers } from "../../../APIHandler/HeaderAPI";
import { reloadHandler } from "../../../utlis/utlis";
import { Company_data_failure, Company_data_request, Company_data_success } from "../../Reducer/CompanydataSlice";

export const CompanydataAPI = (payload) => async (dispatch, getState) => {
    dispatch(Company_data_request())
    try {

        const { data } = await axios.get(`${APIURL.v1}`, {
            headers: Headers
        })
        localStorage.setItem("company_data", JSON.stringify(data?.data));
        // console.log("data", data)
        dispatch(Company_data_success(data))
    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(Company_data_failure(error?.response?.data))

    }
}