import React, { useEffect, useState } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import AdminTheme from '../../Common/AdminTheme';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { renderTimeViewClock } from '@mui/x-date-pickers';
import { useParams } from 'react-router-dom';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
const TimeInput = (props) => {
    const { classes,
        dateValue = "",
        maxFutureDate = "",
        allowedDays = null,
        onBinding,
        start_time,
        checkendtime = false,
        name = "",
        samedatestartend,
        eventcheck = false,
        endstart_timecheck,
        isPastDisable = false,
        isWeekDayDisable = false,
        allowAfter = 0,
        isFutureDisable = false,
        isBlank = false,
        editdata = null,
        startdateValue,
        enddatevalue,
        eventendtime,
        check = false,
        start_date,
        checkstart = false,
        startdatetime,
        eventstarttime,
        enddatetime,
        selectmode = "" } = props;
    const { id } = useParams();
    const [isOpen, setIsOpen] = useState(false)
    const [value, setValue] = useState("");
    const [change, setchange] = useState(false)
    const EventStart_date = localStorage.getItem("Start_date")
    const EventEnd_date = localStorage.getItem("End_date")
    // console.log("samedatestartend", samedatestartend)
    const archive = JSON.parse(localStorage.getItem("archive"));

    useEffect(() => {
        if (value != "") {

            onBinding({ [name]: value?.$d });
        }
    }, [value]);
    // 03:16:00
    const hourset = dateValue?.split(":")[0]
    const minuteset = dateValue?.split(":")[1]
    useEffect(() => {
        if (dateValue && change == false) {
            const defaultTime = dayjs().set('hour', hourset).set('minute', minuteset).set('second', 0);
            setValue(defaultTime)
        } else if (dateValue == "") {
            setValue("")

        }
    }, [dateValue])


    const EventStartdatecurrentdatematch = () => {
        const dateString = EventStart_date;

        // Convert the dateString to a Date object
        const targetDate = new Date(dateString);

        // Get the current date
        const currentDate = new Date(startdateValue?.dob);
        // console.log("targetDate",targetDate,"ccc     ",currentDate)
        targetDate.setHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);
        // Compare the two dates
        // console.log("startdatetime", targetDate, currentDate)
        // console.log("currentDatesss",startdateValue,"        jj",currentDate,"targetDate",targetDate)
        if (targetDate > currentDate) {
            return false
        } else if (targetDate < currentDate) {
            return false
        } else {
            return true
        }

    }

    const Currentdateinfuture = () => {

        const dateString = startdateValue?.dob;

        // Convert the dateString to a Date object
        const targetDate = new Date(dateString);

        // Get the current date
        const currentDate = new Date();
        // console.log("targetDate",targetDate,"ccc     ",currentDate)
        targetDate.setHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);
        // Compare the two dates
        // console.log("currentDatesss",startdateValue,"        jj",currentDate,"targetDate",targetDate)
        if (targetDate > currentDate) {
            return false
        } else if (targetDate < currentDate) {
            return false
        } else {
            return true
        }

    }
    const sameeventdatecheck = () => {

        const dateString = EventStart_date;

        // Convert the dateString to a Date object
        const targetDate = new Date(dateString);

        // Get the current date
        const currentDate = new Date();
        // console.log("targetDate",targetDate,"ccc     ",currentDate)
        targetDate.setHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);
        // Compare the two dates
        // console.log("currentDatesss",startdateValue,"        jj",currentDate,"targetDate",targetDate)
        if (targetDate > currentDate) {
            return false
        } else if (targetDate < currentDate) {
            return false
        } else {
            return true
        }

    }
    const samedatecheck = () => {

        const dateString = EventStart_date;

        // Convert the dateString to a Date object
        const targetDate = new Date(dateString);

        // Get the current date
        const currentDate = new Date(startdateValue?.dob);
        // console.log("targetDate",targetDate,"ccc     ",currentDate)
        targetDate.setHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);
        // Compare the two dates
        // console.log("currentDatesss",startdateValue,"        jj",currentDate,"targetDate",targetDate)
        if (targetDate > currentDate) {
            return false
        } else if (targetDate < currentDate) {
            return false
        } else {
            return true
        }

    }
    const sameenddatecheck = () => {

        const dateString = EventStart_date;

        // Convert the dateString to a Date object
        const targetDate = new Date(dateString);

        // Get the current date
        const currentDate = new Date(enddatevalue?.dob);
        // console.log("targetDate",targetDate,"ccc     ",currentDate)
        targetDate.setHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);
        // Compare the two dates
        // console.log("currentDatesss",startdateValue,"        jj",currentDate,"targetDate",targetDate)
        if (targetDate > currentDate) {
            return false
        } else if (targetDate < currentDate) {
            return false
        } else {
            return true
        }

    }
    const enddatetimevalidationcheck = () => {

        const dateString = EventEnd_date;

        // Convert the dateString to a Date object
        const targetDate = new Date(dateString);

        // Get the current date
        const currentDate = new Date(enddatevalue?.dob);
        // console.log("targetDate",targetDate,"ccc     ",currentDate)
        targetDate.setHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);
        // Compare the two dates
        // console.log("targetDate",targetDate,"        jj",currentDate,currentDate?.getTime() === targetDate?.getTime())
        if (currentDate?.getTime() === targetDate?.getTime()) {
            return true
        } else {
            return false
        }

    }
    // console.log("sameenddatecheck", enddatetimevalidationcheck(), EventEnd_date)
    const MinTimeval = () => {
        const mintime = endstart_timecheck?.includes("IST") ? endstart_timecheck?.split(" ")[0] : endstart_timecheck
        const defaultTime = dayjs().set('hour', mintime?.split(':')[0]).set('minute', mintime?.split(':')[1]).set('second', mintime?.split(':')[2]);

        return defaultTime
    }
    // eventstarttime!="" && checkstart=true &&start_time!=""
    const startdatetimechecck = () => {
        const currentdate = new Date()
        const dayjscurrentdate = dayjs(currentdate);
        if (startdatetime > dayjscurrentdate) {
            return startdatetime
        } else {
            return dayjscurrentdate
        }
    }
    // console.log("startdatetime", startdatetimechecck(), startdatetime)
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['TimePicker']}>
                {
                    samedatestartend ? <>
                        <MobileTimePicker
                            sx={{
                                width: "100%",
                                paddingTop: "0",
                                border: "none",
                                marginTop: "-8px !important",
                                "& .MuiInputBase-root": {
                                    height: "42px !important",
                                    borderRadius: "4px !important",
                                    background: "#F3F6F9 !important",
                                    border: "none",
                                },
                                "& .MuiInputBase-input": {
                                    fontFamily: AdminTheme.poppins,
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    lineHeight: "21px",
                                    color: "#434349",
                                    padding: "10px 20px",
                                    "&::placeholder": {
                                        color: "#D6D6E0",
                                        fontWeight: "400",
                                    },
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                    border: "none"
                                },
                            }}
                            label=""
                            // defaultValue={new Date(0, 0, 0, 15, 16, 0)}
                            viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                            }}
                            // disablePast={true}
                            // value={id ? (value) : dayjs(dateValue)}
                            value={id ? (value) : value}
                            // value={defaultTime}
                            onChange={(newValue) => {
                                setchange(true)
                                setValue(newValue);
                            }}
                            ampm={false}
                            minTime={MinTimeval()} // Example minimum time (8:00 AM)
                            // maxTime={new Date(0, 0, 0, 18, 0, 0)}
                            disabled={archive == true ? true : false}

                        />
                    </> : checkstart == true && Currentdateinfuture() == true ? <>
                        <MobileTimePicker
                            sx={{
                                width: "100%",
                                paddingTop: "0",
                                border: "none",
                                marginTop: "-8px !important",
                                "& .MuiInputBase-root": {
                                    height: "42px !important",
                                    borderRadius: "4px !important",
                                    background: "#F3F6F9 !important",
                                    border: "none",
                                },
                                "& .MuiInputBase-input": {
                                    fontFamily: AdminTheme.poppins,
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    lineHeight: "21px",
                                    color: "#434349",
                                    padding: "10px 20px",
                                    "&::placeholder": {
                                        color: "#D6D6E0",
                                        fontWeight: "400",
                                    },
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                    border: "none"
                                },
                            }}
                            label=""
                            // defaultValue={new Date(0, 0, 0, 15, 16, 0)}
                            viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                            }}
                            // disablePast={true}
                            // value={id ? (value) : dayjs(dateValue)}
                            value={id ? (value) : value}
                            // value={defaultTime}
                            onChange={(newValue) => {
                                setchange(true)
                                setValue(newValue);
                            }}
                            ampm={false}
                            minTime={sameenddatecheck() == true && EventStartdatecurrentdatematch() == true ? startdatetimechecck() : Currentdateinfuture() == true ? dayjs() : null} // Example minimum time (8:00 AM)
                            // maxTime={new Date(0, 0, 0, 18, 0, 0)}
                            disabled={archive == true ? true : false}

                        />
                    </> :
                        <>
                            {checkendtime == true ? <MobileTimePicker
                                sx={{
                                    width: "100%",
                                    paddingTop: "0",
                                    border: "none",
                                    marginTop: "-8px !important",
                                    "& .MuiInputBase-root": {
                                        height: "42px !important",
                                        borderRadius: "4px !important",
                                        background: "#F3F6F9 !important",
                                        border: "none",
                                    },
                                    "& .MuiInputBase-input": {
                                        fontFamily: AdminTheme.poppins,
                                        fontSize: "14px",
                                        fontWeight: "600",
                                        lineHeight: "21px",
                                        color: "#434349",
                                        padding: "10px 20px",
                                        "&::placeholder": {
                                            color: "#D6D6E0",
                                            fontWeight: "400",
                                        },
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        border: "none"
                                    },
                                }}
                                label=""
                                // defaultValue={new Date(0, 0, 0, 15, 16, 0)}
                                viewRenderers={{
                                    hours: renderTimeViewClock,
                                    minutes: renderTimeViewClock,
                                    seconds: renderTimeViewClock,
                                }}
                                // disablePast={true}
                                // value={id ? (value) : dayjs(dateValue)}
                                value={id ? (value) : value}
                                // value={defaultTime}
                                onChange={(newValue) => {
                                    setchange(true)
                                    setValue(newValue);
                                }}
                                ampm={false}
                                minTime={sameenddatecheck() == true ? startdatetime : null} // Example minimum time (8:00 AM)
                                maxTime={enddatetimevalidationcheck() == true ? enddatetime : null}
                                disabled={archive == true ? true : false}

                            /> :
                                eventcheck == true ?
                                    <MobileTimePicker
                                        sx={{
                                            width: "100%",
                                            paddingTop: "0",
                                            border: "none",
                                            marginTop: "-8px !important",
                                            "& .MuiInputBase-root": {
                                                height: "42px !important",
                                                borderRadius: "4px !important",
                                                background: "#F3F6F9 !important",
                                                border: "none",
                                            },
                                            "& .MuiInputBase-input": {
                                                fontFamily: AdminTheme.poppins,
                                                fontSize: "14px",
                                                fontWeight: "600",
                                                lineHeight: "21px",
                                                color: "#434349",
                                                padding: "10px 20px",
                                                "&::placeholder": {
                                                    color: "#D6D6E0",
                                                    fontWeight: "400",
                                                },
                                            },
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                border: "none"
                                            }
                                        }}
                                        label=""
                                        // defaultValue={new Date(0, 0, 0, 15, 16, 0)}
                                        viewRenderers={{
                                            hours: renderTimeViewClock,
                                            minutes: renderTimeViewClock,
                                            seconds: renderTimeViewClock,
                                        }}
                                        // disablePast={true}
                                        // value={id ? (value) : dayjs(dateValue)}
                                        value={id ? (value) : value}
                                        // value={defaultTime}
                                        onChange={(newValue) => {
                                            setchange(true)
                                            setValue(newValue);
                                        }}
                                        ampm={false}
                                        minTime={sameeventdatecheck() == true ? dayjs() : null} // Example minimum time (8:00 AM)
                                        // maxTime={new Date(0, 0, 0, 18, 0, 0)}
                                        disabled={archive == true ? true : false}

                                    /> : <MobileTimePicker
                                        sx={{
                                            width: "100%",
                                            paddingTop: "0",
                                            border: "none",
                                            marginTop: "-8px !important",
                                            "& .MuiInputBase-root": {
                                                height: "42px !important",
                                                borderRadius: "4px !important",
                                                background: "#F3F6F9 !important",
                                                border: "none",
                                            },
                                            "& .MuiInputBase-input": {
                                                fontFamily: AdminTheme.poppins,
                                                fontSize: "14px",
                                                fontWeight: "600",
                                                lineHeight: "21px",
                                                color: "#434349",
                                                padding: "10px 20px",
                                                "&::placeholder": {
                                                    color: "#D6D6E0",
                                                    fontWeight: "400",
                                                },
                                            },
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                border: "none"
                                            }
                                        }}
                                        label=""
                                        // defaultValue={new Date(0, 0, 0, 15, 16, 0)}
                                        viewRenderers={{
                                            hours: renderTimeViewClock,
                                            minutes: renderTimeViewClock,
                                            seconds: renderTimeViewClock,
                                        }}
                                        // disablePast={true}
                                        // value={id ? (value) : dayjs(dateValue)}
                                        value={id ? (value) : value}
                                        // value={defaultTime}
                                        onChange={(newValue) => {
                                            setchange(true)
                                            setValue(newValue);
                                        }}
                                        ampm={false}
                                        minTime={samedatecheck() == true ? startdatetime : null} // Example minimum time (8:00 AM)
                                        // maxTime={new Date(0, 0, 0, 18, 0, 0)}
                                        disabled={archive == true ? true : false}

                                    />
                            }
                        </>
                }
            </DemoContainer>
        </LocalizationProvider>
    )
}

export default TimeInput
