import axios from "axios";
import { APIURL } from "../../../../APIHandler/APIURL"
import { Headers } from "../../../../APIHandler/HeaderAPI";
import { reloadHandler } from "../../../../utlis/utlis";
import { GetSpeakerAPI } from "./GetSpeakers";
import { Delete_speakers_failure, Delete_speakers_request, Delete_speakers_success } from "../../../Reducer/Admin/Speakers/DeleteSpeakers";

export const DeleteSpeakerAPI = (payload,id) => async (dispatch, getState) => {
    // const eventItemId=localStorage.getItem("eventItemId")
    const auth_token = localStorage.getItem("auth_token")
    const admineventid = localStorage.getItem("admineventid")
    dispatch(Delete_speakers_request())
    try {
        const { data } = await axios.delete(`${APIURL.createspeaker}/${payload}`, {
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
               "event-slug":Headers?.["event-slug"],
                "Content-type": "application/json",
                Authorization: `Bearer ${auth_token}`
            },
        })
      
        dispatch(Delete_speakers_success(data))
        if(id){

            dispatch(GetSpeakerAPI(id))
        }else{
            dispatch(GetSpeakerAPI(admineventid))

        }
    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(Delete_speakers_failure(error?.response?.data))
    }
}

