import React from 'react'
import { useStyles } from './Style'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const TextEditor = (props) => {
    const classes = useStyles();
    const { setdescription, description ,setdescriptionerror} = props;
    const archive = JSON.parse(localStorage.getItem("archive"));
    const handlechange = (event, editor) => {
        const data = editor.getData();
        // console.log("datatext",data)
        setdescription(data)
        setdescriptionerror(false)
        // console.log("data", data)
    }
    console.log("setdescription",description)
    return (
        <div className={classes.RichEditor}>
            <CKEditor
                editor={ClassicEditor}
                data={description}
                onReady={editor => { }}
                onChange={handlechange}
                disabled={archive==true?true:false}
                onBlur={(event, editor) => {
                    console.log('Blur.', editor);
                }}
            
                onFocus={(event, editor) => {
                    console.log('Focus.', editor);
                }}
                config={{
                placeholder: 'Write here',
                toolbar: {
                    items: [
                        'undo', 'redo','|','heading', '|',
                        'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|',
                        'blockQuote', 'mediaEmbed'  
                     
                    ]
                },
                isReadOnly:archive== true?true:false,
            }}
            
            />
        </div>
    )
}

export default TextEditor