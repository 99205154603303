import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
  error:{}
}

export const getEditEventListSlice = createSlice({
  name: 'getEditEventList',
  initialState,
  reducers: {
    GetEdit_EventList_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    GetEdit_EventList_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};

    },
    GetEdit_EventList_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_GetEdit_EventList_Check: (state, action) => {
      state.value = {}
      state.error = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const {Clear_GetEdit_EventList_Check, GetEdit_EventList_failure, GetEdit_EventList_success, GetEdit_EventList_request } = getEditEventListSlice.actions

export default getEditEventListSlice.reducer