import React from 'react'
import { useStyles } from "./Style";
import { Button } from '@mui/material';
const Buttonprimary = (props) => {
    const { label ,handleClick} = props;
    const classes = useStyles();
    const check =handleClick?true:false
    console.log(check,"check",handleClick)
    return (
        <>
            {check?<Button
                className={`${classes.BtnTheme} btn`}
                variant='contained'
                onClick={()=>handleClick()}
                >
                {label}
            </Button>:
            <Button
                className={`${classes.BtnTheme} btn`}
                variant='contained'
                type="submit"
                // onClick={()=>handleClick()}
                >
                {label}
            </Button>}

        </>
    )
}

export default Buttonprimary;
