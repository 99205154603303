// editeventlist

// getediteventlist


import axios from "axios";
import { APIURL } from "../../../../APIHandler/APIURL"
import { Headers } from "../../../../APIHandler/HeaderAPI";
import { reloadHandler } from "../../../../utlis/utlis";
import { Admin_Partcipant_failure, Admin_Partcipant_request, Admin_Partcipant_success } from "../../../Reducer/Admin/Particants/ParticpantsSlice";
import { GetEdit_EventList_failure, GetEdit_EventList_request, GetEdit_EventList_success } from "../../../Reducer/Admin/getEditEventList/getEditEventList";
import { Edit_EventList_failure, Edit_EventList_request, Edit_EventList_success } from "../../../Reducer/Admin/getEditEventList/EditEventList";
import { Admin_Create_Event_failure, Admin_Create_Event_request, Admin_Create_Event_success } from "../../../Reducer/Admin/CreateEvents/CreateEvents";

export const EditEventListAPI = (payload,id) => async (dispatch, getState) => {
    const admineventid=localStorage.getItem("admineventid")
    const auth_token = localStorage.getItem("auth_token")
    dispatch(Admin_Create_Event_request())
    try {
        const { data } = await axios.post(`${APIURL.editeventlist}/${id}`,payload,{
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
               "event-slug":Headers?.["event-slug"],
                // "Content-type": "application/json",
                "Content-type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${auth_token}`
            },
        })
        dispatch(Admin_Create_Event_success(data))
    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(Admin_Create_Event_failure(error?.response?.data))
    }
}


  