import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
}

export const AddParticipantActivitiesSlice = createSlice({
  name: 'AddParticipantActivities',
  initialState,
  reducers: {
    Add_Participant_Activities_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    Add_Participant_Activities_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};

    },
    Add_Participant_Activities_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_Add_Participant_Activities_Check: (state, action) => {
      state.value = {}
      state.error = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const {Clear_Add_Participant_Activities_Check, Add_Participant_Activities_failure, Add_Participant_Activities_success, Add_Participant_Activities_request } = AddParticipantActivitiesSlice.actions

export default AddParticipantActivitiesSlice.reducer