import React, { useEffect } from 'react';
import EventCardStaff from '../../Components/EventCardStaff/EventCardStaff';
import Grid from '@mui/joy/Grid';
import Typography from '@mui/joy/Typography';
import ColorTheme from '../../Common/CommonCss/ColorTheme';
import { useDispatch, useSelector } from 'react-redux';
import { StaffVoucherAPI } from '../../redux/Action/StaffVoucher/StaffVoucher';
import Loader from '../../Components/Loader/Loader';
import { useNavigate } from 'react-router-dom';
import { QrStaffgenratorAPI } from '../../redux/Action/QRStaffgenerator/QRStaffgenrator';
import ButtonLogOut from '../../Components/ButtonLogOut/ButtonLogOut';
import Soket from '../../Soket/Soket';
import { useState } from 'react';
import QrCodeIcon from '@mui/icons-material/QrCode';
import Button from '@mui/material/Button';
import { EventDetailsAPI } from '../../redux/Action/EventDetails/EventDetails';

const StaffVouchers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const StaffVouchersSlice = useSelector(state => state?.StaffVouchersSlice)
  const auth_name = localStorage.getItem("auth_name")
  const eventItemId = localStorage.getItem("eventItemId")
  const userdetails = JSON.parse(localStorage.getItem("userdetails"))
  useEffect(() => {
    dispatch(StaffVoucherAPI())
    dispatch(EventDetailsAPI(eventItemId))
  }, [])

  const handlenavigate = (e) => {
    // console.log("vbjhghjhg")
    dispatch(QrStaffgenratorAPI(e))
    navigate(`/staff/participants-info/${e}`)
  }
  const [start_datecheck, setstart_datecheck] = useState(false)
  const [end_datecheck, setend_datecheck] = useState(false)
  const StartEventDateString = () => {
    const dateString = userdetails?.data?.start_date;
    const starttimeString = userdetails?.data?.start_time;

    // Convert the dateString to a Date object
    const targetDate = new Date(dateString);
    if (starttimeString != "" && starttimeString != undefined && starttimeString != "undefined") {

      const [hours, minutes, seconds] = starttimeString?.split(":")?.map(Number);

      // Set the hours, minutes, and seconds of the targetDate
      targetDate.setHours(hours);
      targetDate.setMinutes(minutes);
      targetDate.setSeconds(seconds);
    }
    // Get the current date
    const currentDate = new Date();
    // targetDate.setHours(0, 0, 0, 0);
    // currentDate.setHours(0, 0, 0, 0);
    // Compare the two dates
    if (targetDate > currentDate) {
      return false
    } else if (targetDate < currentDate) {
      return true
    } else {
      return true
    }
  }
  const EndEventdateCheck = () => {
    const enddateString = userdetails?.data?.end_date;
    const endtimeString = userdetails?.data?.end_time;

    // Convert the dateString to a Date object
    const targetDate = new Date(enddateString);
    if (endtimeString != "" && endtimeString != undefined && endtimeString != "undefined") {

      const [hours, minutes, seconds] = endtimeString?.split(":")?.map(Number);

      // Set the hours, minutes, and seconds of the targetDate
      targetDate.setHours(hours);
      targetDate.setMinutes(minutes);
      targetDate.setSeconds(seconds);
    }
    // Get the current date
    const currentDate = new Date();
    // targetDate.setHours(0, 0, 0, 0);
    // currentDate.setHours(0, 0, 0, 0);
    // Compare the two dates
    if (targetDate > currentDate) {
      return true
    } else if (targetDate < currentDate) {
      return false
    } else {
      return true
    }
  }




  console.log("StartEventDateString", StartEventDateString(), EndEventdateCheck())

  const EventCard = (data) => {



    const StartDateString = () => {
      const dateString = data?.start_date;
      const starttimeString = data?.start_time;

      // Convert the dateString to a Date object
      const targetDate = new Date(dateString);
      if (starttimeString != "" && starttimeString != undefined && starttimeString != "undefined") {

        const [hours, minutes, seconds] = starttimeString?.split(":")?.map(Number);

        // Set the hours, minutes, and seconds of the targetDate
        targetDate.setHours(hours);
        targetDate.setMinutes(minutes);
        targetDate.setSeconds(seconds);
      }
      // Get the current date
      const currentDate = new Date();
      // targetDate.setHours(0, 0, 0, 0);
      // currentDate.setHours(0, 0, 0, 0);
      // Compare the two dates
      if (targetDate > currentDate) {
        return false
      } else if (targetDate < currentDate) {
        return true
      } else {
        return true
      }
    }
    const EnddateCheck = () => {
      const enddateString = data?.end_date;
      const endtimeString = data?.end_time;

      // Convert the dateString to a Date object
      const targetDate = new Date(enddateString);
      if (endtimeString != "" && endtimeString != undefined && endtimeString != "undefined") {

        const [hours, minutes, seconds] = endtimeString?.split(":")?.map(Number);

        // Set the hours, minutes, and seconds of the targetDate
        targetDate.setHours(hours);
        targetDate.setMinutes(minutes);
        targetDate.setSeconds(seconds);
      }
      // Get the current date
      const currentDate = new Date();
      // targetDate.setHours(0, 0, 0, 0);
      // currentDate.setHours(0, 0, 0, 0);
      // Compare the two dates
      if (targetDate > currentDate) {
        return true
      } else if (targetDate < currentDate) {
        return false
      } else {
        return true
      }
    }

    // console.log("data", data, StartDateString(), EnddateCheck())
    return <>
      {data?.is_qr_enable == 1 && <Grid xs={6} sm={4} md={3} lg={2} key={data?.id} style={{
        "opacity": `${StartDateString() == true && EnddateCheck() == true ? 1 : 0.5}`,
        "cursor": `${StartDateString() == true && EnddateCheck() == true ? "pointer" : ""}`
      }} onClick={(e) => {
        if (StartDateString() == true && EnddateCheck() == true) {
          localStorage.setItem("ActivityName", data?.title)
          handlenavigate(data?.id)
        }
      }}>

        <EventCardStaff EventPic={data?.banners[0]?.image_url} EventTitle={data?.title} data={data} />
      </Grid>}
    </>
  }


  const handleAttendance = (e) => {
    navigate(`/staff/attendance/${eventItemId}`)
  }
  console.log("StaffVouchersSlice", StaffVouchersSlice?.value?.data)
  return (

    <>
      {
        StaffVouchersSlice?.loading && (
          <Loader />
        )
      }
      <Soket />
      <div className='main'>
        <div className='container'>
          <Grid container spacing={2} alignItems='center' sx={{ flexGrow: 1 }}>
            <Grid sx={{ flex: '1' }}>
              <Typography level="h1"
                sx={{
                  fontSize: "20px",
                  lineHeight: "24px",
                  fontWeight: "400",
                  fontFamily: ColorTheme.publicSans,
                  color: ColorTheme.textColor,
                  margin: "30px 0",
                  textTransform: "capitalize",

                }}
              > Hello, <strong>{auth_name}</strong>
              </Typography>
            </Grid>
            <Grid>
              <ButtonLogOut />
            </Grid>
          </Grid>


          {StaffVouchersSlice?.value?.data != undefined && StaffVouchersSlice?.value?.data[0]?.is_check_in == 1 && <Button startIcon={<QrCodeIcon />} sx={{
            width: '100%',
            borderRadius: ColorTheme.cardRadius,
            boxShadow: ColorTheme.cardShadow,
            background: ColorTheme.cardBg,
            height: "44px",
            textTransform: 'capitalize',
            fontFamily: ColorTheme.publicSans,
            color: ColorTheme.textColor,
            fontSize: "14px",
            lineHeight: "16px",
            fontWeight: "400",
            marginBottom: '15px',
            gap: '15px',
            opacity: `${StartEventDateString() == true && EndEventdateCheck() == true ? 1 : 0.5}`,
            cursor: `${StartEventDateString() == true && EndEventdateCheck() == true ? "pointer" : ""}`

          }}
            disableRipple={StartEventDateString() == true && EndEventdateCheck() == true ? false : true}
            onClick={() => {
              if (StartEventDateString() == true && EndEventdateCheck() == true) {

                handleAttendance()
              }
            }
            }
          >
            Attendance
          </Button>}




          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 2 }}
          >
            {
              StaffVouchersSlice?.value?.data?.map((data) => {
                return <>

                  {EventCard(data)}
                </>

              })
            }


          </Grid>
        </div>
      </div>
    </>


  )
}

export default StaffVouchers