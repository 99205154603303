import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
}

export const EventManagerListSlice = createSlice({
  name: 'EventManagerList',
  initialState,
  reducers: {
    EventManagerList_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    EventManagerList_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};

    },
    EventManagerList_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_EventManagerList_Check: (state, action) => {
      state.value = {}
      state.error = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const {EventManagerList_request, EventManagerList_success, EventManagerList_failure, Clear_EventManagerList_Check } = EventManagerListSlice.actions

export default EventManagerListSlice.reducer