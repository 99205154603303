import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
}

export const EditActivitesSlice = createSlice({
  name: 'EditActivitesSlice',
  initialState,
  reducers: {
    Edit_Activites_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    Edit_Activites_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};

    },
    Edit_Activites_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_Edit_Activites_Check: (state, action) => {
      state.value = {}
      state.error = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const {Edit_Activites_request, Edit_Activites_success, Edit_Activites_failure, Clear_Edit_Activites_Check } = EditActivitesSlice.actions

export default EditActivitesSlice.reducer