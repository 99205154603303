import React from 'react'
import { useStyles } from "./Style";
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';

export const PDFBox = (props) => {
    const classes = useStyles();
    const {eventsList}=props;
   
  return (
    <>
        <a
        className={classes.PDFContainer}
        href={eventsList}
        download="Example-PDF-document"
        target="_blank"
        rel="noreferrer"
      >
        <div className='icon'>
            <DownloadForOfflineRoundedIcon className='icn' />
        </div>
        <div className='text text-truncate'>
        Activity.pdf
        </div>
      </a>
    </>
  )
}
