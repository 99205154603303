import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: true,
  loading: true,
}

export const ReloadSlice = createSlice({
  name: 'Reload',
  initialState,
  reducers: {
   
    Reload_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      
    }
  }
})

// Action creators are generated for each case reducer function
export const {Reload_success } = ReloadSlice.actions

export default ReloadSlice.reducer