import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
}

export const UserAttendaceSlice = createSlice({
  name: 'UserAttendace',
  initialState,
  reducers: {
    UserAttendace_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    UserAttendace_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};

    },
    UserAttendace_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_UserAttendace_Check: (state, action) => {
      state.value = {}
      state.error = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const {UserAttendace_request, UserAttendace_success, UserAttendace_failure, Clear_UserAttendace_Check } = UserAttendaceSlice.actions

export default UserAttendaceSlice.reducer