import { makeStyles } from '@mui/styles';
import ColorTheme from '../../Common/CommonCss/ColorTheme';
import { Opacity } from '@mui/icons-material';
export const useStyles = makeStyles({

    EventTicketList: {
        padding: "15px",
        background: "#fff",
        borderRadius: ColorTheme.cardRadius,
        boxShadow: ColorTheme.cardShadow,
        fontSize: "20px",
        fontWeight: "400",
        lineHeight: "24px",
        color: ColorTheme.textColor,
        fontFamily: ColorTheme.publicSans,
        transition: ColorTheme.transition,
        "&:hover": {
            transition: ColorTheme.transition,
            cursor: "pointer",
            boxShadow: ColorTheme.cardShadowHov,
        },
    },
    Row: {
        display: "flex",
        justifyContent:'space-between',
        alignItems: 'center',
        paddingBottom: "5px",
        marginBottom: "5px",
        "&.disabledQr": {
            opacity: "0.4",
        },
        "&:last-child": {
            borderBottom: "0px",
            paddingBottom: "0px",
            marginBottom: "0px",
        }
    },
    ColLeft: {
        flex: "1"
    },
    ColLeftInner: {
        display: "flex",
        alignItems: "center",
    },
    EventTxt: {
        marginRight: "auto"
    },
    EventCount: {
        marginLeft: "auto"
    },
    Status: {
        display: "flex",
        alignItems: "center",
        fontSize: "14px",
        fontWeight: "600",
        lineHeight: "16px",
        color: ColorTheme.textColor,
        fontFamily: ColorTheme.publicSans,
        marginTop: "10px",
        "& img": {
            width: "14px",
            height: "14px",
            marginRight: "5px",
            lineHeight: "0"
        }
    },
    QRCodeImg: {
        width: "52px",
        height: "52px",
        marginLeft: "10px"
    },
    TextLeft:{
        fontFamily: ColorTheme.publicSans,
        fontWeight: "700",
        fontSize: "14px",
        lineHeight: "16px",
        color: ColorTheme.textColor,
        flexShrink: "0"
    },
    TextRight:{
        fontFamily: ColorTheme.publicSans,
        fontWeight: "300",
        fontSize: "14px",
        lineHeight: "16px",
        color: ColorTheme.textColor
    },
    BottomBorder: {
        borderBottom: "1px solid",
        borderBottomColor: ColorTheme.borderColor,
        paddingBottom: "10px",
        marginBottom: "10px",
        "&.disabledQr": {
            opacity: "0.4",
        },
    }
});