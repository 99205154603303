// bulkdeleteparticipants
import axios from "axios";
import { APIURL } from "../../../../APIHandler/APIURL"
import { Headers } from "../../../../APIHandler/HeaderAPI";
import { reloadHandler } from "../../../../utlis/utlis";
import { Bulk_Delete_Participant_failure, Bulk_Delete_Participant_request, Bulk_Delete_Participant_success } from "../../../Reducer/Admin/ParticipantActivitesList/BulkDeleteParticipant";
import { PartcipantsProgramListingAPI } from "./ParticipantProgram";
import { EventDashboardAPI } from "../EventList/EventDashboardList";
import { PartcipantsAPI } from "../Partcipants/Partcipants";

export const BulkDeleteProgramAPI = (payload,newValue) => async (dispatch, getState) => {
    const admineventid=localStorage.getItem("admineventid")
    const auth_token = localStorage.getItem("auth_token")
    const paginationnumber = localStorage.getItem("paginationnumber")
    dispatch(Bulk_Delete_Participant_request())
    try {
        const { data } = await axios.post(`${APIURL.bulkdeleteparticipants}`,payload,{
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
              "event-slug":Headers?.["event-slug"],
                "Content-type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${auth_token}`
            },
        })
        dispatch(Bulk_Delete_Participant_success(data))
        dispatch(PartcipantsProgramListingAPI(newValue))
        dispatch(EventDashboardAPI(newValue))
        dispatch(PartcipantsAPI(newValue,1,paginationnumber))
    } catch (error) {
        // reloadHandler(error?.response?.status)
        dispatch(Bulk_Delete_Participant_failure(error?.response?.status))
    }
}


  