import React, { useEffect, useState } from 'react'
import Header from '../../Components/Header/Header'
import { useStyles } from "./Style";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import ButtonDanger from '../../Components/ButtonDanger/ButtonDanger';
import ColorTheme from '../../Common/CommonCss/ColorTheme';
import { useDispatch, useSelector } from 'react-redux';
import { Clear_Scan_Activity_Check } from '../../redux/Reducer/ScanActivity/ScanActivity';
import { useNavigate } from 'react-router-dom';
import Snacbar from '../../Components/Snackbar/Snacbar';
import { Clear_UserAttendance_Check } from '../../redux/Reducer/Admin/UserAttendance/UserAttendanceSlice';
import { PerecentageCheck_success } from '../../redux/Reducer/PercentageCheckSlice';

const CheckInError = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const ScanActivitySlice = useSelector(state => state?.ScanActivitySlice)
  const [open, setIsopen] = useState(false);
  const [message, setmessage] = useState("")
  const UserAttendanceSlice = useSelector(state => state?.UserAttendanceSlice)
  const [Open, setopen] = useState(false)
  const [error, seterror] = useState("")

  useEffect(() => {
    localStorage.removeItem("checkin")
    dispatch(PerecentageCheck_success(0))

  })
  useEffect(() => {
    if (ScanActivitySlice?.error?.message) {

      setIsopen(true)
      setmessage(ScanActivitySlice?.error?.message)
      setTimeout(() => {

        dispatch(Clear_Scan_Activity_Check())
      }, 100)
    }
  }, [ScanActivitySlice])
  const handleClick = () => {
    navigate("/qrscanner")
  }
  useEffect(() => {
    if (UserAttendanceSlice?.error?.message) {
      seterror(UserAttendanceSlice?.error?.message)
      dispatch(Clear_UserAttendance_Check())
      setopen(true)
    }
  }, [UserAttendanceSlice])
  // console.log("ScanActivitySlice", ScanActivitySlice)
  return (
    <>
      <div className={`${classes.CheckInError} main pb-0`}>
        <Header EventTitle="Check-in QR" />
        <div className={`${classes.CheckInErrorContent} container`}>
          <div className={classes.IconError}>
            <PriorityHighIcon sx={{ color: ColorTheme.dangerColor, fontSize: '112px' }} />
          </div>
          <div className='custom-snackbar'>
            {
              (message != "") && (
                <Snacbar
                  open={open}
                  setOpen={setIsopen}
                  messagetype={"error"}
                  message={message}
                />

              )
            }
            {
              (error) && (
                <Snacbar
                  open={Open}
                  setOpen={setopen}
                  messagetype={"error"}
                  message={(error)}
                />

              )
            }
          </div>
          <div class={classes.btnContainer}>
            {/* <ButtonDanger label={'Try again'} handleClick={() => handleClick()} /> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default CheckInError