import axios from "axios";
import { APIURL } from "../../../../APIHandler/APIURL"
import { Headers } from "../../../../APIHandler/HeaderAPI";
import { reloadHandler } from "../../../../utlis/utlis";
import { Admin_Partcipant_failure, Admin_Partcipant_request, Admin_Partcipant_success } from "../../../Reducer/Admin/Particants/ParticpantsSlice";
import { Program_Listing_failure, Program_Listing_request, Program_Listing_success } from "../../../Reducer/Admin/ProgramLisiting/ProgramLisitingSlice";

export const PartcipantsProgramListingAPI = (payload) => async (dispatch, getState) => {
    const admineventid=localStorage.getItem("admineventid")
    const auth_token = localStorage.getItem("auth_token")
    dispatch(Program_Listing_request())
    try {
        const { data } = await axios.get(`${APIURL.partcipantsprogram}/${payload}`,{
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
              "event-slug":Headers?.["event-slug"],
                "Content-type": "application/json",
                Authorization: `Bearer ${auth_token}`
            },
        })
        dispatch(Program_Listing_success(data))
    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(Program_Listing_failure(error?.response?.status))
    }
}


  