import React, { useEffect, useState } from 'react'
import ButtonPrimary from '../ButtonPrimary/ButtonPrimary';
import ButtonSecondary from '../ButtonSecondary/ButtonSecondary'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useStyles } from "../../Common/FormStyle"
import AdminTheme from '../../Common/AdminTheme';
import Input from '@mui/joy/Input';
import Select, { selectClasses } from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { validateEmail, validatePassword, validatePasswordminlength } from '../../../utlis/Validations';
import { EventManagerEditAPI, EventManagerPostAPI } from '../../../redux/Action/Admin/EventManager.js/EventManager';
import { Clear_EventManagerPost_Check } from '../../../redux/Reducer/Admin/EventManager/EventManagerPostSlice';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Snacbar from '../../../Components/Snackbar/Snacbar';


const EventManagerModal = () => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [name, setname] = useState("")
  const [nameerror, setnameerror] = useState(false)
  const [email, setemail] = useState("")
  const [emailerror, setemailerror] = useState(false)
  const [emailformaterror, setemailformateerror] = useState(false)
  const [password, setpassword] = useState("")
  const [passworderror, setpassworderror] = useState(false)
  const [passwordformaterror, setpasswordformateerror] = useState(false)
  const [eventid, seteventid] = useState("")
  const [eventiderror, seteventiderror] = useState(false)
  const EventManagerPostSlice = useSelector(state => state?.EventManagerPostSlice)
  const [isopenmsg, setisopenmsg] = useState(false);
  const [Eventsuccessmsg, setEventsuccessmsg] = useState("")
  const EventGetEditManagerSlice = useSelector(state => state?.EventGetEditManagerSlice)
  const [selectedvalue, setselectedvalue] = React.useState("")
  const eventmanagerid = localStorage.getItem("eventmanagerid")
  const [errormanage, seterrormanage] = useState(false)
  const [errormanagemsg, seterrormanagemsg] = useState(false)
  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setname("")
    setemail("")
    seteventid("")
    setpassword("")
    setselectedvalue("")
    localStorage.removeItem("eventmanagerid")
    setOpen(true);
  };
  const AdminEventListSlice = useSelector(state => state?.AdminEventListSlice?.value)

  useEffect(() => {
    if (EventGetEditManagerSlice?.value?.message) {
      setOpen(true);
      setselectedvalue(EventGetEditManagerSlice?.value?.data?.event_id)
      seteventid(EventGetEditManagerSlice?.value?.data?.event_id)
      setemail(EventGetEditManagerSlice?.value?.data?.email)
      setname(EventGetEditManagerSlice?.value?.data?.name)
    }
  }, [EventGetEditManagerSlice])
  console.log("EventGetEditManagerSlice", EventGetEditManagerSlice)
  const handleClose = () => {
    setname("")
    setemail("")
    seteventid("")
    setpassword("")
    setselectedvalue("")
    setpasswordformateerror(false)
    setemailerror(false)
    setemailformateerror(false)
    setpassworderror(false)
    seteventiderror(false)
    setnameerror(false)
    setOpen(false);
  };

  const handleClick = () => {
    if (name == "" || email == "" || validatePasswordminlength(email) || password == "" || validatePasswordminlength(password) || eventid == "" || eventid == null) {
      if (name == "") {
        setnameerror(true)
      }
      if (email == "") {
        setemailerror(true)
      }
      if (validateEmail(email)) {
        setemailformateerror(true)
      }
      if (password == "") {
        setpassworderror(true)
      }
      console.log("vikalpeven", eventid)
      if (eventid == "" || eventid == null) {
        seteventiderror(true)
      }
      if (validatePasswordminlength(password)) {
        setpasswordformateerror(true)
      }
    } else {

      const obj = {
        name: name,
        email: email,
        password: password,
        event_id: eventid
      }
      if (eventmanagerid) {

        dispatch(EventManagerEditAPI(obj, eventmanagerid, setname, setemail, seteventid, setpassword, setOpen))
      } else {

        dispatch(EventManagerPostAPI(obj, setname, setemail, seteventid, setpassword, setOpen))
      }


    }
  }

  useEffect(() => {
    setname("")
    setemail("")
    seteventid("")
    setpassword("")
    setselectedvalue("")
  }, [])
  console.log("AdminEventListSlice", AdminEventListSlice)
  // EventManagerPostAPI

  const handleChange = (event, newValue) => {
    seteventid(newValue)
    setselectedvalue(newValue)
    seteventiderror(false)
    // console.log("AdminEventListSliceeee",newValue)
  }
  console.log("EventManagerPostSlice", EventManagerPostSlice)
  useEffect(() => {
    if (EventManagerPostSlice?.value?.message) {
      setisopenmsg(true)
      setTimeout(()=>{
        setisopenmsg(false)

      },3000)
      setEventsuccessmsg(EventManagerPostSlice?.value?.message)
      dispatch(Clear_EventManagerPost_Check())

    } else if (EventManagerPostSlice?.error?.message) {
      seterrormanage(true)
      setTimeout(()=>{
        seterrormanage(false)

      },3000)
      seterrormanagemsg(EventManagerPostSlice?.error?.message)
      dispatch(Clear_EventManagerPost_Check())
    }
  }, [EventManagerPostSlice])
  console.log("EventManagerPostSlice", EventManagerPostSlice)
  return (
    <React.Fragment>
      {
        (errormanage) && (
          <Snacbar
            open={errormanage}
            setOpen={seterrormanage}
            messagetype={"error"}
            message={(errormanagemsg)}
          />

        )
      }
      {
        (isopenmsg) && (
          <Snacbar
            open={isopenmsg}
            setOpen={seterrormanage}
            messagetype={"success"}
            message={(Eventsuccessmsg)}
          />

        )
      }



      <ButtonPrimary label="Add event manager" disabledcheck={true} handleClick={() => handleClickOpen()} />
      <Dialog
        open={open}
        className='event-manager-popup'
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            maxWidth: "640px",
            width: "100%",
          },
          "& .MuiDialogActions-root, .MuiDialogTitle-root, .MuiDialogContent-root": {
            padding: "30px",
          },
          "& .MuiDialogContent-root": {
            paddingTop: "15px",
            paddingBottom: "15px",
          }
        }}
      >
        <DialogTitle id="alert-dialog-title">New event manager</DialogTitle>
        <DialogContent>
          <div className={classes.FormGroup}>
            <label className={classes.FormLabel}>Select Event<span>*</span></label>
            <Select
              placeholder="Select Event"
              indicator={<ArrowDropDownOutlinedIcon />}
              sx={{
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "21px",
                color: "#434349",
                fontFamily: AdminTheme.poppins,
                minHeight: "42px !important",
                borderRadius: "4px !important",
                background: "#F3F6F9 !important",
                padding: "10px 20px !important",
                border: "none",
                "&::placeholder": {
                  color: "#D6D6E0",
                  fontWeight: "400",
                },
                [`& .${selectClasses.indicator}`]: {
                  transition: '0.2s',
                  [`&.${selectClasses.expanded}`]: {
                    transform: 'rotate(-180deg)',
                  },
                },
              }}
              value={selectedvalue}
              onChange={handleChange}
              disabled={eventmanagerid?true:false}
            >
              {AdminEventListSlice?.data?.map((data) => {

                return <Option value={data?.id} key={data?.id}>{data?.name}</Option>
              })}


            </Select>
            {
              eventiderror && (
                <p className='error-msg'>This field is required.</p>
              )
            }
          </div>
          <div className={classes.FormGroup}>
            <label className={classes.FormLabel}>Name<span>*</span></label>
            <Input placeholder="Name" variant="soft" className={classes.FormInput} value={name} onChange={(e) => {
              setname(e.target.value)
              setnameerror(false)
            }} />
            {
              nameerror && (
                <p className='error-msg'>This field is required.</p>
              )
            }
          </div>
          <div className={classes.FormGroup}>
            <label className={classes.FormLabel}>Email<span>*</span></label>
            <Input placeholder="Email" variant="soft" className={classes.FormInput} disabled={eventmanagerid?true:false} value={email} onChange={(e) => {
              setemail(e.target.value)
              setemailerror(false)
              setemailformateerror(false)
            }} />
            {
              emailerror && (
                <p className='error-msg'>This field is required.</p>
              )
            }
            {
              (!emailerror && emailformaterror) && (
                <p className='error-msg'>Please Enter Valid email.</p>
              )
            }
          </div>
          <div className={classes.FormGroup}>

            <label className={classes.FormLabel}>Password<span>*</span></label>
            <div className='input-group'>
              <Input type={!showPassword ? "text" : "password"} placeholder="Password" variant="soft" className={classes.FormInput} value={password} onChange={(e) => {
                setpassword(e.target.value)
                setpassworderror(false)
                setpasswordformateerror(false)
              }} />
              <span className={`${classes.eyeIcon} icon`} onClick={() => setShowPassword(!showPassword)} >
                {!showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
              </span>
            </div>
            {
              passworderror && (
                <p className='error-msg'>This field is required.</p>
              )
            }
            {
              (!passworderror && passwordformaterror) && (
                <p className='error-msg'>Your password must be at least 4 characters.</p>
              )
            }
          </div>

        </DialogContent>
        <DialogActions>
          <ButtonSecondary label="Cancel" handleClick={() => handleClose()} />
          <ButtonPrimary label="Add" disabledcheck={true} handleClick={() => handleClick()} />
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default EventManagerModal
