const AdminTheme = ({
    black: '#000',
    white: '#fff',
    poppins: "'Poppins', sans-serif !important",
    themeColor: '#3699FF',
    btnBgColor: '#3699FF !important',
    btnTextColor: '#fff !important',
    SecondaryBtnBgColor: 'rgba(54, 153, 255, 0.15) !important',
    navBtnBg: 'rgba(255, 255, 255, 0.12)',
    headerOverlay: 'linear-gradient(90deg, rgba(0, 95, 255, 0.40) 0%, rgba(2, 106, 75, 0.40) 100%)',
    headingColor: '#212121',
    secondaryColor: "#1BC5BD",
    lightColor: "#B5B5C3",
    inputBg: "#F3F6F9",
    inputBg50: "rgba(243, 246, 249, 0.5)",
    btnHoverShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    dangerColor: '#FC5C69',
    successColor: '#01C448',
    

    lightGrey: '#F4F6F8',
    textColor: '#0F3952',
    headingFS: "24px",
    headingFW: "700",
    headingLH: "28px",
    cardRadius: "10px",
    cardShadow: "0px 9px 40px 0px rgba(0, 0, 0, 0.10)",
    cardBg: "#fff",
    borderColor: "rgba(203, 203, 203, 0.3)"
    
   
});
export default AdminTheme;