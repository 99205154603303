import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: [],
  loading: false,
}

export const ImportLastStatusCheckSlice = createSlice({
  name: 'ImportLastStatusCheck',
  initialState,
  reducers: {
   
    ImportLastStatusCheck_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      
    },
    Clear_ImportLastStatusCheck: (state, action) => {

      state.value = [];
      state.loading = false;
      
    },

  }
})

// Action creators are generated for each case reducer function
export const {ImportLastStatusCheck_success ,Clear_ImportLastStatusCheck} = ImportLastStatusCheckSlice.actions

export default ImportLastStatusCheckSlice.reducer