import axios from "axios";
import { APIURL } from "../../../APIHandler/APIURL"
import { Headers } from "../../../APIHandler/HeaderAPI";
import { reloadHandler } from "../../../utlis/utlis";
import { SetPassword_failure, SetPassword_request, SetPassword_success } from "../../Reducer/ForgetPassword/SetPassword";
import { ChangePassword_failure, ChangePassword_request, ChangePassword_success } from "../../Reducer/ChangePassword/ChangePassword";

export const ChangePasswordAPI = (payload) => async (dispatch, getState) => {
    dispatch(ChangePassword_request())
    const auth_token = localStorage.getItem("auth_token")
    try {

        const { data } = await axios.put(`${APIURL.changepassword}`,payload, {
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
                "Content-type": "application/json",
               "event-slug":Headers?.["event-slug"],
                // "Content-type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${auth_token}`
            },
        })
        // console.log("data", data)
        localStorage.setItem("is_password_set",data?.data?.is_password_set)
        dispatch(ChangePassword_success(data))
    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(ChangePassword_failure(error?.response?.data))

    }
}