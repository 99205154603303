import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 0,
  loading: false,
}

export const PerecentageCheckSlice = createSlice({
  name: 'PerecentageCheck',
  initialState,
  reducers: {
   
    PerecentageCheck_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      
    },
 
  }
})

// Action creators are generated for each case reducer function
export const {PerecentageCheck_success} = PerecentageCheckSlice.actions

export default PerecentageCheckSlice.reducer