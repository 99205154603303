import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
}

export const AdminSampleExportSlice = createSlice({
  name: 'AdminSampleExport',
  initialState,
  reducers: {
    Admin_Sample_Export_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    Admin_Sample_Export_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};

    },
    Admin_Sample_Export_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_Admin_Sample_Export_Check: (state, action) => {
      state.value = {}
      state.error = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const {Admin_Sample_Export_request, Admin_Sample_Export_success, Admin_Sample_Export_failure, Clear_Admin_Sample_Export_Check } = AdminSampleExportSlice.actions

export default AdminSampleExportSlice.reducer