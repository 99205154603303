import axios from "axios";
import { APIURL } from "../../../APIHandler/APIURL"
import { Headers } from "../../../APIHandler/HeaderAPI";
import { reloadHandler } from "../../../utlis/utlis";
import { QR_Staff_Generator_failure, QR_Staff_Generator_request, QR_Staff_Generator_success } from "../../Reducer/QrStaffgenrator/QrStaffgenrator";

export const QrStaffgenratorAPI = (payload) => async (dispatch, getState) => {
    dispatch(QR_Staff_Generator_request())
    // const eventItemId=localStorage.getItem("eventItemId")
    const auth_token = localStorage.getItem("auth_token")
  
    try {
        const { data } = await axios.get(`${APIURL.qrstaffgenerate}/${payload}`, {
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
              "event-slug":Headers?.["event-slug"],
                "Content-type": "application/json",
                Authorization: `Bearer ${auth_token}`
            },
        })
        dispatch(QR_Staff_Generator_success(data))

    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(QR_Staff_Generator_failure(error?.response?.data))

    }
}