import { makeStyles } from '@mui/styles';
import AdminTheme from '../../Common/AdminTheme';

export const useStyles = makeStyles({

    TabHeader: {
        "&  .inner::-webkit-scrollbar": {
            height: "4px"
        },
        "&  .inner::-webkit-scrollbar-track": {
            background: AdminTheme.lightGrey, 
        },
        "& .inner::-webkit-scrollbar-thumb": {
            backgroundColor: AdminTheme.lightColor,
        },
        "& .inner::-webkit-scrollbar-thumb:hover": {
            backgroundColor: AdminTheme.themeColor,
            cursor: "grab",
        },
        "& .step1":{
            "& .admin-btn-box.btn-info": {
                "& .admin-btn-box-icon svg path": {
                    fill: AdminTheme.themeColor
                },
                "& .admin-btn-box-text": {
                    color: AdminTheme.themeColor,
                }
            },
            "& .arrow-1": {
                "& svg rect, & svg path": {
                    fill: AdminTheme.themeColor
                }
            }
        },


        "& .step2":{
            "& .admin-btn-box.btn-speaker": {
                "& .admin-btn-box-icon svg path": {
                    fill: AdminTheme.themeColor
                },
                "& .admin-btn-box-text": {
                    color: AdminTheme.themeColor,
                }
            },
            "& .arrow-2": {
                "& svg rect, & svg path": {
                    fill: AdminTheme.themeColor
                }
            }
        },
        "& .step3":{
            "& .admin-btn-box.btn-prog-activities": {
                "& .admin-btn-box-icon svg path": {
                    fill: AdminTheme.themeColor
                },
                "& .admin-btn-box-text": {
                    color: AdminTheme.themeColor,
                }
            },
            "& .arrow-3": {
                "& svg rect, & svg path": {
                    fill: AdminTheme.themeColor
                }
            }
        },

        "& .step4":{
            "& .admin-btn-box.btn-staff": {
                "& .admin-btn-box-icon svg path": {
                    fill: AdminTheme.themeColor
                },
                "& .admin-btn-box-text": {
                    color: AdminTheme.themeColor,
                }
            },
            "& .arrow-4": {
                "& svg rect, & svg path": {
                    fill: AdminTheme.themeColor
                }
            }
        },
        "& .step5":{
            "& .admin-btn-box.btn-reg-import": {
                "& .admin-btn-box-icon svg path": {
                    fill: AdminTheme.themeColor
                },
                "& .admin-btn-box-text": {
                    color: AdminTheme.themeColor,
                }
            }
        },
    }

});