import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
}

export const DeleteAdminStaffSlice = createSlice({
  name: 'DeleteAdminStaff',
  initialState,
  reducers: {
    DeleteAdminStaff_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    DeleteAdminStaff_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};

    },
    DeleteAdminStaff_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_DeleteAdminStaff_Check: (state, action) => {
      state.value = {}
      state.error = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const {DeleteAdminStaff_request, DeleteAdminStaff_success, DeleteAdminStaff_failure, Clear_DeleteAdminStaff_Check } = DeleteAdminStaffSlice.actions

export default DeleteAdminStaffSlice.reducer