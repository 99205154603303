import React, { useState } from 'react'
import {useStyles} from './Style'
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import ButtonLogOut from '../ButtonLogOut/ButtonLogOut';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from 'react-router-dom';

const Sidebar = () => {
    const classes = useStyles();

    // const [open, setOpen] = useState(false);

    // const toggleDrawer = (newOpen) => () => {
    //   setOpen(newOpen);
    // };

    const navigate=useNavigate();



    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
      });
    
      const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setState({ ...state, [anchor]: open });
      };




  return (
    <>
    {['bottom'].map((anchor) => (
        <React.Fragment key={anchor}>
            <IconButton className={classes.MenuIcon} onClick={toggleDrawer(anchor, true)}>
                {state.bottom===true ? <ClearIcon/> : <MenuIcon/>}
            </IconButton>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            className={classes.Sidebar}
          >
            <ul className='sidebar-container'>        
                <li>
                    <Button className='btn ' sx={{textTransform: "none"}} onClick={()=>navigate("/change-password")}>Change password</Button>
                </li>
                <li>
                    <ButtonLogOut/>
                </li>
            </ul>
          </Drawer>
        </React.Fragment>
      ))}
    </>
  )
}

export default Sidebar