// QRScanner.js
import React, { useEffect, useRef, useState } from 'react';
// import QrScanner from 'react-qr-scanner';
import { QrReader } from 'react-qr-reader';
import './qrscane.css';
import { useStyles } from "./Style";
import { Button, Typography } from '@mui/material';
import AdminTheme from '../../AdminPanel/Common/AdminTheme';
import ColorTheme from '../../Common/CommonCss/ColorTheme';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ScanActivityAPI } from '../../redux/Action/ScanActivity/ScanActivity';
import Loader from '../../Components/Loader/Loader';
import { UserAttendanceAPI } from '../../redux/Action/Admin/Attendance/Attendance';
import Snacbar from '../../Components/Snackbar/Snacbar';
import {
    isMobile,
    isTablet,
    isDesktop,
    isBrowser,
    isIE,
    isEdge,
    isChrome,
    isFirefox,
    isSafari,
    isOpera,
    isAndroid,
    isIOS,
} from 'react-device-detect';
import { height, width } from '@mui/system';
import { PerecentageCheck_success } from '../../redux/Reducer/PercentageCheckSlice';
const AttendanceQrScane = () => {
    const classes = useStyles();

    const [result, setResult] = useState('');
    const [results, setResults] = useState('');
    const program_id = localStorage.getItem("program_id")
    const navigate = useNavigate()
    const ScanActivitySlice = useSelector(state => state?.ScanActivitySlice)
    const UserAttendanceSlice = useSelector(state => state?.UserAttendanceSlice)
    const PerecentageCheckSlice = useSelector(state => state?.PerecentageCheckSlice)
    const userdetails = JSON.parse(localStorage.getItem("userdetails"))
    // const userdetails = JSON.parse(localStorage.getItem("userdetails"));
    const slugPath = '';
    const eventItemId = localStorage.getItem("eventItemId")
    const user_id = localStorage.getItem("user_id")
    const [loading, setloading] = useState(false);
    const dispatch = useDispatch()
    const url = window.location.href;
    const urlattendance = url?.includes("attendance") ? true : false
    const { id } = useParams();
    const [open, setopen] = useState(false)
    const [value, setvalue] = useState(0);
    const [camerapermission, setcamerapermission] = useState(false);
    const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
    const apiCalledRef = useRef(false);
    const checkin = JSON.parse(localStorage.getItem("checkin"))
    useEffect(() => {
        setloading(true)
        setTimeout(() => {
            setloading(false)

        }, 1000)
    }, [])

    const handleLoad = () => {
        setTimeout(() => {

            setloading(false);
        }, 500)
    };
    // const handleScan = (data) => {
    //     // setResults(data)
    //     // setloading(false)
    //     if (data) {
    //         setResult(data);
    //         localStorage.setItem("scantoken", (data?.text))
    //         if (id != undefined && id != null) {

    //             const obj = {
    //                 program_id: program_id,
    //                 activity_token: data?.text?.split("-")[0],
    //                 staff_id: data?.text?.split("-")[1]
    //             }
    //             dispatch(ScanActivityAPI(obj))
    //         } else if (urlattendance == true) {
    //             // const obj = {
    //             //     activity_token: data?.text?.split("-")[0],
    //             //     staff_id: +data?.text?.split("-")[1]
    //             // }
    //             const obj = {
    //                 user_id: user_id,
    //                 event_id: data?.text?.split("?")[0],
    //                 staff_id: data?.text?.split("?")[1]
    //             }
    //             // console.log("vikalpobj", data?.text)
    //             // dispatch(ScanActivityAPI(obj))
    //             dispatch(UserAttendanceAPI(obj))
    //         }
    //         // navigate(`/participants-info/${program_id}`)
    //         // console.log("data", data)
    //     }
    // };

    const handleError = (err) => {
        // setloading(false);
        console.error(err);
    };
    // console.log("ScanActivitySlice", ScanActivitySlice)
    // useEffect(() => {
    //     if (ScanActivitySlice?.error?.message) {
    //         navigate(`${slugPath}/check-in-error`)
    //     } else if (ScanActivitySlice?.value?.message) {
    //         if (program_id) {

    //             navigate(`${slugPath}/participants-info/${program_id}`)
    //         } else {
    //             navigate(`${slugPath}/participants-info`)

    //         }

    //     }
    // }, [ScanActivitySlice])
    console.log("ScanActivitySlice", ScanActivitySlice, UserAttendanceSlice)

    useEffect(() => {
        if (UserAttendanceSlice?.value?.message) {
            navigate(`/`)
        } else if (UserAttendanceSlice?.error?.message) {
            // setopen(true)
            navigate(`${slugPath}/check-in-error`)
        }
    }, [UserAttendanceSlice])

    const scanOverlay = () => (
        <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <p style={{ background: 'rgba(0, 0, 0, 0.5)', color: 'white', padding: '10px', borderRadius: '5px' }}>Scanning QR code...</p>
        </div>
    );
    useEffect(() => {
        dispatch(PerecentageCheck_success(0))
    }, [])
    useEffect(() => {
        navigator.permissions.query({ name: 'camera' }).then((result) => {
            if (result.state === 'granted') {
                console.log('Camera permission granted');
                // Proceed with accessing the camera
                setcamerapermission(false)

            } else if (result.state === 'prompt') {
                console.log('Camera permission prompt');
                // Show a message to the user to allow camera access
                setcamerapermission(false)
            } else if (result.state === 'denied') {
                setcamerapermission(true)
                console.log('Camera permission denied');
                // Show a message to the user that camera access is blocked
            }
        }).catch((error) => {
            console.error('Error checking camera permission:', error);
        });

    }, [])
    function debounce(func, delay) {
        let timeoutId;
        return function (...args) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    }

    const debouncedApiCall = debounce((obj, dispatchFunction) => {
        setIsApiCallInProgress(true); // Set the flag to true when an API call is initiated

        dispatch(dispatchFunction(obj))
    }, 1000); // Adjust the debounce delay as needed

    console.log("vikalp", PerecentageCheckSlice)
    const handledata = (data) => {
        if (!!data && PerecentageCheckSlice?.value == 0) {
            dispatch(PerecentageCheck_success(1))
            console.log("vikalp", PerecentageCheckSlice)
            setResult(data);
            localStorage.setItem("scantoken", (data?.text))
           if (urlattendance == true) {
                // const obj = {
                //     activity_token: data?.text?.split("-")[0],
                //     staff_id: +data?.text?.split("-")[1]
                // }
                const obj = {
                    user_id: user_id,
                    event_id: data?.text?.split("?")[0],
                    staff_id: data?.text?.split("?")[1]
                }
                // console.log("vikalpobj", data?.text)
                // dispatch(ScanActivityAPI(obj))
                // debouncedApiCall(obj, UserAttendanceAPI);
                dispatch(UserAttendanceAPI(obj,apiCalledRef))

            }
            console.log("data", data)

            setResult(result?.text);
        }

    }


    // console.log("UserAttendanceSlice", UserAttendanceSlice)
    return (
        <>
            {
                loading && (
                    <Loader />
                )
            }
            {
                (UserAttendanceSlice?.error?.message) && (
                    <Snacbar
                        open={open}
                        setOpen={setopen}
                        messagetype={"error"}
                        message={(UserAttendanceSlice?.error?.message)}
                    />

                )
            }
            {
                camerapermission == true && (
                    <Snacbar
                        open={true}
                        setOpen={setopen}
                        messagetype={"error"}
                        message={"Camera Permission is blocked please allow first"}
                    />

                )
            }
            <div className={`${classes.ScannerContainer} scanner-container`}>

                {result}

                <QrReader
                    constraints={{
                        facingMode: 'environment',
                        aspectRatio: { ideal: 1 }
                    }}
                    delay={100}
                    onError={handleError}
                    // onScan={handleScan}
                    // ViewFinder={scanOverlay}
                    className='vd-container'
                    onResult={(data, error) => {
                        if (checkin != true) {

                            handledata(data)
                        }

                        if (!!error) {
                            console.info(error);
                        }
                    }}
                // style={{height:"100vh"}}
                // containerStyle={{height:"100vh"}}
                // videoContainerStyle={{  height: '100vh' }}
                // videoStyle={{  height: '100vh' }}

                />
                <p>{result}</p>

                {/* {isAndroid&&  <QrScanner onScan={handleScan}

                   
                    onError={handleError}
                    key="environment"
                    constraints={{
                        audio: false,
                        video: { facingMode: "environment" }
                    }}
                />}
              {isIOS &&  <QrScanner onScan={handleScan}

                    // onLoad={handleLoad}
                    onError={handleError}
                    key="environment"
                    constraints={{
                        audio: false,
                        video: {
                            facingMode: { exact: "environment" },
                            width: { ideal: 390, max: 1920, min: 640 },
                            height: { ideal: 720, max: 1080, min: 480 }
                        }
                        // video: { facingMode: "environment" }
                    }}
                />} */}
                <div className='overlay x'></div>
                <div className='overlay y'></div>
                <div className="overlay">
                    <Typography
                        className='text'
                        sx={{
                            fontSize: '18px',
                            lineHeight: '24px',
                            color: '#fff',
                            textAlign: 'center',
                            maxWidth: '250px',
                            fontFamily: AdminTheme.poppins,
                            "@media(max-height: 575px)": {
                                fontSize: '12px',
                                lineHeight: '16px',
                                maxWidth: '150px',
                            }
                        }}
                    >
                        Align the QR code within the frame to scan
                    </Typography>
                    <div className={`${classes.ScannerWindow} scanner-window`}>
                        <div className='inner'>
                        </div>
                        <div className='line'></div>
                    </div>
                    <Button className='btn btn-cancel'
                        sx={{
                            background: 'rgba(255,255,255,0.2)',
                            color: '#fff',
                            height: "48px",
                            borderRadius: "10px",
                            fontSize: "16px",
                            padding: '10px 24px',
                            fontFamily: ColorTheme.publicSans,
                            marginBottom: "0",
                            textTransform: "none",
                            "@media(max-height: 575px)": {
                                height: "32px !important",
                                minHeight: "32px !important",
                                borderRadius: "8px",
                                fontSize: "10px",
                                lineHeight: "12px",
                                padding: '6px 12px',
                            }
                        }}
                        onClick={() => navigate(-1)}
                    >Cancel Scanning</Button>
                </div>

            </div>

        </>

    );
};




export default AttendanceQrScane;







