import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
}

export const ProgramListingSlice = createSlice({
  name: 'ProgramListing',
  initialState,
  reducers: {
    Program_Listing_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    Program_Listing_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};

    },
    Program_Listing_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_Program_Listing_Check: (state, action) => {
      state.value = {}
      state.error = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const {Clear_Program_Listing_Check, Program_Listing_failure, Program_Listing_success, Program_Listing_request } = ProgramListingSlice.actions

export default ProgramListingSlice.reducer