import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
}

export const UserAttendanceSlice = createSlice({
  name: 'UserAttendance',
  initialState,
  reducers: {
    UserAttendance_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    UserAttendance_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};

    },
    UserAttendance_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_UserAttendance_Check: (state, action) => {
      state.value = {}
      state.error = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const {UserAttendance_request, UserAttendance_success, UserAttendance_failure, Clear_UserAttendance_Check } = UserAttendanceSlice.actions

export default UserAttendanceSlice.reducer