import { makeStyles } from '@mui/styles';
import ColorTheme from '../../Common/CommonCss/ColorTheme';
export const useStyles = makeStyles({
    BtnTheme:{
        backgroundColor: `${ColorTheme.orange} !important`,
        color: `${ColorTheme.BtnTextColor} !important`,
        width: "100%",
        height: "48px",
        borderRadius: "10px !important",
        fontSize: "18px !important",
        fontFamily: `${ColorTheme.publicSans} !important`,
        marginTop: "auto !important",
        marginBottom: "0",
        textTransform: "none !important"
    }

})