import React from 'react'
import { Button } from '@mui/material';
import AdminTheme from '../../Common/AdminTheme';
const ButtonSecondary = (props) => {
    const { label ,handleClick} = props;
    return (
        <>
            <Button
                variant='contained'
                onClick={()=>handleClick()}
                sx={{
                    minWidth: "110px",
                    height: "43px",
                    borderRadius: "4px",
                    fontSize: "13px",
                    fontWeight: "600",
                    lineHeight: "19px",
                    fontFamily: AdminTheme.poppins,
                    background: AdminTheme.SecondaryBtnBgColor,
                    color: AdminTheme.themeColor,
                    boxShadow: "none",
                    textTransform: "none",
                    '&:hover': {
                        color: AdminTheme.themeColor,
                        backgroundColor: AdminTheme.SecondaryBtnBgColor,
                        boxShadow: AdminTheme.btnHoverShadow,
                      },
                    }
                }
                >
                {label}
            </Button>

        </>
    )
}

export default ButtonSecondary;
