import React from 'react'
import { Button } from '@mui/material';
import AdminTheme from '../../Common/AdminTheme';
const ButtonPrimary = (props) => {
    const { label ,handleClick,check,disablecheck,disabledcheck} = props;
    // console.log("disablecheck",disablecheck)
    return (
        <>{
            check==true ?
            <Button
                variant='contained'
                // onClick={()=>handleClick()}
                type='submit'
                className='btn-primary'
                sx={{
                    minWidth: "110px",
                    height: "43px",
                    borderRadius: "4px",
                    fontSize: "13px",
                    fontWeight: "600",
                    lineHeight: "19px",
                    fontFamily: AdminTheme.poppins,
                    background: AdminTheme.btnBgColor,
                    color: AdminTheme.btnTextColor,
                    boxShadow: "none",
                    marginLeft: "auto",
                    textTransform: "none",
                    '&:hover': {
                        color: AdminTheme.btnTextColor,
                        backgroundColor: AdminTheme.btnBgColor,
                      },
                      '&.disabled':{
                        opacity: "0.5",
                        pointerEvents: "none"
                    }
                }}
                >
                {label}
            </Button>:
            <Button
                variant='contained'
                onClick={()=>handleClick()}
                // type='submit'
                disabled={disablecheck}
                className={`btn-primary ${disabledcheck==true?"":"disabled"}`}
                sx={{
                    minWidth: "110px",
                    height: "43px",
                    borderRadius: "4px",
                    fontSize: "13px",
                    fontWeight: "600",
                    lineHeight: "19px",
                    fontFamily: AdminTheme.poppins,
                    background: AdminTheme.btnBgColor,
                    color: AdminTheme.btnTextColor,
                    boxShadow: "none",
                    marginLeft: "auto",
                    textTransform: "none",
                    '&:hover': {
                        color: AdminTheme.btnTextColor,
                        backgroundColor: AdminTheme.btnBgColor,
                      },
                    '&.disabled':{
                        opacity: "0.5",
                        pointerEvents: "none"
                    }
                }}
                >
                {label}
            </Button>
        }

        </>
    )
}

export default ButtonPrimary;
