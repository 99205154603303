import React, { useState } from 'react'
import Grid from '@mui/joy/Grid';
import { ButtonSquared } from '../../Components/ButtonSquare/ButtonSquare'
import { ReactComponent as QrIcon } from '../../Images/icons/qr-icon.svg';
import { ReactComponent as InfoIcon } from './../../Images/icons/info-icon.svg'
import { ReactComponent as TicketInfoIcon } from './../../Images/icons/ticket-info-icon.svg'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
const QRButton = () => {
    const EventDetailsSlice = useSelector(state => state?.EventDetailsSlice)
    const Navigate = useNavigate()
    const userdetails = JSON.parse(localStorage.getItem("userdetails"));
    const slugPath =  '';
    const UserAttendaceSlice = useSelector(state => state?.UserAttendaceSlice)
    const [start_datecheck, setstart_datecheck] = useState(false)
      
    const [end_datecheck, setend_datecheck] = useState(false)
    const handleClick = () => {
        Navigate(`${slugPath}/voucher`)
    }

    const handleqr = () => {
        // localStorage.setItem("program_id", EventDetailsSlice?.value?.data?.id)
        Navigate(`${slugPath}/qrscanner/attendance`)
    }
    useEffect(() => {
        const dateString = EventDetailsSlice?.value?.data?.start_date;
        const starttimeString = EventDetailsSlice?.value?.data?.start_time;

        // Convert the dateString to a Date object
        const targetDate = new Date(dateString);
        if (starttimeString != "" && starttimeString != undefined && starttimeString != "undefined") {

            const [hours, minutes, seconds] = starttimeString?.split(":")?.map(Number);

            // Set the hours, minutes, and seconds of the targetDate
            targetDate.setHours(hours);
            targetDate.setMinutes(minutes);
            targetDate.setSeconds(seconds);
        }
        // Get the current date
        const currentDate = new Date();
        // targetDate.setHours(0, 0, 0, 0);
        // currentDate.setHours(0, 0, 0, 0);
        // Compare the two dates
        if (targetDate > currentDate) {
            setstart_datecheck(false)
        } else if (targetDate < currentDate) {
            setstart_datecheck(true)
        } else {
            setstart_datecheck(true)
        }
    }, [EventDetailsSlice])
    useEffect(() => {
        const enddateString = EventDetailsSlice?.value?.data?.end_date;
        const endtimeString = EventDetailsSlice?.value?.data?.end_time;

        // Convert the dateString to a Date object
        const targetDate = new Date(enddateString);
        if (endtimeString != "" && endtimeString != undefined && endtimeString != "undefined") {

            const [hours, minutes, seconds] = endtimeString?.split(":")?.map(Number);

            // Set the hours, minutes, and seconds of the targetDate
            targetDate.setHours(hours);
            targetDate.setMinutes(minutes);
            targetDate.setSeconds(seconds);
        }
        // Get the current date
        const currentDate = new Date();
        // targetDate.setHours(0, 0, 0, 0);
        // currentDate.setHours(0, 0, 0, 0);
        // Compare the two dates
        if (targetDate > currentDate) {
            setend_datecheck(true)
        } else if (targetDate < currentDate) {
            setend_datecheck(false)
        } else {
            setend_datecheck(true)
        }
    }, [EventDetailsSlice])
    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ flexGrow: 1 }}
                className="square-btn-group"
            >
                {userdetails?.data?.is_check_in == 1 && <Grid className="cl"  >
                    <ButtonSquared icon={<QrIcon />} label={'Check-in'} check={ (start_datecheck == true && end_datecheck == true) ? false : true} handleClick={() => {
                        if (start_datecheck == true && end_datecheck == true) {

                        handleqr()
                        }
                    }} />
                </Grid>}
                <Grid className="cl"
                    style={{
                        display: `${userdetails?.data?.is_check_in == 1 ? "" : "flex"}`,
                        justifyContent: `${userdetails?.data?.is_check_in == 1 ? "" : "center"}`
                    }}

                >
                    <ButtonSquared icon={<InfoIcon />} label={'Event Information'} handleClick={() => Navigate(`${slugPath}/about-event`)} />
                </Grid>
                <Grid className="cl" >
                    <ButtonSquared icon={<TicketInfoIcon />} label={'Ticket Information'} start_datecheck={start_datecheck} end_datecheck={end_datecheck} check={userdetails?.data?.is_check_in == 0 ? false: (UserAttendaceSlice?.error?.message?true:((start_datecheck == true && end_datecheck == true) ? false : true))} handleClick={() => {
                        if (UserAttendaceSlice?.error?.message && userdetails?.data?.is_check_in == 1) {
                            return
                        }
                        if (start_datecheck == true && end_datecheck == true) {
                            handleClick()
                        }
                    }} />
                </Grid>
            </Grid>
        </>
    )
}

export default QRButton
