import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
}

export const EventGetEditManagerSlice = createSlice({
  name: 'EventGetEditManager',
  initialState,
  reducers: {
    EventGetEditManager_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    EventGetEditManager_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};

    },
    EventGetEditManager_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_EventGetEditManager_Check: (state, action) => {
      state.value = {}
      state.error = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const {EventGetEditManager_request, EventGetEditManager_success, EventGetEditManager_failure, Clear_EventGetEditManager_Check } = EventGetEditManagerSlice.actions

export default EventGetEditManagerSlice.reducer