import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const Snacbar = (props) => {
    const { message, messagetype, open, setOpen } = props;
    // const [open, setOpen] = React.useState(false);



    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <div>

            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity={messagetype}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    <span
                        dangerouslySetInnerHTML={{
                            __html: message
                        }}
                    >

                    </span>
                    {/* {message} */}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default Snacbar
