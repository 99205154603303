import { makeStyles } from '@mui/styles';
import ColorTheme from '../../Common/CommonCss/ColorTheme';
import AdminTheme from '../../AdminPanel/Common/AdminTheme';
import { maxWidth, textAlign } from '@mui/system';


const AdminCheckfunction=()=>{
    const role =localStorage.getItem("role")
    const staffurl=role=="admin"?true:false;
    return staffurl
}


export const useStyles = makeStyles({

    PageNotFound: {
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        paddingLeft: "30px",
        paddingRight: "30px",
        backgroundColor: ColorTheme.lightGrey,
        "& svg":{
            marginBottom: "60px",
            maxWidth: "100%",
            height: "auto"
        },
        "& h1, & h2, & p": {
            fontFamily: AdminCheckfunction() ?AdminTheme.poppins:ColorTheme.publicSans,
            color: ColorTheme.textColor,
            fontWeight: "600",
            textAlign: "center",
        },
        "& h1": {
            fontSize: "200px",
            marginBottom: "60px",
            textAlign: "center",
        },
        "& h2": {
            fontSize: "54px",
            marginBottom: "20px",
            textAlign: "center",
        },
        "& p": {
            fontSize: "18px",
            marginBottom: "20px",
            paddingBottom: "10px",
            textAlign: "center",
        },
        "& .btn": {
            fontFamily: AdminCheckfunction() ?AdminTheme.poppins:ColorTheme.publicSans,
            // backgroundColor: AdminCheckfunction() ?AdminTheme.btnBgColor:ColorTheme.orange,
            backgroundColor: ColorTheme.orange,
            color: AdminCheckfunction() ?AdminTheme.btnTextColor:ColorTheme.BtnTextColor,
            height: AdminCheckfunction() ?"43px":"48px",
            borderRadius: AdminCheckfunction() ?"4px": "10px",
            fontSize: AdminCheckfunction() ?"14px": "18px",
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            textDecoration: "none",
            minWidth: "180px",
            fontWeight: "600",
            lineHeight: "20px",
            boxShadow: "none",
            marginLeft: "auto",
            marginRight: "auto",
            textTransform: "none",
        }
    },

    "@media(max-width: 767px)": {
        PageNotFound: {
            "& h1": {
                fontSize: "150px",
                marginBottom: "40px"
            },
            "& h2": {
                fontSize: "36px",
            },
            "& p": {
                fontSize: "16px",
            }
        }

    },
    "@media(max-width: 575px)": {
        PageNotFound: {
            "& h1": {
                fontSize: "80px",
                marginBottom: "30px"
            },
            "& h2": {
                fontSize: "24px",
            },
            "& p": {
                fontSize: "14px",
            }
        }

    }

});