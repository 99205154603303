import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
}

export const RestoreEventSlice = createSlice({
  name: 'RestoreEvent',
  initialState,
  reducers: {
    RestoreEvent_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    RestoreEvent_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};

    },
   RestoreEvent_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_RestoreEvent_Check: (state, action) => {
      state.value = {}
      state.error = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const {  RestoreEvent_request, RestoreEvent_success,  RestoreEvent_failure, Clear_RestoreEvent_Check } = RestoreEventSlice.actions

export default RestoreEventSlice.reducer