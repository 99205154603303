import axios from "axios";
import { APIURL } from "../../../APIHandler/APIURL"
import { Headers } from "../../../APIHandler/HeaderAPI";
import { reloadHandler } from "../../../utlis/utlis";
import { Event_Details_failure, Event_Details_request, Event_Details_success } from "../../Reducer/EventDetails/EventDetailsSlice";
import { ForgetPassword_failure, ForgetPassword_request, ForgetPassword_success } from "../../Reducer/ForgetPassword/ForgetPassword";

export const ForgetPasswordAPI = (payload) => async (dispatch, getState) => {
    dispatch(ForgetPassword_request())
    const auth_token = localStorage.getItem("auth_token")
    try {

        const { data } = await axios.post(`${APIURL.forgotpassword}`,payload, {
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
               "event-slug":Headers?.["event-slug"],
                // "Content-type": "application/json",
                "Content-type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${auth_token}`
            },
        })
        // console.log("data", data)
        dispatch(ForgetPassword_success(data))
    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(ForgetPassword_failure(error?.response?.data))

    }
}