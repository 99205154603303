import React, { useState } from 'react'
import { useStyles } from "./Style";
import '../../../AdminPanel/Common/Admin.css'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import HeaderBG from '../../../Images/admin/header-bg.png'
import ButtonPrimary from '../ButtonPrimary/ButtonPrimary';
import Dropdown from '@mui/joy/Dropdown';
import MenuButton from '@mui/joy/MenuButton';
import Menu from '@mui/joy/Menu';
import MenuItem from '@mui/joy/MenuItem';
import { useNavigate } from 'react-router-dom';
import AdminLogout from '../AdminLogout/AdminLogout';
import LanguageSelect from '../LanguageSelect/LanguageSelect';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import EventManagerModal from '../EventManagerModal/EventManagerModal';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Clear_EventGetEditManager_Check } from '../../../redux/Reducer/Admin/EventManager/EventGetEditManagerSlice';

const AdminHeader = (props) => {
  const classes = useStyles();
  const { Title, PageName, PageIcon, setactiveclass, activeclass } = props;
  // const [activeclass, setactiveclass] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false)
  const url = window.location.href;
  const urlhomecheck = url?.charAt(url?.length - 1) == "/" ? true : false;
  // console.log("urlhomecheck", urlhomecheck)
  const event_slug = localStorage.getItem("event_slug")
  const slugPath = '';
  const urlcheck = url?.includes(`/admin${slugPath}/dashboard`) ? true : false;
  const auth_name = localStorage.getItem("auth_name");
  const [isDesktop, setDesktop] = useState(window.innerWidth <= 1024);
  const [opendrawer, setOpendrawer] = React.useState(false);
  const role = localStorage.getItem("role")
  const dispatch = useDispatch()

  const mobileWidth = () => {
    setDesktop(window.innerWidth <= 575);
  };
  useEffect(() => {
    window.addEventListener("resize", mobileWidth);
    return () => window.removeEventListener("resize", mobileWidth);
  }, []);
  const handleClick = () => {
    localStorage.setItem("archive", false)
    localStorage.setItem("typecheck", 'new')
    navigate(`/admin${slugPath}/events/create-event/event-information`)
  }
  useEffect(() => {
    if (!isDesktop) {
      setOpendrawer(false)
    }
  }, [isDesktop])

  const toggleDrawer = (newOpen) => () => {
    setOpendrawer(newOpen);
  };


  const LeftMenu = () => {
    return (
      <>
        <div className={`${classes.NavLeft} nav-left`}>
          <div className={classes.MenuToggleClose} onClick={() => setOpendrawer(false)}><CloseIcon /></div>
          <Button className={activeclass == 1 ? classes.BtnActive : ""} onClick={() => {
            if((urlcheck == true || urlhomecheck == true)){

            localStorage.setItem("activityclass", 1)
            }else{

            localStorage.removeItem("activityclass")
            }
            navigate(`/admin${slugPath}/dashboard`)
            setactiveclass(1)
            localStorage.removeItem("programactivitesID")
            localStorage.removeItem("typecheck")
            setOpendrawer(false)
            dispatch(Clear_EventGetEditManager_Check())
            localStorage.setItem("archive", false)

          }}>Dashboard</Button>
          {(urlcheck == true || urlhomecheck == true) && <Button className={activeclass == 2 ? classes.BtnActive : ""} onClick={() => {

            setactiveclass(2)
            localStorage.setItem("activityclass", 2)
            localStorage.removeItem("programactivitesID")
            localStorage.removeItem("typecheck")

            setOpendrawer(false)
            dispatch(Clear_EventGetEditManager_Check())
            localStorage.setItem("archive", false)

            // navigate("/admin/events")
          }}>Events</Button>}
          {(role != "Event Manager" && (urlcheck == true || urlhomecheck == true)) && <Button className={activeclass == 3 ? classes.BtnActive : ""} onClick={() => {

            setactiveclass(3)
            setOpendrawer(false)
            localStorage.setItem("activityclass", 3)
            localStorage.removeItem("programactivitesID")
            localStorage.removeItem("typecheck")

            dispatch(Clear_EventGetEditManager_Check())
            localStorage.setItem("archive", false)

            // navigate("/admin/events")
          }}>Event Archive</Button>}
          {(role != "Event Manager" && (urlcheck == true || urlhomecheck == true)) && <Button className={activeclass == 4 ? classes.BtnActive : ""} onClick={() => {

            setactiveclass(4)
            setOpendrawer(false)
            localStorage.setItem("activityclass", 4)
            localStorage.removeItem("programactivitesID")
            localStorage.removeItem("typecheck")

            dispatch(Clear_EventGetEditManager_Check())
            localStorage.setItem("archive", false)

            // navigate("/admin/events")
          }}>Event Management</Button>}
        </div>
      </>
    )
  }


  return (
    <>
      <div id="AdminHeader" className={`${classes.Header} hedaer`}>
        <img src={HeaderBG} alt="" className='cover' />
        <div className='content'>
          <div className='container'>
            <div className={`${classes.NavBar} navbar`}>
              <div className={classes.MenuToggle} onClick={() => setOpendrawer(true)}><MenuIcon /></div>
              <div className={classes.DV}><LeftMenu /></div>
              <Drawer
                anchor={'left'}
                open={opendrawer} onClose={toggleDrawer(false)}
              >
                <div className={classes.MV}><LeftMenu /></div>
              </Drawer>
              <div className={`${classes.NavRight} nav-right`}>
                <LanguageSelect />
                <div className={`${classes.ProfileName} profile-name`}>
                  <p>Hi,</p>
                  <h6>{auth_name}</h6>
                </div>

                <div className={classes.ProfilePicCard}>
                  <Dropdown>
                    <MenuButton sx={{ padding: '0', border: 'none', }}>
                      <Avatar className={classes.ProfilePic} alt={auth_name} src="/static/images/avatar/2.jpg" variant="rounded" />
                    </MenuButton>
                    <Menu placement="bottom-end" className={classes.ProfilePicCardDropdown}>
                      {/* <MenuItem>
                        <Button className='btn btn-change-pwd' variant="soft">
                          Change Password
                        </Button>
                      </MenuItem> */}
                      <MenuItem>
                        <Button className='btn btn-logout' variant="soft" color="danger" onClick={() => setOpen(true)}>
                          Log Out
                        </Button>
                      </MenuItem>
                    </Menu>
                  </Dropdown>
                </div>

                <AdminLogout setOpen={setOpen} open={open} />

              </div>
            </div>
          </div>

          <hr style={{ margin: '0' }} />

          <div className='container'>
            <div className={classes.AdminBreadcrumbsRow}>
              <div className={`${classes.AdminBreadcrumbs} admin-breadcrumbs`}>
                <h1>{Title}</h1>
                <div className={`${classes.AdminBreadcrumbsContainer} admin-breadcrumbs-container`}>
                  <div class={classes.PageIcon}>
                    {PageIcon}
                  </div>
                  <ul>
                    <li>{PageName}</li>
                  </ul>
                </div>
              </div>
              {activeclass != 4 &&
                role != "Event Manager" && (urlcheck == true || urlhomecheck == true) && (

                  <ButtonPrimary disabledcheck={true} label="Create new event" handleClick={() => handleClick()} />
                )
              }
              {activeclass == 4 && <EventManagerModal />}
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default AdminHeader