import axios from "axios";
import { APIURL } from "../../../../APIHandler/APIURL"
import { Headers } from "../../../../APIHandler/HeaderAPI";
import { reloadHandler } from "../../../../utlis/utlis";
import { Create_Speaker_failure, Create_Speaker_request, Create_Speaker_success } from "../../../Reducer/Admin/Speakers/craetespeakerSlice";
import { GetSpeakerAPI } from "./GetSpeakers";

export const CreateSpeakerAPI = (payload,id) => async (dispatch, getState) => {
    // const eventItemId=localStorage.getItem("eventItemId")
    const auth_token = localStorage.getItem("auth_token")
    dispatch(Create_Speaker_request())
    const admineventid = localStorage.getItem("admineventid")
   
    try {
        const { data } = await axios.post(`${APIURL.createspeaker}`,payload, {
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
                // "Content-type": "application/json",
              "event-slug":Headers?.["event-slug"],
                "Content-type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${auth_token}`
            },
        })
      
        dispatch(Create_Speaker_success(data))
        if(id){

            dispatch(GetSpeakerAPI(id))
        }else{
            
            dispatch(GetSpeakerAPI(admineventid))
        }
    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(Create_Speaker_failure(error?.response?.data))
    }
}

