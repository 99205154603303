import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
}

export const EventManagerdeleteSlice = createSlice({
  name: 'EventManagerdelete',
  initialState,
  reducers: {
    EventManagerdelete_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    EventManagerdelete_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};

    },
    EventManagerdelete_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_EventManagerdelete_Check: (state, action) => {
      state.value = {}
      state.error = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const {EventManagerdelete_request, EventManagerdelete_success, EventManagerdelete_failure, Clear_EventManagerdelete_Check } = EventManagerdeleteSlice.actions

export default EventManagerdeleteSlice.reducer