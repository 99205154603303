import axios from "axios";
import { APIURL } from "../../../../APIHandler/APIURL"
import { Headers } from "../../../../APIHandler/HeaderAPI";
import { reloadHandler } from "../../../../utlis/utlis";
import { Admin_Create_Event_failure, Admin_Create_Event_request, Admin_Create_Event_success } from "../../../Reducer/Admin/CreateEvents/CreateEvents";
import { Get_Activites_failure, Get_Activites_request, Get_Activites_success } from "../../../Reducer/Admin/CreateActivities/GetActivitiesSlice";

export const GetActivitiesAPI = (payload) => async (dispatch, getState) => {
    // const eventItemId=localStorage.getItem("eventItemId")
    const auth_token = localStorage.getItem("auth_token")
    dispatch(Get_Activites_request())
    try {
        const { data } = await axios.get(`${APIURL.getActivites}/${payload}`,{
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
             "event-slug":Headers?.["event-slug"],
                "Content-type": "application/json",
                Authorization: `Bearer ${auth_token}`
            },
        })
        dispatch(Get_Activites_success(data))
    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(Get_Activites_failure(error?.response?.status))
    }
}


  