import axios from "axios";
import { APIURL } from "../../../APIHandler/APIURL"
import { Headers } from "../../../APIHandler/HeaderAPI";
import { reloadHandler } from "../../../utlis/utlis";
import { Email_Check_failure, Email_Check_request, Email_Check_success } from "../../Reducer/EmailCheck/EmailCheckSlice";

export const EmailCheckAPI = (payload) => async (dispatch, getState) => {
    dispatch(Email_Check_request())
    try {

        const { data } = await axios.post(`${APIURL.emailCheck}`, payload,{
            headers: Headers
        })
        // console.log("data", data)
        dispatch(Email_Check_success(data))
    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(Email_Check_failure(error?.response?.data))

    }
}