import axios from "axios";
import { APIURL } from "../../../../APIHandler/APIURL"
import { Headers } from "../../../../APIHandler/HeaderAPI";
import { reloadHandler } from "../../../../utlis/utlis";
import { Get_Edit_Activites_failure, Get_Edit_Activites_request, Get_Edit_Activites_success } from "../../../Reducer/Admin/CreateActivities/GetEditActivities";
import { Admin_Partcipant_failure, Admin_Partcipant_request, Admin_Partcipant_success } from "../../../Reducer/Admin/Particants/ParticpantsSlice";

export const PartcipantsAPI = (payload,page,offset) => async (dispatch, getState) => {
    const admineventid=localStorage.getItem("admineventid")
    const auth_token = localStorage.getItem("auth_token")
    dispatch(Admin_Partcipant_request())
    try {
        const { data } = await axios.get(`${APIURL.partcipants}/${payload}?page=${page?page:1}&offset=${offset?offset:20}`,{
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
              "event-slug":Headers?.["event-slug"],
                "Content-type": "application/json",
                Authorization: `Bearer ${auth_token}`
            },
        })
        dispatch(Admin_Partcipant_success(data))
    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(Admin_Partcipant_failure(error?.response?.data))
    }
}


export const PartcipantsSearchAPI = (payload,search) => async (dispatch, getState) => {
    const admineventid=localStorage.getItem("admineventid")
    const auth_token = localStorage.getItem("auth_token")
    dispatch(Admin_Partcipant_request())
    try {
        const { data } = await axios.get(`${APIURL.partcipants}/${payload}?search=${search}`,{
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
                "Content-type": "application/json",
                "event_slug":Headers?.event_slug,
                Authorization: `Bearer ${auth_token}`
            },
        })
        dispatch(Admin_Partcipant_success(data))
    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(Admin_Partcipant_failure(error?.response?.data))
    }
}


  