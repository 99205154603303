import { applyMiddleware, combineReducers, createStore } from '@reduxjs/toolkit'
import {thunk} from 'redux-thunk'
import CompanydataSliceReducer from './Reducer/CompanydataSlice';
import EmailCheckSliceReducer from './Reducer/EmailCheck/EmailCheckSlice';
import EventIDSliceReducer  from './Reducer/EventIdSlice';
import LoginCheckSliceReducer  from './Reducer/Login/LoginSlice';
import EventDetailsSliceReducer from './Reducer/EventDetails/EventDetailsSlice';
import VouchersSliceReducer from './Reducer/Vouchers/VouchersSlice';
import ProgramDetailsSliceReducer from './Reducer/ProgramDetails/ProgramDetailsSlice';
import StaffVouchersSliceReducer  from './Reducer/StaffVoucher/StaffVoucherSlice';
import QRStaffGeneratorSliceReducer  from './Reducer/QrStaffgenrator/QrStaffgenrator';
import AdminSampleExportSliceReducer  from './Reducer/Admin/Sampleexport/SampleExportSlice';
import AdminCreateEventSliceReducer  from './Reducer/Admin/CreateEvents/CreateEvents';
import AdminCreateActivitiesSliceReducer  from './Reducer/Admin/CreateActivities/CreateActivities';
import ScanActivitySliceReducer  from './Reducer/ScanActivity/ScanActivity';
import StartDateSliceReducer  from './Reducer/startdateSlice';
import ProgramQrGenrateSliceReducer  from './Reducer/ProgramQrGenerate/ProgramQrGenerate';
import CreateSpeakerSliceReducer  from './Reducer/Admin/Speakers/craetespeakerSlice';
import GetspeakersSliceReducers  from './Reducer/Admin/Speakers/getSpeakersSlice';
import EditspeakersSliceReducers  from './Reducer/Admin/Speakers/EditSpeakerSlice';
import DeletespeakersSliceReducer  from './Reducer/Admin/Speakers/DeleteSpeakers';
import AdminEventListSliceReducer  from './Reducer/Admin/EventList/EventListSlice';
import GetActivitesSliceReducer  from './Reducer/Admin/CreateActivities/GetActivitiesSlice';
import ImportCsvSliceReducer  from './Reducer/Admin/Sampleexport/ImportCSVSlice';
import GetEditActivitesSliceReducer  from './Reducer/Admin/CreateActivities/GetEditActivities';
import EditActivitesSliceReducer  from './Reducer/Admin/CreateActivities/EditActivity';
import DeleteActivitesSliceReducer  from './Reducer/Admin/CreateActivities/DeleteActivitySlice';
import GetIdspeakersSliceReducer  from './Reducer/Admin/Speakers/GetIdSpeakerSlice';
import PartcipantSliceReducer  from './Reducer/Admin/Particants/ParticpantsSlice';
import AdminDeleteEventSliceReducer  from './Reducer/Admin/EventList/DeleteEventListSlice';
import ProgramListingSliceReducer  from './Reducer/Admin/ProgramLisiting/ProgramLisitingSlice';
import AddParticipantActivitiesSliceReducer  from './Reducer/Admin/Addpartcipantactivities/Addpartcipantactivities';
import PartcipantactivitesSliceReducer  from './Reducer/Admin/ParticipantActivitesList/PartcipantActivitiesList';
import IncreasePartcipantQuantitySliceReducer  from './Reducer/Admin/increaseparticipantQuantity/IncreaseParticipantQuantity';
import getEditEventListSliceReducer  from './Reducer/Admin/getEditEventList/getEditEventList';
import EditEventListSliceReducer  from './Reducer/Admin/getEditEventList/EditEventList';
import DeletePartcipantSliceReducer from './Reducer/Admin/Particants/DeletePartcipantSlice';
import AdminBulkDeleteEventSliceReducer  from './Reducer/BulkDeleteEvent/BulkDeleteEvent';
import DashboardListSliceReducer  from './Reducer/DashboardList/DashboardList';
import IncrementDecrementSliceReducer from './Reducer/IncrementDecrement';
import SingleDeleteEventSliceReducer  from './Reducer/BulkDeleteEvent/SingleEventDelete';
import BulkDeleteParticipantSliceReducer  from './Reducer/Admin/ParticipantActivitesList/BulkDeleteParticipant';
import ForgetPasswordSliceReducer  from './Reducer/ForgetPassword/ForgetPassword';
import SetPasswordSliceReducer  from './Reducer/ForgetPassword/SetPassword';
import ChangePasswordSliceReducer  from './Reducer/ChangePassword/ChangePassword';
import ResetPasswordSliceReducer  from './Reducer/ResetPassword/ResetPAsswordSlice';
import AddAdminStaffSliceReducer from './Reducer/Admin/AdminStaff/AddAdminStaffSlice';
import EndDateSliceReducer from './Reducer/EndDateSlice';
import GetAdminStaffSliceReducer from './Reducer/Admin/AdminStaff/GetAdminStaffSlice';
import DeleteAdminStaffSliceReducer from './Reducer/Admin/AdminStaff/DeleteAdminStaffSlice';
import GeteditAdminStaffSliceReducer  from './Reducer/Admin/AdminStaff/GetEditStaffSlice';
import ImportExportSoketSliceReducer  from './Reducer/ImportExportSoketdata';
import ImportLastStatusCheckSliceReducer  from './Reducer/ImportLaststatusCheckSlice';
import ImportExportErrorSoketSliceReducer  from './Reducer/ImportExportErrorSlice';
import ArchiveEventSliceReducer from './Reducer/ArchiveEvent/ArchiveEventSlice';
import EventManagerListSliceReducer  from './Reducer/Admin/EventManager/EventManagerSlice';
import EventManagerPostSliceReducer from './Reducer/Admin/EventManager/EventManagerPostSlice';
import DuplicateListSliceReducer  from './Reducer/Admin/duplicateEvent/DuplicateEventSlice';
import EventManagerdeleteSliceReducer  from './Reducer/Admin/EventManager/EventManagerDeleteSlice';
import EventGetEditManagerSliceReducer from './Reducer/Admin/EventManager/EventGetEditManagerSlice';
import AttendanceStaffSliceReducer  from './Reducer/Admin/Addpartcipantactivities/Attendance/AttendanceStaff';
import UserAttendanceSliceReducer from './Reducer/Admin/UserAttendance/UserAttendanceSlice';
import UpdateBannerSliceSliceReducer  from './Reducer/UpdateBannerSlice/UpdateBannerSlice';
import DeleteBannerSliceReducer  from './Reducer/UpdateBannerSlice/DeleteBannerUrl';
import UserAttendaceSliceReducer  from './Reducer/UserAttendance/UserAttendanceSlice';
import AdminBulkcheckinEventSliceResucer  from './Reducer/AdminBluckCheckin/AdminBluckCheckinSlice';
import ReloadSliceReducer from './Reducer/ReloadSlice';
import EventSlugSliceReducer from './Reducer/EventSlug/EventSlugSlice';
import RestoreEventSliceReducer from './Reducer/RestoreEvent/RestoreEventSlice';
import PerecentageCheckSliceReducer  from './Reducer/PercentageCheckSlice';
import PrinterBadgeSliceReducer from './Reducer/PrinterBadge/PrinterBadgeSlice';
const rootReducer = combineReducers({
    CompanydataSlice:CompanydataSliceReducer,
    EmailCheckSlice:EmailCheckSliceReducer,
    EventIDSlice:EventIDSliceReducer,
    LoginCheckSlice:LoginCheckSliceReducer,
    EventDetailsSlice:EventDetailsSliceReducer,
    VouchersSlice:VouchersSliceReducer,
    ProgramDetailsSlice:ProgramDetailsSliceReducer,
    StaffVouchersSlice:StaffVouchersSliceReducer,
    QRStaffGeneratorSlice:QRStaffGeneratorSliceReducer,
    AdminSampleExportSlice:AdminSampleExportSliceReducer,
    AdminCreateEventSlice:AdminCreateEventSliceReducer,
    AdminCreateActivitiesSlice:AdminCreateActivitiesSliceReducer,
    ScanActivitySlice:ScanActivitySliceReducer,
    StartDateSlice:StartDateSliceReducer,
    ProgramQrGenrateSlice:ProgramQrGenrateSliceReducer,
    CreateSpeakerSlice:CreateSpeakerSliceReducer,
    GetspeakersSlice:GetspeakersSliceReducers,
    EditspeakersSlice:EditspeakersSliceReducers,
    DeletespeakersSlice:DeletespeakersSliceReducer,
    AdminEventListSlice:AdminEventListSliceReducer,
    GetActivitesSlice:GetActivitesSliceReducer,
    ImportCsvSlice:ImportCsvSliceReducer,
    GetEditActivitesSlice:GetEditActivitesSliceReducer,
    EditActivitesSlice:EditActivitesSliceReducer,
    DeleteActivitesSlice:DeleteActivitesSliceReducer,
    GetIdspeakersSlice:GetIdspeakersSliceReducer,
    PartcipantSlice:PartcipantSliceReducer,
    AdminDeleteEventSlice:AdminDeleteEventSliceReducer,
    ProgramListingSlice:ProgramListingSliceReducer,
    AddParticipantActivitiesSlice:AddParticipantActivitiesSliceReducer,
    PartcipantactivitesSlice:PartcipantactivitesSliceReducer,
    IncreasePartcipantQuantitySlice:IncreasePartcipantQuantitySliceReducer,
    getEditEventListSlice:getEditEventListSliceReducer,
    EditEventListSlice:EditEventListSliceReducer,
    DeletePartcipantSlice:DeletePartcipantSliceReducer,
    AdminBulkDeleteEventSlice:AdminBulkDeleteEventSliceReducer,
    DashboardListSlice:DashboardListSliceReducer,
    IncrementDecrementSlice:IncrementDecrementSliceReducer,
    SingleDeleteEventSlice:SingleDeleteEventSliceReducer,
    BulkDeleteParticipantSlice:BulkDeleteParticipantSliceReducer,
    ForgetPasswordSlice:ForgetPasswordSliceReducer,
    SetPasswordSlice:SetPasswordSliceReducer,
    ChangePasswordSlice:ChangePasswordSliceReducer,
    ResetPasswordSlice:ResetPasswordSliceReducer,
    AddAdminStaffSlice:AddAdminStaffSliceReducer,
    EndDateSlice:EndDateSliceReducer,
    GetAdminStaffSlice:GetAdminStaffSliceReducer,
    DeleteAdminStaffSlice:DeleteAdminStaffSliceReducer,
    GeteditAdminStaffSlice:GeteditAdminStaffSliceReducer,
    EventManagerPostSlice:EventManagerPostSliceReducer,
    ImportExportSoketSlice:ImportExportSoketSliceReducer,
    ImportLastStatusCheckSlice:ImportLastStatusCheckSliceReducer,
    ImportExportErrorSoketSlice:ImportExportErrorSoketSliceReducer,
    ArchiveEventSlice:ArchiveEventSliceReducer,
    EventManagerListSlice:EventManagerListSliceReducer,
    DuplicateListSlice:DuplicateListSliceReducer,
    EventManagerdeleteSlice:EventManagerdeleteSliceReducer,
    EventGetEditManagerSlice:EventGetEditManagerSliceReducer,
    AttendanceStaffSlice:AttendanceStaffSliceReducer,
    UserAttendanceSlice:UserAttendanceSliceReducer,
    UpdateBannerSliceSlice:UpdateBannerSliceSliceReducer,
    DeleteBannerSlice:DeleteBannerSliceReducer,
    UserAttendaceSlice:UserAttendaceSliceReducer,
    AdminBulkcheckinEventSlice:AdminBulkcheckinEventSliceResucer,
    ReloadSlice:ReloadSliceReducer,
    EventSlugSlice:EventSlugSliceReducer,
    RestoreEventSlice:RestoreEventSliceReducer,
    PerecentageCheckSlice:PerecentageCheckSliceReducer,
    PrinterBadgeSlice:PrinterBadgeSliceReducer

});
export const store = createStore(rootReducer, applyMiddleware(thunk))