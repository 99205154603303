import { makeStyles } from '@mui/styles';
import ColorTheme from '../../Common/CommonCss/ColorTheme';
export const useStyles = makeStyles({   

    StatusHeader: {
        "& .cl": {
            flex: "1",
        },
        "& .h2": {
            fontSize: "20px",
            fontWeight: "300",
            lineHeight: "24px",
            color: ColorTheme.textColor,
            fontFamily: ColorTheme.publicSans,
            marginBottom: "0px",
            marginTop: "0px",
            textTransform: "capitalize"
        }
    },

    TextLeft:{
        fontFamily: ColorTheme.publicSans,
        fontWeight: "700",
        fontSize: "14px",
        lineHeight: "16px",
        color: ColorTheme.textColor,
        flexShrink: "0"
    },
    TextRight:{
        fontFamily: ColorTheme.publicSans,
        fontWeight: "300",
        fontSize: "14px",
        lineHeight: "16px",
        color: ColorTheme.textColor
    },
    
});