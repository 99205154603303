import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
}

export const DeleteBannerSlice = createSlice({
  name: 'DeleteBannerSlice',
  initialState,
  reducers: {
    DeleteBannerSlice_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    DeleteBannerSlice_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};

    },
    DeleteBannerSlice_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_DeleteBannerSlice_Check: (state, action) => {
      state.value = {}
      state.error = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const {DeleteBannerSlice_request, DeleteBannerSlice_success, DeleteBannerSlice_failure, Clear_DeleteBannerSlice_Check } = DeleteBannerSlice.actions

export default DeleteBannerSlice.reducer