import React, { useEffect, useState } from 'react'
import Select, { selectClasses } from '@mui/joy/Select';
import ColorTheme from '../../Common/CommonCss/ColorTheme';
import Option from '@mui/joy/Option';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { useStyles } from "./Style";
import { useDispatch, useSelector } from 'react-redux';
import { Event_ID_success } from '../../redux/Reducer/EventIdSlice';
import InnerDimension from '../InnerDimension/InnerDimension';
const SelectInput = (props) => {
    const { eventList } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const [list, setlist] = useState();
    const {width}=InnerDimension();
    console.log("eventList", eventList)
    const handleChange = (event, newvalue) => {
        console.log("event", newvalue)
        dispatch(Event_ID_success(newvalue))
    }
    useEffect(() => {
        const obj = eventList?.filter((data) => data?.event_id != null)
        //  console.log("vikalp",obj)
        setlist(obj)
    }, [eventList])
    console.log("width",width)
    return (
        <>
            <Select
                className={`${classes.Input} form-input`}
                placeholder="Choose event"
                indicator={<KeyboardArrowDown />}
                onChange={handleChange}
                sx={{
                    width: "100%",
                    [`& .${selectClasses.indicator}`]: {
                        transition: '0.2s',
                        [`&.${selectClasses.expanded}`]: {
                            transform: 'rotate(-180deg)',
                        },
                        '& .MuiSvgIcon-root': {
                            color: ColorTheme.textColor,
                            opacity: 0.6
                        }
                    },
                }}
            >
                {
                    list?.map((data) => {
                        return <>

                            <Option value={data?.event_id}>{width<=340 ?  data?.event_name?.slice(0,30)+"...":  data?.event_name?.length>40?data?.event_name?.slice(0,40)+"...":data?.event_name}</Option>
                        </>
                    })
                }

            </Select>
        </>
    )
}

export default SelectInput
