import axios from "axios";
import { APIURL } from "../../../APIHandler/APIURL"
import { Headers } from "../../../APIHandler/HeaderAPI";
import { reloadHandler } from "../../../utlis/utlis";
import { Login_Check_failure, Login_Check_request, Login_Check_success } from "../../Reducer/Login/LoginSlice";

export const LoginAPI = (payload) => async (dispatch, getState) => {
    dispatch(Login_Check_request())
    try {

        const { data } = await axios.post(`${APIURL.login}`, payload, {
            headers: Headers
        })
        // console.log("data", data)
        localStorage.setItem("auth_token", data?.data?.auth_token)
        localStorage.setItem("user_id", data?.data?.user_id)
        localStorage.setItem("is_password_set", data?.data?.is_password_set)
        localStorage.setItem("role", data?.data?.role)
        localStorage.setItem("rolecheck", data?.data?.role)
        localStorage.setItem("auth_name", (data?.data?.name))
        if (data?.data?.event_slug) {

            localStorage.setItem("event_slug", (data?.data?.event_slug))
        }
        dispatch(Login_Check_success(data))
    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(Login_Check_failure(error?.response?.data))

    }
}