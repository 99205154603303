import { makeStyles } from '@mui/styles';
import ColorTheme from '../../Common/CommonCss/ColorTheme';
export const useStyles = makeStyles({


    UserTitleHeader: {
        padding: '15px 20px',
        display: "flex",
        alignItems: 'center', 
        justifyContent: 'space-between',
        "& .cl:first-child": {
            marginRight: "10px",
        },
        "& .h1":{
            fontSize: "20px",
            fontWeight: "300",
            lineHeight: "24px",
            color: ColorTheme.textColor,
            fontFamily: ColorTheme.publicSans,
            marginBottom: "auto",
            marginTop: "auto",
            textTransform: "capitalize",
        },
        "& .btn-logout": {
            marginRight: "-8px",
        },
        "@media(max-width:340px)": {
            padding: '15px 15px', 
        }
    },

    Home: {
        "& .square-btn-group": {
            "& .cl": {
                flex: "1",
                "& button": {
                    width: "100%",
                }
            }
        }
    }


});