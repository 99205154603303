import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
  error:{}
}

export const AdminCreateEventSlice = createSlice({
  name: 'AdminCreateEvent',
  initialState,
  reducers: {
    Admin_Create_Event_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    Admin_Create_Event_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};

    },
    Admin_Create_Event_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_Admin_Create_Event_Check: (state, action) => {
      state.value = {}
      state.error = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const {Clear_Admin_Create_Event_Check, Admin_Create_Event_failure, Admin_Create_Event_success, Admin_Create_Event_request } = AdminCreateEventSlice.actions

export default AdminCreateEventSlice.reducer