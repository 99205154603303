import React from 'react'
import { useStyles } from "./Style";
import Avatar from '@mui/material/Avatar';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import china from './../../../Images/china.png'
import Thailand from './../../../Images/thailand.png'
import usa from './../../../Images/flag.png'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names =
    [
        {
            lang: "English",
            code: "en",
            countrycode: "US",
            icon: usa,
        },
        {
            lang: "Chinese",
            code: "chi",
            countrycode: "CN",
            icon: china,

        },
        {
            lang: "Thailand",
            code: "thai",
            countrycode: "TH",
            icon: Thailand
        },

    ]

const options = [
    // { value: '1', label: 'Thailand', src: Thailand },
    { value: '1', label: 'English', src: usa },
    // { value: '3', label: 'China', src: china },
  ];
  
  function renderValue(option) {
      if (!option) {
          return null;
        }
    return (
      <React.Fragment>
          <Avatar className="language-icon" src={options.find((o) => o.value === option.value)?.src} />
      </React.Fragment>
    );
  }

const LanguageSelect = () => {
    const classes = useStyles();
  return (
    <>
    <Select className={`${classes.LanguageSelectDropdown} language-select-dropdown`}
      defaultValue="1"
      renderValue={renderValue}
    >
      {options.map((option, index) => (
          <Option className={classes.LanguageLi} value={option.value} label={option.label}>
            <Avatar className={classes.LanguageIcon} src={option.src} />
            {option.label}
          </Option>
      ))}
    </Select>



    </>
  )
}

export default LanguageSelect