import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import EventListTable from '../../Components/ParticipantsTable/EventListTable'
import { useDispatch } from 'react-redux'
import { AdminEventListAPI } from '../../../redux/Action/Admin/EventList/EventList'
import { useSelector } from 'react-redux'
import AdmLoader from '../../Components/AdmLoader/AdmLoader'

const AllEvents = () => {
  const dispatch = useDispatch()
  const AdminEventListSlice = useSelector(state => state?.AdminEventListSlice)
  const [open, setopen] = useState(false);
  useEffect(() => {
    dispatch(AdminEventListAPI())
  }, [])
  useEffect(() => {
    if (AdminEventListSlice?.loading == true) {
      setopen(true)

    } else if (AdminEventListSlice?.loading == false)  {
      setTimeout(() => {
        setopen(false)
      }, 700)
    }
  }, [AdminEventListSlice])
  console.log("AdminEventListSlice", AdminEventListSlice)
  return (
    <>
    {open && <AdmLoader/>}
      <Helmet>
        <title>Events | Ticketing</title>
        {/* <meta name="description" content="Ticketing application" /> */}
      </Helmet>
      <EventListTable />
    </>
  )
}

export default AllEvents