import React from 'react'
import { useStyles } from "./Style";
import { Button } from '@mui/material';
import ReactAudioPlayer from 'react-audio-player';

const ButtonSecondaryUser = (props) => {
    const { icon, label, type, handleClick } = props;
    const classes = useStyles();
    return (
        <>
            <Button
                className={`${classes.BtnSecondary} btn-secondary`}
                onClick={() => handleClick()}
                type={type}
            >
                <span className='icn'>{icon}</span>
                <span className='txt'>{label}</span>
            </Button>
            <ReactAudioPlayer

                src="https://ticketingproduction-files.s3.ap-southeast-1.amazonaws.com/success-notification-alert_A_major.wav"
                // autoPlay
                controls
                id="autoaudioplay"
            />
            <ReactAudioPlayer

                src="https://ticketingproduction-files.s3.ap-southeast-1.amazonaws.com/error-170796.mp3"
                // autoPlay
                controls
                id="autoaudioplay1"
            />
        </>
    )
}

export default ButtonSecondaryUser;
