import { makeStyles } from '@mui/styles';
import AdminTheme from '../../Common/AdminTheme';
export const useStyles = makeStyles({

    ActivitySlider: {
        marginBottom: "calc(20px + 16px)",

        "& .slick-track": {
            marginLeft: "-5px !important",
            marginRight: "-5px !important",
        },
        "& .slick-track .slick-slide": {
            paddingLeft: "5px !important",
            paddingRight: "5px !important",
        },

        "& .act-slider-card": {
            borderRadius: "12px !important",
            borderColor: "#F2EFEE !important",
            overflow: "hidden",
            "& .MuiCardCover-root.css-34ozei-JoyCardCover-root": {
                background: "linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0)) !important",
                top: "40% !important"
            }
        },

        "& .MuiCardContent-root": {
            "& h5": {
                fontSize: "18px",
                lineHeight: "24px",
                fontWeight: "600",
                color: "#fff",
                fontFamily: AdminTheme.poppins,
                marginBottom: "0px",
                marginTop: "0"
            },
            "& p": {
                fontSize: "14px",
                lineHeight: "21px",
                fontWeight: "400",
                color: "#fff",
                fontFamily: AdminTheme.poppins,
                marginBottom: "0px",
                marginTop: "0"
            },
        },
        "& .slick-arrow": {
            color: AdminTheme.themeColor,
            background: "#fff",
            width: "32px",
            height: "32px",
            boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.15)",
            borderRadius: "6px",
            zIndex: "3"
        },
        "& .slick-prev": {
            left: "-16px"
        },
        "& .slick-next": {
            right: "0px"
        },
        "& .slick-disabled": {
            display: "none !important"
        }
    }

});