import axios from "axios";
import { APIURL } from "../../../APIHandler/APIURL"
import { Headers } from "../../../APIHandler/HeaderAPI";
import { reloadHandler } from "../../../utlis/utlis";
import { Program_Details_failure, Program_Details_request, Program_Details_success } from "../../Reducer/ProgramDetails/ProgramDetailsSlice";

export const ProgramDetailsAPI = (payload) => async (dispatch, getState) => {
    dispatch(Program_Details_request())
    // const eventItemId=localStorage.getItem("eventItemId")
    const auth_token = localStorage.getItem("auth_token")
  
    try {
        const { data } = await axios.get(`${APIURL.programdetails}/${payload}`, {
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
                "Content-type": "application/json",
               "event-slug":Headers?.["event-slug"],
                Authorization: `Bearer ${auth_token}`
            },
        })
        dispatch(Program_Details_success(data))

    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(Program_Details_failure(error?.response?.data))

    }
}