import React, { useEffect, useState } from 'react';
import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import MusicPlayer from './Sound';
import { Headers } from '../APIHandler/HeaderAPI';
import SuccessPopu from '../Components/SuccessPopup/SuccessPopup';
import ReactAudioPlayer from 'react-audio-player';
// Initialize Pusher

function Soket(props) {
    const { changeContent } = props;
    const auth_token = localStorage.getItem("auth_token")
    const user_id = localStorage.getItem("user_id")
    const [count, setcount] = useState(0)
    // console.log("auth_token", auth_token)
    const staffId = 3;
    const [open, setOpen] = useState(false);
    const [data, setdata] = useState(false);

    // const handleClickOpen = () => {
    //     setOpen(true);
    // };
    // console.log("changeContent", changeContent)
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        // Initialize Echo with Pusher
        // window.Echo = new Echo({
        //     broadcaster: 'pusher',
        //     key: 'TicketKeytProd', // Your Pusher key
        //     cluster: 'ap1', // Your Pusher cluster
        //     wsHost: "admin.eventsprog.com",
        //     wsPort: 6001,
        //     wssPort: 6001,
        //     forceTLS: true,
        //     disableStats: false,
        //     enabledTransports: ['ws', 'wss'],
        // });
        const pusher = new Pusher('TicketKeytProd', {
            cluster: 'ap1',
            encrypted: true,
            wsHost: process.env.REACT_APP_BASE_URL?.includes("localhost") ? "ticketing-react.myevent360.com" : process.env.REACT_APP_BASE_URL?.includes("www.") ? process.env.REACT_APP_BASE_URL?.split("www.")[1]?.split("/")[0] : process.env.REACT_APP_BASE_URL?.split("//")[1]?.split("/")[0],
            wsPort: 6001,
            wssPort: 6001,
            forceTLS: true,
            disableStats: false,
            // authEndpoint: '/pusher/auth',
            auth: {
                headers: {
                    'Authorization': `Bearer ${auth_token}`,
                },
            },
        });
        pusher.connection.bind('error', function (err) {
            console.error('Pusher error:', err);
        });

        const channel = pusher.subscribe(`staff.${user_id}`);
        // console.log("vgfdfh")
        channel.bind('scanned.activity', (data) => {
            console.log('Event data:', data);
            if (changeContent === false && data?.status=="success") {
                // console.log("changeContentvvv",changeContent,changeContent===true)
                // handleClick()
                console.log("lhggfhhkgdrfhjkg",data?.status)
                const btnsecondary = document.getElementById("autoaudioplay");
                btnsecondary.autoplay = true;
                btnsecondary.load()
                btnsecondary.play();
            }
             if (changeContent === false && data?.status=="error"){
                const btnsecondary1 = document.getElementById("autoaudioplay1");
                btnsecondary1.autoplay = true;
                btnsecondary1.load()
                btnsecondary1.play();
                console.log("lhggfhhkgdrfhjkgfail",data?.status)
             }
            // setcount(count + 1)
            setdata(data?.data)
            setOpen(true);
            // setTimeout(()=>{
            //     setOpen(false);

            // },1000)
            // <SuccessPopu handleClose={handleClose} setOpen={setOpen} open={true} data={data}/>
            // setTimeout(() => {
            //     setcount(0)

            // }, 1000)
            // Update your component's state or perform other actions here
        });
        // const channel = pusher.subscribe('user-channel');
        // console.log("vgfdfh")
        // channel.bind('UserEvent', (data) => {
        //     console.log('Event data:', data);
        //     // Update your component's state or perform other actions here
        // });
        // Subscribe to the private channel
        // alert("vikalp");
        // window.Echo.channel('user-channel')
        // .listen('UserEvent', (e) => {
        //     alert("vikalp");
        //     // console.log("vvvvvv", e.data); // Log the activity data
        // });
        // window.Echo.private(`staff.${staffId}`)
        //     .listen('scanned.activity', (e) => {
        //         alert("vikalp");
        //         console.log("vvvvvv",e.data); // Log the activity data
        //     });

        // Cleanup function to unsubscribe when the component unmounts
        return () => {
            // alert("Error");
            channel.unbind_all();
            channel.unsubscribe();
            pusher.disconnect();
        };
    }, [staffId, changeContent]);
    // const handleClick = () => {
    //     const audio = new Audio("https://ticketingproduction-files.s3.ap-southeast-1.amazonaws.com/success-notification-alert_A_major.wav");
    //     audio.autoPlay = true;
    //     audio.play()
    //         .then(() => {
    //             console.log('Audio playback started successfully.');
    //         })
    //         .catch(error => {
    //             console.error('Failed to play audio:', error);
    //         });
    // }
    useEffect(() => {
        const btnsecondary = document.getElementById("vikalp");
        // btnsecondary.click();
        // console.log("btnsecondary", btnsecondary)
    }, [])
    // const SuccessPopupss = () => {
    //     console.log("vikalppopup",open)
    //     return <SuccessPopu  handleClose={handleClose} setOpen={setOpen} open={true} />
    // }
    // console.log("openvikalp", open)
    const handleClick = () => {
        const btnsecondary = document.getElementById("autoaudioplay");
        btnsecondary.autoplay = true;
        btnsecondary.load()
        btnsecondary.play();
    }
    return (
        <div>
            {/* <ReactAudioPlayer

                src="https://ticketingproduction-files.s3.ap-southeast-1.amazonaws.com/success-notification-alert_A_major.wav"
                autoPlay
                controls
                id="autoaudioplay"
            /> */}
            {/* {
                open == true && (
                    <SuccessPopu
                        handleClose={handleClose}
                        setOpen={setOpen}
                        open={open} 
                        data={data}
                        />

                )
            } */}
            {/* {count > 0 && <MusicPlayer />} */}
        </div>
    );
}

export default Soket;
