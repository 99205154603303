import React from 'react'
import { useStyles } from "./Style";
import { Link as Linkscroll } from "react-scroll";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import { useDispatch } from 'react-redux';
import { DeleteAdminStaffAPI, EditGetAdminStaffAPI } from '../../../redux/Action/Admin/AddStaff/AddStaff';
import { useParams } from 'react-router-dom';

const StaffCard = (props) => {
    const { data,setaddstaffopen,seteditcheck } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const {id}=useParams();
  const archive = JSON.parse(localStorage.getItem("archive"));
    
    const handleDelete = () => {
        dispatch(DeleteAdminStaffAPI(data?.user_id,id))

    }
    const handleEdit=()=>{
        setaddstaffopen(true)
        seteditcheck(true)
        localStorage.setItem("staffidedit",data?.user_id)
        dispatch(EditGetAdminStaffAPI(data?.user_id))
    }
    return (
        <>
            <div className={`${classes.StaffCard} staff-card`}>
                <div className='rw'>
                    <div className='cl lft'>Name</div>
                    <div className='cl ryt'>{data?.name}</div>
                </div>
                <div className='rw'>
                    <div className='cl lft'>Email</div>
                    <div className='cl ryt'>{data?.email}</div>
                </div>

             {archive!=true &&   <div className='actions'>
                    <Linkscroll
                activeClass="active"
                to="editspeaker"
                spy={true}
                smooth={true}
                offset={50}
                duration={300}
            >
                <IconButton aria-label="Edit" onClick={()=>handleEdit()}>
                    <EditNoteRoundedIcon />
                </IconButton>
            </Linkscroll>
                    <IconButton aria-label="Delete" onClick={() => handleDelete()}>
                        <DeleteIcon />
                    </IconButton>
                </div>}
            </div>
        </>
    )
}

export default StaffCard