import axios from "axios";
import { APIURL } from "../../../APIHandler/APIURL"
import { Headers } from "../../../APIHandler/HeaderAPI";
import { reloadHandler } from "../../../utlis/utlis";
import { Program_Qr_Genrate_failure, Program_Qr_Genrate_request, Program_Qr_Genrate_success } from "../../Reducer/ProgramQrGenerate/ProgramQrGenerate";

export const ProgramQrGenerateAPI = (payload,id) => async (dispatch, getState) => {
    dispatch(Program_Qr_Genrate_request())
    // const eventItemId=localStorage.getItem("eventItemId")
    const auth_token = localStorage.getItem("auth_token")
  
    try {
        const { data } = await axios.get(`${APIURL.qrprogramgenerate}/${payload}/${id==1?"id":"token"}`, {
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
                "Content-type": "application/json",  "event_slug":Headers?.event_slug,
             "event-slug":Headers?.["event-slug"],
                Authorization: `Bearer ${auth_token}`
            },
        })
        dispatch(Program_Qr_Genrate_success(data))

    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(Program_Qr_Genrate_failure(error?.response?.data))

    }
}