import axios from "axios";
import { APIURL } from "../../../APIHandler/APIURL"
import { Headers } from "../../../APIHandler/HeaderAPI";
import { reloadHandler } from "../../../utlis/utlis";
import { EventSlug_failure, EventSlug_request, EventSlug_success } from "../../Reducer/EventSlug/EventSlugSlice";

export const EventSlugAPI = (payload,id) => async (dispatch, getState) => {
    dispatch(EventSlug_request())
    const auth_token = localStorage.getItem("auth_token")
    const idcheck=id ?`${APIURL.eventslug}/${payload}/${id}`:`${APIURL.eventslug}/${payload}`
    try {

        const { data } = await axios.get(idcheck, {
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
               "event-slug":Headers?.["event-slug"],
                "Content-type": "application/json",
                Authorization: `Bearer ${auth_token}`
            },
        })
        // console.log("data", data)
        dispatch(EventSlug_success(data))
    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(EventSlug_failure(error?.response?.data))

    }
}