import { makeStyles } from '@mui/styles';
import AdminTheme from '../../Common/AdminTheme';
export const useStyles = makeStyles({

    Header: {
        background: AdminTheme.headerOverlay
    
    },

    MenuToggle: {display: "none"},
    MenuToggleClose: {display: "none"},

    NavLeft: {
        "& button":{
            color: AdminTheme.white,
            fontFamily: AdminTheme.poppins,
            borderRadius: '6px !important'   
        }

    },
    BtnActive: {
        backgroundColor: `${AdminTheme.navBtnBg} !important`
    },
    CountrySelect: {
        flexShrink: '0',
        width: '22px !important',
        height: '22px !important'
    },

    ProfilePicCard: {
        "& .MuiMenuButton-root:hover": {
            backgroundColor: "transparent",
        }
    },

    ProfilePic: {
        flexShrink: '0',
        width: '42px !important',
        height: '42px !important',
        borderRadius: '6px !important',
        background: `${AdminTheme.navBtnBg} !important`
    },

    ProfileName: {
        minWidth: '50px',
        marginLeft: '10px',
        color: AdminTheme.white,
        fontFamily: AdminTheme.poppins,
        fontWeight: "500",
        flexShrink: '0',
        "& p": {
            fontFamily: 'inherit',
            color: 'inherit',
            fontWeight: "inherit",
            fontSize: "11px",
            lineHeight: "16px",
            opacity: '0.5',
            margin: '0'
        },
        "& h6": {
            fontFamily: 'inherit',
            color: 'inherit',
            fontWeight: "inherit",
            fontSize: "13px",
            lineHeight: "19px",
            margin: '0',
            textTransform: "capitalize"
        },
    },


    AdminBreadcrumbsRow: {
        display: "flex",
        alignItems: "flex-end",
        flexWrap: "wrap",
    },

    AdminBreadcrumbs: {
        "& h1": {
            fontSize: '22px',
            lineHeight: '33px',
            fontWeight: '500',
            fontFamily: AdminTheme.poppins,
            color: '#fff',
            marginTop: '0',
            marginBottom: '5px'
        }
    },
    AdminBreadcrumbsContainer: {
        opacity: '0.7',
        "& ul": {
            lineHeight: '0px',
            margin: '0px'
        },
        "& li": {
            fontSize: '12px',
            lineHeight: '18px',
            fontWeight: '500',
            fontFamily: AdminTheme.poppins,
            color: '#fff'
        }
    },
    PageIcon: {
        height: '19px'
    },


    ProfilePicCardDropdown: {
        padding: "10px !important",
        "& li": {
            padding: "0px !important",
            border: "none !important",
            margin: "5px 0",
            "& .btn": {
                width: "100%",
                textTransform: "none !important",
                fontFamily: AdminTheme.poppins,
                fontSize: "16px !important",
                fontWeight: "500 !important",
                color: AdminTheme.textColor,
                background: AdminTheme.lightGrey,
            },
            "& .btn-logout": {
                color: AdminTheme.dangerColor,
            }
        }
    },

    '@media (max-width: 767px) and (min-width: 576px)' : {
        ProfileName: {
            marginLeft: "5px",
            marginRight: "5px",
            "& p": {
                fontSize: "8px",
                lineHeight: "16px",
            },
            "& h6": {
                fontSize: "10px",
                lineHeight: "19px",
            },
        },
        ProfilePic: {
            width: '32px !important',
            height: '32px !important',
            borderRadius: '4px !important',
        },

    },

    '@media (max-width: 575px)' : {
        MenuToggle: {
            display: "flex",
            width: "32px",
            height: "32px",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            cursor: "pointer",
            "& svg": {
                width: "28px",
                height: "28px",
            }
        },
        DV: {display: "none"},
        NavLeft: {
            minWidth: "280px",
            padding: "30px 15px",
            background: "#fff",
            flexDirection: "column",
            alignItems: "flex-start",
            "& button":{
                color: AdminTheme.black,
                height: "unset",
                justifyContent: "flex-start",
                marginTop: "15px",
            },
        },
        MenuToggleClose: {
            position: "absolute",
            right: "5px",
            top: "5px",
            display: "flex",
            width: "32px",
            height: "32px",
            justifyContent: "center",
            alignItems: "center",
            color: "#000",
            cursor: "pointer",
            "& svg": {
                width: "28px",
                height: "28px",
            }
        },

        AdminBreadcrumbsRow: {
            flexDirection: "column",
            alignItems: "flex-start",
            "& button": {
                margin: "30px 0"
            }
        },
        ProfilePic: {
            width: '38px !important',
            height: '38px !important',
            borderRadius: '4px !important',
        },

      }

})