import React, { useEffect } from 'react'
import ButtonWidthIcon from '../ButtonWithIcon/ButtonWithIcon'
import { ReactComponent as AddParticipantIcon } from '../../../Images/admin/add-user.svg'
import Modal from '@mui/material/Modal';
import { useStyles } from './Style'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Grid from '@mui/material/Grid';
import Select, { selectClasses } from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import AdminTheme from '../../Common/AdminTheme';
import IncDecButton from '../IncDecButton/IncDecButton';
import { Button } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ParticipantCard from '../ParticipantCard/ParticipantCard';
import Pic from '../../../Images/admin/picture.png'
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { AddpartcipantactivitiesAPI } from '../../../redux/Action/Admin/Addparticipant/AddParticipantActivites';
import { PartcipantsActivitesAPI } from '../../../redux/Action/Admin/PartcipantActivites/PartcipantActivities';
import AdmLoader from '../AdmLoader/AdmLoader';
import { IncreaseParticipantQuantityAPI } from '../../../redux/Action/Admin/increaseparticipantquantity/IncreaseParticipantQuantity';
import Snacbar from '../../../Components/Snackbar/Snacbar';
import { DeleteProgramAPI } from '../../../redux/Action/Admin/increaseparticipantquantity/DeleteProgram';
import ResetPasswordPopup from '../ResetPasswordPopup/ResetPasswordPopup';
import { Reload_success } from '../../../redux/Reducer/ReloadSlice';


const AddParticipantModal = (props) => {

    const classes = useStyles();
    const { setOpen, open, eventid } = props;
    // const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const [Datas, setdatas] = React.useState([])
    const AdminEventListSlice = useSelector(state => state?.AdminEventListSlice)
    const [counter, setCounter] = useState(1);
    const [counterlist, setCounterlist] = useState(0)
    const ProgramListingSlice = useSelector((state) => state?.ProgramListingSlice)
    const dispatch = useDispatch();
    const [programid, setprogramid] = useState("")
    const [programiderror, setprogramiderror] = useState(false)
    const PartcipantactivitesSlice = useSelector(state => state?.PartcipantactivitesSlice)
    const handleClose = () => {
        setOpen(false)

        dispatch(Reload_success(true))
    };
    const IncreasePartcipantQuantitySlice = useSelector(state => state?.IncreasePartcipantQuantitySlice)
    const programactivitesID = localStorage.getItem("programactivitesID")
    const [count, setcount] = useState(0)
    const [checktruefalse, setchecktruefalse] = useState(false);
    const [openmessage, setOpenmessage] = useState(false)
    const [particularprogramis, setparticularid] = useState("")
    const IncrementDecrementSlice = useSelector(state => state?.IncrementDecrementSlice?.value)
    const Event_image_url = localStorage.getItem("Eventimageurl")
    React.useEffect(() => {
        if (PartcipantactivitesSlice?.value?.data?.programs != undefined) {
            setdatas(PartcipantactivitesSlice?.value?.data?.programs)
        }
    }, [PartcipantactivitesSlice])
    // console.log("counterlist", counterlist)

    // console.log("particularprogramis", particularprogramis)

    // useEffect(() => {
    //     dispatch(PartcipantsActivitesAPI(eventid, programactivitesID))
    // }, [])
    // useEffect(() => {
    //     const obj={
    //         program_id:programactivitesID,
    //         user_id:eventid,
    //         quantity:counterlist
    //     }
    //     if(checktruefalse==true){

    //         dispatch(IncreaseParticipantQuantityAPI(obj, programactivitesID,eventid))
    //     }
    // }, [counterlist])

    const searchobj = () => {
        const formData = new FormData();
        // formData.append('program_id', +programactivitesID);
        // formData.append('user_id', eventid);
        // formData.append('quantity', IncrementDecrementSlice);
        const obj = {
            program_id: +programactivitesID,
            user_id: eventid,
            quantity: IncrementDecrementSlice
        }
        dispatch(IncreaseParticipantQuantityAPI(obj, particularprogramis, eventid))

    }

    const handleDelete = (programids) => {

        dispatch(DeleteProgramAPI(programids, eventid, setOpen))

        // DeleteProgramAPI

    }
    useEffect(() => {
        const delay = 500; // Adjust the debounce delay in milliseconds
        const timerId = setTimeout(() => {
            if (checktruefalse == true) {
                searchobj()

            }
        }, delay);

        // Cleanup the timer when the component unmounts or when inputValue changes
        return () => clearTimeout(timerId);
    }, [counterlist]);
    const handleChange = (event, newValue) => {
        // console.log("Data", Data,newValue)
        // localStorage.setItem("programactivitesID",newValue)
        setprogramid(newValue)
        setprogramiderror(false)
        // dispatch
    }

    const handleadd = () => {
        if (programid != "") {

            const formData = new FormData();
            formData.append("program_id", programid)
            formData.append("user_id", eventid)
            formData.append("quantity", counter)
            setprogramiderror(false)
            dispatch(AddpartcipantactivitiesAPI(formData, eventid, programactivitesID))
        } else if (programid == "") {
            setprogramiderror(true)
        }

    }


    useEffect(() => {
        if (IncreasePartcipantQuantitySlice?.error?.message) {
            setOpenmessage(true)
        }
    }, [IncreasePartcipantQuantitySlice])
    // console.log("IncreasePartcipantQuantitySlice", Datas)
    return (
        <>
            {
                PartcipantactivitesSlice?.loading == true && (
                    <AdmLoader />
                )
            }
            {
                IncreasePartcipantQuantitySlice?.error?.message && (
                    <Snacbar
                        open={openmessage}
                        setOpen={setOpenmessage}
                        messagetype={"error"}
                        message={(IncreasePartcipantQuantitySlice?.error?.message)}
                    />
                )
            }

            {/* <ButtonWidthIcon handleClick={() => handleOpen()} icon={<AddParticipantIcon/>} label="New Participants" /> */}
            <Modal open={open} onClose={handleClose}>
                <div className={classes.APModal}>
                    <div class={classes.CloseBtn} onClick={handleClose}>
                        <CloseRoundedIcon />
                    </div>
                    <div className={classes.APModalBody}>
                        <div className='title-rw'>
                            <h4>Add new activities</h4>
                            <ResetPasswordPopup eventid={eventid} setOpentrue={setOpen} />
                            {/* <button className={classes.BtnLink}></button> */}
                        </div>
                        <ParticipantCard
                            Image={Event_image_url}
                            Name={PartcipantactivitesSlice?.value?.data?.event_name}
                            Validity={`${PartcipantactivitesSlice?.value?.data?.start_date} to ${PartcipantactivitesSlice?.value?.data?.end_date}`}
                        />
                        {Datas?.some((data) => data?.total_can_use == 0) &&

                            <Grid className='input-container' container spacing={2}>
                                <Grid item sx={{ flex: "1" }}>
                                    <div className={classes.FormGroup}>
                                        <label className={classes.FormLabel}>Select Activities</label>
                                        <Select
                                            placeholder="Select Activities "
                                            indicator={<ArrowDropDownOutlinedIcon />}
                                            sx={{
                                                fontSize: "14px",
                                                fontWeight: "600",
                                                lineHeight: "21px",
                                                color: "#434349",
                                                fontFamily: AdminTheme.poppins,
                                                minHeight: "42px !important",
                                                borderRadius: "4px !important",
                                                background: "#F3F6F9 !important",
                                                padding: "10px 20px !important",
                                                border: "none",
                                                "&::placeholder": {
                                                    color: "#D6D6E0",
                                                    fontWeight: "400",
                                                },
                                                [`& .${selectClasses.indicator}`]: {
                                                    transition: '0.2s',
                                                    [`&.${selectClasses.expanded}`]: {
                                                        transform: 'rotate(-180deg)',
                                                    },
                                                },
                                            }}
                                            onChange={handleChange}
                                        >

                                            {
                                                Datas?.map((data) => {
                                                    return <>
                                                        {
                                                            data?.total_can_use == 0 && (

                                                                <Option value={data?.id}>{data?.name}</Option>
                                                            )
                                                        }
                                                    </>

                                                })
                                            }

                                        </Select>
                                        {
                                            programiderror == true && (
                                                <p className='error-msg'>This Field is required.</p>

                                            )
                                        }
                                    </div>

                                </Grid>
                                <Grid item>
                                    <div className={classes.FormGroup}>
                                        <label className={classes.FormLabel}>Unit</label>
                                        <IncDecButton setCounter={setCounter} counter={counter} />
                                    </div>
                                </Grid>
                                <Grid item>
                                    <ButtonWidthIcon label="Add" icon={<AddRoundedIcon />} handleClick={() => handleadd()} />
                                    {/* <Button startIcon={<AddRoundedIcon />}
                        sx={{
                            fontFamily: AdminTheme.poppins,
                            fontSize: "12px",
                            lineHeight: "18px",
                            color: AdminTheme.btnTextColor,
                            background: AdminTheme.btnBgColor,
                            width: "100%",
                            height: "46px",
                            marginTop: "20px",
                            textTransform: "none"
                        }}
                    >
                        Add
                    </Button> */}
                                </Grid>
                            </Grid>
                        }

                        <div className={classes.ActiviteisTable}>
                            <table className='custom-table'>
                                <thead>
                                    <tr>
                                        <th>Activities</th>
                                        <th>Unit</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        PartcipantactivitesSlice?.value?.data?.programs?.map((data) => {
                                            return <>
                                                {(data?.total_can_use == 0 && data?.total_used == 0) ? "" : <tr onClick={() => { setparticularid(data?.id) }}>
                                                    <td>{data?.name}</td>
                                                    <td align='center'>
                                                        <IncDecButton bg="transparent" size="small" counter={counterlist} totalcount={data?.total_can_use} setCounter={setCounterlist} setchecktruefalse={setchecktruefalse} showturefalse={true} />
                                                        {/* {data?.total_can_use} */}
                                                    </td>
                                                    <td>
                                                        <IconButton aria-label="delete" size="small" className={classes.DelBtn} onClick={() => handleDelete(data?.id)}>
                                                            <DeleteIcon fontSize="small" />
                                                        </IconButton>
                                                    </td>
                                                </tr>}
                                            </>
                                        })
                                    }


                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </Modal>
        </>
    )
}




export default AddParticipantModal