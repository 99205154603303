import * as React from 'react';
import { useStyles } from './Style'
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
export default function ParticipantDropdown(props) {
  const {selectedvalue,setSelected,handlecheckin,handleBluckRemovecheckin,handleDelete}=props;
    const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  // BulkremovecheckinProgramAPI
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
const handleNotCheckin=()=>{

}

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className='view-more'>
      <IconButton aria-describedby={id} variant="contained" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <div className={classes.Dropdown}>
            {/* <div className='txt'><FormatListBulletedIcon /> All</div> */}
            <div className='txt' onClick={()=>{handlecheckin()
              handleClose()
            }}><DoneAllIcon /> Check-in / Attendence</div>
            <div className='txt' onClick={()=>{handleBluckRemovecheckin()
              handleClose()
            }}><ClearIcon /> Not check-in</div>
            <div className='txt' onClick={()=>{handleDelete()
              handleClose()
            }}><DeleteIcon /> Delete</div>
        </div>
      </Popover>
    </div>
  );
}