import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useStyles } from "./Style";
import { useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import AddLinkIcon from '@mui/icons-material/AddLink';
import Input from '@mui/joy/Input';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import IconButton from '@mui/material/IconButton';
import { useDispatch } from "react-redux";
import { DeleteActivityBannerUrlAPI, DeleteBannerUrlAPI, UpdateBannerUrlAPI } from "../../../redux/Action/UpdateBannerUrl/UpdateBannerUrl";

const fileTypes = ["JPG", "PNG", "GIF"];

const FileInput = (props) => {
    const classes = useStyles();
    const { FileIcon, editgetimage, Text, editopen, multiimage, setmultimage, setmultimageerror, setmultimagelength } = props;

    const [file, setFile] = useState(null);
    const [files, setfiles] = useState([])
    const [filesedit, setfilesedit] = useState([])
    const [filterimageobj, setfilterimageobj] = useState([])
    const [indexcheck, setindexcheck] = useState("")
    const editactivityid = localStorage.getItem("editactivityid")
    const dispatch = useDispatch()
    const { id } = useParams();
    const url = window.location.href
    const urlevent = url?.includes("events/create-event/event-information") ? true : false
    const archive = JSON.parse(localStorage.getItem("archive"));

    useEffect(() => {
        // console.log("multiimage", multiimage)
        if (editgetimage) {
            setmultimagelength(editgetimage)
            setfilesedit(editgetimage)

        }
    }, [editgetimage])

    console.log("editgetimage", editgetimage)
    const handleChange = (fil) => {

        setFile(fil);
        const filep = []
        const filearrayimage = []
        const formData = new FormData();
        const newImages = Array.from(fil).map(file => URL.createObjectURL(file));
        const newImagesfile = Array.from(fil).map(file => (file));
        setfilterimageobj(prevImages => [...prevImages, ...newImages]);
        setfiles(prevImages => [...prevImages, ...newImages]);
        setmultimage(prevImages => [...prevImages, ...newImagesfile])
        setmultimagelength(prevImages => [...prevImages, ...newImagesfile])
        // for (let i = 0; i < fil.length; i++) {
        //     const file = fil[i];
        //     console.log("File", i + 1, ":", URL.createObjectURL(file));

        //     filep.push(URL.createObjectURL(file))
        //     filearrayimage.push(file)
        //     // setfilterimageobj(filterimageobj.push(file))


        // }
        // setfiles(filep)
        setmultimageerror(false)
        // setmultimage(filearrayimage)
        // localStorage.setItem("fileobject", JSON.stringify(filep))
        // console.log("map", filearrayimage);

    };
    // console.log("mapobj", filterimageobj)


    const [showURLInput, setShowURLInput] = useState(false);

    const [inputTitle, setInputTitle] = useState("");
    const [invalideurl, setivalidurl] = useState(false);
    function isValidUrl(string) {
        try {
            // Append http:// if no scheme is provided
            if (!string.match(/^[a-zA-Z][a-zA-Z\d+\-.]*:/)) {
                string = 'http://' + string;
            }
            const url = new URL(string);

            // Ensure the hostname includes at least one dot, and has a valid TLD
            const parts = url.hostname.split('.');
            const hasValidTld = parts.length > 1 && parts[parts.length - 1].length > 1;

            if (!hasValidTld) {
                return false;
            }

            return (url.protocol === 'http:' || url.protocol === 'https:');
        } catch (error) {
            return false;
        }
    }
    console.log("isValidUrl", isValidUrl(inputTitle))
    const handleClear = (e) => {
        if (!isValidUrl(inputTitle)) {
            return setivalidurl(true)
        }
        setivalidurl(false)
        const obj = {
            id: e,
            banner_url: inputTitle,
            event_id: id
        }
        dispatch(UpdateBannerUrlAPI(obj, id))
        setShowURLInput(false)
        setindexcheck("");
        setInputTitle("")
    };
    const handleClearinput = (e) => {
        // const obj = {
        //     id: e,
        //     banner_url: inputTitle,
        //     event_id: id
        // }
        // dispatch(UpdateBannerUrlAPI(obj))
        setShowURLInput(false)
        setindexcheck("");
        setInputTitle("")
    };
    const handleDelete = (index) => {
        if (urlevent) {

            dispatch(DeleteBannerUrlAPI(id, index))

        } else {

            dispatch(DeleteActivityBannerUrlAPI(editactivityid, index))
        }
        setfiles(files.filter((_, i) => i !== index));
        setmultimagelength(files.filter((_, i) => i !== index));

        setmultimage(files.filter((_, i) => i !== index));
    }
    console.log("multiimagemultiimage", multiimage)
    return (
        <>
            <div className={classes.FileInputBoxMultiple}>
                <div className={classes.FileInputBox}>
                    <div className={classes.FileInputLabel}>
                        <img src={FileIcon} alt="" className={classes.FileInputIcon} />
                        <p>{Text}</p>
                    </div>
                    <FileUploader
                        handleChange={handleChange}
                        name="file"
                        types={fileTypes}
                        multiple={true}
                        disabled={archive == true ? true : false}
                    />
                </div>
                {
                    files?.length > 0 || filesedit?.length > 0 ?
                        <div className="after-uploaded-file">

                            {filesedit?.map((data, i) => {
                                return <div className="img-container img-with-add-url-btn">
                                    {archive != true &&
                                        <>
                                            <span style={{ fontSize: "12px", color: "red", fontWeight: "bold", paddingRight: "10px" }}>Delete Banner and URL &nbsp; </span>

                                            <IconButton IconButton aria-label="delete" size="small" className="btn-remove" onClick={() => { handleDelete(data?.id) }}>
                                                <DeleteOutlineIcon fontSize="small" />
                                            </IconButton>
                                        </>
                                    }
                                    <div className="rw">
                                        <div className="cl"><div className="uploaded-img-card"><img src={data?.image_url} alt="" /></div></div>
                                        <div className="cl-right">
                                            {data?.url && <h6>{data?.url?.slice(0, 50)}</h6>}
                                            {urlevent && <>   {(showURLInput && indexcheck == i) ?
                                                <>
                                                    <div className='input-group'>
                                                        <Input
                                                            type="text"
                                                            placeholder="Enter URL here"
                                                            variant="soft"
                                                            className={classes.FormInput}
                                                            value={inputTitle}
                                                            onChange={(e) => {setInputTitle(e.target.value)
                                                            if(isValidUrl(inputTitle)==true){
                                                                setivalidurl(false)
                                                            }
                                                            }}
                                                        />
                                                        <span className="icon" onClick={() => handleClearinput(data?.id)} >
                                                            <CancelOutlinedIcon />
                                                        </span>
                                                    </div>
                                                    {invalideurl == true && <><span style={{ fontSize: "12px", color: "red" }}>
                                                        Please Enter valid URL.
                                                    </span>
                                                    <br />
                                                    </>
                                                    }
                                                   
                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        startIcon={<AddLinkIcon />}
                                                        sx={{ textTransform: 'unset', marginTop: '5px' }}
                                                        onClick={() => handleClear(data?.id)}
                                                    >
                                                        Save URL
                                                    </Button>
                                                </>
                                                :
                                                <>
                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        startIcon={<AddLinkIcon />}
                                                        sx={{ textTransform: 'unset' }}
                                                        onClick={() => {
                                                            if (archive != true) {
                                                                setShowURLInput(!showURLInput)
                                                                setindexcheck(i)
                                                            }
                                                        }}
                                                    >
                                                        {data?.url ? "Update URL" : "Add URL"}
                                                    </Button>
                                                </>
                                            }</>}
                                        </div>
                                    </div>
                                </div>
                            })}
                            {files?.map((data, i) => {
                                return <div className="img-container img-with-add-url-btn">
                                    <IconButton IconButton aria-label="delete" size="small" className="btn-remove" onClick={() => { handleDelete(i) }}>
                                        <DeleteOutlineIcon fontSize="small" />
                                    </IconButton>
                                    <div className="rw">
                                        <div className="cl"><div className="uploaded-img-card"><img src={data} alt="" /></div></div>
                                        <div className="cl-right">
                                            <h6>Example-banner.jpg</h6>
                                            {/* {(showURLInput && indexcheck==i) ? 
                                                    <>
                                                    <div className='input-group'>
                                                    <Input 
                                                        type="text" 
                                                        placeholder="Enter URL here" 
                                                        variant="soft" 
                                                        className={classes.FormInput} 
                                                        value={inputTitle} 
                                                        onChange={(e) => setInputTitle(e.target.value)} 
                                                    />
                                                        <span className="icon" onClick={handleClear} >
                                                            <CancelOutlinedIcon />
                                                        </span>
                                                    </div>
                                                    </>
                                                    : 
                                                    <>
                                                    <Button 
                                                    variant="contained"  
                                                    size="small" 
                                                    startIcon={<AddLinkIcon />} 
                                                    sx={{textTransform: 'unset'}}
                                                    onClick={() => {setShowURLInput(!showURLInput)
                                                        setindexcheck(i)
                                                    }}
                                                    >
                                                        Add URL
                                                    </Button>
                                                    </>
                                                } */}
                                        </div>
                                    </div>
                                </div>
                            })}
                            {/* {filesedit?.map((data) => {
                                        return <div className="cl"><div className="uploaded-img-card"><img src={data?.image_url} alt="" /></div></div>
                                    })} */}



                        </div>
                        :
                        <></>
                }

            </div>
        </>
    )
}

export default FileInput