import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
  error: {},
}

export const AdminBulkcheckinEventSlice = createSlice({
  name: 'AdminBulkcheckinEvent',
  initialState,
  reducers: {
    AdminBulkcheckinEvent_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    AdminBulkcheckinEvent_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};

    },
    AdminBulkcheckinEvent_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_AdminBulkcheckinEvent_Check: (state, action) => {
      state.value = {}
      state.error = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const {Clear_AdminBulkcheckinEvent_Check, AdminBulkcheckinEvent_failure, AdminBulkcheckinEvent_success, AdminBulkcheckinEvent_request } = AdminBulkcheckinEventSlice.actions

export default AdminBulkcheckinEventSlice.reducer