import React from 'react'
import { Button } from '@mui/material';
import AdminTheme from '../../Common/AdminTheme';
const ButtonWithIcon = (props) => {
    const { label, icon, handleClick} = props;
    return (
        <>
            <Button className='btn-with-icon'
                variant='contained'
                onClick={()=>handleClick()}
                sx={{
                    height: "40px",
                    padding: "11px 20px",
                    borderRadius: "6px",
                    fontSize: "12px",
                    fontWeight: "600",
                    lineHeight: "18px",
                    fontFamily: AdminTheme.poppins,
                    background: AdminTheme.btnBgColor,
                    color: AdminTheme.btnTextColor,
                    boxShadow: "none",
                    marginLeft: "auto",
                    textTransform: "none",
                    flexShrink: "0",
                    "& svg":{
                        height: "19px",
                        marginRight: "10px",
                        flexShrink: "0",
                    },
                    '&:hover': {
                        color: AdminTheme.btnTextColor,
                        backgroundColor: AdminTheme.btnBgColor,
                      },
                }}
                >
                {icon}
                <span>
                {label}
                </span>
                
            </Button>

        </>
    )
}

export default ButtonWithIcon;
