import React, { useEffect, useState } from 'react'
import AdminHeader from '../../Components/Header/Header'
import Input from '@mui/joy/Input';
import { useStyles } from "../../Common/FormStyle"
import Divider from '@mui/joy/Divider';
import Grid from '@mui/joy/Grid'
import { Helmet } from 'react-helmet'
import ButtonPrimary from '../../Components/ButtonPrimary/ButtonPrimary'
import ButtonWithIcon from '../../Components/ButtonWithIcon/ButtonWithIcon'
import { ReactComponent as FileIcon } from '../../../Images/admin/file-icon.svg'
import ButtonSecondary from '../../Components/ButtonSecondary/ButtonSecondary'
import { ReactComponent as EventIcon } from '../../../Images/admin/event-icon.svg'
import TabHeader from '../../Components/TabHeader/TabHeader'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { AddAdminStaffAPI, EditAdminStaffAPI, GetAdminStaffAPI } from '../../../redux/Action/Admin/AddStaff/AddStaff';
import { useNavigate, useParams } from 'react-router-dom';
import StaffCard from '../../Components/StaffCard/StaffCard';
import { GetAdminStaffSlice } from '../../../redux/Reducer/Admin/AdminStaff/GetAdminStaffSlice';
import Snacbar from '../../../Components/Snackbar/Snacbar';
import { Clear_AddAdminStaff_Check } from '../../../redux/Reducer/Admin/AdminStaff/AddAdminStaffSlice';
import { validateEmail, validatePassword, validatePasswordminlength } from '../../../utlis/Validations';
import { Clear_GetEditAdminStaff_Check } from '../../../redux/Reducer/Admin/AdminStaff/GetEditStaffSlice';
import { PerecentageCheck_success } from '../../../redux/Reducer/PercentageCheckSlice';
import ImportexportSoket from '../../../Soket/ImportexportSoket';

const AddStaff = () => {
  const classes = useStyles();
  const [showstaffPassword, setShowstaffPassword] = useState(true);
  const [name, setname] = useState("")
  const [nameerror, setnameerror] = useState(false)
  const [emailStaff, setemailstaff] = useState("")
  const [emailerror, setemailerror] = useState(false)
  const [password, setpassword] = useState("")
  const [passworderror, setpassworderror] = useState(false)
  const dispatch = useDispatch();
  const { id } = useParams();
  const [addstaffopen, setaddstaffopen] = useState(false);
  const [checknavigation, setnavigation] = useState(false)
  const navigate = useNavigate();
  const event_slug=localStorage.getItem("event_slug")
  const slugPath =  '';
  const GetAdminStaffSlice = useSelector(state => state?.GetAdminStaffSlice)
  const DeleteAdminStaffSlice = useSelector(state => state?.DeleteAdminStaffSlice)
  const AddAdminStaffSlice = useSelector(state => state?.AddAdminStaffSlice)
  const GeteditAdminStaffSlice = useSelector(state => state?.GeteditAdminStaffSlice)
  const [open, setopen] = useState(false);
  const [editcheck, seteditcheck] = useState(false);
  const staffidedit = localStorage.getItem("staffidedit")
  // AdminCreateActivitiesAPI
  const [newPassworderrorvalidation, setnewPassworderrorvalidation] = useState(false);
  const [emailformateerror, setemailformateerror] = useState("");
  const archive = JSON.parse(localStorage.getItem("archive"));
  const typecheck=localStorage.getItem("typecheck")
  
  const handleClick = () => {
    if (name == "" || emailStaff == "" ||(editcheck==false &&( validatePasswordminlength(password) || password == "" ))|| validateEmail(emailStaff)) {

      if (name == "") {
        setnameerror(true)
      }
      if (emailStaff == "") {
        setemailerror(true)
      }
      if (password == "") {
        setpassworderror(true)
      }
      if (validateEmail(emailStaff)) {
        setemailformateerror(true)
      }
      if (validatePasswordminlength(password)) {
        setnewPassworderrorvalidation(true)
      }
    }
    else if (name != "" && emailStaff != "" ) {
    // console.log("mkjhgf")
      if (editcheck == true) {
        const obj = {
          name: name,
          email: emailStaff,
          password: password
        }
        dispatch(EditAdminStaffAPI(obj, setaddstaffopen, id, setname, setemailstaff, setpassword, staffidedit))

      } else {

        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', emailStaff);
        formData.append('password', password);
        dispatch(AddAdminStaffAPI(formData, setaddstaffopen, id, setname, setemailstaff, setpassword))
      }
    }
  }
  useEffect(() => {
    if (id) {

      dispatch(GetAdminStaffAPI(id))
    } else {
      dispatch(GetAdminStaffAPI())

    }
  }, [])
  useEffect(() => {
    if (GeteditAdminStaffSlice?.value?.data != undefined && GeteditAdminStaffSlice?.value?.data != null) {
      setname(GeteditAdminStaffSlice?.value?.data?.name)
      setemailstaff(GeteditAdminStaffSlice?.value?.data?.email)
    }

  }, [GeteditAdminStaffSlice])
  // console.log("GeteditAdminStaffSlice", GeteditAdminStaffSlice)
  useEffect(() => {
    if (GetAdminStaffSlice?.value?.data?.length > 0) {
      setnavigation(true)
    } else if (GetAdminStaffSlice?.value?.data?.length == 0) {
      setnavigation(false)
    }
  }, [GetAdminStaffSlice])
  const handlenavigate = () => {
    if (id) {
      if (checknavigation == true) {

        navigate(`/admin${slugPath}/events/create-event/registration-import/${id}`)
      } else {
        // setaddstaffopen(true)
        handleClick()
      }
    } else {


      if (checknavigation == true) {

        navigate(`/admin${slugPath}/events/create-event/registration-import`)
      } else {
        // setaddstaffopen(true)
        handleClick()
      }
    }
  }

  useEffect(() => {
    if (AddAdminStaffSlice?.error?.message || DeleteAdminStaffSlice?.error?.message) {
      setopen(true)
      setTimeout(() => {
        setopen(false)
        dispatch(Clear_AddAdminStaff_Check())

      }, 3000)
    } else if (AddAdminStaffSlice?.value?.message) {
      setopen(true)
      setTimeout(() => {
        setopen(false)
        dispatch(Clear_AddAdminStaff_Check())

      }, 3000)
    }
  }, [AddAdminStaffSlice])
  useEffect(() => {
    dispatch(PerecentageCheck_success(0))
  }, [])
  // GetAdminStaffSlice
  // console.log("AddAdminStaffSlice", AddAdminStaffSlice)
  return (
    <>
      {
        (AddAdminStaffSlice?.error?.message || DeleteAdminStaffSlice?.error?.message) && (
          <Snacbar
            open={open}
            setOpen={setopen}
            messagetype={"error"}
            message={(AddAdminStaffSlice?.error?.message || DeleteAdminStaffSlice?.error?.message)}
          />

        )
      }
      {
        (AddAdminStaffSlice?.value?.message) && (
          <Snacbar
            open={open}
            setOpen={setopen}
            messagetype={"success"}
            message={(AddAdminStaffSlice?.value?.message)}
          />

        )
      }

      <Helmet>
        <title>Create New Event | Ticketing</title>
        {/* <meta name="description" content="Ticketing application" /> */}
      </Helmet>
      <ImportexportSoket />
      <div className='admin-main'>
        <AdminHeader Title="Event" PageName={`${typecheck=="new"?'Create New Event':'Edit New Event'}`} PageIcon={<EventIcon />} />

        <div className='container sec-bottom-sapce'>
          <div className='box'>
            <TabHeader active="step4" />
            <Divider sx={{ backgroundColor: '#ECF0F3' }} />
            <div className='form-container'>
              <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                <Grid sx={{ flex: 1 }}>
                  <h2 className={classes.FormTitle}>Station Information</h2>
                </Grid>

                {addstaffopen == false && archive!=true && <Grid onClick={() => {
                  setaddstaffopen(true)
                  seteditcheck(false)
                  dispatch(Clear_GetEditAdminStaff_Check())
                }}>
                  <ButtonWithIcon label="Add Station" icon={<FileIcon />} />
                </Grid>}
              </Grid>

              {addstaffopen == true && <>
                <div className={classes.FormGroup}>
                  <label className={classes.FormLabel}>Name<span>*</span></label>
                  <Input placeholder="Station Name" variant="soft" className={classes.FormInput} value={name} onChange={(e) => {
                   if(e.target.value.length<=100){ 
                    setname(e.target.value)
                    setnameerror(false)}
                  }} />
                  {
                    nameerror && (
                      <p className='error-msg'>Name is required.</p>
                    )
                  }
                </div>

                <div className={classes.FormGroup}>
                  <label className={classes.FormLabel}>Email<span>*</span></label>
                  <Input placeholder="Station Email" variant="soft" className={classes.FormInput} value={emailStaff} onChange={(e) => {
                    setemailstaff(e.target.value)
                    setemailerror(false)
                    setemailformateerror(false)
                  }} />
                  {
                    emailerror && (
                      <p className='error-msg'>Email is required.</p>
                    )
                  }
                  {
                    emailformateerror && emailerror == false && (
                      <p className='error-msg'>Please Enter valid Email.</p>
                    )
                  }
                </div>

                <div className={classes.FormGroup}>
                  {GeteditAdminStaffSlice?.value?.data != undefined && GeteditAdminStaffSlice?.value?.data != null ?
                    <label className={classes.FormLabel}>Password (Leave empty to keep the same)</label>
                    :
                    <label className={classes.FormLabel}>Password<span>*</span></label>
                  }
                  <div className='input-group'>
                    <Input type={showstaffPassword ? "text" : "password"} placeholder="Station Password" variant="soft" className={classes.FormInput} value={password} onChange={(e) => {
                      setpassword(e.target.value)
                      setpassworderror(false)
                      setnewPassworderrorvalidation(false)
                    }} />
                    <span className={`${classes.eyeIcon} icon`} onClick={() => setShowstaffPassword(!showstaffPassword)} >
                      {showstaffPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                    </span>
                  </div>
                  {
                    passworderror && (
                      <p className='error-msg'>Password is required.</p>
                    )
                  }
                  {
                    newPassworderrorvalidation && !passworderror && (
                      <p className='error-msg'>Your password must be at least 4 characters.</p>
                    )
                  }
                </div>
                <div className='text-right'>
                  <ButtonPrimary label="Save" disabledcheck={true} handleClick={() => handleClick()} />
                </div>
              </>}
              <br />

              {addstaffopen == false && <> {
                GetAdminStaffSlice?.value?.data?.length > 0 && GetAdminStaffSlice?.value?.data?.map((data) => {
                  return <StaffCard data={data} setaddstaffopen={setaddstaffopen} seteditcheck={seteditcheck} />
                })
              }
              </>
              }



              <div className='form-container-footer'>
                <ButtonSecondary label="Back" handleClick={() => navigate(-1)} />
                <ButtonPrimary label="Next Step" disabledcheck={  GetAdminStaffSlice?.value?.data?.length>0 ?true:false} disablecheck={GetAdminStaffSlice?.value?.data?.length == 0 ? true : false} handleClick={() => handlenavigate()} />
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}

export default AddStaff