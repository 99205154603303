import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useStyles } from "./Style";


const fileTypes = ["PDF"];

const FileInputsingle = (props) => {
    const classes = useStyles();
    const { FileIcon,editgetimage, Text,setpdf,pdf ,setpdferror,editopen} = props;

    const [file, setFile] = useState(null);
    const [filesedit, setfilesedit] = useState([])
    const [files, setfiles] = useState([])
    useEffect(() => {
        if(editgetimage?.length>0 && editopen==true){

            setfilesedit(editgetimage)
        }
    }, [editgetimage])
    const handleChange = (fil) => {
        setFile(URL.createObjectURL(fil));
        setpdf(fil)
        // console.log("file",fil)
        setpdferror(false)
        
        
    };
    // console.log("pdf",pdf)
//     useEffect(()=>{
//         if(pdf!=""&& pdf!=null && setpdf!=undefined && editopen==true){

//             setFile((pdf));
//         }

// },[pdf])
// console.log("filesedit",filesedit,file)
    return (
        <>
        <div className={classes.FileInputBoxSingle}>
            {
                (file!=null ||filesedit!="")?
                <div className="after-uploaded-file">
                 
                   {file!=null ?
                    <div className="iframe-container"><iframe src={file} /></div>
                  :<>
                  {
                    filesedit!="" && (

                    <div className="iframe-container"><iframe src={filesedit} /></div>
                    )
                  }
                  </> 
                   }
                    <div className={`${classes.FileInputBox} file-uploaded`}>
                        <FileUploader
                        handleChange={handleChange}
                        name="file"
                        types={fileTypes}            
                        />
                        <p>Upload New</p>
                    </div>
                </div>
                :
                <>
                <div className={classes.FileInputBox}>
                    <div className={classes.FileInputLabel}>
                        <img src={FileIcon} alt="" className={classes.FileInputIcon} />
                        <p>{Text}</p>
                    </div>
                    <FileUploader
                        handleChange={handleChange}
                        name="file"
                        types={fileTypes}
                        // multiple={true}
                    />
                </div>
                </>
            }
        </div>  
        </>
    )
}

export default FileInputsingle