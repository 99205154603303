// rearrangeactivity

import axios from "axios";
import { APIURL } from "../../../../APIHandler/APIURL"
import { Headers } from "../../../../APIHandler/HeaderAPI";
import { reloadHandler } from "../../../../utlis/utlis";
import { Program_Listing_failure, Program_Listing_request, Program_Listing_success } from "../../../Reducer/Admin/ProgramLisiting/ProgramLisitingSlice";

export const ReaarangeActivitesAPI = (id,payload) => async (dispatch, getState) => {
    const admineventid=localStorage.getItem("admineventid")
    const auth_token = localStorage.getItem("auth_token")
    // dispatch(Program_Listing_request())
    try {
        const { data } = await axios.post(`${APIURL.rearrangeactivity}/${id}`,payload,{
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
             "event-slug":Headers?.["event-slug"],
                // "Content-type": "application/json",
                "Content-type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${auth_token}`
            },
        })
        // dispatch(Program_Listing_success(data))
    } catch (error) {
        reloadHandler(error?.response?.status)
        // dispatch(Program_Listing_failure(error?.response?.status))
    }
}