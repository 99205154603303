import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
}

export const ScanActivitySlice = createSlice({
  name: 'ScanActivity',
  initialState,
  reducers: {
    Scan_Activity_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    Scan_Activity_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};

    },
    Scan_Activity_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_Scan_Activity_Check: (state, action) => {
      state.value = {}
      state.error = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const {  Clear_Scan_Activity_Check, Scan_Activity_failure,  Scan_Activity_success, Scan_Activity_request } = ScanActivitySlice.actions

export default ScanActivitySlice.reducer