import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
  error:{}
}

export const PrinterBadgeSlice = createSlice({
  name: 'PrinterBadge',
  initialState,
  reducers: {
    PrinterBadge_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    PrinterBadge_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};

    },
    PrinterBadge_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_PrinterBadge_Check: (state, action) => {
      state.value = {}
      state.error = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const {PrinterBadge_request, PrinterBadge_success, PrinterBadge_failure, Clear_PrinterBadge_Check } = PrinterBadgeSlice.actions

export default PrinterBadgeSlice.reducer