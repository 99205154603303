import { makeStyles } from '@mui/styles';
import AdminTheme from '../../Common/AdminTheme';

export const useStyles = makeStyles({

    UploadImageCard: {
        position: "relative",
        width: "200px",
        height: "160px",
        borderRadius: "4px",
        backgroundColor: AdminTheme.inputBg,
        overflow: "hidden"
    },

    UploadBtn: {
        position: "absolute",
        left: "0",
        top: "0",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        "& input": {
            opacity: "0",
            position: "absolute",
            left: "0",
            top: "0",
        },
        "& .MuiSvgIcon-root": {
            fontSize: "54px",
            color: AdminTheme.themeColor,
            marginBottom: "10px"
        },
        "& p": {
            fontSize: "11px",
            lineHeight: "16px",
            fontWeight: "400",
            color: "#464E5F",
            margin: "0",
            fontFamily: AdminTheme.poppins
        },
    },

    PreviewCard: {
        width: "100%",
        height: "100%",
    }

});