import React, { useEffect, useState } from 'react'
import Header from '../../Components/Header/Header'
import { useStyles } from "./Style";
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { ProgramDetailsAPI } from '../../redux/Action/ProgramDetails/ProgramDetails';
import { QrStaffgenratorAPI } from '../../redux/Action/QRStaffgenerator/QRStaffgenrator';
import { useParams } from 'react-router-dom';
import { ScanActivityAPI } from '../../redux/Action/ScanActivity/ScanActivity';
import { Clear_Scan_Activity_Check } from '../../redux/Reducer/ScanActivity/ScanActivity';
import { ProgramQrGenerateAPI } from '../../redux/Action/programqrgenrate/ProgramQrGenerate';
import Loader from '../../Components/Loader/Loader';
import Soket from '../../Soket/Soket';
import ButtonSecondaryUser from '../../Components/ButtonSecondaryUser/ButtonSecondaryUser';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { AttendanceStaffAPI } from '../../redux/Action/Admin/Attendance/Attendance';

const AttendanceStaff = () => {
    const classes = useStyles();

    const [changeContent, setChangeContent] = useState(true);
    const ScanActivitySlice = useSelector(state => state?.ScanActivitySlice)
    const url = window.location.href;
    const urlcheck = url?.includes("staff")
    const dispatch = useDispatch();
    const { id } = useParams();
    const eventItemId = localStorage.getItem("eventItemId")
    const AttendanceStaffSlice = useSelector(state => state?.AttendanceStaffSlice)
    const ActivityName = localStorage.getItem("ActivityName")
    useEffect(() => {
        dispatch(AttendanceStaffAPI(eventItemId))

    }, [])
    // console.log("QRStaffGeneratorSlice", ProgramQrGenrateSlice)

    const handleClick=(value)=>{
      if(value==false){
  
        const btnsecondary=document.getElementById("autoaudioplay");
        const btnsecondary1=document.getElementById("autoaudioplay1");
        btnsecondary.autoplay = true;
        btnsecondary.load()
        btnsecondary.play();
        btnsecondary1.autoplay = true;
        btnsecondary1.load()
        btnsecondary1.play();
      }
      // const audio = new Audio("https://ticketingproduction-files.s3.ap-southeast-1.amazonaws.com/success-notification-alert_A_major.wav");
      // audio.play()
      // .then(() => {
      //   console.log('Audio playback started successfully.');
      // })
      // .catch(error => {
      //   console.error('Failed to play audio:', error);
      // });
    }
    // console.log("AttendanceStaffSlice",AttendanceStaffSlice)
    return (
        <>
            {
                (AttendanceStaffSlice?.loading == true ) && (
                    <Loader />
                )
            }
            <Soket changeContent={changeContent} />
            <div className={`${classes.ParticipantInfo} main pb-0`}>
                <Header EventTitle={urlcheck ? ActivityName : "Participants info"} />
                <div>
                    <div className='container'>
                        <div className='content'>
                            <div className={classes.ParticipantInfoCard}>
                                <div className={classes.ParticipantInfoCardBody}>
                                    <Typography level="h2">Attendance</Typography>
                                    {/* <img src={qrCodeIcon} alt='' /> */}
                                    <img src={`data:image/svg+xml;base64,${btoa(AttendanceStaffSlice?.value)}`} alt='SVG IMAGE' />
                                </div>
                                <div className={classes.ParticipantInfoCardBottom}>
                                    {urlcheck ? <>
                                        <div className="staff-text">Check - in</div>
                                    </> : <>
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            spacing={1}
                                        >
                                            <h3 className={classes.TextLeft}>Status</h3>
                                            <h3 className={classes.TextRight}>Valid</h3>
                                        </Stack>
                                        {/* <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={1}
                    >
                      <h3 className={classes.TextLeft}>Valid for</h3>
                      <h3 className={classes.TextRight}>{ScanActivitySlice?.value?.data?.valid_for}</h3>
                    </Stack> */}
                                        {/*   {ScanActivitySlice?.value?.data?.table_number != "" && <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={1}
                    >
                      <h3 className={classes.TextLeft}>Table No.</h3>
                      <h3 className={classes.TextRight}>20</h3>
                    </Stack>}
                    {ScanActivitySlice?.value?.data?.seat_number != "" && <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={1}
                    >
                      <h3 className={classes.TextLeft}>Seat No.</h3>
                      <h3 className={classes.TextRight}>5</h3>
                    </Stack>}  */}
                                    </>}
                                </div>
                            </div>
                          
                        </div>


                    </div>
                </div>


                {/* {urlcheck ? <></> 
        : <>
        <div className={classes.ParticipantInfoFooter}>
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={1}
            alignItems="center"
          >
            <IconButton variant="solid" disabled className={classes.BtnIcon}>
              <ChevronLeftOutlinedIcon sx={{ fontSize: '24px' }} />
            </IconButton>
            <span>
              {initialvalue}/{finallvalue}
            </span>
            <IconButton variant="solid" disabled={finallvalue == initialvalue ? true : false} className={classes.BtnIcon} >
              <ChevronRightOutlinedIcon sx={{ fontSize: '24px' }} />
            </IconButton>
          </Stack>
        </div>
        </>
        } */}
        {urlcheck ? <>
                <ButtonSecondaryUser 
              label={changeContent ? "Disable Sound" : "Enable Sound"} 
              icon={changeContent ? <VolumeOffIcon /> : <VolumeUpIcon />} 
              type="button"
              handleClick={() => {
                setChangeContent(!changeContent)
                handleClick(!changeContent)
              }}
            />
              </>
              : <>
              <div className={classes.CheckInStatus}>
                <CheckCircleOutlineOutlinedIcon sx={{
                  fontSize: '40px',
                  textAlign: 'center',
                  color: 'inherit'
                }}
                />
                <Typography level="h4" sx={{
                  fontSize: '14px',
                  textAlign: 'center',
                  color: 'inherit',
                  fontWeight: '700',
                  marginTop: '10px'
                }}
                >Check - in Successfully</Typography>
              </div>
              </>
              }
            </div>
        </>
    )
}

export default AttendanceStaff