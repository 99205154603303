import React, { useEffect } from 'react'
import Login from './Pages/Login/Login'
import Navigation from './Navigation/Navigation'
import { useDispatch, useSelector } from 'react-redux'
import { CompanydataAPI } from './redux/Action/Companydata/Companydata'
import ColorTheme from './Common/CommonCss/ColorTheme'
import InnerDimension from './Components/InnerDimension/InnerDimension'
import { Helmet } from 'react-helmet'
import faviconlogo from './Images/favlogo.jpeg'
import Soket from './Soket/Soket'
import MusicPlayer from './Soket/Sound'
const App = () => {
  const dispatch = useDispatch();
  const CompanydataSlice = useSelector(state => state?.CompanydataSlice?.value)
  useEffect(() => {
    dispatch(CompanydataAPI())
  }, [])
  useEffect(() => {
    // var bodyElement = document.getElementsByTagName("body")[0];
    // bodyElement.style.minHeight = InnerDimension().height + "px";

    // const Header = document.getElementById("AdminHeader");
    // const TabHeader = document.getElementsByClassName("tabheader")[0];
    // const FormContainer = document.getElementsByClassName("form-container")[0];
    // let TabHeaderH = TabHeader.offsetHeight;
    // let HeaderH = Header.offsetHeight;
    // FormContainer.style.minHeight = InnerDimension().height - (HeaderH + TabHeaderH) + "px";

  }, [])
  useEffect(() => {
    if ((ColorTheme.orange == undefined) && CompanydataSlice?.data?.primary_color != undefined) {
      window.location.reload()
    }
  })
  const stripHtmlTags = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  };
  
  const descriptionContent = stripHtmlTags(CompanydataSlice?.data?.description || "");

  return (
    <div className='App'>
      <Helmet>

        <link rel="icon" href={faviconlogo} />
        <meta name="description" content={descriptionContent} />

      </Helmet>

      {/* <MusicPlayer/> */}
      <Navigation />
    </div>
  )
}

export default App
