import { makeStyles } from '@mui/styles';
import AdminTheme from '../../Common/AdminTheme';

export const useStyles = makeStyles({

    ParticipantTable: {
        background: "#fff",
        padding: "25px 30px",
        borderRadius: "6px",
        // marginTop: "-16px",
        marginTop: "16px",
        "& th, & th .Mui-active": {
            fontSize: "12px !important",
            lineHeight: "18px",
            fontWeight: "600",
            fontFamily: AdminTheme.poppins,
            color: AdminTheme.lightColor,
            whiteSpace: "nowrap",
            minWidth: "100px",
            "& svg.MuiTableSortLabel-icon": {
                display: "none",
            },
        },
        "& th:first-child": {
            textAlign: "left !important",
        },
        "& th:last-child": {
            textAlign: "right !important",
        },
        
        "& .MuiCheckbox-root": {
            color: AdminTheme.lightColor,
        },
        "& .MuiCheckbox-root.Mui-checked": {
            color: AdminTheme.themeColor,
        },
        "& .participant-card": {
            marginTop: "auto",
            marginBottom: "auto",
        },
        "&  .MuiTableContainer-root::-webkit-scrollbar": {
            height: "8px"
        },
        "&  .MuiTableContainer-root::-webkit-scrollbar-track": {
            background: AdminTheme.lightGrey, 
        },
        "& .MuiTableContainer-root::-webkit-scrollbar-thumb": {
            backgroundColor: AdminTheme.lightColor,
        },
        "& .MuiTableContainer-root::-webkit-scrollbar-thumb:hover": {
            backgroundColor: AdminTheme.themeColor,
            cursor: "grab",
        },
        "& .MuiTablePagination-root": {
            fontFamily: AdminTheme.poppins,
            marginTop: "25px",
            "& *": {
                fontFamily: "inherit"
            },
            "& p": {
                fontSize: "12px"
            }
        }
    },

    ParticipantTableHeader: {
        display: "flex",
        paddingBottom: "20px",
        "& .search-input": {
            // height: "40px !important",
            "& ::placeholder": {
                color: `${AdminTheme.textColor} !important`,
            },
            "& .MuiInput-startDecorator": {
                marginLeft: "-8px",
            }
        },
    },

    ParticipantTableHeaderLeft:{
        flex: "1",
        "& h4":{
            fontSize: "18px",
            lineHeight: "22px",
            fontFamily: AdminTheme.poppins,
            fontWeight: "500",
            color: AdminTheme.headingColor,
            marginTop: "0",
            marginBottom: "8px",
        },
        "& p":{
            fontSize: "12px",
            lineHeight: "22px",
            fontFamily: AdminTheme.poppins,
            fontWeight: "500",
            color: AdminTheme.lightColor,
            marginTop: "0",
            marginBottom: "0px",
        }
    },

    ParticipantTableHeaderRight: {
        "& .rw": {
            display: "flex",
            margin: "0 -4px",
            "& .cl": {
                padding: "0px 4px",
                "& .MuiSelect-root": {maxWidth: "400px"}
            }
        },

        "& .view-more .MuiIconButton-root , &.view-more .MuiIconButton-root:hover": {
            backgroundColor: AdminTheme.inputBg,
            borderColor: AdminTheme.inputBg,
            color: AdminTheme.headingColor,
            width: "40px",
            height: "40px",
            borderRadius: "6px",
            boxShadow: "none",
            outline: "0"
        },
        "& .view-more .MuiIconButton-root:hover": {
            boxShadow: AdminTheme.btnHoverShadow
        }


    },


    ActionRow: {
        display: "flex",
        justifyContent: "flex-end",
        "& .MuiIconButton-root": {
            background: AdminTheme.inputBg,
            color: AdminTheme.btnBgColor,
            width: "32px",
            height: "32px",
            borderRadius: "6px",
            marginLeft: "10px",
            transition: "0.5s ease"
        },
        "& .MuiIconButton-root:first-child": {
            marginLeft: "0",
        },
    },

    EventTable: {
        marginTop: "0",
    },


    PlaceHolder: {
        fontSize: "24px",
        lineHeight: "28px",
        fontFamily: AdminTheme.poppins,
        fontWeight: "500",
        color: AdminTheme.lightColor,
        padding: "90px 30px",
        textAlign: "center",
        opacity: "0.5",
    },



    IconBtnDelete: {
        "&.MuiIconButton-root, &.MuiIconButton-root:hover": {
            backgroundColor: AdminTheme.dangerColor,
            color: "#fff",
            width: "40px",
            height: "40px",
            borderRadius: "6px",
        },
        "&.MuiIconButton-root:hover": {
            boxShadow: AdminTheme.btnHoverShadow
        }
    },

    IconBtncheckin: {
        "&.MuiIconButton-root, &.MuiIconButton-root:hover": {
            backgroundColor: AdminTheme.successColor,
            color: "#fff",
            width: "40px",
            height: "40px",
            borderRadius: "6px",
        },
        "&.MuiIconButton-root:hover": {
            boxShadow: AdminTheme.btnHoverShadow
        }
    },


    '@media(max-width:1100px)':{
        ParticipantTableHeaderRight: {
            "& .rw": {
                "& .cl": {
                    "& .search-input": {maxWidth: "260px"},
                    "& .MuiSelect-root": {maxWidth: "380px"}
                }
            },
        }
    },

    '@media(max-width:1024px)':{
        ParticipantTableHeader: {
            "& .search-input, & .MuiSelect-root": {
                minWidth: "auto",
                width: "300px",
            },
        },
    },

    '@media(max-width:991px)':{
        ParticipantTableHeader: {
            "& .search-input, & .MuiSelect-root": {
                minWidth: "auto",
                width: "200px",
            },
        },
    },

    '@media(max-width:767px)':{
        ParticipantTableHeader: {
            flexDirection: "column",
            "& .search-input, & .MuiSelect-root": {
                minWidth: "auto",
                width: "100%",
            },
        },
        ParticipantTableHeaderLeft:{
            marginBottom: "10px"
        },
        ParticipantTableHeaderRight: {
            "& .rw": {
                "& .cl": {
                    flex: "1",
                    width: "50%",
                }
            }
        },
    },

    '@media(max-width:500px)':{
        ParticipantTableHeader:{
            "& .btn-with-icon": {
                minWidth: "unset",
                width: "44px",
                height: "40px",
                padding: "0",
            },
            "& .btn-with-icon svg": {
                margin: "auto"
            },
            "& .btn-with-icon span": {
                display: "none",
            }
        }
    },

    '@media(max-width:460px)':{
        ParticipantTableHeaderRight: {
            "& .rw": {
                flexDirection: "column",
                "& .cl": {
                    flex: "1",
                    width: "100%",
                    margin: "5px 0"
                }
            }
        },
    },

});