import { makeStyles } from '@mui/styles';
import AdminTheme from '../../Common/AdminTheme';
export const useStyles = makeStyles({

    PreviewTableCard: {
        background: "#F3F6F9",
        // minHeight: "194px",
        borderRadius: "4px"
    }

});