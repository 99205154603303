import React, { useEffect } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AdminTheme from '../../Common/AdminTheme';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { MobileDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

const DateInput = (props) => {
  const { start_date, setstart_time, start_time,startdatefieldcheck, onBinding, name = "", dateValue = "", allowafter = 0, programcheclk = false } = props;
  const [value, setValue] = React.useState("");
  const StartDateSlice = useSelector(state => state?.StartDateSlice?.value)
  const Startdateprogram = localStorage.getItem("Start_date")
  const Enddateprogram = localStorage.getItem("End_date")
  const archive = JSON.parse(localStorage.getItem("archive"));

  // console.log("startdatefieldcheck",startdatefieldcheck,startdatefieldcheck!=undefined &&startdatefieldcheck!="" && startdatefieldcheck!="",Startdateprogram)
  useEffect(() => {
    if (value != "") {

      onBinding({ [name]: value?.$d });
    }
  }, [value]);




  // console.log("start_date", start_date)

  const checkafterdays = () => {
    const baseDate = dayjs(StartDateSlice != "" ? StartDateSlice : new Date());
    const minSelectableDate = baseDate.add(allowafter, 'day').toDate();
    return minSelectableDate

  }
// console.log("programcheclk",programcheclk)
  const mindate = checkafterdays();
const oneDayBeforeCurrentDate = dayjs().subtract(1, 'day');

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['MobileDatePicker']}>

        {allowafter == 1 ?
         <MobileDatePicker
          sx={{
            width: "100%",
            padding: "0",
            border: "none",
            marginTop: "-8px !important",
            "& .MuiInputBase-root": {
              height: "42px !important",
              borderRadius: "4px !important",
              background: "#F3F6F9 !important",
              border: "none",
            },
            "& .MuiInputBase-input": {
              fontFamily: AdminTheme.poppins,
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "21px",
              color: "#434349",
              padding: "10px 20px",
              "&::placeholder": {
                color: "#D6D6E0",
                fontWeight: "400",
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none"
            }
          }}
          minDate={programcheclk==true ? dayjs(Startdateprogram) : dayjs(mindate)}
          maxDate={programcheclk==true ? dayjs(Enddateprogram) : null}

          disablePast={true}
          value={start_date != undefined && start_date != "" ? dayjs(start_date?.dob) : dateValue?.dob ? dayjs(dateValue?.dob) : ""}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          disabled={archive==true?true:false}
        /> :allowafter==2 ?<MobileDatePicker
          sx={{
            width: "100%",
            padding: "0",
            border: "none",
            marginTop: "-8px !important",
            "& .MuiInputBase-root": {
              height: "42px !important",
              borderRadius: "4px !important",
              background: "#F3F6F9 !important",
              border: "none",
            },
            "& .MuiInputBase-input": {
              fontFamily: AdminTheme.poppins,
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "21px",
              color: "#434349",
              padding: "10px 20px",
              "&::placeholder": {
                color: "#D6D6E0",
                fontWeight: "400",
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none"
            }
          }}
          minDate={programcheclk==true ?(startdatefieldcheck!=undefined &&startdatefieldcheck!="" && startdatefieldcheck!="")? dayjs(startdatefieldcheck): dayjs(Startdateprogram) : dayjs(mindate)}
          maxDate={programcheclk==true ? dayjs(Enddateprogram) : null}

          disablePast={true}
          value={start_date != undefined && start_date != "" ? dayjs(start_date?.dob) : dateValue?.dob ? dayjs(dateValue?.dob) : ""}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          disabled={archive==true?true:false}

        />
        
        
         :
          <MobileDatePicker
            sx={{
              width: "100%",
              padding: "0",
              border: "none",
              marginTop: "-8px !important",
              "& .MuiInputBase-root": {
                height: "42px !important",
                borderRadius: "4px !important",
                background: "#F3F6F9 !important",
                border: "none",
              },
              "& .MuiInputBase-input": {
                fontFamily: AdminTheme.poppins,
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "21px",
                color: "#434349",
                padding: "10px 20px",
                "&::placeholder": {
                  color: "#D6D6E0",
                  fontWeight: "400",
                },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none"
              }
            }}
            // minDate={dayjs(mindate)}
            minDate={programcheclk==true ? dayjs(Startdateprogram) : oneDayBeforeCurrentDate}
            maxDate={programcheclk==true ? dayjs(Enddateprogram) : null}
            // disablePast={true}
            value={start_date != undefined && start_date != "" ? dayjs(start_date?.dob) : dateValue?.dob ? dayjs(dateValue?.dob) : ""}
            onChange={(newValue) => {
              setValue(newValue);
            }}
            disabled={archive==true?true:false}

          />
        }
      </DemoContainer>
    </LocalizationProvider>
  )
}

export default DateInput
