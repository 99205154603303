import { makeStyles } from '@mui/styles';
import ColorTheme from '../../Common/CommonCss/ColorTheme';
import InnerDimension from '../../Components/InnerDimension/InnerDimension';

export const useStyles = makeStyles({

    ParticipantInfo: {
        minHeight: `${InnerDimension().height}px`,
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        "& .content": {
            minHeight: `calc(${InnerDimension().height}px - 40%)`,
        },
        "& .btn-secondary": {
            margin: "40px auto 0px auto !important",
            maxWidth: "311px",
        }
    },



    ParticipantInfoCard: {
        backgroundColor: "#F4F6F8",
        borderRadius: "20px",
        padding: "16px",
        maxWidth: "311px",
        margin: "auto"
    },

    ParticipantInfoCardBody: {
        textAlign: "center",
        paddingTop: "42px",
        paddingBottom: "96px",
        "& h2": {
            textAlign: "center",
            marginBottom: "16px",
            fontSize: "20px",
            lineHeight: "24px",
            color: ColorTheme.textColor,
            fontWeight: "700"
        },
        "& img": {
            width: "210px",
            height: "210px",
            marginLeft: "auto",
            marginRight: "auto"
        }
    },
    ParticipantInfoCardBottom:{
        borderTop: "2px dashed #dfdfdf",
        position: "relative",
        paddingTop: "32px",
        "&::after":{
            content: "''",
            display: "block",
            width: "46px",
            height: "46px",
            backgroundColor: "#fff",
            position: "absolute",
            top: "-23px",
            left: "calc(-23px - 15px)",
            borderRadius: "100%"
        },
        "&::before":{
            content: "''",
            display: "block",
            width: "46px",
            height: "46px",
            backgroundColor: "#fff",
            position: "absolute",
            top: "-23px",
            right: "calc(-23px - 15px)",
            borderRadius: "100%"
        },
        "& .staff-text": {
            fontFamily: ColorTheme.publicSans,
            color: ColorTheme.textColor,
            fontWeight: "700",
            fontSize: "24px",
            lineHeight: "28px",
            margin: "auto 0",
            paddingBottom: "20px",
            textAlign: "center",
        }
    },
    TextLeft:{
        fontFamily: ColorTheme.publicSans,
        color: ColorTheme.textColor,
        fontWeight: "700",
        fontSize: "14px",
        lineHeight: "16px",
        flexShrink: "0",
        margin: "auto 0",
        padding: "5px 0"
    },
    TextRight:{
        fontFamily: ColorTheme.publicSans,
        color: ColorTheme.textColor,
        fontWeight: "300",
        fontSize: "14px",
        lineHeight: "16px",
        margin: "auto 0",
        padding: "5px 0"
    },
    
    CheckInStatus: {
        textAlign: "center",
        marginTop: "20px",
        color: ColorTheme.successColor,
        "& .MuiSvgIcon-fontSizeMedium": {
            width: "96px",
            height: "96px",
        }
    },
    ParticipantInfoFooter: {
        padding: "30px 40px",
        marginTop: "auto",
        "& span": {
            fontFamily: ColorTheme.publicSans,
            color: ColorTheme.textColor,
            fontWeight: "700",
            fontSize: "18px",
            lineHeight: "22px"
        },
        "@media(max-width:340px)":{
            padding: "20px 40px",
        }
    },
    BtnIcon: {
        backgroundColor: `${ColorTheme.orange} !important`, 
        color: `${ColorTheme.BtnTextColor} !important`, 
        width: '48px', 
        height: '48px', 
        borderRadius: '10px',
        "&:disabled": {
            opacity: "0.35"
        }
    },

    // CheckInStatus: {

    // }

});