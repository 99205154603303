import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
}

export const VouchersSlice = createSlice({
  name: 'Vouchers',
  initialState,
  reducers: {
    Vouchers_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    Vouchers_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};

    },
    Vouchers_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_Vouchers_Check: (state, action) => {
      state.value = {}
      state.error = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const {  Vouchers_request, Vouchers_success,  Vouchers_failure, Clear_Vouchers_Check } = VouchersSlice.actions

export default VouchersSlice.reducer