import React, { useEffect, useState } from 'react'
import { useStyles } from "./Style";
import Typography from '@mui/joy/Typography';
import Input from '@mui/joy/Input';
import Buttonprimary from '../../Components/Button/Button';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { ChangePasswordAPI } from '../../redux/Action/ChangePassword/ChangePassword';
import Loader from '../../Components/Loader/Loader';
import { useNavigate } from 'react-router-dom';
import { Clear_ChangePassword_Details } from '../../redux/Reducer/ChangePassword/ChangePassword';
import Snacbar from '../../Components/Snackbar/Snacbar';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';

const ChangePassword = () => {
    const classes = useStyles();
    const company_data = JSON.parse(localStorage.getItem("company_data"));
    const [showPassword, setShowPassword] = useState(false);
    const [shownewPassword, setShownewPassword] = useState(false);
    const [showconfirmPassword, setShowconfirmPassword] = useState(false);
    const [currentPassword, setcurrentPassword] = useState("")
    const [currentPassworderror, setcurrentPassworderror] = useState(false)
    const [newPassword, setnewPassword] = useState("")
    const [newPassworderror, setnewPassworderror] = useState(false)
    const [ConfirmPassword, setConfirmPassword] = useState("")
    const [ConfirmPassworderror, setConfirmPassworderror] = useState(false)
    const dispatch = useDispatch();
    const [open, setopen] = useState(false)
    const ChangePasswordSlice = useSelector(state => state?.ChangePasswordSlice)
    const navigate = useNavigate();
    // ChangePasswordAPI

    const roles = localStorage.getItem("roles")
    const is_password_set = localStorage.getItem("is_password_set")
    const url = window.location.href;
    const urlchangepassword = url.includes("change-password") ? true : false
    const [ChangePasswordtimecheck, setchangepasswordtimecheck] = useState(false);

    useEffect(() => {
        if (roles == "user" && is_password_set && (is_password_set == "null" || is_password_set == null)) {
            setchangepasswordtimecheck(false)
        } else {
            setchangepasswordtimecheck(true)
        }
    })
    const handleSubmit = (e) => {
        e.preventDefault()
        if (currentPassword == "" || newPassword == "" || ConfirmPassword == "") {
            if (currentPassword == "") {
                setcurrentPassworderror(true)
            }
            if (newPassword == "") {
                setnewPassworderror(true)
            }
            if (ConfirmPassword == "") {
                setConfirmPassworderror(true)
            }
        }
        else if (currentPassword != "" && newPassword != "" && ConfirmPassword != "") {

            // const formData = new FormData();
            const obj = {
                current_password: currentPassword,
                new_password: newPassword,
                confirm_password: ConfirmPassword
            }
            // formData.append('current_password', currentPassword);
            // formData.append('new_password', newPassword);
            // formData.append('confirm_password', ConfirmPassword);

            dispatch(ChangePasswordAPI(obj))
        }

    }
    useEffect(() => {
        if (ChangePasswordSlice?.value?.message) {
            navigate("/")
        } else if (ChangePasswordSlice?.error?.message) {
            setopen(true)
            setTimeout(() => {
                dispatch(Clear_ChangePassword_Details())
            }, 3000)
        }
    }, [ChangePasswordSlice])
    // console.log("ChangePasswordSlice", ChangePasswordSlice)
    return (
        <>
            {
                ChangePasswordSlice?.loading && (
                    <Loader />
                )
            }
            {
                (ChangePasswordSlice?.error?.message) && (
                    <Snacbar
                        open={open}
                        setOpen={setopen}
                        messagetype={"error"}
                        message={(ChangePasswordSlice?.error?.message)}
                    />

                )
            }
            <div className={`${classes.Login} main pb-0`} style={{ backgroundImage: `url(${company_data?.banner})` }}>
                <div className={classes.header}>
                    <img src={company_data?.banner} alt="" className='cover' />
                </div>
                {
                    ChangePasswordtimecheck == true && (
                        <button className='btn btn-back' onClick={() => { navigate(-1) }}><KeyboardBackspaceOutlinedIcon />Back</button>

                    )
                }
                <div className={`${classes.loginWrapper} login-card`}>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <Typography level="h2">Change Password</Typography>
                        <div className="form-group">
                            <label className={`${classes.Label} form-label`}>Current Password</label>
                            <div className='input-group'>
                                <Input id="ChangePwd" type={showPassword ? "text" : "password"} placeholder="Password" className={`${classes.Input} form-input`} value={currentPassword} onChange={(e) => {
                                    setcurrentPassword(e.target.value)
                                    setcurrentPassworderror(false)
                                }} />
                                <label htmlFor="ChangePwd" className={`${classes.eyeIcon} icon`} onClick={() => setShowPassword(!showPassword)} >
                                    {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                </label>
                            </div>
                            {
                                currentPassworderror && (
                                    <p className='error-msg'>This field is required.</p>
                                )
                            }

                        </div>
                        <div className="form-group">
                            <label className={`${classes.Label} form-label`}>New Password</label>
                            <div className='input-group'>
                                <Input id="ShowPwd" type={shownewPassword ? "text" : "password"} placeholder="Password" className={`${classes.Input} form-input`} value={newPassword} onChange={(e) => {
                                    setnewPassword(e.target.value)
                                    setnewPassworderror(false)
                                }} />
                                <label htmlFor="ShowPwd" className={`${classes.eyeIcon} icon`} onClick={() => setShownewPassword(!shownewPassword)} >
                                    {shownewPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                </label>
                            </div>
                            {
                                newPassworderror && (
                                    <p className='error-msg'>This field is required.</p>
                                )
                            }
                        </div>
                        <div className="form-group">
                            <label className={`${classes.Label} form-label`}>Confirm Password</label>
                            <div className='input-group'>
                                <Input id="ConfirmPwd" type={showconfirmPassword ? "text" : "password"} placeholder="Password" className={`${classes.Input} form-input`} value={ConfirmPassword} onChange={(e) => {
                                    setConfirmPassword(e.target.value)
                                    setConfirmPassworderror(false)
                                }} />
                                <label htmlFor="ConfirmPwd" className={`${classes.eyeIcon} icon`} onClick={() => setShowconfirmPassword(!showconfirmPassword)} >
                                    {showconfirmPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                </label>
                            </div>
                            {
                                ConfirmPassworderror && (
                                    <p className='error-msg'>This field is required.</p>
                                )
                            }
                        </div>
                        <Buttonprimary disabledcheck={true} label={'Change Password'} type="submit" />
                    </form>
                </div>
            </div>
        </>
    )
}

export default ChangePassword