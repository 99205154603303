export const Hipster_Base_URL = process.env.REACT_APP_BASE_URL + "/api/v1";
export const Hipster_Base_URL_2 = process.env.REACT_APP_BASE_URL;

const url = window.location.href;
// const url ='https://www.eventsprog.com'
// const url='https://hiking-trails.eventsprog.com'
// const url='https://hikings-trails.eventsprog.com'
const withoutProtocol = url.replace(/^https?:\/\//, '');
const parts = withoutProtocol.split('.');
console.log(parts[2]?.split('/')[0])
const event_slug=localStorage.getItem("event_slug")
export const Headers = {
    "host-url": parts?.length==3 && !url?.includes("www.") ?parts[1]+"."+parts[2]?.split('/')[0]: url?.includes("localhost") ?"ticketing-react.myevent360.com" :url?.includes("www.")? url?.split("www.")[1]?.split("/")[0] :url?.split("//")[1]?.split("/")[0],
    // "host-url": "staging.eventsprog.com",
    // "host-url": parts[1]+"."+parts[2]?.split('/')[0],
    "language": "en",
    "event-slug": url?.includes("www.") ? null: parts?.length==3? parts[0]:null

}