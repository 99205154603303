import React, { useEffect } from 'react'
import AdminHeader from '../../Components/Header/Header'
import ButtonTab from '../../Components/ButtonTab/ButtonTab'
import EventInfoIconActive from '../../../Images/admin/event-info-icon-active.svg'
import AddProgramIcon from '../../../Images/admin/add-program-icon.svg'
import RegistrationImportIcon from '../../../Images/admin/registration-import-icon.svg'
import AddSpeakerIcon from '../../../Images/admin/add-speaker-icon.svg'
import { ReactComponent as Arrow } from '../../../Images/admin/arrow-right.svg'
import { ReactComponent as ArrowActive } from '../../../Images/admin/arrow-right-active.svg'
import Input from '@mui/joy/Input';
import { useStyles } from "../../Common/FormStyle"
import Divider from '@mui/joy/Divider';
import TextEditor from '../../Components/TextEditor/TextEditor'
import Grid from '@mui/joy/Grid'
import DateInput from '../../Components/DatePicker/DateInput'
import TimeInput from '../../Components/TimePicker/TimeInput'
import TimeZone from '../../Components/TimeZone/TimeZone'
import { Helmet } from 'react-helmet'
import ButtonPrimary from '../../Components/ButtonPrimary/ButtonPrimary'
import { ReactComponent as EventIcon } from '../../../Images/admin/event-icon.svg'
import { useDispatch } from 'react-redux'
import { AdminSampleExportAPI } from '../../../redux/Action/Admin/SampleExport/SampleExport'
import EventInfo from './EventInfo'
import TabHeader from '../../Components/TabHeader/TabHeader'

const EventInformation = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const typecheck=localStorage.getItem("typecheck")

  useEffect(() => {
    // dispatch(AdminSampleExportAPI())
  }, [])

  return (
    <>
      <Helmet>
        <title>Create New Event | Ticketing</title>
        {/* <meta name="description" content="Ticketing application" /> */}
      </Helmet>
      <div className='admin-main'>
        <AdminHeader Title="Event" PageName={`${typecheck=="new"?'Create New Event':'Edit New Event'}`} PageIcon={<EventIcon />} />

        <div className='container sec-bottom-sapce'>
          <div className='box'>
            <TabHeader active="step1"/>
            <Divider sx={{ backgroundColor: '#ECF0F3' }} />
            <EventInfo/>
          </div>
        </div>
      </div>
    </>
  )
}

export default EventInformation