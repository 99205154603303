import { makeStyles } from '@mui/styles';
import ColorTheme from '../../Common/CommonCss/ColorTheme';
export const useStyles = makeStyles({


    SlideCard: {
        borderRadius: "16px",
        overflow: "hidden",
        background: ColorTheme.lightGrey,
    },
    SlideCard1: {
    display:"inline-block",
    width:"100%"
    },
    HomeSlider:{
        marginLeft: "20px",
        "& .slick-dots": {
            lineHeight: "0",
            bottom: "calc(-4px - 0px) !important",
        },
        "& li":{
            "& button":{
                padding: "0",
                "& svg": {
                    width: "100%",
                    height: "100%",
                    "& .inner, & circle": {
                        fill: ColorTheme.orange,
                    }
                }
            },
            "& button:before": {
                display: "none"
            },
        },
        "& li.slick-active":{
                "& button svg .inner":{
                    fill: "#fff"
            }
        },
        "&.single-slide": {
            marginRight: "20px",
            paddingBottom: "0px",
            "& .slick-slide, & .slick-track":{
                minWidth: "100%",
            }
        }
    },


    "@media(min-width: 1025px)": {
        SlideCard: {
            height: "350px",
        },
        HomeSlider:{
            marginBottom: "40px",
            paddingBottom: "40px",
            "& li":{
                "& button":{
                    width: "18px",
                    height: "18px",
                }
            },
        },
    },
    "@media(max-width: 1024px) and (min-width: 768px)": {
        SlideCard: {
            height: "250px",
        },
        HomeSlider:{
            marginBottom: "30px",
            paddingBottom: "30px",
            "& li":{
                "& button":{
                    width: "16px",
                    height: "16px",
                }
            },
        },
    },
    "@media(max-width: 767px) and (min-width: 576px)": {
        SlideCard: {
            height: "200px",
        },
        HomeSlider:{
            marginBottom: "30px",
            paddingBottom: "30px",
            "& li":{
                "& button":{
                    width: "14px",
                    height: "14px",
                }
            },
        },
    },

    "@media(max-width: 575px) and (min-width: 431px)": {
        SlideCard: {
            height: "180px",
        },
        HomeSlider:{
            marginBottom: "20px",
            paddingBottom: "20px",
            "& li":{
                "& button":{
                    width: "10px",
                    height: "10px",
                }
            },
        },
    },

    "@media(max-width: 430px)": {
        SlideCard: {
            height: "156px",
        },
        HomeSlider:{
            marginBottom: "20px",
            paddingBottom: "20px",
            "& li":{
                "& button":{
                    width: "7px",
                    height: "7px",
                }
            },
        },
    },
    "@media(max-width: 340px)": {
        SlideCard: {
            height: "120px",
        },
        HomeSlider:{
            marginLeft: "15px",
            "&.single-slide": {
                marginRight: "15px",
            }
        }
    }
});