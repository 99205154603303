import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import AdminHeader from '../../Components/Header/Header'
import { ReactComponent as DashboardIcon } from '../../../Images/admin/dashboard-icon.svg'
import StatsCard from '../../Components/StatsCard/StatsCard'
import ActivitySlider from '../../Components/ActivitySlider/ActivitySlider'
import Grid from '@mui/joy/Grid';
import { useDispatch, useSelector } from 'react-redux'
import { AdminEventListAPI } from '../../../redux/Action/Admin/EventList/EventList'
import ParticipantsTable from '../../Components/ParticipantsTable/ParticipantsTable'
import AllEvents from '../Events/AllEvents'
import { PartcipantsProgramListingAPI } from '../../../redux/Action/Admin/ParticipantProgramLisiting/ParticipantProgram'
import AdmLoader from '../../Components/AdmLoader/AdmLoader'
import Snacbar from '../../../Components/Snackbar/Snacbar'
import { Clear_Add_Participant_Activities_Check } from '../../../redux/Reducer/Admin/Addpartcipantactivities/Addpartcipantactivities'
import { Clear_Admin_Create_Event_Check } from '../../../redux/Reducer/Admin/CreateEvents/CreateEvents'
import { Clear_Admin_Single_Delete_Event_Check } from '../../../redux/Reducer/BulkDeleteEvent/SingleEventDelete'
import { Clear_Admin_Bulk_Delete_Event_Check } from '../../../redux/Reducer/BulkDeleteEvent/BulkDeleteEvent'
import { ArchiveEvent } from '../../../redux/Action/Admin/ArchiveEvents/ArchiveEvent'
import ArchiveTable from '../../Components/ParticipantsTable/Archive'
import EventManagerTable from '../../Components/ParticipantsTable/EventManager'
import { EventManagerListAPI } from '../../../redux/Action/Admin/EventManager.js/EventManager'
import { useParams } from 'react-router-dom'
import { Clear_Bulk_Delete_Participant_Check } from '../../../redux/Reducer/Admin/ParticipantActivitesList/BulkDeleteParticipant'
import { Clear_AdminBulkcheckinEvent_Check } from '../../../redux/Reducer/AdminBluckCheckin/AdminBluckCheckinSlice'
import { PerecentageCheck_success } from '../../../redux/Reducer/PercentageCheckSlice'

const Dashboard = () => {
  const dispatch = useDispatch()
  const [activeclass, setactiveclass] = useState(1);
  const activityclass = JSON.parse(localStorage.getItem("activityclass"))
  const admineventid = localStorage.getItem("admineventid")
  const IncreasePartcipantQuantitySlice = useSelector(state => state?.IncreasePartcipantQuantitySlice)
  const AddParticipantActivitiesSlice = useSelector(state => state?.AddParticipantActivitiesSlice)
  const DashboardListSlice = useSelector(state => state?.DashboardListSlice)
  const [open, setopen] = useState(false);
  const [loaderopen, setloaderopen] = useState(false);
  const SingleDeleteEventSlice = useSelector(state => state?.SingleDeleteEventSlice)
  const AdminBulkDeleteEventSlice = useSelector(state => state?.AdminBulkDeleteEventSlice)
  const PartcipantSlice = useSelector(state => state?.PartcipantSlice)
  const BulkDeleteParticipantSlice = useSelector(state => state?.BulkDeleteParticipantSlice)
  const AdminBulkcheckinEventSlice = useSelector(state => state?.AdminBulkcheckinEventSlice)
  const rolecheck = localStorage.getItem("rolecheck")
  const ReloadSlice = useSelector(state => state?.ReloadSlice?.value)

  const { slug } = useParams();
  useEffect(() => {
    if (activityclass != undefined && activityclass != null) {

      setactiveclass(activityclass)
    }
  }, [activityclass])
  useEffect(() => {
    dispatch(PerecentageCheck_success(0))
  }, [])
  useEffect(() => {
    if (AddParticipantActivitiesSlice?.error?.message) {
      setopen(true)
      setTimeout(() => {
        dispatch(Clear_Add_Participant_Activities_Check())
      }, 3000)
    }
  }, [AddParticipantActivitiesSlice])
  useEffect(() => {
    if (SingleDeleteEventSlice?.value?.message || AdminBulkDeleteEventSlice?.value?.message) {
      setopen(true)
      setTimeout(() => {
        dispatch(Clear_Admin_Bulk_Delete_Event_Check())
        dispatch(Clear_Admin_Single_Delete_Event_Check())
        dispatch(Clear_Bulk_Delete_Participant_Check())
      }, 3000)
    }
  }, [SingleDeleteEventSlice, AdminBulkDeleteEventSlice])
  useEffect(() => {
    if (AdminBulkcheckinEventSlice?.value?.message) {
      setopen(true)
      setTimeout(() => {
        dispatch(Clear_AdminBulkcheckinEvent_Check())
      }, 3000)
    }
  }, [AdminBulkcheckinEventSlice])
  useEffect(() => {
    dispatch(ArchiveEvent())
    dispatch(EventManagerListAPI())
    dispatch(AdminEventListAPI())
    localStorage.removeItem("archive")
    dispatch(Clear_Admin_Create_Event_Check())
  }, [])
  useEffect(() => {
    if (DashboardListSlice?.loading == true || AddParticipantActivitiesSlice?.loading == true || AdminBulkcheckinEventSlice?.loading == true) {
      setloaderopen(true)
      setTimeout(() => {
        setloaderopen(false)

      }, 1500)
    }
  }, [DashboardListSlice, AddParticipantActivitiesSlice, AdminBulkcheckinEventSlice])
  console.log("slug", slug)
  useEffect(() => {
    if (rolecheck == "Event Manager") {
      localStorage.removeItem("rolecheck")
      window.location.reload()

    }
  })
  console.log("AdminBulkcheckinEventSlice", ReloadSlice)
  return (
    <>

      {
        (PartcipantSlice?.loading == true && ReloadSlice == true ) && (
          <AdmLoader />
        )
      }
      {
        ( BulkDeleteParticipantSlice?.loading==true  ) && (
          <AdmLoader />
        )
      }
      {(AddParticipantActivitiesSlice?.error?.message) && (


        <Snacbar
          open={open}
          setOpen={setopen}
          messagetype={"error"}
          message={(AddParticipantActivitiesSlice?.error?.message)}
        />
      )}
      {(SingleDeleteEventSlice?.value?.message || AdminBulkDeleteEventSlice?.value?.message) && (


        <Snacbar
          open={open}
          setOpen={setopen}
          messagetype={"success"}
          message={(SingleDeleteEventSlice?.value?.message || AdminBulkDeleteEventSlice?.value?.message)}
        />
      )}
      {(BulkDeleteParticipantSlice?.value?.message) && (


        <Snacbar
          open={open}
          setOpen={setopen}
          messagetype={"success"}
          message={(BulkDeleteParticipantSlice?.value?.message)}
        />
      )}
      {(AdminBulkcheckinEventSlice?.value?.message) && (


        <Snacbar
          open={open}
          setOpen={setopen}
          messagetype={"success"}
          message={(AdminBulkcheckinEventSlice?.value?.message)}
        />
      )}
      <Helmet>
        <title>Dashboard | Ticketing</title>
        {/* <meta name="description" content="Ticketing application" /> */}
      </Helmet>
      <div className='admin-main'>
        <AdminHeader Title={activeclass == 2 ? "Events" : activeclass == 1 ? "Dashboard" : activeclass == 3 ? "Event Archive" : "Event Management"} PageName={activeclass == 2 ? "All Events" : activeclass == 1 ? "The Creativity Conference" : activeclass == 3 ? "Event Archive" : "Event Management"} PageIcon={<DashboardIcon />} setactiveclass={setactiveclass} activeclass={activeclass} />
        <div className='container sec-bottom-sapce'>
          <div className='dbbox'>
            {activeclass == 1 && <Grid container spacing={2} sx={{ flexGrow: 1 }}>
              <Grid md={4} xs={12}>
                <StatsCard datalist={DashboardListSlice?.value?.data} />
              </Grid>
              <Grid md={8} xs={12} sx={{ paddingLeft: "32px" }}>
                <ActivitySlider datalist={DashboardListSlice?.value?.data?.activity} />
              </Grid>
            </Grid>}
            <div className=''>
              {
                activeclass == 2 ? <AllEvents /> : activeclass == 1 ?
                  <ParticipantsTable /> : activeclass == 3 ? <ArchiveTable /> : <EventManagerTable />
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard