import React, { useEffect, useState } from 'react'
import { Route, Routes, useNavigate } from "react-router-dom";
import Login from '../Pages/Login/Login';
import Home from '../Pages/Home/Home';
import Ticket from '../Pages/Ticket/Ticket';
import AboutEvent from '../Pages/AboutEvent/AboutEvent';
import PrivateRoutes from '../utlis/PrivateRoute/PrivateRoutes';
import CheckInError from '../Pages/CheckInError/CheckInError';
import ParticipantInfo from '../Pages/ParticipantInfo/ParticipantInfo';
import StaffVouchers from '../Pages/StaffVouchers/StaffVouchers';
import AdminHome from '../AdminPanel/Pages/AdminHome';
import AdminDashboard from '../AdminPanel/Pages/AdminDashboard/AdminDashboard'
import EventInformation from '../AdminPanel/Pages/Events/EventInformation';
import AddProgram from '../AdminPanel/Pages/Events/AddProgram';
import RagistrationImport from '../AdminPanel/Pages/Events/RegistrationImport';
import QRCodeScanner from '../Pages/QRScanner/Qrscanner';
import PrivateRouteStaff from '../utlis/PrivateRoute/PrivateRouteStaff';
import PrivateRouteAdmin from '../utlis/PrivateRoute/PrivateRoutesAdmin';
import AddSpeaker from '../AdminPanel/Pages/Events/AddSpeaker';
import AllEvents from '../AdminPanel/Pages/Events/AllEvents';
import ForgotPassword from '../Pages/Login/ForgotPassword';
import SetPassword from '../Pages/Login/SetPassword';
import ChangePassword from '../Pages/Login/ChangePassword';
import PageNotFound from '../Pages/PageNotFound/PageNotFound';
import AddStaff from '../AdminPanel/Pages/Events/AddStaff';
import PreventDesktop from '../Pages/PreventDesktop/PreventDesktop';
import {
  isMobile,
  isTablet,
  isDesktop,
  isBrowser,
  isIE,
  isEdge,
  isChrome,
  isFirefox,
  isSafari,
  isOpera,
  isAndroid,
  isIOS,
} from 'react-device-detect';
import LogoutSoket from '../Soket/LogoutSoket';
import AttendanceStaff from '../Pages/ParticipantInfo/Attendance';
import AttendanceQrScane from '../Pages/QRScanner/AttendanceQrScane';
import { useSelector } from 'react-redux';


const Navigation = () => {

  const URL = window.location.href;
  const URLCheck = URL.includes("admin") ? true : false;
  const URLCheckStaff = URL.includes("staff") ? true : false;
  const urlhomecheck = URL?.charAt(URL?.length - 1) == "/" ? true : false;
  const roles = localStorage.getItem("roles")
  const is_password_set = localStorage.getItem("is_password_set")
  const auth_token = localStorage.getItem("auth_token")
  const urlchangepassword = URL.includes("change-password") ? true : false
  const navigate = useNavigate();
  const CompanydataSlice = useSelector(state => state?.CompanydataSlice)
  // const [isDesktop, setDesktop] = useState(window.innerWidth <= 1024);
  // const mobileWidth = () => {
  //   setDesktop(window.innerWidth <= 1024);
  // };
  // useEffect(() => {
  //   window.addEventListener("resize", mobileWidth);
  //   return () => window.removeEventListener("resize", mobileWidth);
  // }, []);
  console.log("CompanydataSlice", CompanydataSlice?.error?.message == "Event not found or event slug not found")
  useEffect(() => {
    if (roles == "user" && !urlchangepassword && is_password_set && (is_password_set == "null" || is_password_set == null)) {
      navigate("/change-password")
    }
  })
  useEffect(() => {
    if (!auth_token && urlhomecheck) {
      navigate("/login")
    }
  })
  useEffect(() => {
    if ((roles == "Staff" && !URLCheckStaff && !urlhomecheck) || (roles == "user" && (URLCheckStaff || URLCheck)) || (roles == "admin" && !urlhomecheck && !URLCheck)) {
      navigate("/")
    }
  })

  useEffect(() => {
    var bodyElement = document.getElementsByTagName("body")[0];
    if (URLCheck) {
      bodyElement.classList.add("admin-panel");
    } else if (URLCheckStaff) {
      bodyElement.classList.add("staff-panel");
    } else {
      bodyElement.classList.add("user-panel");

    }
  })

  console.log("isDesktop", isDesktop)
  return (
    <>
      <LogoutSoket />
      <Routes>




        {/* <Route path='/prevent-desktop' element={
          <PreventDesktop />
        } /> */}


        <Route path='/forgot-password' element={

          <ForgotPassword />
        } />

        <Route path='/set-password/:id' element={

          <SetPassword />
        } />

        <Route path='/login' element={
                  CompanydataSlice?.error?.message == "Event not found or event slug not found" || CompanydataSlice?.error?.message == "Invalid domain used, Kindly contact admin" ?   <PageNotFound /> :   <Login />
          
        } />

        <Route path='/'>
          <Route path='/login' element={
                  CompanydataSlice?.error?.message == "Event not found or event slug not found" || CompanydataSlice?.error?.message == "Invalid domain used, Kindly contact admin"  ?   <PageNotFound /> :   <Login />

          
          } />

          {
            (roles == "admin" || roles == "Event Manager") ?
              <Route path='/' element={
                <PrivateRouteAdmin>
                  {CompanydataSlice?.error?.message == "Event not found or event slug not found" || CompanydataSlice?.error?.message == "Invalid domain used, Kindly contact admin"  ?   <PageNotFound /> : <AdminDashboard />}

                </PrivateRouteAdmin>
              } /> : roles == "user" ?

                <Route path=':slug?/' element={
                  <PrivateRoutes>
                    {CompanydataSlice?.error?.message == "Event not found or event slug not found" || CompanydataSlice?.error?.message == "Invalid domain used, Kindly contact admin"  ?   <PageNotFound />: !isDesktop ? <Home /> : <PreventDesktop />}


                  </PrivateRoutes>
                } /> : <Route path='/' element={
                  <PrivateRouteStaff>
                    {CompanydataSlice?.error?.message == "Event not found or event slug not found" || CompanydataSlice?.error?.message == "Invalid domain used, Kindly contact admin"  ?   <PageNotFound />: <StaffVouchers />}


                  </PrivateRouteStaff>
                } />

          }

          {
            !isDesktop ? <>
              <Route path='*' element={
                <PrivateRoutes>

                  <PageNotFound />
                </PrivateRoutes>
              } />
              <Route path=':slug?/change-password' element={
                <PrivateRoutes>

                  <ChangePassword />
                </PrivateRoutes>
              } />
              <Route path=':slug?/home' element={
                <PrivateRoutes>

                  <Home />
                </PrivateRoutes>
              } />
              <Route path=':slug?/voucher' element={

                <PrivateRoutes>

                  <Ticket />
                </PrivateRoutes>

              } />
              <Route path=':slug?/qrscanner' element={

                <PrivateRoutes>

                  <QRCodeScanner />
                </PrivateRoutes>

              } />
              <Route path=':slug?/qrscanner/attendance' element={

                <PrivateRoutes>

                  <AttendanceQrScane />
                </PrivateRoutes>

              } />
              <Route path=':slug?/qrscanner/:id' element={

                <PrivateRoutes>

                  <QRCodeScanner />
                </PrivateRoutes>

              } />
              <Route path=':slug?/about-event' element={
                <PrivateRoutes>

                  <AboutEvent />
                </PrivateRoutes>
              } />
              <Route path=':slug?/program-event/:id' element={
                <PrivateRoutes>

                  <AboutEvent />
                </PrivateRoutes>
              } />
              <Route path=':slug?/check-in-error' element={
                <PrivateRoutes>

                  <CheckInError />
                </PrivateRoutes>
              } />
              <Route path=':slug?/participants-info/:id' element={
                <PrivateRoutes>

                  <ParticipantInfo />
                </PrivateRoutes>
              } />
              <Route path=':slug?/participants-info' element={
                <PrivateRoutes>

                  <ParticipantInfo />
                </PrivateRoutes>
              } />
            </> : <>
              <Route path='*' element={

                <PreventDesktop />
              } />

            </>
          }

          <Route path='*' element={

            <PageNotFound />
          } />


          <Route path='/staff'>
            <Route path='*' element={
              <PrivateRouteStaff>

                <PageNotFound />
              </PrivateRouteStaff>
            } />
            <Route path='voucher-list' element={
              <PrivateRouteStaff>

                <StaffVouchers />
              </PrivateRouteStaff>
            } />


            {/* <Route path='participants-info' element={
              <PrivateRouteStaff>

                <StaffParticipantInfo />
              </PrivateRouteStaff>
            } /> */}
            <Route path='participants-info/:id' element={
              <PrivateRouteStaff>

                <ParticipantInfo />
              </PrivateRouteStaff>
            } />
            <Route path='attendance/:id' element={
              <PrivateRouteStaff>

                <AttendanceStaff />
              </PrivateRouteStaff>
            } />
          </Route>

          <Route path='/admin/:slug?'>

            <Route path='*' element={

              <PageNotFound />
            } />
            <Route path='home' element={
              <PrivateRouteAdmin>

                <AdminHome />
              </PrivateRouteAdmin>
            } />
            <Route path='dashboard' element={
              <PrivateRouteAdmin>

                <AdminDashboard />
              </PrivateRouteAdmin>
            } />
            <Route path='events' element={
              <PrivateRouteAdmin>

                <AllEvents />
              </PrivateRouteAdmin>
            } />
            <Route path='events/create-event/event-information' element={
              <PrivateRouteAdmin>

                <EventInformation />
              </PrivateRouteAdmin>
            } />
            <Route path='events/create-event/event-information/:id' element={
              <PrivateRouteAdmin>

                <EventInformation />
              </PrivateRouteAdmin>
            } />
            <Route path='events/create-event/add-program-activities' element={
              <PrivateRouteAdmin>

                <AddProgram />
              </PrivateRouteAdmin>

            } />
            <Route path='events/create-event/add-program-activities/:id' element={
              <PrivateRouteAdmin>

                <AddProgram />
              </PrivateRouteAdmin>

            } />
            <Route path='events/create-event/registration-import' element={
              <PrivateRouteAdmin>

                <RagistrationImport />
              </PrivateRouteAdmin>
            } />
            <Route path='events/create-event/registration-import/:id' element={
              <PrivateRouteAdmin>

                <RagistrationImport />
              </PrivateRouteAdmin>
            } />
            <Route path='events/create-event/add-staff' element={
              <PrivateRouteAdmin>

                <AddStaff />
              </PrivateRouteAdmin>
            } />
            <Route path='events/create-event/add-staff/:id' element={
              <PrivateRouteAdmin>

                <AddStaff />
              </PrivateRouteAdmin>
            } />
            <Route path='events/create-event/add-speakers' element={
              <PrivateRouteAdmin>

                <AddSpeaker />
              </PrivateRouteAdmin>
            } />
            <Route path='events/create-event/add-speakers/:id' element={
              <PrivateRouteAdmin>

                <AddSpeaker />
              </PrivateRouteAdmin>
            } />
            <Route path='*' element={
              <PrivateRouteAdmin>

                <PageNotFound />
              </PrivateRouteAdmin>
            } />
          </Route>
        </Route>


      </Routes>

    </>
  )
}

export default Navigation
