import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
}

export const GetEditActivitesSlice = createSlice({
  name: 'GetEditActivitesSlice',
  initialState,
  reducers: {
    Get_Edit_Activites_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    Get_Edit_Activites_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};

    },
    Get_Edit_Activites_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_Edit_Get_Activites_Check: (state, action) => {
      state.value = {}
      state.error = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const {Get_Edit_Activites_request, Get_Edit_Activites_success, Get_Edit_Activites_failure, Clear_Get_Edit_Activites_Check } = GetEditActivitesSlice.actions

export default GetEditActivitesSlice.reducer