import { React, useEffect, useState } from 'react'
import { useStyles } from "./Style"
import CropOriginalIcon from '@mui/icons-material/CropOriginal';

const ImageUpload = (props) => {
  const { setimage, setimagerror, image,GetIdspeakersSlice } = props;
  const classes = useStyles();

  const [file, setFile] = useState("");
  function handleChange(e) {
    // console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
    if (e.target.files[0]) {
      setimagerror(false)
      setimage((e.target.files[0]));
    }
  }

  useEffect(() => {
    // console.log("image",image)
    if (GetIdspeakersSlice?.value?.data) {
      setFile(GetIdspeakersSlice?.value?.data?.image)
    }
  }, [GetIdspeakersSlice])

  return (
    <>
      <div className={`${classes.UploadImageCard} upload-image-card`}>
        <label className={classes.UploadBtn}>
          <input type="file" onChange={handleChange} />
          {file == "" && <>
            <CropOriginalIcon />
            <p>Upload image</p>
          </>
          }
        </label>
        <div className={classes.PreviewCard}>
          {file != "" && <img src={file} alt='' className='cover' />}
        </div>
      </div>
    </>
  )
}

export default ImageUpload