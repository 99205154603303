import axios from "axios";
import { APIURL } from "../../../../APIHandler/APIURL"
import { Headers } from "../../../../APIHandler/HeaderAPI";
import { reloadHandler } from "../../../../utlis/utlis";
import { AttendanceStaff_failure, AttendanceStaff_request, AttendanceStaff_success } from "../../../Reducer/Admin/Addpartcipantactivities/Attendance/AttendanceStaff";
import { UserAttendance_failure, UserAttendance_request, UserAttendance_success } from "../../../Reducer/Admin/UserAttendance/UserAttendanceSlice";

export const AttendanceStaffAPI = (payload) => async (dispatch, getState) => {
    // const eventItemId=localStorage.getItem("eventItemId")
    const auth_token = localStorage.getItem("auth_token")
    dispatch(AttendanceStaff_request())
    const admineventid = localStorage.getItem("admineventid")
 
    try {
        const { data } = await axios.get(`${APIURL.attendancestaff}/${payload}`, {
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
               "event-slug":Headers?.["event-slug"],
                // "Content-type": "application/json",
                "Content-type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${auth_token}`
            },
        })
        
        dispatch(AttendanceStaff_success(data))
        
    } catch (error) {
        reloadHandler(error?.response?.status)
        // setaddactivites(false)
        dispatch(AttendanceStaff_failure(error?.response?.data))
    }
}
const abortController = new AbortController();

export const UserAttendanceAPI = (payload,apiCalledRef) => async (dispatch, getState) => {
    // const eventItemId=localStorage.getItem("eventItemId")
    if (apiCalledRef.current) {
        return; // If API call has already been made, do nothing
    }
    apiCalledRef.current = true;
    const auth_token = localStorage.getItem("auth_token")
    dispatch(UserAttendance_request())
    const admineventid = localStorage.getItem("admineventid")
    localStorage.setItem("checkin",true)
 
    try {
        const { data } = await axios.post(`${APIURL.userattendance}`,payload, {
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
                // "Content-type": "application/json",
                "Content-type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${auth_token}`
            },
            signal: abortController.signal
        })
        
        dispatch(UserAttendance_success(data))
        
    } catch (error) {
        reloadHandler(error?.response?.status)
        // setaddactivites(false)
        dispatch(UserAttendance_failure(error?.response?.data))
    }
}


export const cancelScanActivityAPI = () => {
    abortController.abort();
};