import { makeStyles } from '@mui/styles';
import AdminTheme from '../../Common/AdminTheme';
import { display } from '@mui/system';

export const useStyles = makeStyles({

    Dropdown:{
            "& .txt":{
                display: "flex",
                alignItems: "center",
                gap: "14px",
                fontSize: "16px",
                lineHeight: "16px",
                fontFamily: AdminTheme.poppins,
                fontWeight: "400",
                color: AdminTheme.headingColor,
                marginTop: "0",
                marginBottom: "0px",
                padding: "15px",
                cursor: "pointer",
                "&:hover":{
                    background: AdminTheme.inputBg,
                }

        }
    }

});