import { makeStyles } from '@mui/styles';
import ColorTheme from '../../Common/CommonCss/ColorTheme';
export const useStyles = makeStyles({

    AboutEventCard: {
        "& h2": {
            marginTop: "0px !important",
            // marginBottom: "0px !important",
            textTransform: "capitalize"
        }
    },
    ColDate: {
        flex: "1",
        marginBottom: "20px",
        "& h5": {
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "24px",
            marginBottom: "auto",
            marginTop: "auto",
            marginLeft: "5px",
            color: ColorTheme.textColor
        },
        "& h6": {
            fontSize: "16px",
            fontWeight: "300",
            lineHeight: "24px",
            marginBottom: "0px",
            marginTop: "5px",
            color: ColorTheme.textColor
        },
        "& svg": {
            fontSize: "18px"
        }

    },

    Description: {
        marginBottom: "20px!important",
    },

    Para: {
        fontSize: "16px !important",
        fontWeight: "400 !important",
        lineHeight: "24px !important",
        fontFamily: `${ColorTheme.publicSans} !important`,
        wordBreak:"break-word",
        opacity: "0.8",
        marginBottom: "10px !important",
        "& p, & *": {
            marginTop: "0"
        },
    },
    ReadMore: {
        fontSize: "14px !important",
        fontWeight: "500 !important",
        lineHeight: "16px !important",
        fontFamily: `${ColorTheme.publicSans} !important`,
        color: ColorTheme.textColor,
    },
    Status: {
        display: "flex",
        alignItems: "center",
        fontSize: "14px",
        fontWeight: "600",
        lineHeight: "16px",
        color: ColorTheme.textColor,
        fontFamily: ColorTheme.publicSans,
        marginTop: "10px",
        "& img": {
            width: "14px",
            height: "14px",
            marginRight: "5px",
            lineHeight: "0"
        }
    },
    MapCard: {
        width: "100%",
        height: "580px",
        borderRadius: "20px",
        marginBottom: "30px",
        overflow: "hidden",
        marginTop: "20px"
    },

    "@media(max-width:1025px)": {
        MapCard: {
            height: "400px",
            marginBottom: "20px",
        }
    },
    "@media(max-width:991px)": {
        MapCard: {
            height: "350px",
            marginBottom: "15px",
        }
    },
    "@media(max-width:767px)": {
        ColDate: {
            "& h5": {
                fontSize: "14px",
                lineHeight: "22px",
            },
            "& h6": {
                fontSize: "14px",
                lineHeight: "22px",
            },
            "& svg": {
                fontSize: "16px"
            }
        },

        MapCard: {
            height: "250px",
            marginBottom: "10px",
        }
    },
    "@media(max-width:575px)": {
        Para: {
            fontSize: "14px !important",
            lineHeight: "20px !important",
        },
        ColDate: {
            "& h5": {
                fontSize: "12px",
                lineHeight: "14px",
            },
            "& h6": {
                fontSize: "12px",
                lineHeight: "14px",
            },
            "& svg": {
                fontSize: "14px"
            }
        },

        MapCard: {
            height: "150px",
        }
    },
    
});