import React from 'react'
import { Navigate, useLocation } from 'react-router-dom';
import Home from '../../Pages/Home/Home';
import { useAuth } from '../../Hooks/useAuth';
import Login from '../../Pages/Login/Login';
const PrivateRouteStaff = ({ children, isVisible }) => {
    const auth = useAuth();
    const location = useLocation(); // <-- get current location being accessed
    const roles = localStorage.getItem("roles")
    // console.log("loggedIn", auth ? "c" : "v", auth)
    return auth
        ? <>
            {
                roles == "Staff" && (
                    <div className="paddingTop">{children}</div>

                )
            }
        </>
        : (
            <Navigate
                to={'/login'}
                state={{ from: location }} // <-- pass in route state
                replace
            />
        );
}

export default PrivateRouteStaff
