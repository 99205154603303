import React, { useEffect, useState } from 'react'
import { useStyles } from "./Style";
import Typography from '@mui/joy/Typography';
import Input from '@mui/joy/Input';
import Buttonprimary from '../../Components/Button/Button';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SetConfirmPasswordAPI } from '../../redux/Action/ForgetPassword/SetPassword';
import Snacbar from '../../Components/Snackbar/Snacbar';
import { validatePassword } from '../../utlis/Validations';
import Loader from '../../Components/Loader/Loader';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';

const SetPassword = () => {
    const classes = useStyles();
    const company_data = JSON.parse(localStorage.getItem("company_data"));
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [newPassword, setnewPassword] = useState("");
    const [newPassworderror, setnewPassworderror] = useState(false);
    const [newPassworderrorvalidation, setnewPassworderrorvalidation] = useState(false);
    const [ConfirmPassword, setConfirmPassword] = useState("");
    const [ConfirmPassworderror, setConfirmPassworderror] = useState(false);
    const [ConfirmPassworderrorvalidation, setConfirmPassworderrorvalidation] = useState(false);
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false);
    const { id } = useParams();
    const [passucessmessage, setpasswordsuccessmessage] = useState(false)
    const SetPasswordSlice = useSelector(state => state?.SetPasswordSlice)
    const navigate = useNavigate();
    // SetPasswordAPI
    const handleClick = (e) => {
        e.preventDefault();
        // console.log("vvvv")
        if (newPassword == "" || validatePassword(newPassword) || ConfirmPassword == "" || validatePassword(ConfirmPassword)) {
            if (newPassword == "") {

                setnewPassworderror(true)
            }
            if (ConfirmPassword=="") {

                setConfirmPassworderror(true)
            }
            if (validatePassword(newPassword)) {

                setnewPassworderrorvalidation(true)
            }
            // if (validatePassword(ConfirmPassword)) {
            //     setConfirmPassworderrorvalidation(true)
            // }
        }
        else if (newPassword != "" && ConfirmPassword != "") {

            const formData = new FormData();
            formData.append('password', newPassword);
            formData.append('password_confirmation', ConfirmPassword);
            formData.append('password_token', id);
            dispatch(SetConfirmPasswordAPI(formData))
        }
    }
    useEffect(() => {
        if (SetPasswordSlice?.value?.message) {
            navigate("/")
        } else if (SetPasswordSlice?.error?.message) {
            setpasswordsuccessmessage(true)

        }
        // console.log("SetPasswordSlice", SetPasswordSlice)
    }, [SetPasswordSlice])
    // console.log("setnewPassworderrorvalidation",newPassworderror)
    return (
        <>
        {
            SetPasswordSlice?.loading==true && (
                <Loader/>
            )
        }
            {
                (SetPasswordSlice?.error?.message) && (
                    <Snacbar
                        open={passucessmessage}
                        setOpen={setpasswordsuccessmessage}
                        messagetype={"error"}
                        message={(SetPasswordSlice?.error?.message)}
                    />

                )
            }

            <div className={`${classes.Login} main pb-0`} style={{backgroundImage: `url(${company_data?.banner})`}}>
                <div className={classes.header}>
                    <img src={company_data?.banner} alt="" className='cover' />
                </div>
                {/* <button className='btn btn-back' onClick={()=>{navigate("/")}}><KeyboardBackspaceOutlinedIcon/>Back</button> */}
                <div className={`${classes.loginWrapper} login-card`}>
                    <form onSubmit={(e) => handleClick(e)} >
                        <Typography level="h2">Set Password</Typography>
                        <div className="form-group">
                            <label className={`${classes.Label} form-label`}>New Password</label>
                            <div className='input-group'>
                                <Input id="NewPwd" type={showPassword ? "text" : "password"} placeholder="Password" className={`${classes.Input} form-input`} value={newPassword} onChange={(e) => {
                                setnewPassworderror(false)
                                setnewPassworderrorvalidation(false)
                                    setnewPassword(e.target.value)
                                }} />
                                <label htmlFor="NewPwd" className={`${classes.eyeIcon} icon`} onClick={() => setShowPassword(!showPassword)} >
                                    {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                </label>
                            </div>
                                {
                                    newPassworderror && (
                                        <p className='error-msg'>This field is required.</p>
                                    )
                                }
                                {
                                    newPassworderrorvalidation && !newPassworderror && (
                                        <p className='error-msg'>Your password must be at least 8 characters, with at least one number, one special character, and have a mixture of uppercase and lowercase letters.</p>
                                    )
                                }
                                
                        </div>
                        <div className="form-group">
                            <label className={`${classes.Label} form-label`}>Confirm Password</label>
                            <div className='input-group'>
                                <Input id="SetPwd" type={showConfirmPassword ? "text" : "password"} placeholder="Password" className={`${classes.Input} form-input`} ConfirmPassword value={ConfirmPassword} onChange={(e) => {setConfirmPassword(e.target.value)
                                setConfirmPassworderror(false)
                                }} />
                                <label htmlFor="SetPwd" className={`${classes.eyeIcon} icon`} onClick={() => setShowConfirmPassword(!showConfirmPassword)} >
                                    {showConfirmPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                </label>
                                {
                                    ConfirmPassworderror && (
                                        <p className='error-msg'>This field is required.</p>
                                    )
                                }
                                {/* {
                                   ( ConfirmPassworderror==false && newPassword!=ConfirmPassword && ConfirmPassword!="") && (
                                        <p className='error-msg'>Password required.</p>
                                    )
                                } */}
                            </div>
                        </div>
                        <Buttonprimary disabledcheck={true} label={'Set Password'} type="submit" />
                    </form>
                </div>
            </div>
        </>
    )
}

export default SetPassword