import axios from "axios";
import { APIURL } from "../../../APIHandler/APIURL"
import { Headers } from "../../../APIHandler/HeaderAPI";
import { reloadHandler } from "../../../utlis/utlis";
import { Staff_Vouchers_failure, Staff_Vouchers_request, Staff_Vouchers_success } from "../../Reducer/StaffVoucher/StaffVoucherSlice";
import { UpdateBannerSlice_failure, UpdateBannerSlice_request, UpdateBannerSlice_success } from "../../Reducer/UpdateBannerSlice/UpdateBannerSlice";
import { DeleteBannerSlice_failure, DeleteBannerSlice_request, DeleteBannerSlice_success } from "../../Reducer/UpdateBannerSlice/DeleteBannerUrl";
import { GetEditEventListAPI } from "../Admin/getEditEventList/GetEditEventList";
import { GetEditActivitiesAPI } from "../Admin/CreateActitvites/GetEditActivity";

export const UpdateBannerUrlAPI = (payload,id) => async (dispatch, getState) => {
    dispatch(UpdateBannerSlice_request())
    const eventItemId=localStorage.getItem("eventItemId")
    const auth_token = localStorage.getItem("auth_token")
  
    try {
        const { data } = await axios.post(`${APIURL.updatebanner}`,payload, {
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
              "event-slug":Headers?.["event-slug"],
                "Content-type": "application/json",
                Authorization: `Bearer ${auth_token}`
            },
        })
        dispatch(UpdateBannerSlice_success(data))
        dispatch(GetEditEventListAPI(id))
    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(UpdateBannerSlice_failure(error?.response?.data))

    }
}
export const DeleteBannerUrlAPI = (payload,id) => async (dispatch, getState) => {
    dispatch(DeleteBannerSlice_request())
    const eventItemId=localStorage.getItem("eventItemId")
    const auth_token = localStorage.getItem("auth_token")
  
    try {
        const { data } = await axios.delete(`${APIURL.deletebanner}/${payload}/${id}`, {
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
                "event_slug":Headers?.event_slug,
                "Content-type": "application/json",
                Authorization: `Bearer ${auth_token}`
            },
        })
        dispatch(DeleteBannerSlice_success(data))
        dispatch(GetEditEventListAPI(payload))
    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(DeleteBannerSlice_failure(error?.response?.data))

    }
}
export const DeleteActivityBannerUrlAPI = (payload,id) => async (dispatch, getState) => {
    dispatch(DeleteBannerSlice_request())
    const eventItemId=localStorage.getItem("eventItemId")
    const auth_token = localStorage.getItem("auth_token")
    const editactivityid = localStorage.getItem("editactivityid")
  
    try {
        const { data } = await axios.delete(`${APIURL.deleteprogrambanner}/${payload}/${id}`, {
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
                "event_slug":Headers?.event_slug,
                "Content-type": "application/json",
                Authorization: `Bearer ${auth_token}`
            },
        })
        dispatch(DeleteBannerSlice_success(data))
        dispatch(GetEditActivitiesAPI(editactivityid))

    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(DeleteBannerSlice_failure(error?.response?.data))

    }
}