import axios from "axios";
import { APIURL } from "../../../../APIHandler/APIURL"
import { Headers } from "../../../../APIHandler/HeaderAPI";
import { reloadHandler } from "../../../../utlis/utlis";
import { EventManagerList_failure, EventManagerList_request, EventManagerList_success } from "../../../Reducer/Admin/EventManager/EventManagerSlice";
import { EventManagerPost_failure, EventManagerPost_request, EventManagerPost_success } from "../../../Reducer/Admin/EventManager/EventManagerPostSlice";
import { EventManagerdelete_failure, EventManagerdelete_request, EventManagerdelete_success } from "../../../Reducer/Admin/EventManager/EventManagerDeleteSlice";
import { EventGetEditManager_failure, EventGetEditManager_request, EventGetEditManager_success } from "../../../Reducer/Admin/EventManager/EventGetEditManagerSlice";

export const EventManagerListAPI = (page) => async (dispatch, getState) => {
    // const eventItemId=localStorage.getItem("eventItemId")
    const auth_token = localStorage.getItem("auth_token")
    dispatch(EventManagerList_request())
    try {
        const { data } = await axios.get(`${APIURL.eventManager}?page=${page?page:1}`, {
            headers: {
                "host-url": Headers?.["host-url"],
              "event-slug":Headers?.["event-slug"],
                "language": Headers?.language,
                "Content-type": "application/json",
                Authorization: `Bearer ${auth_token}`
            },
        })
       
        dispatch(EventManagerList_success(data))
    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(EventManagerList_failure(error?.response?.data))
    }
}

export const EventManagerPostAPI = (payload, setname, setemail, seteventid, setpassword, setOpen) => async (dispatch, getState) => {
    // const eventItemId=localStorage.getItem("eventItemId")
    const auth_token = localStorage.getItem("auth_token")
    dispatch(EventManagerPost_request())
    try {
        const { data } = await axios.post(`${APIURL.eventManager}`, payload, {
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
                "event-slug":Headers?.["event-slug"],
                "Content-type": "application/json",
                Authorization: `Bearer ${auth_token}`
            },
        })
        dispatch(EventManagerPost_success(data))
        dispatch(EventManagerListAPI())
        setname("")
        setemail("")
        seteventid("")
        setpassword("")
        setOpen(false)
    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(EventManagerPost_failure(error?.response?.data))
    }
}

export const EventDeleteManagerAPI = (payload) => async (dispatch, getState) => {
    // const eventItemId=localStorage.getItem("eventItemId")
    const auth_token = localStorage.getItem("auth_token")
    dispatch(EventManagerdelete_request())
    try {
        const { data } = await axios.delete(`${APIURL.eventManager}/${payload}`, {
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
                "event-slug":Headers?.["event-slug"],
                "Content-type": "application/json",
                Authorization: `Bearer ${auth_token}`
            },
        })
        dispatch(EventManagerdelete_success(data))
        dispatch(EventManagerListAPI())

    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(EventManagerdelete_failure(error?.response?.data))
    }
}


export const EventGetEditManagerListAPI = (payload) => async (dispatch, getState) => {
    // const eventItemId=localStorage.getItem("eventItemId")
    const auth_token = localStorage.getItem("auth_token")
    dispatch(EventGetEditManager_request())
    try {
        const { data } = await axios.get(`${APIURL.eventManager}/${payload}`, {
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
                "event-slug":Headers?.["event-slug"],
                "Content-type": "application/json",
                Authorization: `Bearer ${auth_token}`
            },
        })
        dispatch(EventGetEditManager_success(data))
    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(EventGetEditManager_failure(error?.response?.data))
    }
}


export const EventManagerEditAPI = (payload,eventmanagerid, setname, setemail, seteventid, setpassword, setOpen) => async (dispatch, getState) => {
    // const eventItemId=localStorage.getItem("eventItemId")
    const auth_token = localStorage.getItem("auth_token")
    dispatch(EventManagerPost_request())
    try {
        const { data } = await axios.put(`${APIURL.eventManager}/${eventmanagerid}`, payload, {
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
                "event-slug":Headers?.["event-slug"],
                "Content-type": "application/json",
                Authorization: `Bearer ${auth_token}`
            },
        })
        console.log("vikalppost",data)
        dispatch(EventManagerPost_success(data))
        dispatch(EventManagerListAPI())
        setname("")
        setemail("")
        seteventid("")
        setpassword("")
        setOpen(false)
    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(EventManagerPost_failure(error?.response?.data))
    }
}