import React from 'react'
import Avatar from '@mui/material/Avatar';
import { Typography } from '@mui/material';
import ColorTheme from '../../Common/CommonCss/ColorTheme';

const Participant = (props) => {
    const {ParticipantPic, ParticipantName,speakerid,dataid} = props
  return (
    <>
        <Avatar
        alt={ParticipantName}
        src={ParticipantPic}
        sx={{ width: 64, height: 64, marginLeft: 'auto', marginRight: 'auto',border: dataid==speakerid?'4px solid #032e49':"" }} />
        <div className='text-truncate' style={{
            textAlign: 'center',
            marginTop: '10px',
            color: ColorTheme.textColor,
            fontFamily: ColorTheme.publicSans,
            fontSize: "12px",
            fontWeight: "600",
            lineHeight: "14px",
            textTransform: "capitalize"
            }}>{dataid==speakerid ?ParticipantName:""}</div>
        {/* <Typography level="h2" sx={{
            textAlign: 'center',
            marginTop: '10px',
            color: ColorTheme.textColor,
            fontFamily: ColorTheme.publicSans,
            fontSize: "12px",
            fontWeight: "600",
            lineHeight: "14px"
            }}>{ParticipantName}</Typography> */}
    </>
  )
}

export default Participant