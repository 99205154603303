import { makeStyles } from '@mui/styles';
import ColorTheme from '../../Common/CommonCss/ColorTheme';
export const useStyles = makeStyles({
    SuccessPopup: {
        "& .MuiDialog-paper": {
            margin: "0px",
            width: "calc(100% - 20px)"
        },
        "& .content": {
            padding: "60px 20px",
            textAlign: "center",
            "& .success-animation": {
                marginBottom: "60px",
            },
            "& h1": {
                fontSize: "32px",
                lineHeight: "42px",
                color: ColorTheme.textColor,
                fontFamily: ColorTheme.publicSans,
                marginBottom: "5px",
                textTransform: "Uppercase" 
            },
            "& img": {
                maxHeight: "200px"
            },
            "& p": {
                fontSize: "16px",
                lineHeight: "24px",
                color: ColorTheme.textColor,
                fontFamily: ColorTheme.publicSans,
                marginTop: "0",
                marginBottom: "30px",
            },
            "& .btn": {
                textTransform: "Uppercase !important" 
            }
        }
    }
})