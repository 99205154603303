import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
}

export const AdminBulkDeleteEventSlice = createSlice({
  name: 'AdminBulkDeleteEvent',
  initialState,
  reducers: {
    Admin_Bulk_Delete_Event_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    Admin_Bulk_Delete_Event_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};

    },
    Admin_Bulk_Delete_Event_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_Admin_Bulk_Delete_Event_Check: (state, action) => {
      state.value = {}
      state.error = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const {Admin_Bulk_Delete_Event_request, Admin_Bulk_Delete_Event_success, Admin_Bulk_Delete_Event_failure, Clear_Admin_Bulk_Delete_Event_Check } = AdminBulkDeleteEventSlice.actions

export default AdminBulkDeleteEventSlice.reducer