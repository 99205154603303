import { makeStyles } from '@mui/styles';
import ColorTheme from '../../Common/CommonCss/ColorTheme';
export const useStyles = makeStyles({
   
    EventCard: {
        borderRadius: ColorTheme.cardRadius,
        boxShadow: ColorTheme.cardShadow,
        background: ColorTheme.cardBg,
        padding: "15px",
        height: "calc(100% - 32px)",
        "& figure": {
            height: "97px",
            margin: "0px",
            marginBottom: "0px",
            overflow: "hidden",
            borderRadius: "5px",
            position: "relative",
            "& img": {
                width: "100%",
                height: "100%",
                objectFit: "cover",
                lineHeight: "0"
            }
        }
    },

    QRIcon: {
        background: "white",
        borderRadius: "5px",
        padding: "4px",
        position: "absolute",
        right: "10px",
        bottom: "10px",
        zIndex: "9"
    },

    EventCardFooter: {
        fontSize: "14px",
        lineHeight: "16px",
        fontWeight: "400",
        fontFamily: ColorTheme.publicSans,
        color: ColorTheme.textColor,
        textAlign: "center",
        minHeight: "calc(100% - 97px)",
        marginTop: "10px"
    },
})