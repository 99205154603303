import { makeStyles } from '@mui/styles';
import AdminTheme from './AdminTheme';
import { fontFamily, fontSize, fontWeight, lineHeight, margin } from '@mui/system';
export const useStyles = makeStyles({
    
    FormTitleContainer: {
        marginBottom: "30px",
        "& .col:first-child": {
            minWidth: "180px"
        },
        "& .col .import-form-btn, & .col .btn-with-icon": {
            minWidth: "160px",
            width: "100%",
        }
    },
    FormTitle: {
        fontSize: "19px",
        fontWeight: "500",
        lineHeight: "28px",
        color: AdminTheme.headingColor,
        fontFamily: AdminTheme.poppins,
        marginTop: "0px",
        marginBottom: "30px",
    },

    FormGroup :{
        marginBottom: "27px",
        "& .error-msg": {
            color: AdminTheme.dangerColor,
            fontSize: "12px",
            lineHeight: "16px",
            fontWeight: "600",
            marginTop: "5px",
            marginBottom: "0",
            fontFamily: AdminTheme.poppins,
        }
    },

    FormLabel :{
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "21px",
        color: "#464E5F",
        fontFamily: AdminTheme.poppins,
        marginTop: "0px",
        marginBottom: "5px",
        "& span": {
            color: "#EA0000",
        }
    },

    FormInput: {
        fontSize: "14px !important",
        fontWeight: "600 !important",
        lineHeight: "21px !important",
        color: "#434349 !important",
        fontFamily: AdminTheme.poppins,
        height: "42px !important",
        borderRadius: "4px !important",
        background: `${AdminTheme.inputBg} !important`,
        padding: "0px 20px !important",
        boxShadow: "none !important",
        "& input:-internal-autofill-selected": {
            fontSize: "14px !important",
            fontWeight: "600 !important",
            lineHeight: "21px !important",
            color: "#434349 !important",
        },
        "& ::placeholder": {
            color: "#D6D6E0 !important",
            fontWeight: "400",
        },
        "&.Mui-focused:before": {
            boxShadow: `0px 0px 0px 1.5px ${AdminTheme.themeColor} !important`
        },
    },

    ErrorBox: {
        width: "100%",
        maxHeight: "115px",
        overflow: "auto",
        marginBottom: "15px",
        "& p": {
            fontSize: "14px",
            lineHeight: "20px",
            // fontWeight: "600",
            color: AdminTheme.dangerColor,
            fontFamily: AdminTheme.poppins,
            margin: "2px 0"
        }
    }
    
});
