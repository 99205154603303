import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
  error:{}
}

export const CompanydataSlice = createSlice({
  name: 'Companydata',
  initialState,
  reducers: {
    Company_data_request: (state, action) => {
      state.value={};
      state.loading = true;
      state.error = {};

    },
    Company_data_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};

      
    },
    Company_data_failure: (state, action) => {
        state.error = action.payload;
        state.loading = false;
        state.value = {};

    }
  }
})

// Action creators are generated for each case reducer function
export const {Company_data_request, Company_data_success,Company_data_failure } = CompanydataSlice.actions

export default CompanydataSlice.reducer