import React from 'react'
import { useStyles } from "./Style";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


const DeletePopup = (props) => {
  const { setOpen, open ,handleDelete} = props;
  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
  };



  return (
    <>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-description"
        className={classes.AdminDeletePopUp}
      >
        <DialogTitle>Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-description">
            Are you sure you want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={`${classes.Btn} ${classes.BtnCancel}`} onClick={handleClose}>Cancel</Button>
          <Button autoFocus className={`${classes.Btn} ${classes.BtnLogout}`} onClick={() => {
            handleDelete()
          }}>Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DeletePopup