import React from 'react'
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import ColorTheme from '../../Common/CommonCss/ColorTheme';
import { useStyles } from './Style'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import AdminTheme from '../../AdminPanel/Common/AdminTheme';
import { useNavigate } from 'react-router-dom';

const ButtonLogOut = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const url = window.location.href;
  const staffurl = localStorage.getItem("roles") == "Staff" ? true : false
  // const staffurl=url?.includes("staff")?true:false;
  const navigate = useNavigate();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleLogout = () => {
    localStorage.clear();
    localStorage.removeItem("roles")
    window.location.reload();
    navigate("/")
  }
  return (
    <React.Fragment>
      {
        staffurl == true ?
          <IconButton className='btn-logout' aria-label="Log out" sx={{ color: ColorTheme.textColor, }} onClick={handleClickOpen}>
            <LogoutIcon />
          </IconButton> :

          <Button className='btn btn-logout' aria-label="Log out" onClick={handleClickOpen} sx={{ textTransform: "none" }}>Log out</Button>
      }
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.LogoutPopup}
      >
        <DialogTitle id="alert-dialog-title" sx={{ fontFamily: AdminTheme.poppins }}>
          Log Out
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ fontFamily: AdminTheme.poppins }}>
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={`${classes.Btn} ${classes.BtnCancel}`}>Cancel</Button>
          <Button onClick={() => handleLogout()} autoFocus className={`${classes.Btn} ${classes.BtnLogout}`}>Log out</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default ButtonLogOut