// getediteventlist


import axios from "axios";
import { APIURL } from "../../../../APIHandler/APIURL"
import { Headers } from "../../../../APIHandler/HeaderAPI";
import { reloadHandler } from "../../../../utlis/utlis";
import { Admin_Partcipant_failure, Admin_Partcipant_request, Admin_Partcipant_success } from "../../../Reducer/Admin/Particants/ParticpantsSlice";
import { GetEdit_EventList_failure, GetEdit_EventList_request, GetEdit_EventList_success } from "../../../Reducer/Admin/getEditEventList/getEditEventList";

export const GetEditEventListAPI = (payload) => async (dispatch, getState) => {
    const admineventid=localStorage.getItem("admineventid")
    const auth_token = localStorage.getItem("auth_token")
    dispatch(GetEdit_EventList_request())
    try {
        const { data } = await axios.get(`${APIURL.getediteventlist}/${payload}`,{
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
            "event-slug":Headers?.["event-slug"],
                "Content-type": "application/json",
                Authorization: `Bearer ${auth_token}`
            },
        })
        dispatch(GetEdit_EventList_success(data))
    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(GetEdit_EventList_failure(error?.response?.data))
    }
}


  