// deleterogram

import axios from "axios";
import { APIURL } from "../../../../APIHandler/APIURL"
import { Headers } from "../../../../APIHandler/HeaderAPI";
import { reloadHandler } from "../../../../utlis/utlis";
import { Increase_Partcipant_Quantity_failure, Increase_Partcipant_Quantity_request, Increase_Partcipant_Quantity_success } from "../../../Reducer/Admin/increaseparticipantQuantity/IncreaseParticipantQuantity";
import { PartcipantsProgramListingAPI } from "../ParticipantProgramLisiting/ParticipantProgram";
import { PartcipantsAPI } from "../Partcipants/Partcipants";
import { EventDashboardAPI } from "../EventList/EventDashboardList";

export const DeleteProgramAPI = ( eventid, id,setOpen) => async (dispatch, getState) => {
    // const eventItemId=localStorage.getItem("eventItemId")
    const auth_token = localStorage.getItem("auth_token")
    const programactivitesID = localStorage.getItem("programactivitesID")
    const participantpage = localStorage.getItem("participantpage")
    const paginationnumber=localStorage.getItem("paginationnumber")

    // dispatch(Increase_Partcipant_Quantity_request())
    try {
        const { data } = await axios.delete(`${APIURL?.deleterogram}/${eventid}/${id}`, {
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
                "Content-type": "application/json",
              "event-slug":Headers?.["event-slug"],
                // "Content-type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${auth_token}`
            },
        })
        dispatch(PartcipantsProgramListingAPI(programactivitesID))
        dispatch(PartcipantsAPI(programactivitesID,participantpage,paginationnumber))
        dispatch(EventDashboardAPI(programactivitesID))
        setOpen(false)
        // dispatch(Increase_Partcipant_Quantity_success(data))
    } catch (error) {
        reloadHandler(error?.response?.status)
        // dispatch(Increase_Partcipant_Quantity_failure(error?.response?.data))
    }
}


