const company_data=JSON.parse(localStorage.getItem("company_data"));
const ColorTheme = ({
    black: '#000',
    white: '#fff',
    orange: company_data?.primary_color,
    BtnTextColor: company_data?.secondary_color,
    orange5: 'rgba(240, 130, 62, 0.05)',
    lightGrey: '#F4F6F8',
    textColor: '#0F3952',
    dangerColor: '#FC5C69',
    successColor: '#01C448',
    publicSans: "'Public Sans', sans-serif",
    headingFS: "20px",
    headingFW: "700",
    headingLH: "24px",
    cardRadius: "10px",
    cardShadow: "0px 9px 40px 0px rgba(0, 0, 0, 0.10)",
    cardShadowHov: "0px 9px 40px 0px rgba(0, 0, 0, 0.2)",
    cardShadowTop: "0px -35px 40px 0px rgba(0, 0, 0, 0.10)",
    transition: "0.3s ease",
    cardBg: "#fff",
    borderColor: "rgba(203, 203, 203, 0.3)",
    containerWidth: "575px",
    QrAngleColor: "#FFBA15",
   
});
export default ColorTheme;