import { makeStyles } from '@mui/styles';
import AdminTheme from '../../Common/AdminTheme';
export const useStyles = makeStyles({
    
    BtnTab:{
        minWidth: "137px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    BtnTabText:{
        fontFamily: AdminTheme.poppins,
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "21px",
        marginBottom: "0",
        marginTop: "0px",
        textAlign: "center",
        textTransform: "none !important",
        color: '#80808F',
        "&[active='active']": {
            color: AdminTheme.themeColor,
        },
    }
    
});