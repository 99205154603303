import React, { useEffect, useState } from 'react';
import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import MusicPlayer from './Sound';
import { Headers } from '../APIHandler/HeaderAPI';
import SuccessPopu from '../Components/SuccessPopup/SuccessPopup';
import ReactAudioPlayer from 'react-audio-player';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ImportExportSoket_success } from '../redux/Reducer/ImportExportSoketdata';
import AdmLoader from '../AdminPanel/Components/AdmLoader/AdmLoader';
import { ImportLastStatusCheck_success } from '../redux/Reducer/ImportLaststatusCheckSlice';
import { ImportExportErrorSoket_success } from '../redux/Reducer/ImportExportErrorSlice';
// Initialize Pusher

function LogoutSoket(props) {
    const { changeContent } = props;
    const auth_token = localStorage.getItem("auth_token")
    const user_id = localStorage.getItem("user_id")
    const [count, setcount] = useState(0)
    // console.log("auth_token", auth_token)
    const staffId = 3;
    const [open, setOpen] = useState(false);
    const [data, setdata] = useState(false);
    const { id } = useParams();
    const dispatch = useDispatch();
    const event_id = localStorage.getItem("admineventid")
    const eventItemId = localStorage.getItem("eventItemId")
    const [listdata, setlistdata] = useState([])
    const [listalldata, setlistalldata] = useState([])
    const [loader, setloader] = useState(false)
    const navigate = useNavigate()
    useEffect(() => {
        dispatch(ImportExportSoket_success(listdata))
    }, [listdata])
    useEffect(() => {
        dispatch(ImportExportErrorSoket_success(listalldata))
    }, [listalldata])
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {

        const pusher = new Pusher('TicketKeytProd', {
            cluster: 'ap1',
            encrypted: true,
            wsHost: process.env.REACT_APP_BASE_URL?.includes("localhost") ? "ticketing-react.myevent360.com" : process.env.REACT_APP_BASE_URL?.includes("www.") ? process.env.REACT_APP_BASE_URL?.split("www.")[1]?.split("/")[0] : process.env.REACT_APP_BASE_URL?.split("//")[1]?.split("/")[0],
            wsPort: 6001,
            wssPort: 6001,
            forceTLS: true,
            disableStats: false,
            // authEndpoint: '/pusher/auth',
            auth: {
                headers: {
                    'Authorization': `Bearer ${auth_token}`,
                },
            },
        });
        pusher.connection.bind('connected', () => {
            console.log("socket connect")
        });

        // Optional: Handle disconnection

        pusher.connection.bind('error', function (err) {
            console.error('Pusher error:', err);
        });

        const channel = pusher.subscribe(`event-status.${eventItemId}`);
        channel.bind('event.status', (data) => {
            console.log('Event data:', data);
            setlistalldata((prevData) => [...prevData, data]);
            dispatch(ImportLastStatusCheck_success(data))
            // if (data?.user_data != undefined && data?.type=="success") {
            //     setlistdata((prevData) => [...prevData, data?.user_data]);
            //     setloader(true)
            //     setTimeout(() => {
            //         setloader(false)
            //     }, 500)
            // }
            if (data?.type == "participant_delete") {
                if (data?.data[0] == user_id) {
                    localStorage.clear();
                    navigate("/login")
                }
            }
            else if(data?.type=="event_delete"){
                if (data?.data[0] == eventItemId) {
                    localStorage.clear();
                    navigate("/login")
                }    
            }
            else if(data?.type=="staff_delete"){
                if (data?.data[0] == user_id) {
                    localStorage.clear();
                    navigate("/login")
                }    
            }


            console.log("lhggfhhkgdrfhjkg")
            setdata(data)
            setOpen(true);
        });
        pusher.connection.bind('disconnected', () => {
            console.log("socket disconnect")
        });
        return () => {
            channel.unbind_all();
            channel.unsubscribe();
            pusher.disconnect();
        };
    }, []);


    console.log("listdata", listdata, data)
    return (
        <div>
            {/* {loader == true && (
                <AdmLoader />
            )} */}

        </div>
    );
}

export default LogoutSoket;
