import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
  error:{}
}

export const ForgetPasswordSlice = createSlice({
  name: 'ForgetPassword',
  initialState,
  reducers: {
    ForgetPassword_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    ForgetPassword_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};

    },
    ForgetPassword_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_ForgetPassword_Details: (state, action) => {
      state.value = {}
      state.error = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const { ForgetPassword_request, ForgetPassword_success, ForgetPassword_failure, Clear_ForgetPassword_Details } = ForgetPasswordSlice.actions

export default ForgetPasswordSlice.reducer