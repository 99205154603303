import React from 'react'
import { useStyles } from "./Style"
import { Oval } from "react-loader-spinner";
import ColorTheme from "../../Common/CommonCss/ColorTheme"
const Loader = () => {
    const classes = useStyles();
  return (
    <div className={`${classes.loaderContainer} loader`}>
    <div className={`${classes.loaderContainerInner} loader-inner`}>
      <Oval
        ariaLabel="loading-indicator"
        height={40}
        width={40}
        strokeWidth={5}
        strokeWidthSecondary={5}
        color={`${ColorTheme.orange}`}
        secondaryColor={`${ColorTheme.white}`}
      />
      {/* <p className={classes.loaderText} style={{ color: `${ColorTheme.white}` }}>Loading..</p> */}
    </div>
  </div>
  )
}

export default Loader
