import axios from "axios";
import { APIURL } from "../../../../APIHandler/APIURL"
import { Headers } from "../../../../APIHandler/HeaderAPI";
import { reloadHandler } from "../../../../utlis/utlis";
import { DuplicateList_failure, DuplicateList_request, DuplicateList_success } from "../../../Reducer/Admin/duplicateEvent/DuplicateEventSlice";
import { AdminEventListAPI } from "../EventList/EventList";

export const EventduplicateListAPI = (payload) => async (dispatch, getState) => {
    // const eventItemId=localStorage.getItem("eventItemId")
    const auth_token = localStorage.getItem("auth_token")
    dispatch(DuplicateList_request())
    try {
        const { data } = await axios.get(`${APIURL.duplicateevent}/${payload}`,{
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
                "Content-type": "application/json",
              "event-slug":Headers?.["event-slug"],
                Authorization: `Bearer ${auth_token}`
            },
        })
        dispatch(DuplicateList_success(data))
        dispatch(AdminEventListAPI())
    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(DuplicateList_failure(error?.response?.status))
    }
}



  