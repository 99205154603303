import React from 'react'
import {useStyles} from './Style'
import { Link } from 'react-router-dom';
import FourZeroFour from './FourZeroFour';
import { useSelector } from 'react-redux';

const PageNotFound = () => {
    const classes = useStyles();
    const CompanydataSlice = useSelector(state => state?.CompanydataSlice)
  return (
    <>
    <div className={classes.PageNotFound}>
        <div className='container'>
          <FourZeroFour/>
  
            <h2>Page not found!</h2>
            <p>The page you are looking for doesn't exist <br/> or an other error occured.</p>
          {CompanydataSlice?.error?.message == "Event not found or event slug not found" || CompanydataSlice?.error?.message == "Invalid domain used, Kindly contact admin"  ?"":  <Link to="/" className='btn'>Back to home</Link>}
        </div>
    </div>
    </>
  )
}

export default PageNotFound