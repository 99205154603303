import React, { useState, useEffect } from 'react';
import Input from '@mui/joy/Input';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useStyles } from "./Style";
import Buttonprimary from '../../Components/Button/Button';
import SelectInput from '../../Components/SelectInput/SelectInput';
import { Link, useNavigate } from 'react-router-dom';
import Typography from '@mui/joy/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { EmailCheckAPI } from '../../redux/Action/EmailCheck/EmailCheck';
import Loader from '../../Components/Loader/Loader';
import { LoginAPI } from '../../redux/Action/Login/Login';
import { EventDetailsAPI } from '../../redux/Action/EventDetails/EventDetails';
import Snacbar from '../../Components/Snackbar/Snacbar';
import { Clear_Email_Check } from '../../redux/Reducer/EmailCheck/EmailCheckSlice';
import { StaffVoucherAPI } from '../../redux/Action/StaffVoucher/StaffVoucher';
import InnerDimension from '../../Components/InnerDimension/InnerDimension';
import { Clear_Login_Check } from '../../redux/Reducer/Login/LoginSlice';
import { Clear_ForgetPassword_Details } from '../../redux/Reducer/ForgetPassword/ForgetPassword';

const Login = () => {

    const classes = useStyles();
    const [email, setemail] = useState("")
    const [password, setpassword] = useState("")
    const [showPassword, setShowPassword] = useState(false);
    const [open, setOpen] = useState(false)
    const [emailsuccess, setemailsuccess] = useState(false);
    const [emailerrormsg, setemailerrormsg] = useState("");
    const [eventList, seteventList] = useState("");
    const company_data = JSON.parse(localStorage.getItem("company_data"));
    const EmailCheckSlice = useSelector(state => state?.EmailCheckSlice);
    const LoginCheckSlice = useSelector(state => state?.LoginCheckSlice);
    const EventIDSlice = useSelector(state => state?.EventIDSlice?.value);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const roles = localStorage.getItem("roles")
    const auth_token = localStorage.getItem("auth_token")
    const eventItemId = localStorage.getItem("eventItemId")
    const [Forgotemail, setforgetemail] = useState("");
    const [Forgotemailsuccess, setforgetemailsucess] = useState(false);
    const ForgetPasswordSlice = useSelector(state => state?.ForgetPasswordSlice)
    const [passucessmessage, setpasswordsuccessmessage] = useState(false)
    const SetPasswordSlice = useSelector(state => state?.SetPasswordSlice)
    const CompanydataSlice = useSelector(state => state?.CompanydataSlice)
    const userdetails = JSON.parse(localStorage.getItem("userdetails"));
    const slugPath = '';

    const { height } = InnerDimension();
    // console.log("height", height)
    const handleClick = (e) => {
        e.preventDefault()
        // console.log("email", email)
        if (emailsuccess == false) {
            const obj = {
                email: email
            }
            dispatch(EmailCheckAPI(obj))
        }
        else if (emailsuccess == true) {
            const obj = {
                email: email,
                password: password,
                event_id: eventList?.length == 1 ? eventList[0]?.event_id : EventIDSlice,


            }
            dispatch(LoginAPI(obj))

        }
        // setemailsuccess(true);
        // if(emailsuccess==true){
        //     navigate('/home')
        // }
    }
    useEffect(() => {
        if (eventList?.length > 0 && auth_token) {
        }
    }, [eventList])
    useEffect(() => {
        if (EmailCheckSlice?.value?.data?.length > 0) {
            setemailsuccess(true)
            seteventList(EmailCheckSlice?.value?.data)
            localStorage.setItem("roles", EmailCheckSlice?.value?.data[0]?.role)

        } else if (EmailCheckSlice?.error?.message) {
            localStorage.removeItem("roles")
            setOpen(true)
            setemailerrormsg(EmailCheckSlice?.error?.message)
            setTimeout(() => {
                dispatch(Clear_Email_Check())
            }, 3000)
        }
    }, [EmailCheckSlice])
    useEffect(() => {
        dispatch(Clear_Email_Check())
        dispatch(Clear_ForgetPassword_Details())
        if (!auth_token) {

            localStorage.removeItem("roles");
            localStorage.removeItem("eventItemId");
        }
    }, [])
    useEffect(() => {
        if (LoginCheckSlice?.value?.data?.auth_token) {
            dispatch(EventDetailsAPI(eventList?.length == 1 ? eventList[0]?.event_id : EventIDSlice))
            if (!eventItemId) {

                localStorage.setItem("eventItemId", eventList?.length == 1 ? eventList[0]?.event_id : EventIDSlice)
            }
            if (roles == "user") {

                navigate(`/${LoginCheckSlice?.value?.data?.event_slug}/home`)
            }
            else if (roles == "Staff") {

                dispatch(StaffVoucherAPI())
                navigate('/staff/voucher-list')
            }
            else if (roles == "admin") {


                // navigate('/admin/events/create-event/event-information')
                navigate('/admin/dashboard')
                // window.lo
            }
        }

        else if (LoginCheckSlice?.error?.message) {
            setOpen(true)
            setTimeout(() => {
                dispatch(Clear_Login_Check())
            }, 3000)
        }
    }, [LoginCheckSlice])
    useEffect(() => {
        if (ForgetPasswordSlice?.value?.message) {
            setforgetemailsucess(true);
            setTimeout(() => {
                dispatch(Clear_ForgetPassword_Details())
            }, 3000)
        }
    }, [ForgetPasswordSlice])

    // console.log("EmailCheckSlice", EmailCheckSlice)
    useEffect(() => {
        if (SetPasswordSlice?.value?.message) {
            setpasswordsuccessmessage(true)
            // navigate("/")
        }
        // console.log("SetPasswordSlice", SetPasswordSlice)
    }, [SetPasswordSlice])


    useEffect(() => {
        if (auth_token) {
            // if (LoginCheckSlice?.value?.data?.role == "user") {

            //     navigate(`/${LoginCheckSlice?.value?.data?.event_slug}/`)
            // } else {

                navigate(`/`)
            // }
        }
    })
    return (
        <>
            {
                CompanydataSlice?.loading && (
                    <Loader />
                )
            }
            {
                (ForgetPasswordSlice?.value?.message) && (
                    <Snacbar
                        open={Forgotemailsuccess}
                        setOpen={setforgetemailsucess}
                        messagetype={"success"}
                        message={(ForgetPasswordSlice?.value?.message)}
                    />

                )
            }
            {
                (SetPasswordSlice?.value?.message) && (
                    <Snacbar
                        open={passucessmessage}
                        setOpen={setpasswordsuccessmessage}
                        messagetype={"success"}
                        message={(SetPasswordSlice?.value?.message)}
                    />

                )
            }
            {
                (EmailCheckSlice?.error?.message || LoginCheckSlice?.error?.message) && (
                    <Snacbar
                        open={open}
                        setOpen={setOpen}
                        messagetype={"error"}
                        message={(EmailCheckSlice?.error?.message || LoginCheckSlice?.error?.message)}
                    />

                )
            }
            {
                (LoginCheckSlice?.loading || EmailCheckSlice?.loading) && (
                    <Loader />

                )
            }
            <div className={`${classes.Login} main pb-0`} style={{ backgroundImage: `url(${company_data?.banner})` }}>
                <div className={classes.header}>
                    <img src={company_data?.banner} alt="" className='cover' />
                </div>
                <div className={`${classes.loginWrapper} login-card`}>
                    <form onSubmit={(e) => handleClick(e)} >
                        <div className='title-rw'>
                            <div className='cl'>
                                <h2>Login</h2>
                            </div>
                            <div className='cl'>
                                <span onClick={() => {
                                    dispatch(Clear_Email_Check())
                                    localStorage.removeItem("roles")
                                    navigate('/forgot-password')
                                }} className={classes.BtnLink}>Forgot Password</span>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className={`${classes.Label} form-label`}>Username</label>
                            <Input type="text" placeholder="Username" className={`${classes.Input} form-input`} value={email} error={emailerrormsg != "" ? "danger" : ""} onChange={(e) => {
                                setemail(e.target.value)
                                setemailerrormsg("")
                                setemailsuccess(false)
                            }} />
                        </div>

                        {emailsuccess == true && (
                            <>
                                {
                                    eventList?.length > 1 && (
                                        <>
                                            <div className="form-group">
                                                <label className={`${classes.Label} form-label`}>Select Event</label>


                                                <SelectInput eventList={eventList} />

                                            </div>
                                        </>

                                    )
                                }


                                <div className="form-group">
                                    <label className={`${classes.Label} form-label`}>Password</label>
                                    <div className='input-group'>
                                        <Input id="pwd" type={showPassword ? "text" : "password"} placeholder="Password" className={`${classes.Input} form-input`} value={password} onChange={(e) => setpassword(e.target.value)} />
                                        <label htmlFor="pwd" className={`${classes.eyeIcon} icon`} onClick={() => setShowPassword(!showPassword)} >
                                            {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                        </label>
                                    </div>
                                </div>
                            </>
                        )}
                        <Buttonprimary
                            label={'Next'}
                            disabledcheck={true}
                            // handleClick={() => handleClick()}
                            type="submit"
                        />
                    </form>
                </div>
            </div>
        </>
    )
}
export default Login